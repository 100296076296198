import React, { useState, useRef, useEffect} from 'react';
import OverlayView from '../overlay';
import app from '../../app/app';
import * as Constants from '../../config/constants';
import { FormattedMessage, useIntl } from 'react-intl';


export default function PartyView (props:any) {
    const { formatMessage } = useIntl();
    const [hasChange, setChange] = useState<boolean | null>();
    const [childernHint, setChildernHint] = useState(parseInt(props.stepModel.inputs.get(Constants.NETMATCH_INPUT_PARTY_CHILDREN).get('inputValue'), 10) !== 0);

    // let interval: any = 0;


    const handleChildernHint = (value: number) => {
        if (value !== 0) {
            setChildernHint(true);
        } else {
            setChildernHint(false);
        }
    };

    const doAutoSubmit = () => {
        // clearInterval(interval);
        // interval = setTimeout(() => {
        if (app) {
            const showAutoSubmitError = () => {
                // @ts-ignore Backbone Event
                app.trigger('overlay:start', {
                    text: formatMessage({
                        id: 'general.save-error'
                    })
                });
            };
            if (app.apiSession) {
                const stepData = props.stepModel.getInputData();
                const stepId = props.stepModel.id;
                app.apiSession.submitStep(stepId, stepData, true)
                    .then(() => {
                        if (props.stepModel.hasSubmitErrors()) {
                            showAutoSubmitError();
                        }
                        setChange(!hasChange);
                        props.callback();
                        // @ts-ignore
                        app.triggerMethod('step:did:change');
                    })
                    .catch(showAutoSubmitError);
            }
        }
        // }, 1000);
    };

    const overlayRef = useRef();
    const onBabiesOverlay = () => {
        // @ts-ignore
        if (overlayRef.current && overlayRef.current.showModal === false) {
            // @ts-ignore
            overlayRef.current.handleOpenModal();

            if (app && app.trackController) {
                app.trackController.eventTracking({
                    action: 'Kabinenwahl_Personen',
                    label: 'Sie sind schwanger oder reisen mit Baby',
                    ga4clickLabel: 'content.icon.person-baby'
                });
            }
        }
    };

    return (
        <div className="party-view">
            {hasChange}
            <h2 className="api-step-headline">
                <FormattedMessage id="components.cabin.party.headline" />
            </h2>
            <div className="row">
                <div className="col-sm-6">
                    <InputNumberElement text={formatMessage({
                        id: 'components.cabin.party.adults'
                    })} inputModel={props.stepModel.inputs.get(Constants.NETMATCH_INPUT_PARTY_ADULTS)} submit={doAutoSubmit} haschange={hasChange}></InputNumberElement>
                </div>
                <div className="col-sm-6">
                    <InputNumberElement text={formatMessage({
                        id: 'components.cabin.party.children'
                    })} inputModel={props.stepModel.inputs.get(Constants.NETMATCH_INPUT_PARTY_CHILDREN)} submit={doAutoSubmit} haschange={hasChange} handleChildernHint={handleChildernHint}></InputNumberElement>
                </div>
            </div>

            <p className={`hint hint-children ${childernHint ? '' : 'hidden'}`}>
                <FormattedMessage id="components.cabin.party.hint" />
            </p>

            <p className="hint help-hint">
                <a onClick={onBabiesOverlay}>
                    <FormattedMessage id="components.cabin.party.hint-babies" />
                </a>
            </p>
            <OverlayView overlayId={'babies'} closeOnBgClick={true} hasBackButton={true} data={null} ref={overlayRef}></OverlayView>
        </div>
    );
}

export interface InputNumberProps {
    text: string;
    inputModel: any;
    submit: Function;
    haschange: any;
    handleChildernHint?: Function;
}
const InputNumberElement = (props: InputNumberProps) => {
    if (props.inputModel.get('inputValue') === '') {
        props.inputModel.setInputValue('0');
    }
    let inter: any;
    const [isRunning, setIsRunning] = useState(false);
    const [state, setState] = useState({
        inputValue: props.inputModel.get('inputValue'),
        valid: true,
        error: ''
    });

    const decrementValue = () => {
        if (+props.inputModel.getInputValue() !== 0) {
            changeValueBy(-1);

            if (app && app.trackController) {
                const value = +props.inputModel.getInputValue();
                app.trackController.eventTracking({
                    action: 'Kabinenwahl_Personen',
                    label: `Minus_${value < 0 ? 0 : value}_${text.replace('(2-14 Jahre)', '')}`,
                    ga4clickLabel: `content.icon.person-minus-${value < 0 ? 0 : value}-${text.replace('(2-14 Jahre)', '').trim()}`
                });
            }
        }
    };

    const incrementValue = () => {
        changeValueBy(1);

        if (app && app.trackController) {
            app.trackController.eventTracking({
                action: 'Kabinenwahl_Personen',
                label: `Plus_${+props.inputModel.getInputValue()}_${text.replace('(2-14 Jahre)', '')}`,
                ga4clickLabel: `content.icon.person-plus-${+props.inputModel.getInputValue()}-${text.replace('(2-14 Jahre)', '').trim()}`
            });
        }
    };

    const handleChange = (event:any) => {
        let value = parseInt(event.target.value, 10);
        if (isNaN(value)) {
            value = props.inputModel.get('id') === Constants.NETMATCH_INPUT_PARTY_ADULTS ? 1 : 0;
        }
        props.inputModel.setInputValue(`${value}`.trim());
        const check = props.inputModel.validateValue(value);

        if (check.valid === true) {
            props.submit();
        }

        setState({
            inputValue: value,
            valid: check.valid,
            error: check.error
        });

        if (props.handleChildernHint) {
            props.handleChildernHint(value);
        }

    };

    /**
     * Increments the input value
     *
     */
    const changeValueBy = (step: number) => {
        let currentValue = +props.inputModel.getInputValue();
        if (isNaN(currentValue)) {
            currentValue = 0;
        }
        let newValue = currentValue + step;
        const rangeValidator = props.inputModel.validators.findWhere({
            type: Constants.NETMATCH_VALIDATOR_TYPE_RANGE
        });
        if (rangeValidator) {
            const min = +rangeValidator.get('minimum') || 0;
            const max = +rangeValidator.get('maximum') || 0;
            newValue = Math.max(Math.min(max, newValue), min);
        }

        props.inputModel.setInputValue(`${newValue}`.trim());
        const check = props.inputModel.validateValue(newValue);
        if (props.handleChildernHint) {
            props.handleChildernHint(newValue);
        }
        setState({
            inputValue: newValue,
            valid: check.valid,
            error: check.error
        });

        // set autosubmit afer 1 sec
        if (!isRunning && check.valid === true) {
            inter = setTimeout(() => {
                clearInterval(inter);
                setIsRunning(false);
                const check = props.inputModel.validateValue(newValue);
                if (check.valid === true && props.inputModel.hasChangedInput()) {
                    props.submit();
                }
            }, 1000);
            setIsRunning(true);
        }
    };

    const { text, inputModel, haschange } = props;
    const data = inputModel.toJSON();

    useEffect(() => {
        setState({
            inputValue: inputModel.get('inputValue'),
            valid: true,
            error: ''
        });
    }, [haschange, inputModel]);


    const disabled = false;


    return (
        <div className={`form-group ${state.valid === false ? 'has-errors' : ''}`}>
            <label htmlFor={`input-element-${data.id}`}>{ text }</label>
            <div className="number-input-element">
                <a className={`button button-minus party-${data.id} ${disabled ? 'disabled' : ''}`} onClick={decrementValue}>
                    <span className="icon-magnifier-minus"></span>
                </a>
                <input type="number" pattern="\d*" value={state.inputValue} className="form-element is-light" id={`input-element-${data.id}`} disabled={disabled} onChange={handleChange}/>
                <a className={`button button-plus party-${data.id} ${disabled ? 'disabled' : ''}`} onClick={incrementValue}>
                    <span className="icon-magnifier-plus"></span>
                </a>
            </div>
            <div className="form-error">{state.error}</div>
        </div>
    );
};

