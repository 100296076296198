import React, {useState, useEffect, useRef } from 'react';
import app from '../../app/app';
import AlertView from '../overlay/alert';
import CabinSectionView from './cabin-section';
import TripAlternativeView from './trip-alternative';
import * as Constants from '../../config/constants';
import { FormattedMessage } from 'react-intl';
import { getIntl } from '../../intl';

type CabinViewProps = {
    initCabin: number,
    stepmodels: any;
    parent: any;
}

export default function CabinView (props:CabinViewProps) {
    const { formatMessage } = getIntl();
    const cabinCountStep = props.stepmodels.get(Constants.NETMATCH_STEP_CABINCOUNT);
    const cabinCountInput = cabinCountStep.inputs.get(Constants.NETMATCH_INPUT_CABINCOUNT);
    let alternativesTripsStep;
    let hasAlternativesTrips = false;

    const regexCabinType = new RegExp(Constants.NETMATCH_STEP_CABINTYPE);
    const cabinTypesStep = props.stepmodels.filter((apiStep:any) => apiStep.id.match(regexCabinType)) || [];
    const cabinTypesStepStatus = cabinTypesStep.map((step:any) => step.get('status'));

    console.log('NETMATCH_STEP_CABINTYPE', cabinTypesStepStatus);

    let cabinTapStatus:Array<string> = [];
    if (app && app.apiSession && app.apiSession.steps) {
        const apiSteps:any = app.apiSession.steps;
        alternativesTripsStep = apiSteps.get(Constants.NETMATCH_STEP_TRIP_ALTERNATIVES);
        if (alternativesTripsStep && alternativesTripsStep.get('netMatchAttributes').length > 1) {
            hasAlternativesTrips = true;
        }
        const regexCabinCategory = new RegExp(Constants.NETMATCH_STEP_CABINCATEGORY);
        const cabinCategoryStep = app.apiSession.steps.filterByRegEx(regexCabinCategory) || [];
        const cabinCategoryStepStatus = cabinCategoryStep.map((step:any) => step.get('status'));
        console.log('NETMATCH_STEP_CABINCATEGORY', cabinCategoryStepStatus);

        const regexCabinDeck = new RegExp(Constants.NETMATCH_STEP_CABINDECK);
        const cabinDeckStep = app.apiSession.steps.filterByRegEx(regexCabinDeck) || [];
        const cabinDeckStepStatus = cabinDeckStep.map((step:any) => step.get('status'));
        console.log('NETMATCH_STEP_CABINDECK', cabinDeckStepStatus);

        const regexCabinNumber = new RegExp(Constants.NETMATCH_STEP_CABINNUMBER);
        const cabinNumberStep = app.apiSession.steps.filterByRegEx(regexCabinNumber) || [];
        const cabinNumberStepStatus = cabinNumberStep.map((step:any) => step.get('status'));
        console.log('NETMATCH_STEP_CABINNUMBER', cabinNumberStepStatus);

        const regexCabinFlow = new RegExp(Constants.NETMATCH_STEP_CABINFLOW);
        const cabinFlowStep = app.apiSession.steps.filterByRegEx(regexCabinFlow) || [];
        const cabinFlowStepStatus = cabinFlowStep.map((step:any) => step.get('status'));
        console.log('NETMATCH_STEP_CABINFLOW', cabinFlowStepStatus);

        const regexCabinVip = new RegExp(Constants.NETMATCH_STEP_CABINVIP);
        const cabinVipStep = app.apiSession.steps.filterByRegEx(regexCabinVip) || [];
        const cabinVipStepStatus = cabinVipStep.map((step:any) => step.get('status'));
        console.log('NETMATCH_STEP_CABINVIP', cabinVipStepStatus);


        // cabinTapStatus
        const cabinStepsTapStatus:Array<any> = [];
        const count = parseInt(cabinCountInput.get('inputValue'), 10);
        for (let i = 0; i < count; i++) {
            cabinStepsTapStatus[i] = [];
            cabinStepsTapStatus[i].push(cabinTypesStepStatus[i]);
            cabinStepsTapStatus[i].push(cabinCategoryStepStatus[i]);
            cabinStepsTapStatus[i].push(cabinDeckStepStatus[i]);
            cabinStepsTapStatus[i].push(cabinNumberStepStatus[i]);
            cabinStepsTapStatus[i].push(cabinFlowStepStatus[i]);
            cabinStepsTapStatus[i].push(cabinVipStepStatus[i]);
        }
        cabinTapStatus = cabinStepsTapStatus.map((item:any) => item.join(','));
    }

    const [count, setCount] = useState(parseInt(cabinCountInput.get('inputValue'), 10));
    const [select, setSelect] = useState(props.initCabin);

    const deleteCabin = () => {
        if (app && app.trackController) {
            app.trackController.eventTracking({
                action: 'Kabinenwahl_Anzahl',
                label: 'Kabine löschen',
                ga4clickLabel: `content.button.kabine-loeschen-${select}`
            });
        }
        if (app.apiSession) {
            const stepData = [{
                'id': `cabins/cabin-${select}`
            }];
            app.apiSession.deleteStepsIfYouKnowWhatYouDo(stepData, true).then(() => {
                const isSelected:number = select;
                const newSelected = (isSelected !== 1) ? ((isSelected - 1)) : 1;
                setSelect(newSelected);
                setCount(parseInt(cabinCountInput.get('inputValue'), 10));
            });
        }
    };

    const decreaseCabin = () => {
        if (app && app.trackController) {
            app.trackController.eventTracking({
                action: 'Kabinenwahl_Anzahl',
                label: 'Kabine hinzufügen',
                ga4clickLabel: `content.button.kabine-hinzufuege-${count + 1}`
            });
        }
        handleCabinCount(1);
    };

    const handleCabinCount = (set:number) => {
        const newValue = (count + set);
        const check = cabinCountInput.validateValue(newValue);
        if (check.valid === true) {
            cabinCountInput.setInputValue(`${newValue}`.trim());
            const stepData = cabinCountStep.getInputData();
            const stepId = cabinCountStep.id;
            if (app.apiSession) {
                app.apiSession.submitStep(stepId, stepData, true).then(() => {
                    setCount(newValue);
                    setSelect(newValue);
                }).catch(() => {
                    // @ts-ignore Backbone Event
                    app.trigger('overlay:start', {
                        text: Constants.NM_SAVE_ERROR_TXT
                    });
                });
            }
        }
    };

    const cabinBlockView = (count:number) => {
        const part = [];
        for (let i = 1; i <= count; i++) {
            // Create the parent and add the children
            if (select === i) {
                part.push(<CabinSectionView key={i} stepmodels={props.stepmodels} cabinIndex={i} callback={triggerChange} />);
            }
        }
        return part;
    };

    const cabinCountIndicatorView = (count:number) => {
        const part = [];
        const showTestMAster = (count <= 2);
        for (let i = 1; i <= count; i++) {
            const is = (select === i) ? 'is-select' : '';
            let isNotCompleted = (!is && cabinTapStatus[(i - 1)] && cabinTapStatus[(i - 1)].indexOf(Constants.NETMATCH_STEP_STATUS_OPEN) !== -1) ? 'is-not-completed' : '';
            // edgeCaseCabinDeckBlocked
            if (!is && cabinTapStatus[(i - 1)] && cabinTapStatus[(i - 1)].indexOf(Constants.NETMATCH_STEP_STATUS_BLOCKED) !== -1) {
                isNotCompleted = 'is-not-completed';
            }
            console.log(isNotCompleted);
            const canIncrease = (select === i && (count !== 1));

            let showTest = true;
            if (select !== i && !showTestMAster) {
                showTest = false;
            }

            part.push(<div key={i} onClick={() => {
                if (!is) {
                    setSelect(i);
                    if (app && app.trackController) {
                        app.trackController.eventTracking({
                            action: 'Kabinenwahl_Anzahl',
                            label: `Kabine_${i}`,
                            ga4clickLabel: `content.button.kabine-${i}`
                        });
                    }
                }
            }} className={`cabin-c ${is} ${isNotCompleted}`}>
                <span><span className={`${showTest ? '' : 'none-text'}`}><FormattedMessage id="general.cabin" /></span> {i}</span>
                {(canIncrease) ? (<span className="link icon-close-small increase-normal" onClick={onOverlay} ></span>) : ''}
            </div>);
            if (canIncrease) {
                part.push(<div key={`in_${i}`} className="decrease-small">
                    <div className="link" onClick={onOverlay}><span><FormattedMessage id="general.delete" /></span><span className="icon-close-small"></span></div>
                </div>);
            }
        }
        if (count !== 5) {
            part.push(<div key='add' onClick={decreaseCabin} className="cabin-c link">
                {(count <= 1) ? (<span className="add-cabin"><FormattedMessage id="components.cabin.index.add-cabin" /></span>) : ''}
                <span className="icon-magnifier-plus"></span>
            </div>);
        }
        return part;
    };

    const deleteData = {
        headline: formatMessage({ id: 'components.cabin.index.deleteData.headline' }, {
            select
        }),
        text: formatMessage({ id: 'components.cabin.index.deleteData.text' }),
        confirmClass: 'right',
        confirmCallback: deleteCabin,
        confirmButtonText: formatMessage({ id: 'components.cabin.index.deleteData.confirmButtonText' }),
        closeButtonText: formatMessage({ id: 'components.cabin.index.deleteData.closeButtonText' }),
        closable: true,
        closeOnBgClick: true
    };

    const cabinNavigationView = (count:number) => {
        const part = [];
        if (count !== 1) {
            for (let i = 1; i <= count; i++) {
                // Create the parent and add the children
                if (select === i) {
                    part.push(<div key={i} className="c-navigation">
                        {(i !== 1)
                            ? (<div className="c-n-right link" onClick={() => {
                                setSelect(i - 1);
                                window.scrollTo(100, 0);
                            }} ><span className="icon-small-arrow-left"></span><FormattedMessage id="components.cabin.index.navigationView.action" values={{idx: i - 1}} /></div>)
                            : (<div></div>) }
                        {(i !== count)
                            ? (<div className="c-n-left link" onClick={() => {
                                setSelect(i + 1);
                                window.scrollTo(100, 0);
                            }}><FormattedMessage id="components.cabin.index.navigationView.action" values={{idx: i + 1}} /><span className="icon-small-arrow-right"></span></div>)
                            : ''}
                    </div>);
                }
            }
        }
        return part;
    };

    useEffect(() => {
        const needToScroll = document.querySelector('body');
        if (needToScroll && needToScroll.className.indexOf('navigation-fixed') !== -1) {
            // @ts-ignore Backbone Event
            // app.trigger('scrollToFromReact', '.page-cabin');
            // this has no Safari support (01.08.2019)
            /* try {
                // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            } catch (error) {
                // just a fallback for older browsers
                window.scrollTo(0, 0);
            } */
        }
    }, [select]);

    const overlayRef = useRef();
    const onOverlay = () => {
        // @ts-ignore
        if (overlayRef.current && overlayRef.current.showModal === false) {
            // @ts-ignore
            overlayRef.current.show();
        }
    };
    const [hasChange, setChange] = useState<boolean | null>();

    const triggerChange = () => {
        setChange(!hasChange);
    };

    // <OverlayView overlayId={'delete-cabin'} closeOnBgClick={true} hasBackButton={false} data={{'cabin': {select}}} callback={increaseCabin} ref={overlayRef}></OverlayView>
    //  <TripAlternativeView model={app.apiSession.steps.get(Constants.NETMATCH_STEP_TRIP_ALTERNATIVES)}/>
    return (
        <div className="cabin-main">
            {hasChange}
            {hasAlternativesTrips ?
                <TripAlternativeView model={alternativesTripsStep} callback={triggerChange}/>
                : ''}

            <div className="cabin-count-choice">
                {cabinCountIndicatorView(count)}
            </div>
            <div className="cabin-part">
                {cabinBlockView(count)}
                {cabinNavigationView(count)}
            </div>
            <AlertView data={deleteData} ref={overlayRef}></AlertView>
        </div>
    );

}
