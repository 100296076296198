import React from 'react';
import { Root } from 'react-dom/client';
import { RawIntlProvider } from 'react-intl';
import { getIntlShapeReact } from './intl';
import { renderToStaticMarkup } from 'react-dom/server';

/**
 * Prepare a React component with our providers
 * @param component
 * @returns
 */
const prepareComponent = (component: React.ReactNode) => {
    return (
        <RawIntlProvider value={getIntlShapeReact()}>
            {component}
        </RawIntlProvider>
    );
};

/**
 * Render a React component to the DOM in the supplied container with our providers
 * @param {ReactDom.Root} root
 * @param {React.ReactNode} component
 */
export const renderReact = (root: Root, component: React.ReactNode) => {
    root.render(prepareComponent(component));
};

/**
 * Render a React component to a string with our providers
 * @param component
 * @returns
 */
export const renderReactToString = (component: React.ReactNode) => {
    return renderToStaticMarkup(prepareComponent(component));
};
