import React from 'react';
import app from '../../app/app';

export default function B2BView () {

    const setCookie = (nummer:number) => {
        let domainStr = '';
        // TUICIBE-563: because local development runs under http://tuic-ibe/
        if (window.location.hostname.indexOf('.') !== -1) {
            domainStr = `domain=.${window.location.hostname.split('.').slice(-2).join('.')}`;
        }
        document.cookie = `AgenturNummer=${nummer}; ${domainStr}`;
        // @ts-ignore Backbone Event
        app.trigger('alert:start', {
            text: `Cookie AgenturNummer=${nummer}`,
            confirmButtonText: 'OK',
            closable: false
        });
    };
    return (
        <div className="b2b-examples">
            <div className="link" onClick={() => { setCookie(1); }}>Agenturnummer 1, PLZ: 20537, Direktinkasso</div>
            <div className="link" onClick={() => { setCookie(16577); }}>Agenturnummer 16577, PLZ: 1014, Agenturinkasso Österreich</div>
            <div className="link" onClick={() => { setCookie(19598); }}>Agenturnummer 19598, PLZ: 8021, Agenturinkasso Schweiz</div>
        </div>
    );
}
