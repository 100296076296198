import ApiInputTextView from './text';
// import fecha from '../../../util/fecha-german';


export default class ApiInputDateView extends ApiInputTextView {
    /**
     * Overwrite view events
     */

    events () {
        const superEvents = super.events.apply(this);
        return {
            ...superEvents,
            [`keyup #${this.model.domId}`]: 'formatDate'
        };
    }

    formatDate (e) {
        const $input = this.$(e.currentTarget);
        const charCode = e.keyCode;
        const value = $input.val();
        if (!value
            || !/^[0-9.]*$/.test(value) // only nums & dots
            || charCode === 37 // arrow left
            || charCode === 39 // arrow right
            || charCode === 46 // delete
            || charCode === 8 // backspace
            || charCode === 9 // tab
        ) {
            return;
        }
        const newValue = value.split('.').join('');
        if (newValue.length > 8) return;
        const dottedValue = [...newValue].reduce((stack, char, index) => {
            return `${stack}${char}${index === 1 || index === 3 ? '.' : ''}`;
        }, '');
        $input.val(dottedValue);
    }

    /**
     * Convertes any previously submitted value from odata string format into german date string
     */
    serializeData () {
        const data = super.serializeData();

        // removed because the reset of the inputValue is wrong! This do not allow any change of this value.
        /* if (data.resultValue) {
            try {
                // TODO: I expected the resultValue date to be in odata format, but it isn't
                // next line does the odata conversion if needed
                // const germanDate = fecha.format(fecha.parse(data.resultValue, 'edmDateTimeOffset'), 'germanShortDate');
                const germanDate = fecha.format(new Date(data.resultValue), 'germanShortDate');
                data.resultValue = germanDate;
                data.inputValue = germanDate;
            } catch (e) {
                // nothing to do here
            }
        } */

        return data;
    }
}
