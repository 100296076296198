import _ from 'underscore';
import $ from 'jquery';
import app from '../../../app/app';
import BaseItem from '../../../base/view/item';
import BaseModel from '../../../base/model/base';
import OverlayFactory from '../../../item/overlay/factory';
import StringUtil from '../../../util/string';
import templateWebpack from '../../../../tpl/item/travel-flight-option/layout.hbs';
import * as Constants from '../../../config/constants';
import {getIntl} from '../../../intl';


export default class TravelFlightOptionView extends BaseItem {
    constructor (options = {}) {
        super({
            key: 'travel-flight-option',
            template: templateWebpack,
            ...options
        });
        this.apiInputFlightId = this.model.get('apiInputModel');
        this.apiStepFlight = this.model.get('apiInputModel').collection.parentModel;
        // TUICUNIT-2534: new Train Options
        this.apiInputTrainToPlaneUpgrade = this.apiInputFlightId.collection.get(Constants.NETMATCH_INPUT_FLIGHT_TRAINTOPLANECLASSUPGRADE);
        this.apiInputTrainToPlaneUpgradeOptions = this.apiInputFlightId.collection.get(Constants.NETMATCH_INPUT_FLIGHT_TRAINTOPLANEUPGRADEOPTIONID);

        this.trainToPlaneLegacy = this.apiInputTrainToPlaneUpgradeOptions ? !this.apiInputTrainToPlaneUpgradeOptions.getOptions() : false;
    }


    /**
     * View events
     */
    events () {
        return {
            'click .js-select-flight': 'onSelectFlight',
            'click .js-flight-details': 'onShowFlightDetails',
            'change .js-select-flight-class': 'onChangeFlightClass',
            'change .js-train-upgrade-radio': 'onChangeTrainToPlane',
            'click .js-overlay-flight-train': 'onShowTrainToFlight'

        };
    }


    templateContext () {
        const {formatMessage} = getIntl();
        const flight = this.model.toJSON();

        let shipBoundTransportType;
        if (!flight.isShipbound) {
            try {
                shipBoundTransportType = app.apiSession.steps.get(Constants.NETMATCH_STEP_SHIPBOUNDTRANSPORTTYPE).inputs.get(Constants.NETMATCH_INPUT_TRANSPORTTYPE_TYPE).getInputValue();
            } catch (e) {
                shipBoundTransportType = '';
            }
        }
        const shipBoundTransportTypeIsFlight = shipBoundTransportType === Constants.NETMATCH_TRANSPORT_OPTION_FLIGHT;
        const displayText = app.contentApi.messages.prepareMessages('transport');
        const relevantText = displayText ? displayText.trainToPlanePurHint || null : null;

        return {
            ...this.prepareFlightDetails(flight.flightClasses[flight.displayedClassIndex]),
            shipBoundTransportTypeIsFlight,
            soleAvailableClass: flight.flightClasses.length === 1 ? flight.flightClasses[0].class : '',
            badgeText: formatMessage({
                id: 'js.item.travel-flight-option.view.item.badge'
            }),
            priceInfo: formatMessage({
                id: flight.isShipbound ? 'js.item.travel-flight-option.view.item.priceInfo.to' : (shipBoundTransportTypeIsFlight ? 'js.item.travel-flight-option.view.item.priceInfo.back-to' : 'js.item.travel-flight-option.view.item.priceInfo.back')
            }),
            flightClasses: flight.flightClasses.map((flightClass, index) => {
                return {
                    ...flightClass,
                    displayed: index === flight.displayedClassIndex
                };
            }), // options from mainView item/api-input/view/flight-options
            hasMoreFlights: this.options.hasMoreFlights,
            showMoreFlights: this.options.showMoreFlights,
            moreFlightsButtonText: this.options.moreFlightsButtonText,
            trainToPlanePurHint: relevantText,
            firstClassNumberLocalized: StringUtil.formatNumberWithOrdinalSuffix('1')
        };
    }


    prepareFlightDetails (flight) {
        const departure = _.first(flight.flightSegments);
        const departureAirport = app.contentApi.airports.get(departure.departureAirportCode) || {};
        const departureAirline = app.contentApi.airlines.get(departure.airlineCode) || {};

        const arrival = _.last(flight.flightSegments);
        const arrivalAirport = app.contentApi.airports.get(arrival.arrivalAirportCode) || {};
        const arrivalAirline = app.contentApi.airlines.get(arrival.airlineCode) || {};

        const layoverStopps = this.getLayoverStopps(flight);

        return {
            id: flight.id,
            price: flight.cruiseAndFlightPrice,
            isPlaceholderFlight: arrival.isVirtual || departure.isVirtual,

            departureAirlineCode: departure.airlineCode,
            departureAirlineLogo: departureAirline.logoURL,
            departureAirport: departureAirport.name,
            departureAirportCode: departure.departureAirportCode,
            departureTime: StringUtil.formatTime(departure.departureTime),
            departureDate: new Date(departure.departureDate),
            arrivalAirlineCode: arrival.airlineCode,
            arrivalAirlineLogo: arrivalAirline.logoURL,
            arrivalAirport: arrivalAirport.name,
            arrivalAirportCode: arrival.arrivalAirportCode,
            arrivalTime: StringUtil.formatTime(arrival.arrivalTime),
            arrivalDate: new Date(arrival.arrivalDate),

            trainToPlaneBlockedOnline: !(flight.trainToPlaneBlockedOnline === false),
            trainToPlaneClassUpgradeAvailable: flight.trainToPlaneClassUpgradeAvailable,
            trainToPlaneDefaultClassIncluded: flight.trainToPlaneDefaultClassIncluded,
            trainToPlaneUpgradePrice: flight.trainToPlaneUpgradePrice,
            trainToPlaneUpgradeSelected: !!this.apiInputTrainToPlaneUpgrade.getInputValue(),
            trainToPlaneLegacy: this.trainToPlaneLegacy,
            trainToPlaneUpgradeOptions: this.apiInputTrainToPlaneUpgradeOptions ? this.apiInputTrainToPlaneUpgradeOptions.getOptions() : false,
            layoverStoppsAirports: layoverStopps.join(','),
            layoverStoppsText: getIntl().formatMessage({
                id: 'general.stop'
            }, {
                amount: layoverStopps.length
            })
        };
    }


    getLayoverStopps (flight) {
        return flight.flightSegments.reduce((stopps, flightSegment, index) => {
            if (index === 0) {
                return stopps;
            }
            const airport = app.contentApi.airports.get(flightSegment.departureAirportCode) ? app.contentApi.airports.get(flightSegment.departureAirportCode).name : flightSegment.departureAirportCode;
            stopps.push(airport);
            return stopps;
        }, []);
    }

    submitStepByInputFlightOptions () {
        if (this.apiStepFlight.hasInputErrors()) {
            return;
        }
        const stepsToSubmit = [];
        let stepData = {};
        const data = this.apiStepFlight.getInputData();

        // TUICIBE-374: if parentModel (Airport) not Completed, then set Airport with Default API Value, to prevent NetMatch Reset Work'a'round
        const parentModel = this.apiStepFlight.collection.get(Constants.NETMATCH_STEP_HOMEBOUNDAIRPORT);
        if (parentModel) {
            if (parentModel.isCompletedWithDefault() || parentModel.isCompletedAutomatically()) {
                stepData = {
                    'id': parentModel.id,
                    'content': {
                        [Constants.NETMATCH_INPUT_AIRPORT_AIRPORTCODE]: parentModel.getInputData()[Constants.NETMATCH_INPUT_AIRPORT_AIRPORTCODE]
                    }
                };
                stepsToSubmit.push(stepData);
            }
        }
        console.log('submitStepByInputFlightOptions ---- ', data);
        // TUICUNIT-2534: new Train Options
        if (this.trainToPlaneLegacy === false) {
            stepData = {
                'id': this.apiStepFlight.id,
                'content': {
                    [Constants.NETMATCH_INPUT_FLIGHT_FLIGHTID]: data[Constants.NETMATCH_INPUT_FLIGHT_FLIGHTID],
                    [Constants.NETMATCH_INPUT_FLIGHT_TRAINTOPLANEUPGRADEOPTIONID]: data[Constants.NETMATCH_INPUT_FLIGHT_TRAINTOPLANEUPGRADEOPTIONID]
                }
            };
        } else {
            stepData = {
                'id': this.apiStepFlight.id,
                'content': {
                    [Constants.NETMATCH_INPUT_FLIGHT_FLIGHTID]: data[Constants.NETMATCH_INPUT_FLIGHT_FLIGHTID],
                    [Constants.NETMATCH_INPUT_FLIGHT_TRAINTOPLANECLASSUPGRADE]: data[Constants.NETMATCH_INPUT_FLIGHT_TRAINTOPLANECLASSUPGRADE]
                }
            };
        }

        const step = app.apiSession.steps.get(stepData.id);
        if (step && step.validateData(stepData).valid) {
            stepsToSubmit.push(stepData);
        }

        app.apiSession.submitSteps(stepsToSubmit, true);

        app.trigger('scrollTo', $(`.api-step-${this.apiStepFlight.sanitizedId}`));
    }


    selectFlight (flightId) {
        this.apiInputFlightId.setInputValue(flightId);
        this.submitStepByInputFlightOptions();
    }


    onSelectFlight () {
        const flightId = this.model.get('flightClasses')[this.model.get('displayedClassIndex')].id;
        this.selectFlight(flightId);
        this.trackEvent(`${this.model.get('isShipbound') === true ? 'Hinflug' : 'Rückflug'}|Auswahl`);
    }


    onChangeTrainToPlane (e) {
        const toSubmit = e.currentTarget.value !== 'none' ? e.currentTarget.value : null;
        this.apiInputTrainToPlaneUpgradeOptions.setInputValue(toSubmit);
        this.submitStepByInputFlightOptions();
    }


    onChangeFlightClass (e) {
        this.model.set('displayedClassIndex', e.currentTarget.selectedIndex);
        this.render();
        if (this.model.get('selected')) {
            const selectedClass = this.model.get('flightClasses')[this.model.get('displayedClassIndex')];
            this.selectFlight(selectedClass.id);
            this.trackEvent(`${selectedClass.class}|${this.model.get('isShipbound') === true ? 'Hinflug' : 'Rückflug'}`);
        }
    }


    onShowFlightDetails () {
        const {formatMessage} = getIntl();
        const flightModel = this.model.toJSON();
        const flight = flightModel.flightClasses[flightModel.displayedClassIndex];

        // special overlay for placeholder flights
        if (flight.flightSegments[0].isVirtual) {
            OverlayFactory.show('travel-flight-without-details');
            return;
        }

        const flightSegments = flight.flightSegments.map((segment, index) => {
            const adultBags = (segment.adultBaggageAllowanceBags && segment.adultBaggageAllowanceBags !== null) ? segment.adultBaggageAllowanceBags : 1;
            const childBags = (segment.childBaggageAllowanceBags && segment.childBaggageAllowanceBags !== null) ? segment.childBaggageAllowanceBags : 1;

            const adultBagsText = formatMessage({
                id: 'js.item.travel-flight-option.view.item.bags'
            }, {
                amount: adultBags,
                weight: segment.adultBaggageAllowanceKilograms
            });
            const childBagsText = formatMessage({
                id: 'js.item.travel-flight-option.view.item.bags'
            }, {
                amount: childBags,
                weight: segment.childBaggageAllowanceKilograms
            });

            return {
                headline: flight.flightSegments.length <= 1 ? formatMessage({id: 'js.item.travel-flight-option.view.item.flightDirect'}) : formatMessage({id: 'js.item.travel-flight-option.view.item.flightNo'}, {idx: index + 1}),
                airline: segment.airlineCode && app.contentApi.airlines.get(segment.airlineCode) ? app.contentApi.airlines.get(segment.airlineCode).name : 'Information folgt',
                flightNo: segment.flightNo !== 'XXZZ' ? segment.flightNo : formatMessage({id: 'js.item.travel-flight-option.view.item.flightNoPending'}),
                class: segment.class || flight.class,
                departureTime: segment.departureTime ? StringUtil.formatTime(segment.departureTime) : null,
                departureDate: new Date(segment.departureDate),
                departureAirport: app.contentApi.airports.get(segment.departureAirportCode),
                arrivalTime: (segment.arrivalTime !== null) ? (StringUtil.formatTime(segment.arrivalTime)) : (null),
                arrivalDate: new Date(segment.arrivalDate),
                arrivalAirport: app.contentApi.airports.get(segment.arrivalAirportCode),
                adultBagsText,
                childBagsText
            };
        });

        const flightDetails = {
            flight,
            flightSegments
        };

        OverlayFactory.show('travel-flight', {
            model: new BaseModel(flightDetails)
        });

        this.trackEvent(`Details|${this.model.get('isShipbound') === true ? 'Hinflug' : 'Rückflug'}`);
    }


    onShowTrainToFlight (e) {
        e.preventDefault();
        OverlayFactory.show('travel-flight-train');
    }


    trackEvent (label) {
        app.trackController.eventTracking({
            action: 'Flug',
            label,
            ga4clickLabel: `content.icon.flug-${label.replace('|', '-')}`
        });
    }
}
