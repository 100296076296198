import React from 'react';
import { createRoot } from 'react-dom/client';
import ChangeView from '../../components/examples/change';

import BasePage from '../../base/view/page';
import templateWebpack from '../../../tpl/page/examples/view.hbs';
import { renderReact } from '../../react';

export default class ChangePage extends BasePage {
    constructor (options) {
        super(Object.assign({
            key: 'examples',
            templateWebpack
        }, options));
    }

    onRender () {
        // react region examples b2b
        if (this.$el.find('.rre-change')[0]) {
            const container = this.$el.find('.rre-change')[0];
            const root = createRoot(container);
            renderReact(root, <ChangeView />);
        }
    }
}
