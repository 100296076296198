import BaseCollection from '../../../../base/collection/base';

export default class BookingCodeModel extends BaseCollection {
    findCode () {
        const codes = this.toJSON();
        let keys = null;
        let findCode = '';
        if (codes) {
            keys = Object.keys(codes);
        }
        if (Array.isArray(keys) && keys.length !== 0) {
            findCode = {
                count: [],
                fromDate: [],
                occupancy: [],
                participantAssignment: [],
                serviceCode: [],
                serviceTypeCode: [],
                toDate: [],
                typeCode: []
            };

            keys.forEach((key) => {
                // TUICIBE-109
                if (codes[key].typeCode === 'K') {
                    findCode.count[key] = codes[key].count;
                    findCode.fromDate[key] = codes[key].fromDate;
                    findCode.occupancy[key] = codes[key].occupancy;
                    findCode.participantAssignment[key] = codes[key].participantAssignment;
                    findCode.serviceCode[key] = codes[key].serviceCode;
                    findCode.serviceTypeCode[key] = codes[key].serviceTypeCode;
                    findCode.toDate[key] = codes[key].toDate;
                    findCode.typeCode[key] = codes[key].typeCode;
                }
            });
        }

        return findCode;
    }
}
