import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function TUICardOverlay () {
    return (
        <div className="overlay-insurance-tuicard">
            <h3 className="overlay-headline">
                <FormattedMessage id="components.overlay.tuicard.headline" />
            </h3>
            <p>
                <FormattedMessage id="components.overlay.tuicard.text" values={{
                    a: (chunks: React.ReactNode) => <a href="http://www.tui-card.de" className="underline" rel="noopener noreferrer">{chunks}</a>
                }} />
            </p>

        </div>
    );
}
