import React, {useMemo, useState} from 'react';


import app from '../../app/app';

import * as Constants from '../../config/constants';
import {getIntl} from '../../intl';

/**
 * approval checkbox Overview
 */
export default function AcknowledgedOverviewView () {
    const {formatMessage} = getIntl();

    const heading = useMemo(() => formatMessage({id: 'components.payment.debit.acknowledged.heading'}), [formatMessage]);

    const contentAPITexte: any = app?.contentApi?.messages.prepareMessages('payment');

    const steps: any = (app.apiSession && app.apiSession.steps) ? app.apiSession.steps : null;
    const payment = (steps) ? steps.get(Constants.NETMATCH_STEP_PAYMENT) : {};
    const acknowledged = (payment) ? payment.inputs.get(Constants.NETMATCH_INPUT_PAYMENT_BANK_TRANFER_ACKNOWLEDGED) : {};

    const isB2B = (app.apiSession && app.apiSession.bookingStatus) ? app.apiSession && app.apiSession.bookingStatus.agency.isB2B() : false;
    const b2bSuffix = isB2B ? '.b2b' : '.b2c';

    const disabled = payment.isBlocked() || payment.isNotApplicable();

    acknowledged.setInputValue(acknowledged.attributes.inputValue);

    return (
        <div id={'sepa-acknowledged'}>
            <div className={'sepa-acknowledged-heading'}>{heading}:</div>
            <CheckboxElement part={acknowledged}
                label={contentAPITexte['debit.personal-data-processing-info' + b2bSuffix]}
                disabled={disabled}
                default={false}/>
        </div>
    );
}

type CheckboxElementProps = {
    part: any;
    disabled: boolean;
    default?: boolean;
    label?: string;
};
const CheckboxElement = (props: CheckboxElementProps) => {
    const {part, disabled, label} = props;
    const partPain = part.toJSON();
    const [select, setSelect] = useState({
        value: props.default ? props.default : partPain.inputValue,
        hasError: false,
        error: ''
    });

    const changeValue = (value: boolean) => {
        const {hasError, details} = checkInput(partPain.validators, value);

        if (hasError) {
            setSelect({
                value: value,
                hasError: true,
                error: details
            });
            part.setInputValue(false);
        } else {
            setSelect({
                value: value,
                hasError: false,
                error: ''
            });
            part.setInputValue(value);
            // callback(value);
        }
        // @ts-ignore Backbone Event
        app.trigger('react:change:inputValue:no:autosubmit');
    };

    return (
        <div className={`form-group${select.hasError ? ' has-errors' : ''}`}>
            <div className="checkbox-element ">
                <input type="checkbox" id={part.id} disabled={disabled} checked={select.value}
                    onChange={() => {
                        changeValue(!select.value);
                    }}/>
                <label htmlFor={part.id} dangerouslySetInnerHTML={{__html: label ?? partPain.label}}/>
            </div>
            <div className="form-error">{select.error}</div>
        </div>
    );
};


type ValidatorType = {
    type: string;
    warning: string;
    matchValue?: any;
}
const checkInput = (validators: ValidatorType[], value: boolean) => {
    let hasError = false;
    let details = '';
    validators.every((item) => {
        if (item.type === Constants.NETMATCH_VALIDATOR_TYPE_REQUIRED && value !== true) {
            hasError = true;
            details = item.warning;
        }
        if (hasError === false && item.type === Constants.NETMATCH_VALIDATOR_TYPE_EQUALS) {
            if (item.matchValue !== value) {
                hasError = true;
                details = item.warning;
            }
        }
        return true;
    });

    return {hasError, details};
};
