import ibeApiConfig from '../../../config/ibe-api';
import { data } from './ParseResponse';
import { session } from './ParseSession';
import 'whatwg-fetch'; // ie11 polyfill

// {'id': 'string', 'content': {sessionId}}
const NewSession = {
    fetchData () {
        return new Promise((resolve, reject) => {
            fetch(`${ibeApiConfig.newSessionURL}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify([])
            }).then(res => {
                resolve({'id': res.headers.get('location')});
            }).catch(err => {
                reject(err);
            });
        });
    }
};

const Session = {
    fetchData (sessionId: string) {
        return new Promise((resolve, reject) => {
            fetch(`${ibeApiConfig.sessionURL.replace('{apiSession}', sessionId)}`)
                .then((res) => {
                    if (!res.ok) {
                        reject(res);
                    } else {
                        res.json()
                            .then(value => {
                                resolve(session.parse(value));
                            })
                            .catch(error => {
                                reject(error);
                            });
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
};

const Steps = {
    fetchData (sessionId: string) {
        return new Promise((resolve, reject) => {
            fetch(`${ibeApiConfig.stepsURL.replace('{apiSession}', sessionId)}`)
                .then((res) => {
                    if (!res.ok) {
                        reject(res);
                    } else {
                        res.json()
                            .then(value => {
                                resolve(data.parseSteps(value));
                            })
                            .catch(error => {
                                reject(error);
                            });
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
};

interface SubmitsData {
    id: string;
    content?: Object;
}
const Submits = {
    setData (data: Array<SubmitsData>, sessionId: string) {
        const toDelete = data.filter(item => !item.content);
        const toPost = data.filter(item => item.content);
        console.log(toPost, toDelete);
        return new Promise((resolve, reject) => {
            /* if (toDelete) {
                this.deleteData(toDelete, sessionId);
            } */
            this.postData(data, sessionId)
                .then(res => {
                    resolve(res);
                }).catch(err => {
                    reject(err);
                });
        });
    },

    postData (data: Array<SubmitsData>, sessionId: string) {
        return new Promise((resolve, reject) => {
            fetch(`${ibeApiConfig.multipleSubmitsURL.replace('{apiSession}', sessionId)}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            });
        });
    },

    deleteData (data: Array<SubmitsData>, sessionId: string) {
        console.log(data);
        return new Promise((resolve, reject) => {
            data.forEach(item => {
                fetch(`${ibeApiConfig.deleteRootURL.replace('{apiSession}', sessionId)}/${item.id}`, {
                    method: 'DELETE'
                }).then(res => {
                    resolve(res);
                }).catch(err => {
                    reject(err);
                });
            });
        });
    }
};

/*
const Submits = {
    setData (data: Array<SubmitsData>, sessionId: string) {
        return new Promise((resolve, reject) => {
            fetch(`${ibeApiConfig.multipleSubmitsURL.replace('{apiSession}', sessionId)}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => {
                resolve(res);
            }).catch(err => {
                reject(err);
            });
        });
    }
}; */
export {NewSession, Session, Steps, Submits};
