import BaseModel from '../../../../base/model/base';
import {getIntl} from '../../../../intl';


export default class PartyModel extends BaseModel {
    /**
     * @override Adding `summary` and `totalCount` properties
     */
    toJSON () {
        return {
            ...super.toJSON(),
            summary: this.getSummary(),
            totalCount: this.getTotalCount()
        };
    }

    /**
     * Returns total number of persons (adults and children)
     *
     * @return {number}
     */
    getTotalCount () {
        return this.get('adultCount') + this.get('childCount');
    }

    /**
     * @return {string}
     */
    getSummary () {
        const numAdults = +this.get('adultCount');
        if (!numAdults) {
            return '';
        }
        const {formatMessage} = getIntl();
        const numChildren = +this.get('childCount');

        let partySummary = `${numAdults} ${formatMessage({id: numAdults === 1 ? 'general.adult' : 'general.adults'})}`;

        if (numChildren > 0) {
            partySummary = `${partySummary}, ${numChildren} ${formatMessage({id: numChildren === 1 ? 'general.child' : 'general.children'})}`;
        }

        return partySummary;
    }
}

