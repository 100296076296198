import _ from 'underscore';
import ApiStepBaseView from './base';
import app from '../../../app/app';
import OverlayFactory from '../../../item/overlay/factory';
import templateWebpack from '../../../../tpl/item/api-step/sepa.hbs';
import BicSelectView from '../../../item/api-input/view/bic-select';
import * as Constants from '../../../config/constants';
import {getIntl, getLocale} from '../../../intl';


// Test
// IBAN DE12500105170648489890
// BIC 50010517
export default class PaymentSepaApiStep extends ApiStepBaseView {
    constructor (options = {}) {
        super({
            templateWebpack, ...options
        });
        this.ibanApiError = false;
        this.on('step:changed', this.onStepInputDataChange);
        this.listenTo(this.model.inputs, 'change:inputValue', this.onChangeTracking);
    }


    regions () {
        return {
            [Constants.NETMATCH_INPUT_PAYMENT_SEPA_IBAN]: {
                el: '.api-input-IBAN',
                replaceElement: true
            },
            [Constants.NETMATCH_INPUT_PAYMENT_SEPA_BIC]: {
                el: '.api-input-BIC',
                replaceElement: false
            },
            [Constants.NETMATCH_INPUT_PAYMENT_SEPA_BANKNAME]: {
                el: '.api-input-InstitutionName',
                replaceElement: true
            },
            [Constants.NETMATCH_INPUT_PAYMENT_SEPA_ACCOUNTHOLDERNAME]: {
                el: '.api-input-AccountHolder',
                replaceElement: false
            },
            'bic-select': {
                el: '.region-bic-select',
                replaceElement: false
            }
        };
    }

    childViewEvents () {
        return {
            'payment:sepa:from:iban': 'onSepaDataFromIbanApi',
            'payment:sepa:from:iban:error': 'onIbanApiError',
            'payment:sepa:from:iban:input:error': 'onIbanInputError',
            'payment:sepa:bic:select:change': 'onChangeBicFromSelectView'
        };
    }

    events () {
        return {
            'click .js-invoice-overlay': 'onInvoiceOverlay',
            'click .js-iban-overlay': 'onIbanOverlay',
            'click .js-accept-checkbox': 'onChangeAcceptCheckbox'
        };
    }

    apiInputViewOptions () {
        const {formatMessage} = getIntl();
        const invoice = app.apiSession.bookingStatus.get('invoice');
        return {
            [Constants.NETMATCH_INPUT_PAYMENT_SEPA_IBAN]: {
                displayType: 'iban',
                additionalTemplateContext: {}
            },
            [Constants.NETMATCH_INPUT_PAYMENT_SEPA_BIC]: {
                additionalTemplateContext: {
                    placeholderValue: (this.ibanApiError === false)
                        ? formatMessage({
                            id: 'tpl.item.api-step.view.sepa.automatically'
                        })
                        : '',
                    disabled: (this.ibanApiError === false)
                }
            },
            [Constants.NETMATCH_INPUT_PAYMENT_SEPA_BANKNAME]: {
                additionalTemplateContext: {
                    placeholderValue: (this.ibanApiError === false)
                        ? formatMessage({
                            id: 'tpl.item.api-step.view.sepa.automatically'
                        })
                        : '',
                    disabled: (this.ibanApiError === false)
                }
            },
            [Constants.NETMATCH_INPUT_PAYMENT_SEPA_ACCOUNTHOLDERNAME]: {
                additionalTemplateContext: {
                    hidden: true, // nothingSubmittedOption: `${invoice.firstName} ${invoice.lastName}`,
                    inputValue: `${invoice.firstName} ${invoice.lastName}`
                }
            }
        };
    }

    /**
     * Add details to the data passed into the template.
     * Will be merged into the model / collection data.
     */
    templateContext () {
        const templateContext = super.templateContext.apply(this);
        return {
            ...templateContext,
            invoice: app.apiSession.bookingStatus.get('invoice'),
            contentAPITexte: app.contentApi.messages.prepareMessages('payment'),
            isB2B: app.apiSession.bookingStatus.agency.isB2B(),
            contentAPITexteB2B: app.contentApi.messages.get('b2blight'),
            isEnglish: getLocale() === 'en'
        };
    }

    onRender () {
        if (this.model.inputs.get(Constants.NETMATCH_INPUT_PAYMENT_SEPA_IBAN).hasError()) {
            this.ibanApiError = true;
        }
        this.renderApiInputsToRegions(this.model.inputs);
    }

    onInvoiceOverlay () {
        OverlayFactory.show('payment-invoice');
    }

    onIbanOverlay () {
        OverlayFactory.show('payment-iban');
    }

    onChangeAcceptCheckbox () {
        if (this.model.hasInputErrors() === false && this.$('.js-accept-checkbox').is(':checked') === true) {
            this.triggerMethod('payment:subviewpageisvalid');
        } else {
            this.triggerMethod('payment:subviewpageisinvalid');
        }

        if (this.$('.js-accept-checkbox').is(':checked') === true) {
            this.trackEvent('SEPA|Mandat');
        }
    }


    /*
     *
     * all this because TUICIBE-111: "Autocomplete" in case NetMatch give us more BIC's for one IBAN. Why, i don't know
     *
     * * * */
    onSepaDataFromIbanApi (data) {
        if (_.isArray(data) && data.length !== 0) {
            this.ibanApiError = false;
            this.$('.js-iban-api-hint').hide();
            this.reRenderBicBankInputs();
            if (data.length > 1) {
                this.$('.js-iban-api-hint').show().text(getIntl().formatMessage({id: 'components.payment.iban.label.bic'}));
                this.$('.api-input-BIC').hide();
                this.onChangeBicIntoSelectView(data);
            }
            this.setBicAndBanknameFormApi(_.first(data));
        } else {
            this.onIbanApiError();
        }
    }

    onIbanApiError () {
        this.ibanApiError = true;
        this.$('.js-iban-api-hint').show().text(getIntl().formatMessage({id: 'components.payment.iban.label.bic-not-automatic'}));
        this.reRenderBicBankInputs();
    }

    onIbanInputError () {
        this.ibanApiError = false;
        this.$('.js-iban-api-hint').hide();
        this.reRenderBicBankInputs();
    }

    reRenderBicBankInputs () {
        this.$('.api-input-BIC').show();
        if (this.getChildView('bic-select')) {
            this.detachChildView('bic-select');
        }
        this.model.inputs.get(Constants.NETMATCH_INPUT_PAYMENT_SEPA_BIC).resetInputValue();
        this.model.inputs.get(Constants.NETMATCH_INPUT_PAYMENT_SEPA_BANKNAME).resetInputValue();
        this.renderApiInputsToRegions([this.model.inputs.get(Constants.NETMATCH_INPUT_PAYMENT_SEPA_BIC)]);
        this.renderApiInputsToRegions([this.model.inputs.get(Constants.NETMATCH_INPUT_PAYMENT_SEPA_BANKNAME)]);
    }

    onChangeBicIntoSelectView (seclectData) {
        const apiInputView = new BicSelectView({
            label: this.model.inputs.get(Constants.NETMATCH_INPUT_PAYMENT_SEPA_BIC).get('label'),
            seclectData
        });
        this.showChildView('bic-select', apiInputView);
    }

    onChangeBicFromSelectView (value) {
        this.setBicAndBanknameFormApi({'bic': value});
    }

    setBicAndBanknameFormApi (value) {
        const bicInput = this.model.inputs.get(Constants.NETMATCH_INPUT_PAYMENT_SEPA_BIC);
        const bankInput = this.model.inputs.get(Constants.NETMATCH_INPUT_PAYMENT_SEPA_BANKNAME);
        if (value.bic) {
            bicInput.setInputValue(value.bic);
        }
        if (value.bankName) {
            bankInput.setInputValue(value.bankName);
        }
    }


    // Work'a'Round for API Step - sepa -> AccountHolderName is Required but not view at Frontend
    onStepInputDataChange (stepId, changedData, stepView) {
        if (stepId === Constants.NETMATCH_STEP_PAYMENT_SEPA) {
            const accountHolderNameInputModel = stepView.model.inputs.get(Constants.NETMATCH_INPUT_PAYMENT_SEPA_ACCOUNTHOLDERNAME);
            if (!accountHolderNameInputModel.getInputValue()) {
                const invoice = app.apiSession.bookingStatus.get(Constants.NETMATCH_STEP_INVOICE);
                // console.log(accountHolderNameInputModel.getInputValue());
                accountHolderNameInputModel.setInputValue(`${invoice.firstName} ${invoice.lastName}`);
            }
        }
        if (this.model.hasInputErrors() === false && this.$('.js-accept-checkbox').is(':checked') === true) {
            this.triggerMethod('payment:subviewpageisvalid');
            // console.log(this.$('.js-accept-checkbox').is(':checked'));
        } else {
            this.triggerMethod('payment:subviewpageisinvalid');
        }
        // console.log(stepView.model.hasInputErrors());
    }

    onChangeTracking (inputModelChange) {
        const translate = {
            [Constants.NETMATCH_INPUT_PAYMENT_SEPA_IBAN]: 'SEPA|IBAN',
            [Constants.NETMATCH_INPUT_PAYMENT_SEPA_BIC]: 'SEPA|BIC',
            [Constants.NETMATCH_INPUT_PAYMENT_SEPA_BANKNAME]: 'SEPA|Geldinstitut'
        };
        // console.log(inputModelChange.changed, inputModelChange.id, inputModelChange.collection.parentModel.id);
        if (translate[inputModelChange.id]) {
            this.trackEvent(translate[inputModelChange.id]);
        }
    }

    trackEvent (label) {
        app.trackController.eventTracking({
            action: 'Zahlung',
            label,
            ga4clickLabel: `content.custom.zahlung-${label.replace('|', '-')}`
        });
    }
}
