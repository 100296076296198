import ApiInputChoiceBaseView from './basechoice';
import templateWebpack from '../../../../tpl/item/api-input/select.hbs';


export default class ApiInputSelectView extends ApiInputChoiceBaseView {
    constructor (options = {}) {
        super({
            templateWebpack,
            ...options
        });
    }
}
