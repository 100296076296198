import _ from 'underscore';
import app from '../../../app/app';
import BaseModel from '../../../base/model/base';
import * as Constants from '../../../config/constants';
import InsurancesCollection from '../collection/insurances';


const memberTypeToInputIdMapping = {
    [Constants.NETMATCH_INSURANCE_MEMBERSTYPE_INDIVIDUAL]: Constants.NETMATCH_INPUT_INSURANCE_INDIVIDUALCOUNT,
    [Constants.NETMATCH_INSURANCE_MEMBERSTYPE_COUPLE]: Constants.NETMATCH_INPUT_INSURANCE_COUPLECOUNT,
    [Constants.NETMATCH_INSURANCE_MEMBERSTYPE_FAMILY]: Constants.NETMATCH_INPUT_INSURANCE_FAMILYCOUNT
};


export default class InsuranceSelectorModel extends BaseModel {
    constructor (attributes, options) {
        super(attributes, options);

        this.apiSteps = options.apiSteps;
        this.insurances = new InsurancesCollection(null, {
            insuranceSelectorModel: this
        });

        this.listenTo(this.apiSteps, 'reset', () => {
            this.resetInsuranceCollection();
        });
        this.listenTo(app, 'change:insurance-selector', () => this.setInsuranceIsBlocked());
        this.resetInsuranceCollection();

        this.listenTo(this.insurances, 'all', () => {
            this.trigger('change');
        });


        this.set('showInsurancesAssignment', this.getApiStepInsurancePolicies().isCompleted() && !this.allPoliciesAreDummies());
        this.set('showInsurancesWaiver', this.getApiStepInsurancePolicies().isCompleted() && this.allPoliciesAreDummies());

        if (!this.getApiStepInsurancePolicies().isCompleted()) {
            this.set('showInsurancesWaiver', true);
        }

        // TUICIBE-554
        this.consultingProtocolAccepted = app.apiSession.bookingStatus.agency.isB2B(); // defaults to true for B2B

        this.setInsuranceIsBlocked();

        //    this.handleRecommendations();
    }

    defaults () {
        return {
            showInsurancesWaiver: true, showInsurancesAssignment: false, isBlocked: false, errors: []
        };
    }

    // TUICIBE-554
    acceptConsultingProtocol (accept) {
        this.consultingProtocolAccepted = accept;
    }

    get hasAcceptedConsultingProtocol () {
        return this.consultingProtocolAccepted || this.allPoliciesAreDummies();
    }


    /**
     * submit exact No Insurance to NETMATCH_STEP_INSURANCEPOLICIES (DummyCount)
     */
    setNoInsurance () {
        return new Promise((resolve) => {
            const stepsToSubmit = [];

            const stepData = {
                'id': Constants.NETMATCH_STEP_INSURANCEPOLICIES,
                'content': {
                    [Constants.NETMATCH_INPUT_INSURANCE_INDIVIDUALCOUNT]: 0,
                    [Constants.NETMATCH_INPUT_INSURANCE_COUPLECOUNT]: 0,
                    [Constants.NETMATCH_INPUT_INSURANCE_FAMILYCOUNT]: 0,
                    [Constants.NETMATCH_INPUT_INSURANCE_DUMMYCOUNT]: this.getPartyTotalCount()
                }
            };
            const step = app.apiSession.steps.get(stepData.id);
            if (step && step.validateData(stepData).valid) {
                stepsToSubmit.push(stepData);
            }

            setTimeout(() => {
                app.trigger('loading:start');
            }, 10);
            app.apiSession.submitSteps(stepsToSubmit).then(resolve);
        });
    }

    /**
     * submit only NETMATCH_STEP_INSURANCEPOLICIES assignment
     */
    submitOnlyInsurancePolicyStep () {
        return new Promise((resolve) => {
            const stepsToSubmit = [];

            const stepData = {
                'id': Constants.NETMATCH_STEP_INSURANCEPOLICIES,
                'content': {
                    [Constants.NETMATCH_INPUT_INSURANCE_INDIVIDUALCOUNT]: this.getIndividualInsurancesCount(),
                    [Constants.NETMATCH_INPUT_INSURANCE_COUPLECOUNT]: this.getCoupleInsurancesCount(),
                    [Constants.NETMATCH_INPUT_INSURANCE_FAMILYCOUNT]: this.getFamilyInsurancesCount(),
                    [Constants.NETMATCH_INPUT_INSURANCE_DUMMYCOUNT]: this.getPartyTotalCount() - this.getInsuredTotalCount()
                }
            };
            const step = app.apiSession.steps.get(stepData.id);
            if (step && step.validateData(stepData).valid) {
                stepsToSubmit.push(stepData);
            }
            app.apiSession.submitSteps(stepsToSubmit).then(resolve);
        });
    }

    submitInsurancePolicyStep () {
        return new Promise((resolve, reject) => {
            this.set('errors', []);
            const policyStepData = {
                'id': Constants.NETMATCH_STEP_INSURANCEPOLICIES,
                'content': {
                    [Constants.NETMATCH_INPUT_INSURANCE_INDIVIDUALCOUNT]: this.getIndividualInsurancesCount(),
                    [Constants.NETMATCH_INPUT_INSURANCE_COUPLECOUNT]: this.getCoupleInsurancesCount(),
                    [Constants.NETMATCH_INPUT_INSURANCE_FAMILYCOUNT]: this.getFamilyInsurancesCount(),
                    [Constants.NETMATCH_INPUT_INSURANCE_DUMMYCOUNT]: this.getPartyTotalCount() - this.getInsuredTotalCount()
                    // due to legacy reasons, the api still delivers the input 'GroupCount' with an required validator. so submit 0 here
                    // ??? [Constants.NETMATCH_INPUT_INSURANCE_GROUPCOUNT]: 0
                }
            };
            const apiStep = this.getApiStepInsurancePolicies();
            const validationResult = apiStep.validateData(policyStepData);
            if (!validationResult.valid) {
                console.error('submitInsurancePolicyStep validation errors', validationResult);
                this.set('errors', validationResult.errors);
                reject(validationResult.errors);
                return;
            }

            const familyAssigmentData = this.getFamilyInsurances().reduce((data, insurance) => {
                data.push({
                    adults: insurance.get('adults'), childs: insurance.get('childs')
                });
                return data;
            }, []);

            app.apiSession.submitSteps([policyStepData], true)
                .then(() => {
                    this.setPolicyAssigments(familyAssigmentData);
                    return this.submitInsuranceAssignmentSteps();
                })
                .then(resolve);
        });
    }


    setPolicyAssigments (familyAssigmentData) {
        // clear any previously assigned participants
        this.insurances.forEach(insurance => {
            insurance.assignedParticipantSteps.reset();
        });

        const participantSteps = [...this.getApiStepsParticipants()];

        // as the childs are always last in the participant steps we can use shift / pop to get the proper steps
        // as a free benefit this handles the case where adults can be childs, as an adult will be returned if there are no more childs.
        const getUnassignedAdult = () => participantSteps.shift();
        const getUnassignedChild = () => participantSteps.pop();

        // sort the insurances by type so we handle the families first and the dummies last
        const sortedInsurances = this.insurances.sortBy(insurance => {
            switch (insurance.get('type')) {
                case Constants.NETMATCH_INSURANCE_MEMBERSTYPE_FAMILY:
                    return 0;
                case Constants.NETMATCH_INSURANCE_MEMBERSTYPE_INDIVIDUAL:
                case Constants.NETMATCH_INSURANCE_MEMBERSTYPE_COUPLE:
                    return 10;
                default:
                    return 100;
            }
        });


        // fill up the insurances with unassigned participants
        const familyAssigments = [...familyAssigmentData];
        sortedInsurances.forEach(insurance => {
            let adults = insurance.get('adults');
            let childs = insurance.get('childs');
            if (insurance.isFamilyInsurance()) {
                const familyAssigment = familyAssigments.shift() || {};
                adults = familyAssigment.adults || adults;
                childs = familyAssigment.childs || childs;
            }
            _.times(adults, () => {
                const adult = getUnassignedAdult();
                if (!adult) {
                    console.error('No unassigned adult found.');
                    return;
                }
                insurance.assignedParticipantSteps.add(adult);
            });

            _.times(childs, () => {
                const child = getUnassignedChild();
                if (!child) {
                    console.error('No unassigned child found.');
                    return;
                }
                insurance.assignedParticipantSteps.add(child);
            });
        });
    }


    submitInsuranceAssignmentSteps () {
        return new Promise((resolve) => {
            const stepsToSubmit = this.insurances.reduce((data, insurance) => {
                // policy assignment
                data.push({
                    'id': insurance.policyAssigmentStep.id,
                    'content': {
                        // ...insurance.policyAssigmentStep.getInputData(),
                        [Constants.NETMATCH_INPUT_ASSIGN_TO]: insurance.assignedParticipantSteps.map(step => step.get('occupantIndex'))
                    }
                });
                return data;
            }, []);
            app.apiSession.submitSteps(stepsToSubmit, true)
                .then(resolve);
        });
    }


    handleRecommendations () {
        const insurancePoliciesStep = this.getApiStepInsurancePolicies();
        /* if (!insurancePoliciesStep || insurancePoliciesStep.get('hasSubmits') || insurancePoliciesStep.hasChangedInput()) {
            return;
        } */
        let recommendedPolicy;
        // let recommendedPolicyTypeCode;
        const policyAttributes = insurancePoliciesStep.get('netMatchAttributes') || [];
        policyAttributes.find(attributes => {
            if (attributes.key === Constants.NETMATCH_INSURANCE_KEY_PREMIUM) {
                recommendedPolicy = attributes.membersTypeName;
                // recommendedPolicyTypeCode = attributes.bookingCode;
                return true;
            }
            return false;
        });

        if (!recommendedPolicy) {
            return Promise.resolve();
        }

        // only handle individual insurances when there is only one person
        if (recommendedPolicy === Constants.NETMATCH_INSURANCE_MEMBERSTYPE_INDIVIDUAL && this.getPartyTotalCount() > 1) {
            return Promise.resolve();
        }

        const addedInsurance = this.addInsuranceByType(recommendedPolicy);

        // add all adults / childs to the family insurance
        if (recommendedPolicy === Constants.NETMATCH_INSURANCE_MEMBERSTYPE_FAMILY) {
            addedInsurance.set({
                adults: this.getPartyAdultCount(), childs: this.getPartyChildCount()
            });
        }

        setTimeout(() => {
            app.trigger('loading:start');
        }, 10);

        return this.submitOnlyInsurancePolicyStep()
            .then(() => {
                this.set('showInsurancesAssignment', true);
            });
    }


    isParticipantsDataRequired () {
        // no, if there are only dummy policies
        if (this.allPoliciesAreDummies()) {
            return false;
        }
        // yes, if not all participants are insured
        const allParticipantsAreInsured = this.getPartyTotalCount() === this.getInsuredTotalCount();
        if (!allParticipantsAreInsured) {
            return true;
        }
        // no, if it's only one insurance
        if (this.insurances.length <= 1) {
            return false;
        }
        // no, if all insurances are individual and have the same type
        if (this.insurances.canSkipParticipantData()) {
            return false;
        }
        // otherwise yes
        return true;
    }


    hasValidInsuranceSelection () {
        const partyTotalCount = this.getPartyTotalCount();
        const insuredTotalCount = this.getInsuredTotalCount();
        return insuredTotalCount <= partyTotalCount;
    }


    allPoliciesAreDummies () {
        return this.getApiStepsPolicyType().every(step => {
            const input = step.inputs.get(Constants.NETMATCH_INPUT_POLICY);
            if (!input) {
                return false;
            }
            return input.getInputValue() === Constants.NETMATCH_SPECIAL_INPUT_VALUE_DUMMY_POLICY;
        });
    }


    isFamilyInsuranceAvailable () {
        return this.getPartyChildCount() > 0 || this.getPartyAdultCount() > 1;
    }


    isCoupleInsuranceAvailable () {
        return this.getPartyAdultCount() > 1;
    }


    getTotalInsuranceCount () {
        return this.getIndividualInsurancesCount() + this.getCoupleInsurancesCount() + this.getFamilyInsurancesCount();
    }


    getIndividualInsurances () {
        return this.insurances.getIndividualInsurances();
    }


    getIndividualInsurancesCount () {
        return this.getIndividualInsurances().length;
    }


    getCoupleInsurances () {
        return this.insurances.getCoupleInsurances();
    }


    getCoupleInsurancesCount () {
        return this.getCoupleInsurances().length;
    }


    getFamilyInsurances () {
        return this.insurances.getFamilyInsurances();
    }


    getFamilyInsurancesCount () {
        return this.getFamilyInsurances().length;
    }


    getInsuredTotalCount () {
        const insuranceCompositionValues = this.insurances.getCompositionValues();
        return insuranceCompositionValues.adults + insuranceCompositionValues.childs;
    }


    getPartyAdultCount () {
        return +app.apiSession.bookingStatus.party.get('adultCount');
    }


    getPartyChildCount () {
        return +app.apiSession.bookingStatus.party.get('childCount');
    }


    getPartyTotalCount () {
        return +app.apiSession.bookingStatus.party.getTotalCount();
    }


    getApiInputIdByType (type) {
        return memberTypeToInputIdMapping[type];
    }


    getTypeByApiInputId (inputId) {
        return Object.keys(memberTypeToInputIdMapping).find(type => memberTypeToInputIdMapping[type] === inputId);
    }


    resetInsuranceCollection () {
        const insuranceModels = this.getApiStepsPolicyAssignment().reduce((models, assigmentStep) => {
            const type = assigmentStep.get('netMatchAttributes').find(attr => attr.key === 'membersType').membersTypeName;
            models.push({
                id: assigmentStep.insuranceNumericStepId, type
            });
            return models;
        }, []);
        this.insurances.reset(insuranceModels);
    }


    addInsuranceByType (type) {
        if (!this.insuranceCanBeAdded(type)) {
            return null;
        }
        return this.insurances.add({
            type
        });
    }

    setInsuranceIsBlocked () {
        const policyTypes = this.getApiStepsPolicyType();
        if (policyTypes && policyTypes.length) {
            const blockedInsurance = policyTypes.some((step) => {
                const input = step.inputs.get(Constants.NETMATCH_INPUT_POLICY);
                if (!input) {
                    return false;
                }
                return input.attributes && input.attributes.options ? input.attributes.options.some((option) => option.id === input.getInputValue() && option.isBlocked) : false;
            });
            this.set('isBlocked', blockedInsurance);
            this.trigger('change');
        }
    }


    insuranceCanBeAdded (type) {
        let maxInsurancesAllowed;
        let currentInsurancesCount;
        switch (type) {
            case Constants.NETMATCH_INSURANCE_MEMBERSTYPE_INDIVIDUAL:
                currentInsurancesCount = this.getIndividualInsurancesCount();
                maxInsurancesAllowed = this.getPartyTotalCount();
                break;
            case Constants.NETMATCH_INSURANCE_MEMBERSTYPE_COUPLE:
                currentInsurancesCount = this.getCoupleInsurancesCount();
                maxInsurancesAllowed = Math.floor(this.getPartyTotalCount() / 2);
                break;
            case Constants.NETMATCH_INSURANCE_MEMBERSTYPE_FAMILY:
                currentInsurancesCount = this.getFamilyInsurancesCount();
                maxInsurancesAllowed = Math.floor(this.getPartyTotalCount() / 2);
                break;
            default:
                maxInsurancesAllowed = 0;
        }
        return maxInsurancesAllowed && currentInsurancesCount < maxInsurancesAllowed;
    }


    removeInsurancesByType (type) {
        const modelToRemove = this.insurances.filterByType(type).pop();
        return this.insurances.remove(modelToRemove);
    }


    getApiStepInsurancePolicies () {
        return this.apiSteps.get(Constants.NETMATCH_STEP_INSURANCEPOLICIES);
    }


    getApiStepTermsAndConditions () {
        return this.apiSteps.get(Constants.NETMATCH_STEP_INSURANCETERMSANDCONDITIONS);
    }


    getApiStepsPolicyAssignment () {
        return this.apiSteps.sortBy('id').filter(step => step.id.match(Constants.NETMATCH_STEP_POLICYASSIGNMENT));
    }


    getApiStepsPolicyType () {
        return this.apiSteps.sortBy('id').filter(step => step.id.match(Constants.NETMATCH_STEP_POLICYTYPE));
    }


    getApiStepsParticipants () {
        return this.apiSteps.sortBy('id').filter(step => step.id.match(Constants.NETMATCH_STEP_ADULT) || step.id.match(Constants.NETMATCH_STEP_CHILD));
    }
}
