var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.api-step.sepa.headline.b2b",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":75}}})) != null ? stack1 : "")
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.api-step.sepa.headline",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":71}}})) != null ? stack1 : "")
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "col-xs-10";
},"7":function(container,depth0,helpers,partials,data) {
    return "col-xs-9";
},"9":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"11":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"contentAPITexteB2B") : depth0)) != null ? lookupProperty(stack1,"acceptSepaB2b") : stack1)) != null ? lookupProperty(stack1,"value") : stack1), depth0))
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"contentAPITexte") : depth0)) != null ? lookupProperty(stack1,"acceptSepa") : stack1), depth0)) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"booking-page-section\">\n    <div class=\"sepa-wrapper\">\n        <div class=\"headline\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isB2B") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":8,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n        <div>\n            "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(alias1,"tpl.item.api-step.creditcard.owner",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":11,"column":70}}})) != null ? stack1 : "")
    + "\n        </div>\n        <div class=\"invoice-name\">\n            "
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"invoice") : depth0)) != null ? lookupProperty(stack1,"firstName") : stack1), depth0))
    + " "
    + alias3(alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"invoice") : depth0)) != null ? lookupProperty(stack1,"lastName") : stack1), depth0))
    + "\n            <span class=\"hint help-hint overlay-info-icon js-invoice-overlay\">\n                <a>&nbsp;</a>\n            </span>\n            <div class=\"api-input-AccountHolder\"></div>\n        </div>\n        <div class=\"row columns-10\">\n            <span class=\"iban-hint-under-xs\">\n                <div class=\"hint help-hint overlay-iban-info-icon js-iban-overlay\">\n                    <a>&nbsp;</a>\n                </div>\n            </span>\n            <div class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEnglish") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":26,"column":24},"end":{"line":26,"column":73}}})) != null ? stack1 : "")
    + "\">\n                <div class=\"api-input-IBAN\"></div>\n                <div class=\"js-iban-api-hint iban-api-hint\">\n                </div>\n            </div>\n            <div class=\"col-xs-1 iban-hint-over-xs "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEnglish") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":51},"end":{"line":31,"column":81}}})) != null ? stack1 : "")
    + "\">\n                <div class=\"hint help-hint overlay-iban-info-icon js-iban-overlay\">\n                    <a>&nbsp;</a>\n                </div>\n            </div>\n\n        </div>\n        <div class=\"row columns-10\">\n            <div class=\"col-md-5\">\n                <div class=\"api-input-BIC\"></div>\n                <div class=\"region-bic-select\"></div>\n            </div>\n            <div class=\"col-md-5\">\n                <div class=\"api-input-InstitutionName\"></div>\n            </div>\n        </div>\n        <div class=\"row columns-10\">\n            <div class=\"col-md-10\">\n                <div class=\"accept\">\n                    <div class=\"checkbox-element\">\n                        <input type=\"checkbox\" id=\"input-accept\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":65},"end":{"line":51,"column":96}}})) != null ? stack1 : "")
    + " class=\"js-accept-checkbox\">\n                        <label for=\"input-accept\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isB2B") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":53,"column":28},"end":{"line":57,"column":35}}})) != null ? stack1 : "")
    + "                        </label>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class=\"info border-top clean margin-top ssl-hint\">\n            "
    + ((stack1 = alias2(((stack1 = (depth0 != null ? lookupProperty(depth0,"contentAPITexte") : depth0)) != null ? lookupProperty(stack1,"privacy") : stack1), depth0)) != null ? stack1 : "")
    + "\n        </div>\n    </div>\n    <br>\n</div>\n";
},"useData":true});