import React from 'react';
import {createRoot} from 'react-dom/client';
import PaymentTextView from '../../../components/overview/payment-text';

import ApiStepBaseView from './base';
import app from '../../../app/app';
import OverlayFactory from '../../../item/overlay/factory';
import templateWebpack from '../../../../tpl/item/api-step/payment-type.hbs';
import * as Constants from '../../../config/constants';
import {renderReact} from '../../../react';


export default class PaymentApiStep extends ApiStepBaseView {
    constructor (options = {}) {
        super({
            templateWebpack,
            ...options
        });
    }

    /**
     * Getter for the views class name
     */
    get className () {
        return `${super.className} api-step-payment-type`;
    }

    events () {
        return {
            'click .js-overlay-payment-paydown': () => {
                OverlayFactory.show('payment-paydown');
            }
        };
    }

    templateContext () {
        const templateContext = super.templateContext.apply(this);
        return {
            ...templateContext,
            contentAPITexte: app.contentApi.messages.prepareMessages('payment'),
            hintPaymentPaydown: this.getPaydownHint(),
            isB2B: app.apiSession.bookingStatus.agency.isB2B(),
            isAgentur: app.apiSession.bookingStatus.agency.isAgentur(),
            cancelHint: this.cancelHint()
        };
    }

    /**
     * View regions.
     * Any region defined under the key of an input id will be filled with the
     * corresponding input view when calling the 'renderApiInputsToRegions' method..
     */
    regions () {
        return {
            [Constants.NETMATCH_INPUT_PAYMENT_OPTION]: {
                el: '.api-input-payment-type',
                replaceElement: true
            }
        };
    }

    /**
     * Any object defined under the key of an input id will be passed as options
     * into the input views created when calling the 'renderApiInputsToRegions' method.
     */
    apiInputViewOptions () {
        return {
            [Constants.NETMATCH_INPUT_PAYMENT_OPTION]: {
                displayType: 'payment-options',
                additionalTemplateContext: {}
            }
        };
    }

    onRender () {
        this.renderApiInputsToRegions(this.model.inputs);

        if (this.$el.find('.rro-pt')[0]) {
            const container = this.$el.find('.rro-pt')[0];
            const root = createRoot(container);
            renderReact(root, <PaymentTextView/>);
        }
    }


    // seems to be redundant
    // submitStepByOverviewChangeOptions () {
    //     const overviewChangeData = this.model.getInputData();
    //
    //     const stepsToSubmit = [];
    //     let stepData = {};
    //     let step = '';
    //
    //     stepData = {
    //         'id': Constants.NETMATCH_STEP_PAYMENT,
    //         'content': {
    //             [Constants.NETMATCH_INPUT_PAYMENT_OPTION]: overviewChangeData[Constants.NETMATCH_INPUT_PAYMENT_OPTION]
    //         }
    //     };
    //
    //     step = app.apiSession.steps.get(stepData.id);
    //     if (step && step.validateData(stepData).valid) {
    //         stepsToSubmit.push(stepData);
    //     }
    //     app.apiSession.submitSteps(stepsToSubmit, true);
    // }


    getPaydownHint () {
        const type = app.apiSession.bookingStatus.getPriceModel();

        let hint;
        const messages = app.contentApi.messages.prepareMessages('payment');
        switch (type) {
            case 'feel':
            case 'feelP':
                hint = messages.feelgoodPriceHint;
                break;
            case 'flex':
                hint = messages.flexPriceHint;
                break;
            case 'plus':
                hint = messages.plusHint;
                break;
            case 'pro':
                hint = messages.proHint;
                break;
            case 'pur':
                hint = messages.purHint;
                break;
            default:
                hint = messages.mixedHint;
                break;
        }
        return hint;
    }

    /**
     *  findbooking cancel Hint Text by priceModel
     **/
    cancelHint () {
        const trip = (app.apiSession.bookingStatus.trip) ? app.apiSession.bookingStatus.trip.toJSON() : '';
        const value = {
            show: false,
            text: ''
        };
        const type = app.apiSession.bookingStatus.getPriceModel();
        if (type === 'feel' || type === 'feelP' || type === 'pro') {
            value.show = true;
        }
        if (value.show === true) {
            value.text = (app.contentApi.messages.prepareMessages('meta')) ? app.contentApi.messages.prepareMessages('meta').cancelHint : '';
            if (trip && trip.travelDates && trip.travelDates.from) {
                if (trip.travelDates.daysToStart < 50) {
                    value.show = false;
                }
            }
        }
        return value;
    }
}
