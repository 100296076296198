import BaseItem from '../../../base/view/item';
import app from '../../../app/app';
import { getIntl } from '../../../intl';


export default class BaseOverlayView extends BaseItem {
    constructor (options = {}) {
        super({
            ...options
        });
    }

    templateContext () {
        const { formatMessage } = getIntl();
        let closeButtonText;
        try {
            const pageModel = app.pageController.currentPage.model;
            closeButtonText = formatMessage({
                id: pageModel.get('button-back-title') ? 'components.overlay.index.back-title' : 'components.overlay.index.back-page-title'
            }, {
                title: pageModel.get('button-back-title') ? pageModel.get('button-back-title') : pageModel.get('page-title')
            });
        } catch (e) {
            closeButtonText = formatMessage({
                id: 'components.overlay.index.back'
            });
        }

        return {
            closeButtonText
        };
    }
}
