import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

type SearchDeckNumberProps = {
    searchDetails: any;
};

/**
 * Dummy for search result
 * @param {SearchDeckNumberProps} props
 */
export default function SearchDeckNumberView (props:SearchDeckNumberProps) {
    const { formatMessage } = useIntl();
    const { searchDetails } = props;

    let deckText = searchDetails.deckNumber;
    if (searchDetails.deckName) {
        deckText = `${deckText} - ${searchDetails.deckName}`;
    }
    return (
        <div className="decknumber">
            <h2 className="api-step-headline">
                <FormattedMessage id="components.cabinnumber.search-deck-number.headline" />
            </h2>
            <div className="row">
                <div className="col-xs-6">
                    <InputSelectElement text={formatMessage({
                        id: 'general.deck'
                    })} label={formatMessage({
                        id: 'general.deck-available'
                    })} details={deckText}></InputSelectElement>
                </div>
                <div className="col-xs-6">
                    <InputSelectElement text={formatMessage({
                        id: 'general.cabin'
                    })} label={formatMessage({
                        id: 'general.cabin-available'
                    })} details={searchDetails.number}></InputSelectElement>
                </div>
            </div>
        </div>
    );
}

export interface InputSelectProps {
    text: string;
    label: string;
    details: string;
}
const InputSelectElement = (props: InputSelectProps) => {
    const { text, label, details } = props;

    return (
        <>
            {details ?
                <div className='form-group'>
                    <label htmlFor='input-elemen'>{text}</label>
                    <div className="select-element">
                        <select className="form-element is-light"
                            defaultValue={details}
                        >
                            <optgroup label={label}>
                                {details ? <option value="">{details}</option> : '' }
                            </optgroup>
                        </select>
                    </div>
                </div>
                : ''}
        </>
    );
};

