export default {
    root: '/',
    pushState: true,
    reloadRoot: true,

    openExternalUrlsInNewTab: true,

    storage: 'local',

    transition: {
        page: {
            in: 512,
            out: 0
        },
        stickyCTA: {
            normal: 512,
            fast: 256,
            out: 10
        },
        scroll: 300,
        slide: 300
    },

    navigation: {
        highlightActivePath: true,
        highlightActiveCallback: false
    },

    inactivityTimeout: 180
};
