import Cookie from './cookie';
import { TUIC_OPT_OUT_COOKIE_NAME } from '../config/constants';

const isUrlOptOut = () => {
    return document.location.search.indexOf('tracking=false') >= 0;
};

const isUrlOptIn = () => {
    return document.location.search.indexOf('tracking=true') >= 0;
};

const updateOptOutCookie = () => {
    if (isUrlOptOut()) {
        Cookie.setValue(TUIC_OPT_OUT_COOKIE_NAME, true, {'expires': 365 * 10, 'secure': true});
    } else if (isUrlOptIn()) {
        Cookie.deleteCookie(TUIC_OPT_OUT_COOKIE_NAME);
    }
};

const isOptOutCookieSet = () => {
    return Cookie.getValue(TUIC_OPT_OUT_COOKIE_NAME) === 'true';
};

const isOptOut = () => {
    updateOptOutCookie();
    return isOptOutCookieSet() || isUrlOptOut();
};

export { isOptOut };
