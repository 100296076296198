import * as Constants from '../config/constants';
import {getIntl} from '../intl';

/**
 * This will be used for the ibe booking pages collection
 */
export default [

    {
        'page-title': getIntl().formatMessage({
            id: 'navigation.routes.cabin'
        }),
        'menu-icon': 'step-cabin',
        'menu-icon-done': 'step-cabin-check',
        'route': 'cabin',
        'api-steps': [
            Constants.NETMATCH_STEP_TRIP,
            Constants.NETMATCH_STEP_CABINCOUNT,
            Constants.NETMATCH_STEP_PARTY,
            Constants.NETMATCH_STEP_CABINTYPE,
            Constants.NETMATCH_STEP_CABINCATEGORY,
            Constants.NETMATCH_STEP_CABINDECK,
            Constants.NETMATCH_STEP_CABINNUMBER,
            Constants.NETMATCH_STEP_CABINFLOW,
            Constants.NETMATCH_STEP_CABINVIP
        ],
        'ctaMessagesKey': 'kabinenwahl',
        'tracking': {
            'pageTitle': 'Kabinenauswahl',
            'ga4Event': 'kabinenwahl'
        }
    },

    {
        'page-title': getIntl().formatMessage({
            id: 'navigation.routes.travel'
        }),
        'menu-icon': 'step-getting-there',
        'menu-icon-done': 'step-getting-there-check',
        'route': 'travel',
        'api-steps': [
            Constants.NETMATCH_STEP_SHIPBOUNDTRANSPORTTYPE,
            Constants.NETMATCH_STEP_HOMEBOUNDTRANSPORTTYPE,

            Constants.NETMATCH_STEP_SHIPBOUNDTRAIN,
            Constants.NETMATCH_STEP_HOMEBOUNDTRAIN,

            Constants.NETMATCH_STEP_SHIPBOUNDBUS,
            Constants.NETMATCH_STEP_HOMEBOUNDBUS,

            Constants.NETMATCH_STEP_SHIPBOUNDAIRPORT,
            Constants.NETMATCH_STEP_HOMEBOUNDAIRPORT,
            Constants.NETMATCH_STEP_SHIPBOUNDFLIGHT,
            Constants.NETMATCH_STEP_HOMEBOUNDFLIGHT
        ],
        'ctaMessagesKey': 'anundabreise',
        'tracking': {
            'pageTitle': 'An- und Abreise',
            'ga4Event': 'an-abreise'
        }
    },

    // {
    //     'page-title': 'Events',
    //     'menu-icon': 'aircraft',
    //     'menu-icon-done': 'check',
    //     'route': 'events',
    //     'api-steps': [
    //         'event.\\d',
    //     ],
    // },

    {
        'page-title': getIntl().formatMessage({
            id: 'navigation.routes.personal'
        }),
        'button-title': getIntl().formatMessage({
            id: 'navigation.routes.personal.button-title'
        }),
        'button-back-title': getIntl().formatMessage({
            id: 'navigation.routes.personal.button-back-title'
        }),
        'menu-icon': 'step-personal-data',
        'menu-icon-done': 'step-personal-data-check',
        'route': 'personal',
        'api-steps': [
            Constants.NETMATCH_STEP_ADULT,
            Constants.NETMATCH_STEP_CHILD,
            Constants.NETMATCH_STEP_INVOICE
        ],
        'ctaMessagesKey': 'ihredaten',
        'tracking': {
            'pageTitle': 'Ihre Daten',
            'ga4Event': 'ihre-daten'
        }
    },


    {
        'page-title': getIntl().formatMessage({
            id: 'navigation.routes.insurance.page-title'
        }),
        'menu-icon': 'step-insurance',
        'menu-icon-done': 'step-insurance-check',
        'route': 'insurance',
        'api-steps': [
            Constants.NETMATCH_STEP_INSURANCEPOLICIES,
            Constants.NETMATCH_STEP_POLICYASSIGNMENT,
            Constants.NETMATCH_STEP_POLICYTYPE,
            Constants.NETMATCH_STEP_ADULT,
            Constants.NETMATCH_STEP_CHILD,
            Constants.NETMATCH_STEP_INSURANCETERMSANDCONDITIONS
        ],
        'ctaMessagesKey': 'versicherung',
        'tracking': {
            'pageTitle': 'Versicherung',
            'ga4Event': 'reiseversicherung'
        }
    },

    {
        'page-title': getIntl().formatMessage({
            id: 'navigation.routes.overview.page-title'
        }),
        'button-title': getIntl().formatMessage({
            id: 'navigation.routes.overview.button-title'
        }),
        'menu-icon': 'step-overview',
        'menu-icon-done': 'step-overview-check',
        'route': 'overview',
        'api-steps': [
            Constants.NETMATCH_STEP_PAYMENT,
            Constants.NETMATCH_STEP_ONLINE_RISK,
            Constants.NETMATCH_STEP_APPROVAL
        ],
        'ctaMessagesKey': 'pruefenundbuchen',
        'tracking': {
            'pageTitle': 'Übersicht',
            'ga4Event': 'prüfen-buchen'
        }
    },

    {
        'page-title': getIntl().formatMessage({
            id: 'navigation.routes.payment'
        }),
        'button-title': getIntl().formatMessage({
            id: 'navigation.routes.payment.button-title'
        }),
        'menu-icon': 'step-payment',
        'menu-icon-done': 'step-payment-check',
        'hide-back-button': true,
        'route': 'payment',
        'api-steps': [
            Constants.NETMATCH_STEP_PAYMENT,
            Constants.NETMATCH_STEP_PAYMENT_CREDITCARD,
            Constants.NETMATCH_STEP_PAYMENT_SEPA,
            Constants.NETMATCH_STEP_PAYMENT_BANK_TRANFER
        ],
        'ctaMessagesKey': 'zahlung',
        'tracking': {
            'pageTitleApiStep': {
                [Constants.NETMATCH_STEP_PAYMENT_BANK_TRANFER]: 'Zahlung Überweisung',
                [Constants.NETMATCH_STEP_PAYMENT_SEPA]: 'Zahlung SEPA',
                [Constants.NETMATCH_STEP_PAYMENT_CREDITCARD]: 'Zahlung Kreditkarte'
            },
            'ga4Event': 'zahlung'
        }
    },

    {
        'page-title': getIntl().formatMessage({
            id: 'navigation.routes.confirm'
        }),
        'button-title': getIntl().formatMessage({
            id: 'navigation.routes.confirm.button-title'
        }),
        'menu-icon': 'step-confirmation',
        'menu-icon-done': 'step-confirmation',
        'route': 'confirm',
        'api-steps': [
            Constants.NETMATCH_STEP_ALL
        ],
        'tracking': {
            'pageTitle': 'Bestätigung',
            'ga4Event': 'bestätigung'
        }
    },

    {
        'page-title': getIntl().formatMessage({
            id: 'navigation.routes.print-offer'
        }),
        'route': 'print-offer',
        'hide-from-menu': true,
        'is-not-booking-page': true,
        'api-steps': []
    }

];
