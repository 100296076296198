const rootURL = '/api/ibe';

const newSessionURL = `${rootURL}/sessions`;

const staticInputOptionsUrl = `${rootURL}/OptionSets`;

const deleteRootURL = `${rootURL}/session/{apiSession}`;

const sessionURL = `${rootURL}/session/{apiSession}/status`;

const stepsURL = `${rootURL}/session/{apiSession}/steps`;

const localeURL = `${rootURL}/session/{apiSession}/language`;

const multipleSubmitsURL = `${rootURL}/session/{apiSession}/submits`;

const cabinSearchURL = `${rootURL}/session/{apiSession}/cabinSearch/cabin-{index}/{number}`;

const resolveBicFromDeIbanURL = `${rootURL}/resolveBicFromDeIban/{iban}`;

const campaignCodeURL = `${rootURL}/session/{apiSession}/campaignCodes`;

export default {
    rootURL,

    newSessionURL,

    staticInputOptionsUrl,

    localeURL,

    deleteRootURL,

    sessionURL,

    stepsURL,

    multipleSubmitsURL,

    cabinSearchURL,

    resolveBicFromDeIbanURL,

    campaignCodeURL
};
