import DefaultLayout from '../layout/default';
import PrintLayout from '../layout/print';
import ErrorLayout from '../layout/error';


export default {
    create: (layoutId, ...args) => {
        let LayoutViewClass;
        switch (layoutId) {
            case 'default':
                LayoutViewClass = DefaultLayout;
                break;
            case 'print':
                LayoutViewClass = PrintLayout;
                break;
            case 'error':
                LayoutViewClass = ErrorLayout;
                break;
            default:
                throw new Error(`Unknown layout "${layoutId}"`);
        }
        return new LayoutViewClass(...args);
    }
};
