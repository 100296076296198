import BaseModel from '../../../../base/model/base';
import { getIntl } from '../../../../intl';

export default class AlertModel extends BaseModel {
    defaults () {
        const { formatMessage } = getIntl();

        return {
            confirmCallback: null,
            text: '',
            closeButtonText: formatMessage({ id: 'general.close' }),
            confirmButtonText: formatMessage({ id: 'general.confirm' }),
            closable: true,
            closeOnBgClick: false,
            closeCallback: null
        };
    }
}

