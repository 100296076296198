import ApiStepBaseView from './base';
import templateWebpack from '../../../../tpl/item/api-step/flight.hbs';
import * as Constants from '../../../config/constants';


export default class FlightApiStep extends ApiStepBaseView {
    constructor (options = {}) {
        super({
            templateWebpack,
            ...options
        });
    }


    /**
     * Getter for the views class name
     */
    get className () {
        return `${super.className} api-step-flight`;
    }


    /**
     * View regions.
     * Any region defined under the key of an input id will be filled with the
     * corresponding input view when calling the 'renderApiInputsToRegions' method..
     */
    regions () {
        return {
            [Constants.NETMATCH_INPUT_FLIGHT_FLIGHTID]: {
                el: '.api-input-flightid',
                replaceElement: true
            }
        };
    }


    /**
     * Any object defined under the key of an input id will be passed as options
     * into the input views created when calling the 'renderApiInputsToRegions' method.
     */
    apiInputViewOptions () {
        return {
            [Constants.NETMATCH_INPUT_FLIGHT_FLIGHTID]: {
                displayType: 'flight-options',
                isShipbound: this.isShipbound()
            }
        };
    }

    isShipbound () {
        return this.model.id.indexOf(Constants.SHIPBOUND) !== -1;
    }

    onRender () {
        this.renderApiInputsToRegions(this.model.inputs);

        // in this step is an InputError from parent not relevant
        const shipBoundAirportCheck = (this.model.collection.get(Constants.NETMATCH_STEP_SHIPBOUNDAIRPORT)) ? this.model.collection.get(Constants.NETMATCH_STEP_SHIPBOUNDAIRPORT).inputs.get(Constants.NETMATCH_INPUT_AIRPORT_AIRPORTCODE) : null;
        const homeBoundAirportCheck = (this.model.collection.get(Constants.NETMATCH_STEP_HOMEBOUNDAIRPORT)) ? this.model.collection.get(Constants.NETMATCH_STEP_HOMEBOUNDAIRPORT).inputs.get(Constants.NETMATCH_INPUT_AIRPORT_AIRPORTCODE) : null;
        if (shipBoundAirportCheck && shipBoundAirportCheck.hasInputError()) {
            shipBoundAirportCheck.triggerHideValidationError();
        }
        if (homeBoundAirportCheck && homeBoundAirportCheck.hasInputError()) {
            homeBoundAirportCheck.triggerHideValidationError();
        }
    }
}
