import BaseItem from '../../../base/view/item';
import templateWebpack from '../../../../tpl/item/api-input/typeahead-result.hbs';


export default class TypeAheadResultsView extends BaseItem {
    constructor (options) {
        super({
            templateWebpack,
            ...options
        });

        this.listenTo(this.model, 'change:suggestions', this.render);
    }

    /**
     * Getter for the views class name
     */
    get className () {
        // using this.options to merge with BaseItem className
        return `${this.options.className} typeahead`;
    }
}
