import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Constants from '../../config/constants';

type Props = {
    isB2B: boolean
};

export default function NoMatchingCabinOverlay ({ isB2B }: Props) {
    const { formatMessage } = useIntl();

    const textNumber = isB2B
        ? (
            <a className="action-link" href={`tel:${Constants.VIEW_B2B_SERVICE_NUMBER_DISPLAY_CTA}`}>{Constants.VIEW_B2B_SERVICE_NUMBER_DISPLAY_INT}</a>
        )
        : (
            <a className="action-link" href={`tel:${Constants.VIEW_SERVICE_NUMBER_DISPLAY_CTA}`}>{Constants.VIEW_SERVICE_NUMBER_DISPLAY_INT}</a>
        );

    const textMail = isB2B
        ? (
            <a className="action-link" href="mailto:reservierung@tuicruises.com">reservierung@tuicruises.com</a>
        )
        : (
            <a className="action-link" href="mailto:info@tuicruises.com">info@tuicruises.com</a>
        );

    return (
        <div className="overlay-no-matching-cabin">
            <h3 className="overlay-headline">Tipp</h3>
            <div className="row bordered-row">
                <div className="col-sm-6">
                    <FormattedMessage id="" values={{
                        textTeam: formatMessage({
                            id: isB2B ? 'general.team.sales' : 'general.team.service'
                        })
                    }} />
                </div>
                <div className="col-sm-6">
                    {textNumber}
                    <p className="action-link-details">
                        <FormattedMessage id="general.opening-hours" />
                    </p>
                    {textMail}
                </div>
            </div>
        </div>
    );
}
