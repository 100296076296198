import React, {useEffect, useRef, useState} from 'react';
import CabinImageView from './cabin-image';
import CabinPriceModelsView from './cabin-price-models';
import CabinSearchView from './cabin-search';

import app from '../../app/app';
import viewSize from '../../util/viewport';

import OverlayView from '../overlay';
import * as Constants from '../../config/constants';
import {FormattedMessage} from 'react-intl';
import {CabinPrice} from './cabin-price';

type PriceModelType = {
    categoryTypeCode: string;
    displayText: string;
    fromPriceCruiseOnly: number;
    hasVipTariffAvailable: boolean;
    highlight: boolean;
    id: string;
    isBlocked: boolean;
    model: string;
    sort: number;
};

type CabinViewType = {
    id: string,
    content: {
        cabinTypeInterior: Array<any>;
        cabinTypeAdditionalInterior?: Array<any> | null;
        cabinTypeName: string;
        cabinTypeSpecifics: Array<any>;
        categoryTypeCode: string;
        description: string;
        exteriorSizeInfo: string;
        exteriorType: string;
        floorplanImageURLs: Array<any>;
        images: Array<any>;
        interiorSizeInfo: string;
        maxOccupation: string;
        minOccupation: string;
        otdsCabinTypeName: string;
        panoramas: Array<any>;
    };
    isBlocked: boolean;
    isCapacityExceeded: boolean;
    vipAvailable: boolean;
    priceModel: Array<PriceModelType>;
    selected: boolean;
    selectedValue: string | null;
    smallView: boolean;
    lowestPrice: number;
    edgeCaseCabinDeckBlocked?: boolean;
};

type CabinsViewProps = {
    cabinList: Array<CabinViewType> | null;
    isSomeSelected: boolean;
    cabinIndex: number;
    callbackCabin: Function;
    callback: Function;
    apiError: boolean;
    searchSubmit: Function;
};

// let inView:{[name: string]:boolean} = {};
export default function CabinsView (props: CabinsViewProps) {
    const {
        cabinList,
        isSomeSelected,
        cabinIndex,
        callbackCabin,
        callback,
        apiError,
        searchSubmit
    } = props;
    const isB2B: boolean = (app.apiSession && app.apiSession.bookingStatus && app.apiSession.bookingStatus.agency) ? app.apiSession.bookingStatus.agency.isB2B() : false;
    const [listing, setListing] = useState(!isSomeSelected);
    const [, setOverlay] = useState(false);
    const [open, setOpen] = useState(!isSomeSelected);


    useEffect(() => {
        setListing(!isSomeSelected);
        setOpen(!isSomeSelected);
    }, [
        isSomeSelected,
        cabinList
    ]);

    const webpAddOn = '';

    let list;
    if (cabinList) {
        list = cabinList.map(() => React.createRef());
    }
    const refs: any = useRef(list);

    const overlayRef = useRef();
    const onSetOverlay = () => {
        setOverlay(true);
        // console.log(overlayRef);
        // @ts-ignore React Ref
        if ((overlayRef && overlayRef.current) && overlayRef.current.showModal === false) {
            // @ts-ignore React Ref
            overlayRef.current.handleOpenModal();
        }
    };

    const searchSubmitAndClose = (stepsToSubmit: any) => {
        setListing(false);
        setOpen(false);
        searchSubmit(stepsToSubmit);
    };


    const view = (data: Array<CabinViewType>) => {
        const parts: Array<any> = [];
        data.forEach((item, key) => {
            parts.push(cabinView(item, key));
        });

        return (parts);
    };

    const cabinView = (item: CabinViewType, key: any) => {
        let cta;
        if (!item.content) {
            return;
        }

        if (item.edgeCaseCabinDeckBlocked) {
            cta = (<div className="hint-box hint-box--clickable" onClick={onSetOverlay}>
                <FormattedMessage id="components.cabin.cabin-view.edgeCaseCabinDeckBlocked" values={{
                    a: (chunks) => (<a className="hidden-xs-down">{chunks}</a>)
                }}/>
            </div>);
        } else if (item.isCapacityExceeded) {
            cta = (<span className="cabin-capacity">
                <FormattedMessage id="components.cabin.cabin-view.isCapacityExceeded"/>
            </span>);
        } else if (item.isBlocked) {
            cta = (<span className="cabin-disabled">
                <FormattedMessage id="components.cabin.cabin-view.isBlocked"/>
            </span>);
        } else if (item.selected) {
            cta = (<span className="cabin-selected">
                <FormattedMessage id="general.selected"/>
            </span>);
        } else {
            cta = (<a className={`button cabin-available typecode-${item.id}`} data-submitvalue={item.id}
                onClick={() => {
                    selectItem(item.id);
                    listExpand();
                    if (viewSize.isSmall()) {
                        setTimeout(() => {
                            // @ts-ignore Backbone Event
                            app.trigger('scrollToFromReact', '.scroll-point-cabin-price-s');
                        }, 20);
                    }

                    if (app && app.trackController) {
                        app.trackController.eventTracking({
                            action: 'Kabinenwahl_Typ',
                            label: `CTA_${item.content.cabinTypeName}`,
                            ga4clickLabel: `content.button.kabine-auswaehlen-${item.content.cabinTypeName}`
                        });
                    }
                }}>
                <FormattedMessage id="general.cabin.select"/>
            </a>);
        }

        let price: any = item.lowestPrice;
        if (item.selected && item.selectedValue) {
            const selectedPriceModel: any = item.priceModel.find((value: any) => value.id === item.selectedValue);
            price = selectedPriceModel ? selectedPriceModel.fromPriceCruiseOnly : price;
        }

        let divStyle: any = {};

        if (!listing) {
            if (item.selected) {
                divStyle = {
                    opacity: 1
                };
            } else {
                divStyle = {
                    opacity: 0,
                    height: 0,
                    padding: 0,
                    marginBottom: 0
                };
            }
        }

        let firstImg: any;
        if (isB2B) {
            if (item.content.images && Array.isArray(item.content.images) && item.content.images[0]?.imageURL) {
                firstImg = (
                    <div className="cabin-images">
                        <div className={`region-image-gallery-${item.content.categoryTypeCode}}`} data-cabin-type={item.content.categoryTypeCode}>
                            <div className="cabin-type-image-gallery">
                                <div className="image-gallery-images">
                                    <div className="image-wrapper">
                                        <img src={`${item.content.images[0].imageURL}?w=430`}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }

        let smallImg;
        if (item?.content?.images && Array.isArray(item.content.images) && item.content.images[0]?.imageURL) {
            smallImg = {
                backgroundImage: `url(${item.content.images[0].imageURL}?w=640&h=111)`
            };
        } else {
            smallImg = {backgroundImage: 'none'};
        }


        // <img src={`its/?src=${item.content.images[0].imageURL}&width=640&height=111${webpAddOn}`} height='111'></img>
        return (
            <React.Fragment key={key}>
                <div className={`cabin${item.selected ? ' selected' : ''}${item.isBlocked ? ' not-available' : ''}${item.edgeCaseCabinDeckBlocked ? ' not-available' : ''}`}
                    ref={refs.current[key]} style={divStyle}>
                    <div className="content-wrapper">
                        <div className={`${open ? 'show' : 'hidden'}`}>
                            {!isB2B ?
                                (item.content.images ? (<CabinImageView data={item}></CabinImageView>) : '') :
                                (firstImg)
                            }
                        </div>
                        <div className={`cabin-images-small ${open ? 'hidden' : 'show'}`}>
                            <div className="small-img" style={smallImg}></div>
                        </div>
                        <div className="cabin-content">
                            <h3 className="cabin-headline">{item.content.cabinTypeName}
                                <span className={`${item.selected && !listing ? '' : 'hidden'} arrow ${open ? 'open' : ''}`} onClick={() => {
                                    setOpen(!open);
                                    if (app && app.trackController) {
                                        app.trackController.eventTracking({
                                            action: 'Kabinenwahl_Typ',
                                            label: `${open ? 'Einklappen' : 'Ausklappen'}`,
                                            ga4clickLabel: `content.icon.kabine-auswahl-${open ? 'Einklappen' : 'Ausklappen'}`
                                        });
                                    }
                                }}></span>
                            </h3>
                            <div className="cabin-info">
                                <div className="cabin-info-item">
                                    <span className="icon icon-roomsize"></span>
                                    {item.content.interiorSizeInfo ? (`ca. ${item.content.interiorSizeInfo}m²${item.content.exteriorType ? ', ' : ''}`) : ''}
                                    {item.content.exteriorType ? (`${item.content.exteriorType} `) : ''}
                                    {item.content.exteriorSizeInfo ? (`${item.content.exteriorSizeInfo}m²`) : ''}
                                </div>
                                <div className="cabin-info-item">
                                    <span className="cabin-info-item-pipe"></span>
                                    <span className="icon icon-insurance-single"></span> {item.content.minOccupation}-{item.content.maxOccupation} <FormattedMessage id="general.persons-shorthand"/>.
                                </div>
                            </div>

                            <div className={`inner-wrapper ${open ? 'show' : 'hidden'}`}>
                                {item.content.cabinTypeInterior ?
                                    (<>
                                        <span className="bold">
                                            <FormattedMessage id="components.cabin.cabin-view.cabinTypeInterior"/>
                                        </span>
                                        <br/>
                                        <ul className="ct-inter">
                                            {item.content.cabinTypeInterior.map((value: any, keyCT: any) => {
                                                return (<li key={keyCT}>{value}</li>);
                                            })}
                                        </ul>
                                    </>)
                                    : ''}
                                {item.content.cabinTypeAdditionalInterior ?
                                    (<>
                                        <br/>
                                        <span className="bold">
                                            <FormattedMessage id="components.cabin.cabin-view.cabinTypeAdditionalInterior"/>
                                        </span>
                                        <br/>
                                        <ul className="ct-inter">
                                            {item.content.cabinTypeAdditionalInterior.map((value: any, keyCT: any) => {
                                                return (<li key={keyCT}>{value}</li>);
                                            })}
                                        </ul>
                                    </>)
                                    : ''}
                                {item.content.cabinTypeSpecifics ?
                                    (<>
                                        <br/>
                                        <span className="bold">
                                            <FormattedMessage id="components.cabin.cabin-view.cabinTypeSpecifics"/>
                                        </span>
                                        <br/>
                                        <ul className="ct-inter">
                                            {item.content.cabinTypeSpecifics.map((value: any, keyCT: any) => {
                                                return (<li key={keyCT}>{value}</li>);
                                            })}
                                        </ul>
                                    </>)
                                    : ''}
                            </div>
                        </div>
                    </div>

                    <div className={`cabin-price-cta-wrapper ${open ? 'show' : 'hidden'}`}>
                        {!item.isBlocked ?
                            (<span className="nobr">
                                <CabinPrice price={price} mode="from_pp"/>
                            </span>)
                            : ''}
                        {cta}
                    </div>
                </div>
                <div className={`cabin-price-model ${item.selected ? '' : 'hidden-anim'}`}>
                    {(item.selected && !listing) ? <CabinPriceModelsView data={item} cabinIndex={cabinIndex} callbackCabin={callbackCabin} callback={callback} searchSubmit={searchSubmitAndClose} apiError={apiError}></CabinPriceModelsView> : ''}
                </div>
            </React.Fragment>
        );
    };

    const listExpand = () => {
        setListing(!listing);
    };

    const selectItem = (itemId: string) => {
        if (cabinList) {
            cabinList.forEach((item: any, key: number) => {
                if (item.id === itemId) {
                    cabinList[key].smallView = true;
                    cabinList[key].selected = true;
                    setOpen(false);
                } else if (item.selected === true) {
                    cabinList[key].smallView = false;
                    cabinList[key].selected = false;
                }
            });
        }
        setTimeout(() => {
            // @ts-ignore Backbone Event
            app.trigger('scrollToFromReact', '.scroll-point-cabin');
        }, 10);
    };

    let isOneSelected = false;
    if (cabinList) {
        cabinList.forEach((item: any) => {
            if (item.selected === true) {
                isOneSelected = true;
            }
        });
    }

    const alternativesTrips = {
        exist: false,
        count: 0
    };
    const alternativeTripsStep: any = (app.apiSession && app.apiSession.steps) ? app.apiSession.steps : null;
    const alternatives = (alternativeTripsStep) ? alternativeTripsStep.get(Constants.NETMATCH_STEP_TRIP_ALTERNATIVES) : {};
    if (alternatives && alternatives.get('netMatchAttributes').length > 1) {
        alternativesTrips.exist = true;
        alternativesTrips.count = (alternatives.get('netMatchAttributes').length - 1);
    }
    const allBlocked = cabinList ? cabinList.every((cabin: any) => cabin.isBlocked === true) : false;


    return (
        <div className="cabin-list">
            <h2 className="api-step-headline expand">
                <FormattedMessage id="components.cabin.cabin-section.headline"/>
                {isOneSelected ?
                    <div className="cabin-is-expand" onClick={() => {
                        listExpand();
                        setOpen(!listing);
                        if (app && app.trackController && !open) {
                            app.trackController.eventTracking({
                                action: 'Kabinenwahl_Typ',
                                label: 'Auswahl ändern',
                                ga4clickLabel: 'content.custom.kabine-aendern'
                            });
                        }
                    }}>
                        <FormattedMessage id={!listing ? 'general.change-selection' : 'general.close'}/>
                        <span className={listing ? 'icon-ic-edit' : ''}> </span>
                    </div>
                    : ''}
            </h2>
            {allBlocked ?
                (<div className="step-are-blocked-hint">
                    <div className="title">
                        <FormattedMessage id="components.cabin.cabin-view.allBlocked.headline"/>
                    </div>
                    {alternativesTrips.exist ?
                        (<div className="text">
                            <FormattedMessage id="components.cabin.cabin-view.allBlocked.text" values={{
                                alternativesTripAmount: alternativesTrips.count
                            }}/>
                        </div>)
                        :
                        (<span>
                            <div className="text">
                                <FormattedMessage id="components.cabin.cabin-view.allBlocked.text2"/>
                            </div>
                            <a href={`${app.ibeController ? app.ibeController.appBackLink() : ''}`} className="button button-light all-blocked-back-button" rel="noopener noreferrer">
                                <span className="icon-arrow-left"></span> <FormattedMessage id="components.cabin.cabin-view.allBlocked.backButton"/>
                            </a>
                        </span>)}
                </div>)
                : ''}
            {cabinList ? view(cabinList) : ''}
            {listing ?
                <CabinSearchView
                    cabinIndex={cabinIndex}
                    searchSubmit={searchSubmitAndClose}></CabinSearchView>
                : ''}
            <OverlayView overlayId={'cabin-only-by-phone'} closeOnBgClick={true} hasBackButton={true} data={null} ref={overlayRef}/>
        </div>
    );
}

