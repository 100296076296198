import {CabinInfo} from './interface';
import React, {useRef} from 'react';
import app from '../../app/app';
import CampaingCodeExitView from '../overlay/campaign-code-exit';
import {FormattedMessage} from 'react-intl';

type CabinInfoProps = {
    cabinInfo: CabinInfo[],
    isB2B: boolean,
    hideEdit?: boolean
}


/**
 * View: Ihre Kabinenwahl
 *
 * @param props CabinInfoType
 */
export default function CabinInfoView (props: CabinInfoProps) {
    const {cabinInfo, isB2B, hideEdit} = props;

    let webpAddOn = '';
    if (app.canWebp) {
        webpAddOn = '&format=webp';
    }

    const trackEvent = () => {
        if (app.trackController) {
            app.trackController.eventTracking({
                action: 'Übersicht',
                label: 'Kabinenwahl',
                ga4clickLabel: 'content.icon.uebersicht-kabinenwahl'
            });
        }
        if (app.router) {
            if (app?.apiSession?.bookingStatus?.campaignCodes.hasEnteredCode()) {
                onCampaignCodeExitOverlay();
            } else {
                app.router.redirect('cabin', true, false);
            }
        }
        return false;
    };

    // TUICUNIT-3170
    const gotToTarget = () => {
        if (app.router) {
            app.router.redirect('cabin', true, false);
        }
    };

    const overlayRef = useRef();
    const onCampaignCodeExitOverlay = () => {
        // @ts-ignore
        if (overlayRef.current && overlayRef.current.showModal === false) {
            // @ts-ignore
            overlayRef.current.show();
        }
    };

    const confirmData = {
        confirmCallback: gotToTarget,
        closeOnBgClick: true
    };

    return (
        <section className="check-section">
            <div className="row">
                {!isB2B ?
                    <div className="col-sm-4 hidden-xs-down">
                        {cabinInfo.map((item, key: number) => {
                            if (key === 0) {
                                const firstImage = getFirstCabinImage(item);
                                if (firstImage) {
                                    return (
                                        <div key={key} className="check-image">
                                            <img alt={item.cabinCategoryTypeName} src={`${firstImage}?w=500`} width="500" loading="eager"/>
                                        </div>
                                    );
                                }
                            }
                            return null;
                        })}
                    </div>
                    : ''}

                <div className="col-xs-12 col-sm-8 text-wrapper">
                    <div className="section-headline">
                        <FormattedMessage id="components.overview.cabin.headline"/>
                    </div>
                    {cabinInfo.map((item, key: number) => {
                        return (
                            <div key={key} className={`per-cabin${key === 0 ? ' is-first' : ''}`}>
                                <div className="bold"><FormattedMessage id="general.cabin"/> {item.cabinIndex}</div>
                                <FormattedMessage id="components.overview.cabin.text" values={{
                                    cabinCategoryTypeName: item.cabinCategoryTypeName,
                                    priceModel: item.priceModel,
                                    // TUICUNIT-3084
                                    maybeHasVIP: `${item.vip ? ` + ${item.vip}` : ''}`
                                }}/><br/>
                                {item.cabinNumber ?
                                    <>
                                        <FormattedMessage id="general.deck"/> {item.deckNumber}<br/>
                                        <FormattedMessage id="general.cabinnumber"/> {item.cabinNumber}
                                    </>
                                    : ''}
                            </div>
                        );
                    })}
                </div>
            </div>
            <span className={`button button-secondary edit-link ${hideEdit ? 'hidden' : ''}`} onClick={trackEvent}>
                <span className="icon-ic-edit"></span>
            </span>
            <CampaingCodeExitView data={confirmData} ref={overlayRef}></CampaingCodeExitView>
        </section>
    );
}

const getFirstCabinImage = (cabin: CabinInfo) => {
    let image;
    const cabinTypesAndCategoriesModel: any = app?.contentApi?.cabinTypesAndCategories;
    const cabinTypeModel = cabinTypesAndCategoriesModel?.get(cabin.cabinCategoryTypeCode);
    if (!cabinTypeModel) {
        image = null;
    } else {
        const images = cabinTypeModel.get('images') || [];
        const cabinImageURLs = [
            ...images.reduce((urls: [string], image: { imageURL: string }) => urls.concat(image.imageURL), [])
        ];
        image = cabinImageURLs.shift();
    }
    return image;
};
