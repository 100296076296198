import * as Constants from '../../config/constants';


import TransportTypeApiStep from './view/transporttype';
import AirportApiStep from './view/airport';
import FlightApiStep from './view/flight';
import TrainServiceOptionsApiSteps from './view/trainservice-options';
import BusApiStep from './view/bus';

import PolicyTypeApiStep from './view/policy-type';
import InsuranceTermsAndConditionsApiStep from './view/insurance-terms-and-conditions';

import AdultApiStep from './view/adult';
import ChildApiStep from './view/child';
import InvoiceApiStep from './view/invoice';

import PaymentTypeApiStep from './view/payment-type';
import PaymentSepaApiStep from './view/sepa';
import PaymentCreditCardApiStep from './view/creditcard';
import PaymentBankTransferApiStep from './view/bank-transfer';

import ConfirmApiStep from './view/confirm';
import OnlineRiskApiStep from './view/online-risk';

export default {

    create: (stepId, ...args) => {
        let StepViewClass;
        switch (stepId) {

            // An- & Abreise
            case Constants.NETMATCH_STEP_SHIPBOUNDTRANSPORTTYPE:
            case Constants.NETMATCH_STEP_HOMEBOUNDTRANSPORTTYPE:
                StepViewClass = TransportTypeApiStep;
                break;
            case Constants.NETMATCH_STEP_SHIPBOUNDAIRPORT:
            case Constants.NETMATCH_STEP_HOMEBOUNDAIRPORT:
                StepViewClass = AirportApiStep;
                break;
            case Constants.NETMATCH_STEP_SHIPBOUNDFLIGHT:
            case Constants.NETMATCH_STEP_HOMEBOUNDFLIGHT:
                StepViewClass = FlightApiStep;
                break;
            case Constants.NETMATCH_STEP_SHIPBOUNDTRAIN:
            case Constants.NETMATCH_STEP_HOMEBOUNDTRAIN:
                StepViewClass = TrainServiceOptionsApiSteps;
                break;
            case Constants.NETMATCH_STEP_SHIPBOUNDBUS:
            case Constants.NETMATCH_STEP_HOMEBOUNDBUS:
                StepViewClass = BusApiStep;
                break;

            // Versicherung
            case Constants.NETMATCH_STEP_INSURANCETERMSANDCONDITIONS:
                StepViewClass = InsuranceTermsAndConditionsApiStep;
                break;

            // Ihre Daten
            case Constants.NETMATCH_STEP_INVOICE:
                StepViewClass = InvoiceApiStep;
                break;

            // Zahlung
            case Constants.NETMATCH_STEP_PAYMENT:
                StepViewClass = PaymentTypeApiStep;
                break;
            case Constants.NETMATCH_STEP_ONLINE_RISK:
                StepViewClass = OnlineRiskApiStep;
                break;
            case Constants.NETMATCH_STEP_PAYMENT_SEPA:
                StepViewClass = PaymentSepaApiStep;
                break;
            case Constants.NETMATCH_STEP_PAYMENT_CREDITCARD:
                StepViewClass = PaymentCreditCardApiStep;
                break;
            case Constants.NETMATCH_STEP_PAYMENT_BANK_TRANFER:
                StepViewClass = PaymentBankTransferApiStep;
                break;

            case Constants.NETMATCH_STEP_CONFIRM:
                StepViewClass = ConfirmApiStep;
                break;


            default:
                // REGEX MATCHING

                // Ihre Daten
                if (stepId.match(new RegExp(Constants.NETMATCH_STEP_ADULT))) {
                    StepViewClass = AdultApiStep;
                    break;
                }
                if (stepId.match(new RegExp(Constants.NETMATCH_STEP_CHILD))) {
                    StepViewClass = ChildApiStep;
                    break;
                }

                // Versicherungs-Zuordnung
                if (stepId.match(new RegExp(Constants.NETMATCH_STEP_POLICYTYPE))) {
                    StepViewClass = PolicyTypeApiStep;
                    break;
                }

                throw new Error(`Unknown API step "${stepId}"`);
        }
        return new StepViewClass(...args);
    }

};
