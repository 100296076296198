// TUICUNIT-3170: Aktionscodes
import app from '../../../app/app';
import ibeApiConfig from '../../../config/ibe-api';
import { ApiFetchError } from '../../../error/errors';
import 'whatwg-fetch'; // ie11 polyfill

/*
https://tuicruises.tst.hosting.netmatch.eu/ibe/preview/swagger/index.html?urls.primaryName=Overview%20and%20Confirmation%20steps%20package#/CampaignCodes/post_session__sessionId__campaignCodes
*/
export const campaignCode = {

    set (code:string[]) {
        if (app.apiSession) {
            const sessionId = app.apiSession.getID();
            const url = ibeApiConfig.campaignCodeURL.replace('{apiSession}', sessionId);

            return new Promise((resolve, reject) => {
                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({codes: code})
                }).then(resolve)
                    .catch((error) => {
                        reject(new ApiFetchError(`API Error: (${error.status}): ${error.statusText}`));
                    });
            });
        }
    },

    get () {
        if (app.apiSession) {
            const sessionId = app.apiSession.getID();
            const url = ibeApiConfig.campaignCodeURL.replace('{apiSession}', sessionId);

            return new Promise((resolve, reject) => {
                fetch(url)
                    .then((res) => {
                        if (!res.ok) {
                            reject(res);
                        } else {
                            res.json()
                                .then(value => {
                                    app?.apiSession?.reloadOnly().then(() => {
                                        resolve(value);
                                    });

                                })
                                .catch(error => {
                                    reject(error);
                                });
                        }
                    })
                    .catch((error) => {
                        console.log(error);

                        reject(new ApiFetchError(`API Error: (${error.status}): ${error.statusText}`));
                    });
            });
        } else {
            return null;
        }
    },

    hasError (value:ResponseInputs) {

        // we only need the deep :( validators values
        const valid : Array<any> = [];
        value.forEach(item => {
            if (item.validators) {
                item.validators.forEach(v => {
                    valid.push(v);
                });
            }
        });

        const result = valid.find((item:any) => {
            if (item.isValid === false) {
                return item;
            }
            return null;
        });
        return result;
    }
};

type ResponseInputs = [{
    label: string;
    previouslySubmitted?: string;
    property:string;
    resultValue: string;
    type: string;
    validators: Array<any>;
    values: {
        result: string;
        submitted: string;
    };
}];
