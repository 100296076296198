import React from 'react';
import app from '../../app/app';

/**
 * Legal Text Overview
 */
export default function LegalOverviewTextView () {
    const tempText: any = app?.contentApi?.messages.prepareMessages('payment');

    const text = tempText?.pauschalreiserichtlinie || null;

    return (
        <>
            {!!text && (
                <>
                    <h1 className="legal headline">
                        {text.headline}
                    </h1>
                    <div className="legal--text" dangerouslySetInnerHTML={{__html: text.text}}/>
                </>
            )}
        </>
    );
}
