import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import app from '../../app/app';
import * as Constants from '../../config/constants';
import StringUtil from '../../util/string';
import { CabinPrice } from './cabin-price';

type VipType = {
    displayText: string;
    id: string | null;
    isBlocked: boolean;
    price: number | null
};
type StepDataType = {id: string, content:{}};

export default function CabinVipView (props:{stepModelVip:any, callback: Function}) {
    const { formatNumber } = useIntl();
    const { stepModelVip, callback } = props;

    const thisInput = stepModelVip.inputs.get(Constants.NETMATCH_INPUT_CABININVIP);
    const thisInputOptions = thisInput ? thisInput.get('options') : null;
    let thisInputValue = thisInput ? thisInput.get('inputValue') : null;

    const toSubmitStep = (id: string) => {
        const stepsToSubmit = [];
        const stepData:StepDataType = {
            'id': stepModelVip.get('id'),
            'content': {
                [Constants.NETMATCH_INPUT_CABININVIP]: id
            }
        };
        if (stepModelVip && stepModelVip.validateData(stepData).valid) {
            setOpen(false);
            stepsToSubmit.push(stepData);
            callback(stepsToSubmit);
        }
    };

    let keep: {id:string, isBlocked: boolean} = {
        id: '',
        isBlocked: false
    };
    let vip: VipType = {
        displayText: '',
        id: null,
        isBlocked: false,
        price: null
    };
    const content = app.contentApi;
    let texte:any;
    let dummyText;
    if (thisInputOptions) {
        thisInputOptions.forEach((item: any) => {
            if (item.isBlocked === false) {
                // TUICUNIT-3084: New VIP Handling
                if (item.id === Constants.NETMATCH_OPTION_CABININVIP_KEEP || item.id === Constants.NETMATCH_OPTION_CABININVIP_KEEP_OLD_NAMEING) {
                    keep = item;
                }
                if (item.id === Constants.NETMATCH_OPTION_CABININVIP_VIP1) {
                    vip = item;
                    texte = content ? content.messages.prepareMessages(Constants.YOURSTRULY_VIP1_TEXT) : {};
                } else if (item.id === Constants.NETMATCH_OPTION_CABININVIP_VIP2) {
                    vip = item;
                    texte = content ? content.messages.prepareMessages(Constants.YOURSTRULY_VIP2_TEXT) : {};
                }
            }
        });
    }

    // vip1 and vip2 isBlocked
    if (vip.id === null) {
        texte = content ? content.messages.prepareMessages('vipoption1') : {};
        if (thisInputValue === keep.id) {
            thisInputValue = null;
        }
        /* if (app && app.trackController) {
            app.trackController.eventTracking({action: 'Kabinenwahl_VIP',label: 'Das Paket ist leider ausgebucht'});
        } */
    }

    if (thisInputValue) {
        if (thisInputValue === keep.id) {
            dummyText = (
                <span className="name">
                    <FormattedMessage id="components.cabin.cabin-vip.select.no" values={{
                        displayText: vip.displayText
                    }} />
                </span>
            );
        } else if (thisInputValue === vip.id) {
            dummyText = (
                <span className="name">
                    <FormattedMessage id="components.cabin.cabin-vip.select.yes" values={{
                        displayText: vip.displayText
                    }} />
                </span>
            );
        }
    }

    const [open, setOpen] = useState(!thisInputValue);

    return (
        <div className="cabin-vip-view">
            <h2 className="api-step-headline expand">
                <FormattedMessage id="components.cabin.cabin-vip.book" values={{
                    displayText: vip.displayText ? vip.displayText : 'VIP-Tarifoption'
                }} />
            </h2>
            {thisInputValue ?
                <div className={`inner-cc none selected${open ? ' hidden-anim' : ' show'}`}>
                    <div className="radiobutton-element cabin-vip-selected">
                        <input type="radio" id="dummy" className="priceCategory" value="dummy"
                            checked={true} onChange={() => {}}/>
                        <label htmlFor="dummy">{dummyText}</label>
                        <span className={`arrow ${open ? 'open' : ''}`} onClick={() => {
                            setOpen(!open);

                            if (app && app.trackController) {
                                app.trackController.eventTracking({
                                    action: `Kabinenwahl_${vip.displayText}`,
                                    label: 'Ausklappen',
                                    ga4clickLabel: `content.icon.${vip.displayText}-ausklappen`
                                });
                            }
                        }}></span>
                    </div>
                </div>
                : ''}
            <div className={`inner-cc${thisInputValue ? ' selected' : ''}${open ? ' show' : ' hidden-anim'}`}>
                {vip.id ?
                    <div className="radiobutton-element vip">
                        <input type="radio" id={vip.id} className="priceCategory" value={vip.id}
                            checked={thisInputValue === vip.id}
                            disabled={vip.isBlocked}
                            onChange={() => {
                                if (vip.id) {
                                    toSubmitStep(vip.id);

                                    if (app && app.trackController) {
                                        app.trackController.eventTracking({
                                            action: `Kabinenwahl_${vip.displayText}`,
                                            label: 'Ja',
                                            ga4clickLabel: `content.button.${vip.displayText}-ja`
                                        });
                                    }
                                }
                            }} />
                        <label htmlFor={vip.id}>
                            <span className="name">
                                <FormattedMessage id="components.cabin.cabin-vip.select.yes" values={{
                                    displayText: vip.displayText
                                }} />
                            </span>
                            {vip.isBlocked
                                ? (
                                    <span className="nobr"> <FormattedMessage id="general.not-available" /></span>
                                )
                                : ''}
                        </label>
                        {thisInputValue && open ?
                            <span className={`arrow ${open ? 'open' : ''}`} onClick={() => {
                                setOpen(!open);

                                if (app && app.trackController) {
                                    app.trackController.eventTracking({
                                        action: `Kabinenwahl_${vip.displayText}`,
                                        label: 'Einklappen',
                                        ga4clickLabel: `content.icon.${vip.displayText}-einklappen`
                                    });
                                }
                            }}></span>
                            : ''}
                    </div>
                    : (
                        <div className="no-vip">
                            <FormattedMessage id="components.cabin.cabin-vip.no-options" />
                        </div>
                    )}
                {vip.id ?
                    <ul className="checkmark-list">
                        {texte.list.map((value:any, key:any) => {
                            return (<li key={key}><span className="bold" dangerouslySetInnerHTML={{__html: value.headline}}></span><br></br><span className="text" dangerouslySetInnerHTML={{__html: value.text}}></span></li>);
                        })}
                    </ul>
                    : ''}
                {vip.price ?
                    <div className="cabin-price-cta-wrapper nobr">
                        <CabinPrice price={vip.price} mode="exact_pp" />
                    </div>
                    : ''}
                <div className={`line${!vip.id ? ' no-vip-spacer' : ''}`}>
                    <div className="vip-hint-wrapper">
                        {texte.more ? (<div className="vip-hint" ><div data-action={`Kabinenwahl_${vip.displayText}`} data-label="VIP-Tarifoptionen" className="extern" dangerouslySetInnerHTML={{__html: texte.more}}/></div>) : ''}
                    </div>
                    {vip.price ?
                        <div>
                            <FormattedMessage id="components.cabin.cabin-vip.increment-price" values={{
                                price: formatNumber(vip.price, {
                                    currency: 'EUR',
                                    currencyDisplay: 'symbol',
                                    style: 'currency',
                                    minimumFractionDigits: 0
                                })
                            }} />
                        </div>
                        : '' }
                </div>

                {vip.id ?
                    <div className="radiobutton-element keep">
                        <input type="radio" id={keep.id} className="priceCategory" value={keep.id}
                            checked={thisInputValue === keep.id}
                            disabled={keep.isBlocked}
                            onChange={() => {
                                toSubmitStep(keep.id);

                                if (app && app.trackController) {
                                    app.trackController.eventTracking({
                                        action: `Kabinenwahl_${vip.displayText}`,
                                        label: 'Nein',
                                        ga4clickLabel: `content.button.${vip.displayText}-nein`
                                    });
                                }
                            }} />
                        <label htmlFor={keep.id}>
                            <span className="name">
                                <FormattedMessage id="components.cabin.cabin-vip.select.no" values={{
                                    displayText: vip.displayText
                                }} />
                            </span>
                            {keep.isBlocked ? (<span className="nobr"> <FormattedMessage id="general.not-available" /></span>) : ''}
                        </label>
                    </div>
                    : ''}
            </div>
        </div>
    );
}
