import Marionette from 'backbone.marionette';
import '../../util/handlebars';

/**
 * Represents a marionette view as a base item (view) for this project
 */
export default class BaseItem extends Marionette.View {
    /**
     * Extend marionettes view constructor with specific behaviour for a base item view
     *
     * @param options
     */
    constructor (options = {}) {
        // update options with defaults, if they are not provided
        // console.log('%c view ', 'background: #222; color: #ffff00', options);

        if (options.key) {
            // setup default template & className based on the given key
            const defaultOptions = {
                template: options.templateWebpack,
                className: `${options.key}-view`
            };
            // update options with defaults, if they are not provided
            options = Object.assign(defaultOptions, options);
        }

        // call the constructor of marionette.view with the new options
        super(options);
    }
}

