import BaseOverlayView from './base';
import templateWebpack from '../../../../tpl/item/overlay/travel-flight-train.hbs';


export default class InactivityOverlayView extends BaseOverlayView {
    constructor (options = {}) {
        super({
            key: 'overlay-travel-flight-train',
            templateWebpack,
            ...options
        });
    }
}
