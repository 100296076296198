var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1 class=\"booking-page-headline\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"stepHeadline") || (depth0 != null ? lookupProperty(depth0,"stepHeadline") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stepHeadline","hash":{},"data":data,"loc":{"start":{"line":1,"column":34},"end":{"line":1,"column":52}}}) : helper)))
    + "</h1>\n\n<h2 class=\"api-step-headline\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"stepSubHeadline") || (depth0 != null ? lookupProperty(depth0,"stepSubHeadline") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"stepSubHeadline","hash":{},"data":data,"loc":{"start":{"line":3,"column":30},"end":{"line":3,"column":51}}}) : helper)))
    + "</h2>\n\n<div class=\"booking-page-section without-border\">\n    <div class=\"api-step-region-transporttype-shipbound\"></div>\n\n    <div class=\"api-step-region-transporttype-homebound\"></div>\n</div>\n\n<div class=\"react-region-travel-individually\"></div>\n<div class=\"react-region-b2b-cabin-detail\"></div>\n\n<div class=\"booking-pager bottom\"></div>\n\n<div class=\"booking-pager sticky\"></div>\n";
},"useData":true});