import app from '../../../../app/app';
import BaseItem from '../../../../base/view/item';
import templateWebpack from '../../../../../tpl/item/modal/spinner/layout.hbs';


export default class SpinnerView extends BaseItem {
    constructor (options) {
        super(Object.assign({
            key: 'modal/spinner',
            templateWebpack,
            className: 'spinner-container is-hidden'
        }, options));

        this.listenTo(app, 'loading:start', this.showSpinner);
        this.listenTo(app, 'loading:stop', this.hideSpinner);
    }


    showSpinner () {
        window.clearInterval(this.hideInterval);
        this.$el.removeClass('is-hidden');
    }


    hideSpinner () {
        // close after a short delay, to avoid flickering if another request starts directly after the previous one
        this.hideInterval = setTimeout(() => {
            this.$el.addClass('is-hidden');
        }, 10);
    }
}
