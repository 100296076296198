import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import app from '../../app/app';
import config from '../../config/config';


export default class BaseRouter extends Backbone.Router {
    initialize () {
        this.on('route', () => {
            this.setScrollPosition();
            // stop spinner
            app.trigger('loading:stop');
        });
    }

    routes () {
        const routes = {};

        // add 404/catchall route as very last route
        _.each(config.routes, (routeItem) => {
            if (routeItem.routes) {
                _.each(routeItem.routes, (route) => {
                    routes[route] = () => this.requestPage(routeItem.key);
                });
            } else if (routeItem.key) {
                // no explicit routes defined
                routes[routeItem.key] = () => this.requestPage(routeItem.key);
            } else {
                throw new Error('Invalid route definition: either a key or one route mus be set.');
            }

            if (routeItem.redirects) {
                _.each(routeItem.redirects, (path) => {
                    routes[path] = () => {
                        const redirect = (routeItem.routes && routeItem.routes.length) ? routeItem.routes[0] : (routeItem.key || 'error404');
                        this.redirect(redirect, true, true);
                    };
                });
            }
        });

        return routes;
    }

    requestPage (routeKey) {
        console.warn('The method "requestPage" must be overwritten!', routeKey);
    }

    error () {
        throw new Error(`Missing handler for route "${this.getCurrentFragment()}"`);
    }

    getCurrentFragment () {
        // fails, if history.root is missing
        return Backbone.history.root ? Backbone.history.getFragment().split('?')[0] : config.root;
    }

    getCurrent () {
        const fragment = this.getCurrentFragment();
        const routes = _.pairs(this.routes);
        let route = null;
        let params = null;

        const matched = _.find(routes, (handler) => {
            route = _.isRegExp(handler[0]) ? handler[0] : this._routeToRegExp(handler[0]);
            return route.test(fragment);
        });

        if (matched) {
            params = this._extractParameters(route, fragment);
            route = matched[1];
        }
        return {
            fragment,
            params,
            route
        };
    }

    getFragments () {
        return this.getCurrentFragment.split('/');
    }

    redirect (path, trigger, replace) {
        this.navigate(path, {
            // explicit false value required
            trigger: trigger !== false,
            // explicit true value required
            replace: replace === true
        });
    }

    setScrollPosition () {
        if ((config.pushState === true) && window.location.hash) {
            app.trigger('scrollTo', $(window.location.hash));
        } else {
            app.trigger('scrollTop');
        }
    }
}

