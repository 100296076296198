import $ from 'jquery';
import _ from 'underscore';
import app from '../../../app/app';
import BaseItem from '../../../base/view/item';
import BookingPagerView from '../../../item/booking-pager/view/layout';
import ApiStepFactory from '../../../item/api-step/factory';
import NavigationUtil from '../../../util/navigation';
import * as Constants from '../../../config/constants';
import {NETMATCH_ONLINE_RISK_NO_DIRECT_DEBIT, NETMATCH_STEP_ONLINE_RISK, NETMATCH_STEP_PAYMENT} from '../../../config/constants';
import OnlineRiskStepModel from '../../../entities/netmatch-api/model/onlineRiskStep';


export default class BookingPageContent extends BaseItem {
    /**
     * Constructor
     *
     */
    constructor (options = {}) {
        super({
            className: '', ...options
        });
        this.listenTo(app, 'react:change:inputValue:no:autosubmit', () => {
            app.triggerMethod('bookingPageStatus:change', this.model.allStepsHaveValidInputs());
        });

        this.apiStepViews = {};
    }

    /**
     * Listen to events of child views
     */
    childViewEvents () {
        let isValid = this.model.allStepsHaveValidInputs();
        return {
            'pager:next:page': 'proceedToNextPage',
            'step:did:change': () => {
                const newValid = this.model.allStepsHaveValidInputs();
                if (this.model.id === Constants.PAGE_INSURANCE) { // special handling insurances TUICIBE-554
                    const showPager = this.insuranceSelectorModel.hasAcceptedConsultingProtocol && (this.model.allStepsHaveValidInputs() || this.model.allCompleted()) && !this.insuranceSelectorModel.isBlocked;
                    app.triggerMethod('bookingPageStatus:change', showPager);
                } else if (this.model.id === Constants.PAGE_TRAVEL) { // always trigger
                    app.triggerMethod('bookingPageStatus:change', this.model.allStepsHaveValidInputs());
                } else if (isValid !== newValid) { // trigger when changed
                    app.triggerMethod('bookingPageStatus:change', this.model.allStepsHaveValidInputs());
                }
                isValid = newValid;
            }
        };
    }


    /**
     * Getter for api steps, that need to be checked for errors and submit before proceeding zo next page.
     * Usually this should be all model.apiSteps, but might get an override,
     * if specific steps are optional on current page (e.g. insurance).
     *
     * @return {BaseCollection}
     */
    get apiStepsToCheckBeforeNextPage () {
        return this.model.apiSteps;
    }

    /**
     * Redirects to the next booking page after making sure
     * that all steps were submitted
     */
    proceedToNextPage (e = {}) {
        const nextPage = this.model.getNextApplicablePage();
        if (!nextPage) {
            return;
        }
        const route = NavigationUtil.normalizePath(nextPage.id);
        // check for invalid step
        const firstInvalidStep = this.apiStepsToCheckBeforeNextPage.find(apiStepModel => apiStepModel.hasInputErrors());
        if (firstInvalidStep) {
            firstInvalidStep.inputs.each(input => {
                input.trigger('change');
            });
            console.log('firstInvalidStep before submit', firstInvalidStep);
            if (this.options.key === Constants.PAGE_PERSONAL) {
                this.model.trigger('stepInvalidNeedCheckReactCabinTab', firstInvalidStep);
            }
            this.scrollToStepView(firstInvalidStep);
            return;
        }
        // eventTracking
        const text = nextPage.get('button-title') || `Weiter zur ${nextPage.get('page-title')}`;
        const options = e.options || {};
        const position = options.position;
        app.trackController.eventTracking({
            action: 'CTA',
            key: this.options.key,
            position,
            text,
            pageView: this,
            ga4clickLabel: `content.button.${text}`
        });

        if (nextPage.id === 'payment') {
            app.trackController.ga4Tracking('payment_add_info');
        }

        // check for steps that need to be submitted before proceeding
        const stepsThatNeedToBeSubmitted = this.apiStepsToCheckBeforeNextPage
            .filter(apiStepModel => {
                console.log('Check if needed: ', apiStepModel);
                if (apiStepModel.id === NETMATCH_STEP_ONLINE_RISK) {
                    return (new OnlineRiskStepModel(apiStepModel.attributes)).cannotAdvance();
                }
                return apiStepModel.needsToBeSubmitted();
            });
        if (!stepsThatNeedToBeSubmitted.length) {
            NavigationUtil.redirect(route);
        }
        const stepDataToSubmit = stepsThatNeedToBeSubmitted.map(apiStepModel => {
            return {
                'id': apiStepModel.id,
                'content': {
                    ...apiStepModel.getInputData()
                }
            };
        });
        if (!stepDataToSubmit.length) {
            NavigationUtil.redirect(route);
            return;
        }
        if (this.options.key === Constants.PAGE_CABIN) {
            stepDataToSubmit.push('noCheckCabinValuesReset');
        }

        // NU-348
        // DY Identify call for invoice email address
        // Dynamic Yield gets loaded via Tealium iQ so we have to check with its loaded or not this way
        if (window && window.DY && window.DY.API && window.DYO && window.DYO.dyhash) {
            const invoice = stepDataToSubmit.find(apiStepModel => apiStepModel.id === Constants.NETMATCH_STEP_INVOICE);
            if (invoice) {
                if (invoice.content && invoice.content[Constants.NETMATCH_INPUT_INVOICE_EMAIL]) {
                    window.DY.API('event', {
                        name: 'Identify',
                        properties: {
                            dyType: 'identify-v1',
                            hashedEmail: window.DYO.dyhash.sha256(invoice.content[Constants.NETMATCH_INPUT_INVOICE_EMAIL].toLowerCase())
                        }
                    });
                }
            }
        }


        app.apiSession.submitSteps(stepDataToSubmit, true)
            .then(() => {
                const invalidStep = this.apiStepsToCheckBeforeNextPage.find(apiStepModel => {
                    if (apiStepModel.id === NETMATCH_STEP_ONLINE_RISK) {
                        // open -> booking not allowed + additionally netmatch had to set completed for the two cases although it means no booking allowed
                        return (new OnlineRiskStepModel(apiStepModel.attributes)).cannotAdvance();
                    }
                    return apiStepModel.hasInputErrors();
                });
                if (!invalidStep) {
                    NavigationUtil.redirect(route);
                    return;
                }
                console.log('invalidStep after submit', invalidStep);
                app.triggerMethod('bookingPageStatus:change', false);
                if (invalidStep.id === NETMATCH_STEP_ONLINE_RISK) {
                    app.triggerMethod('onlineRisk:change', invalidStep);
                } else if (invalidStep.id === NETMATCH_STEP_PAYMENT) {
                    // in case sepa was disabled through online risk call, they give the result via the paymentType step so we have to check onlineRisk additionally...
                    const invalidStepRisk = this.apiStepsToCheckBeforeNextPage.find(apiStepModel => {
                        if (apiStepModel.id === NETMATCH_STEP_ONLINE_RISK) {
                            return (new OnlineRiskStepModel(apiStepModel.attributes)).removeSepa();
                        }
                        return false;
                    });

                    // prioritize risk result before payment result
                    if (invalidStepRisk) {
                        app.triggerMethod('onlineRisk:change', invalidStepRisk);
                        this.scrollToStepView(invalidStep);
                    } else if (invalidStep.inputs.get('Type').attributes.options.some(option => option.isBlocked)) {
                        app.triggerMethod('onlineRisk:change', {
                            attributes: {
                                netMatchAttributes: [
                                    {
                                        key: 'OnlineRisk',
                                        code: NETMATCH_ONLINE_RISK_NO_DIRECT_DEBIT
                                    }
                                ]
                            }
                        });
                    } else {
                        this.scrollToStepView(invalidStep);
                    }
                } else {
                    this.scrollToStepView(invalidStep);
                }

                /* eslint no-useless-return: 0 */
                return;
            })
            .catch((e) => {
                console.log(e);
                app.trigger('overlay:start', {
                    text: Constants.NM_SAVE_ERROR_TXT
                });
            });

        // console.log('next page', route, stepsThatNeedToBeSubmitted);
    }


    /**
     * Scrolls to the step view corresponding to the passed model
     */
    scrollToStepView (apiStepModel) {
        let $scrollToElement;
        if (this.apiStepViews[apiStepModel.id]) {
            $scrollToElement = this.apiStepViews[apiStepModel.id].$el;
        }
        if (!$scrollToElement) {
            $scrollToElement = this.$(`.api-step-${apiStepModel.sanitizedId}`);
        }
        if (!$scrollToElement.length) {
            $scrollToElement = this.$el;
        }

        // ist nur komisch aufgefallen bei step personl
        if (this.options.key === Constants.PAGE_PERSONAL) {
            $scrollToElement = $scrollToElement.parent('.section-personal');
        }

        app.trigger('scrollTo', $scrollToElement, () => {
            // collapse the navigation after scrolling
            const $window = $(window);
            setTimeout(() => {
                $window.scrollTop($window.scrollTop() + 1);
            }, 10);
        });
    }


    /**
     * Renders the booking pager views into their regions
     */
    initPager (autoEnable = true) {
        if (this.$el.find('.booking-pager.bottom').is('*')) {
            this.addRegion('booking-pager-bottom', '.booking-pager.bottom');
            this.showChildView('booking-pager-bottom', new BookingPagerView({
                model: this.model,
                autoEnable,
                position: 'bottom'
            }));
        }
        if (this.$el.find('.booking-pager.sticky').is('*')) {
            this.addRegion('booking-pager-sticky', '.booking-pager.sticky');
            this.showChildView('booking-pager-sticky', new BookingPagerView({
                model: this.model,
                hideBackButton: true,
                autoEnable,
                position: 'sticky'
            }));
        }
    }

    /**
     * Renders the given apiStepModels into their region, when a region with the name of the id of the step is defined
     * Loops through the given apiStepModels, checks for a regions which name matches the id of the step,
     * tries to find matching viewOptions for that step and renders the step to the region
     */
    renderApiStepsToRegions (apiStepModels) {
        apiStepModels.forEach(apiStepModel => {
            const apiStepId = apiStepModel.id;
            if (this.getRegion(apiStepId)) {
                const apiStepView = ApiStepFactory.create(apiStepId, this.getApiStepViewOptions(apiStepModel));
                this.showChildView(apiStepId, apiStepView);
                this.apiStepViews[apiStepId] = apiStepView;
            }
        });

        return this.apiStepViews;
    }


    /**
     * Gets any predefined viewOptions for that steps
     */
    getApiStepViewOptions (apiStepModel) {
        let stepViewOptions = _.result(this, 'apiStepViewOptions', {});
        stepViewOptions = stepViewOptions[apiStepModel.id] || {};
        stepViewOptions.model = apiStepModel;
        return stepViewOptions;
    }
}
