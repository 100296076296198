import {CabinInfo, InvoiceInfo} from './interface';
import React, {useRef} from 'react';
import app from '../../app/app';
import CampaingCodeExitView from '../overlay/campaign-code-exit';
import {FormattedMessage, useIntl} from 'react-intl';

type ParticipantProps = {
    cabinInfo: CabinInfo[],
    invoiceInfo: InvoiceInfo,
    moodImage: string,
    isB2B: boolean,
    hideEdit?: boolean
}

/**
 * View: Ihre Daten
 *
 * @param props ParticipantProps
 */
export default function ParticipantView (props: ParticipantProps) {
    const {formatMessage} = useIntl();
    const {cabinInfo, invoiceInfo, moodImage, isB2B, hideEdit} = props;

    const trackEvent = () => {
        if (app.trackController) {
            app.trackController.eventTracking({
                action: 'Übersicht',
                label: 'Daten',
                ga4clickLabel: 'content.icon.uebersicht-persoenliche-daten'
            });
        }
        if (app.router) {
            if (app?.apiSession?.bookingStatus?.campaignCodes.hasEnteredCode()) {
                onCampaignCodeExitOverlay();
            } else {
                app.router.redirect('personal', true, false);
            }
        }
        return false;
    };

    // TUICUNIT-3170
    const gotToTarget = () => {
        if (app.router) {
            app.router.redirect('personal', true, false);
        }
    };

    const overlayRef = useRef();
    const onCampaignCodeExitOverlay = () => {
        // @ts-ignore
        if (overlayRef.current && overlayRef.current.showModal === false) {
            // @ts-ignore
            overlayRef.current.show();
        }
    };

    const confirmData = {
        confirmCallback: gotToTarget,
        closeOnBgClick: true
    };

    return (
        <section className="check-section print-page-break-before">
            <div className="row">
                {!isB2B ?
                    <div className="col-sm-4 hidden-xs-down">
                        <div className="check-image">
                            <img alt={formatMessage({
                                id: 'components.overview.participant.data'
                            })} src={moodImage} width="500" loading="lazy"/>
                        </div>
                    </div>
                    : ''}
                <div className="col-xs-12 col-sm-8 text-wrapper">
                    <div className="section-headline">
                        <FormattedMessage id="components.overview.participant.headline"/>
                    </div>
                    {cabinInfo.map((item, key: number) => {
                        return (
                            <div key={key} className={`per-cabin${key === 0 ? ' is-first' : ''}`}>
                                <div className="bold"><FormattedMessage id="general.cabin"/> {item.cabinIndex}</div>
                                {item.participantPricesDetail?.map((pItem, pKey: number) => {
                                    return (
                                        <div key={pKey}>
                                            {pItem.displayName}, <FormattedMessage id="components.overview.participant.bday"/>: {pItem.dateOfBirth}, {pItem.nationality}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                    <br/>
                    <div className="bold">
                        <FormattedMessage id="components.overview.participant.invoice-receipent"/>
                    </div>
                    {invoiceInfo ?
                        <>
                            <div>
                                {invoiceInfo.salutation ? `${invoiceInfo.salutation} ` : ''}
                                {invoiceInfo.title ? `${invoiceInfo.title} ` : ''}
                                {invoiceInfo.firstName ? `${invoiceInfo.firstName} ` : ''}
                                {invoiceInfo.lastName ? invoiceInfo.lastName : ''}
                            </div>
                            {invoiceInfo.streetAndHouseNumber}<br/>
                            {invoiceInfo.additionalAddress ? (<>{invoiceInfo.additionalAddress}<br/></>) : ''}
                            {invoiceInfo.postalCode} {invoiceInfo.city}<br/>
                            {invoiceInfo.country}<br/><br/>
                            <FormattedMessage id="components.overview.participant.mail"/>: {invoiceInfo.email}<br/>
                            <FormattedMessage id="components.overview.participant.phone"/>: {invoiceInfo.telephone}<br/>
                            {!!invoiceInfo.mobileNumber && (
                                <><FormattedMessage id="components.overview.participant.mobile"/>: {invoiceInfo.mobileNumber}<br/></>
                            )}
                        </>
                        : ''}
                </div>
            </div>
            <span className={`button button-secondary edit-link ${hideEdit ? 'hidden' : ''}`} aria-label={formatMessage({
                id: 'components.overview.participant.back'
            })} onClick={trackEvent}>
                <span className="icon-ic-edit"></span>
            </span>
            <CampaingCodeExitView data={confirmData} ref={overlayRef}></CampaingCodeExitView>
        </section>
    );
}

