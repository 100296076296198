import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function DefaultOverlay () {
    return (
        <div className="overlay-default">
            <h3 className="overlay-headline">
                <FormattedMessage id="components.overlay.default.headline" />
            </h3>
            <div className="row bordered-row">
                <FormattedMessage id="components.overlay.default.text" />
            </div>
        </div>
    );
}
