import ApiInputTextView from './view/text';
// import ApiInputNumberView from './view/number';
import ApiInputSelectView from './view/select';
import ApiInputBusSelectView from './view/bus-select';
import ApiInputComboboxView from './view/combobox';
import ApiInputCheckboxView from './view/checkbox';
import ApprovalCheckboxView from './view/approval';
import ApiInputRadiobuttonsView from './view/radiobuttons';
import ApiInputDateView from './view/date';
// import ApiInputCabinTypeView from './view/cabintype';
// import ApiInputCabinNumberiew from './view/cabin-number';
import ApiInputTransportTypeOptionsView from './view/transporttype-options';
import ApiInputTypeAheadView from './view/typeahead';
import ApiInputFlightOptionsView from './view/flight-options';
// import ApiInputRelatedOptionsView from './view/related-options';
import ApiInputBusOptionsView from './view/bus-options';
import ApiInputPaymentOptionsView from './view/payment-options';
import ApiInputParticipantSelectView from './view/participant-select';
import ApiInputPolicyTypeView from './view/policy-type';
import ApiInputCountrySelectView from './view/country-select';
import ApiInputEmailView from './view/email';
import ApiInputEmailRepeatView from './view/email-repeat';
import ApiInputIbanView from './view/iban';

export default {

    create: (type, ...args) => {
        let InputViewClass;
        switch (type) {
            case 'text':
                InputViewClass = ApiInputTextView;
                break;
            case 'select':
                InputViewClass = ApiInputSelectView;
                break;
            case 'bus-select':
                InputViewClass = ApiInputBusSelectView;
                break;
            case 'combobox':
                InputViewClass = ApiInputComboboxView;
                break;
            case 'typeahead':
                InputViewClass = ApiInputTypeAheadView;
                break;
            /* case 'number':
                InputViewClass = ApiInputNumberView;
                break; */
            case 'checkbox':
                InputViewClass = ApiInputCheckboxView;
                break;
            case 'approval':
                InputViewClass = ApprovalCheckboxView;
                break;
            case 'radiobuttons':
                InputViewClass = ApiInputRadiobuttonsView;
                break;
            case 'date':
                InputViewClass = ApiInputDateView;
                break;
                /* case 'cabintype':
InputViewClass = ApiInputCabinTypeView;
break; */
                /* case 'cabin-number':
InputViewClass = ApiInputCabinNumberiew;
break; */
            /* case 'related-options':
                InputViewClass = ApiInputRelatedOptionsView;
                break; */
            case 'transporttype-options':
                InputViewClass = ApiInputTransportTypeOptionsView;
                break;
            case 'flight-options':
                InputViewClass = ApiInputFlightOptionsView;
                break;
            case 'bus-options':
                InputViewClass = ApiInputBusOptionsView;
                break;
            case 'payment-options':
                InputViewClass = ApiInputPaymentOptionsView;
                break;
            case 'participant-select':
                InputViewClass = ApiInputParticipantSelectView;
                break;
            case 'policy-type':
                InputViewClass = ApiInputPolicyTypeView;
                break;
            case 'country-select':
                InputViewClass = ApiInputCountrySelectView;
                break;
            case 'email':
                InputViewClass = ApiInputEmailView;
                break;
            case 'email-repeat':
                InputViewClass = ApiInputEmailRepeatView;
                break;
            case 'iban':
                InputViewClass = ApiInputIbanView;
                break;
            default:
                throw new Error(`Unknown input type "${type}"`);
        }
        return new InputViewClass(...args);
    }

};
