import BaseItem from '../../../base/view/item';
import ApiStepFactory from '../../../item/api-step/factory';
import templateWebpack from '../../../../tpl/item/insurance-selector/insurance-assignment.hbs';
import * as Constants from '../../../config/constants';
import {getIntl} from '../../../intl';


export default class InsuranceAssignmentView extends BaseItem {
    constructor (options = {}) {
        super({
            templateWebpack, key: 'InsuranceAssignment', ...options
        });

        this.insuranceSelectorModel = options.insuranceSelectorModel;
    }


    insuranceIsBlocked () {
        const policyTypes = this.insuranceSelectorModel.getApiStepsPolicyType();
        if (policyTypes && policyTypes.length) {
            const idToLookFor = 'policyType/policy-' + this.model.attributes.id;
            const insurance = policyTypes.find((policy) => policy.id === idToLookFor);

            if (insurance) {
                const input = insurance.inputs.get(Constants.NETMATCH_INPUT_POLICY);
                if (!input) {
                    return false;
                }
                return input.attributes && input.attributes.options ? input.attributes.options.some((option) => option.id === input.getInputValue() && option.isBlocked) : false;

            }

        }
        return false;
    }


    get className () {
        return 'insurance-selector-insurance-assignment';
    }


    triggers () {
        return {
            'click .js-button-edit-insurances': 'edit:insurances'
        };
    }


    regions () {
        return {
            'policy-type': {
                el: '.api-step-region-policy-type', replaceElement: true
            }
        };
    }


    events () {
        return {
            'change .js-participant-select': 'onParticipantSelect'
        };
    }


    /**
     * Add details to the data passed into the template.
     * Will be merged into the model / collection data.
     */
    templateContext () {
        const {formatMessage} = getIntl();

        const insuranceMapping = {
            [Constants.NETMATCH_INSURANCE_MEMBERSTYPE_INDIVIDUAL]: {
                name: formatMessage({
                    id: 'js.item.insurance-selector.view.insurance-assignment.individual'
                }),
                icon: 'icon-insurance-single-empty'
            },
            [Constants.NETMATCH_INSURANCE_MEMBERSTYPE_COUPLE]: {
                name: formatMessage({
                    id: 'js.item.insurance-selector.view.insurance-assignment.couple'
                }),
                icon: 'icon-insurance-couple-empty'
            },
            [Constants.NETMATCH_INSURANCE_MEMBERSTYPE_FAMILY]: {
                name: formatMessage({
                    id: 'js.item.insurance-selector.view.insurance-assignment.family'
                }),
                icon: 'icon-insurance-family-empty'
            }
        };

        return {
            'insuranceName': insuranceMapping[this.model.get('type')].name,
            'insuranceIcon': insuranceMapping[this.model.get('type')].icon,
            'participantDetails': this.getParticipantDetails(),
            'isBlocked': this.insuranceIsBlocked()
        };
    }

    onRender () {
        const policyTypeStepView = ApiStepFactory.create(this.model.policyTypeStep.id, {
            model: this.model.policyTypeStep, priceLabel: this.getPriceLabel()
        });
        this.showChildView('policy-type', policyTypeStepView);
    }


    onParticipantSelect (e) {
        const $select = this.$(e.currentTarget);
        const currentParticipantId = $select.data('participant-id');
        const nextParticipantId = $select.val();
        this.model.collection.swapParticipants(this.model, currentParticipantId, nextParticipantId);
    }


    getParticipantDetails () {
        if (!this.insuranceSelectorModel.isParticipantsDataRequired()) {
            return [];
        }

        const {formatMessage} = getIntl();

        return this.model.assignedParticipantSteps.map((currentParticipantStep, index) => {
            const otherParticipants = this.insuranceSelectorModel.getApiStepsParticipants().reduce((participants, participantStep) => {
                let participantName;
                if (participantStep.get('adultIndex')) {
                    participantName = ` ${participantStep.get('adultIndex')}. ${formatMessage({
                        id: 'general.persons.adult'
                    }, {
                        amount: 0
                    })}`;
                }
                if (participantStep.get('childIndex')) {
                    participantName = ` ${participantStep.get('childIndex')}. ${formatMessage({
                        id: 'general.persons.child'
                    }, {
                        amount: 1
                    })}`;
                }

                // generate nice name if possible
                const firstNameInput = participantStep.inputs.get(Constants.NETMATCH_INPUT_INVOICE_FIRSTNAME);
                const firstName = firstNameInput ? firstNameInput.getInputValue() : '';
                const lastNameInput = participantStep.inputs.get(Constants.NETMATCH_INPUT_INVOICE_LASTNAME);
                const lastName = lastNameInput ? lastNameInput.getInputValue() : '';
                if (firstName && lastName) {
                    const salutationInput = participantStep.inputs.get(Constants.NETMATCH_INPUT_INVOICE_SALUTATIONCODE);
                    const salutation = salutationInput ? salutationInput.get('options').reduce((acc, option) => (option.id === salutationInput.getInputValue() ? option.displayText : acc), '') : '';
                    const titleInput = participantStep.inputs.get(Constants.NETMATCH_INPUT_INVOICE_TITLE);
                    const title = titleInput ? titleInput.get('options').reduce((acc, option) => (option.id === titleInput.getInputValue() ? option.displayText : acc), '') : '';
                    participantName = ` ${salutation} ${title} ${firstName} ${lastName}`;
                }

                participants.push({
                    stepId: participantStep.id,
                    name: participantName,
                    selected: (currentParticipantStep === participantStep)
                });
                return participants;
            }, []);

            return {
                id: currentParticipantStep.id,
                label: this.model.assignedParticipantSteps.length > 1
                    ? formatMessage({
                        id: 'js.item.insurance-selector.view.insurance-assignment.label.multi'
                    }, {
                        no: index + 1
                    })
                    : formatMessage({
                        id: 'js.item.insurance-selector.view.insurance-assignment.label.single'
                    }),
                otherParticipants
            };
        });
    }


    getPriceLabel () {
        let priceLabel;
        const {formatMessage} = getIntl();

        switch (this.model.get('type')) {
            case 'individual':
                priceLabel = formatMessage({
                    id: 'js.item.insurance-selector.view.insurance-assignment.price-label.individual'
                });
                break;
            case 'couple':
                priceLabel = formatMessage({
                    id: 'js.item.insurance-selector.view.insurance-assignment.price-label.couple'
                });
                break;
            case 'family':
                priceLabel = formatMessage({
                    id: 'js.item.insurance-selector.view.insurance-assignment.price-label.family'
                });
                break;
            default:
                // fallback
                priceLabel = formatMessage({
                    id: 'js.item.insurance-selector.view.insurance-assignment.price-label.individual'
                });
                break;
        }
        return priceLabel;
    }
}
