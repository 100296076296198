import { CabinInfo } from './interface';
import React from 'react';

import { CabinPrice } from '../cabin/cabin-price';
import { FormattedMessage } from 'react-intl';


type PricePersonProps = {
    cabinInfo: CabinInfo[];
    moodImage: string;
    isB2B: boolean;
}

/**
 * View: Preis pro Person
 *
 * @param props PricePersonProps
 */
export default function PricePersonlView (props:PricePersonProps) {
    const {cabinInfo, moodImage, isB2B } = props;

    return (
        <section className="check-section">
            <div className="row">
                {!isB2B ?
                    <div className="col-sm-4 hidden-xs-down">
                        <div className="check-image">
                            <img alt="Preis pro Person" src={moodImage} width="500" loading="lazy"/>
                        </div>
                    </div>
                    : ''}
                <div className={`col-xs-12${!isB2B ? ' col-sm-8' : ''} text-wrapper`}>
                    <div className="section-headline">
                        <FormattedMessage id="components.overview.price-person.headline" />
                    </div>
                    {cabinInfo.map((item, key: number) => {
                        return (
                            <div key={key} className={`per-cabin${key === 0 ? ' is-first' : ''}`}>
                                <div className="bold"><FormattedMessage id="general.cabin" /> {item.cabinIndex}</div>
                                {item.participantPricesDetail?.map((pItem, pKey: number) => {
                                    return (
                                        <div key={pKey} className="price-per-person">
                                            <div className="row price-total">
                                                <div className="col-xs-8 label">
                                                    {pItem.displayName}
                                                    {pItem.extraNote ? (<> ({pItem.extraNote})</>) : ''}
                                                </div>
                                                <div className="col-xs-4 value total-value nobr">
                                                    <CabinPrice price={pItem.participantTotalPrice} mode="simple" />
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
}
