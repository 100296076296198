import { ParticipantPrices, CabinInfo } from './interface';
import React from 'react';

import * as Constants from '../../config/constants';
import { FormattedMessage } from 'react-intl';
import { CabinPrice } from '../cabin/cabin-price';


type VipDetail = {
    category: string;
    price: number;
    person: string;
    key: number;
    cabinIndex: number;
}
type VipWrapper = {
    cabinIndex: string;
    vipText: string | null;
    value: VipDetail[]
};

type PriceVipProps = {
    participantPrices: [ParticipantPrices],
    cabinInfo: [CabinInfo];
    moodImage: string;
    isB2B: boolean;
}

/**
 * View: VIP
 *
 * @param props PriceVipProps
 */
export default function PriceVipView (props: PriceVipProps) {
    const {participantPrices, cabinInfo, moodImage, isB2B } = props;

    const { hasVip, vip} = prepareVip(participantPrices, cabinInfo);

    return (
        <>
            {hasVip ?
                <section className="check-section">
                    <div className="row">
                        {!isB2B ?
                            <div className="col-sm-4 hidden-xs-down">
                                <div className="check-image">
                                    <img alt="vip" src={moodImage} width="500" loading="lazy"/>
                                </div>
                            </div>
                            : ''}
                        <div className={`col-xs-12${!isB2B ? ' col-sm-8' : ''} text-wrapper`}>
                            <div className="section-headline">
                                {vip[0].vipText}
                            </div>
                            <div>
                                {vip.map((item:VipWrapper, key:number) => {
                                    return (
                                        <div key={key} className="discount-section">
                                            <div className="bold"><FormattedMessage id="general.cabin" /> {item.cabinIndex}</div>
                                            {item.value.map((pItem: VipDetail, pKey: number) => {
                                                return (
                                                    <div key={pKey} className="row price-per-person price-discount">
                                                        <div className="col-xs-7 label">{pItem.person}</div>
                                                        <div className="col-xs-5 value total-value nobr">
                                                            <CabinPrice price={pItem.price} mode="simple" />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </section>
                : ''}
        </>
    );
}


/**
 *  filter, group, participantPrices VIP
 **/
const prepareVip = (raw: [ParticipantPrices], rawCabin: [CabinInfo]) => {
    // prepared participantPrices list to flat list with necessary discount data
    const filter = raw.reduce((list: VipDetail[], item: ParticipantPrices) => {
        item.prices.forEach((value: { price: number; serviceCategory: string;}) => {
            if (value.serviceCategory === Constants.NETMATCH_STATUS_PRICES_VIP_SERVICECATEGORY) {
                list.push({
                    category: value.serviceCategory,
                    price: value.price,
                    person: item.displayName,
                    key: item.index,
                    cabinIndex: item.cabinIndex
                });
            }
        });
        return list;
    }, []);

    type GroupType = {
        [name:string]: VipDetail[]
    }
    // group list by cabinIndex
    const groupByCategory = filter.reduce((list: GroupType, item) => {
        list[item.cabinIndex] = (list[item.cabinIndex] || []).concat(item);
        return list;
    }, {});

    let hasVip = false;
    const vip: VipWrapper[] = [];
    Object.keys(groupByCategory).forEach((index) => {
        hasVip = true;
        const cabinData = rawCabin.find(item => item.cabinIndex === parseInt(index, 10));
        const value = groupByCategory[index].sort((a: VipDetail, b: VipDetail) => {
            return (a.key) - (b.key);
        });
        vip.push({
            cabinIndex: index,
            vipText: cabinData ? cabinData.vip : 'VIP-Tarifoption',
            value
        });
    });
    return {hasVip, vip};
};
