import ApiStepModel from './step';
import {
    NETMATCH_ONLINE_RISK_NO_BOOKING_BLACKLISTED, NETMATCH_ONLINE_RISK_NO_BOOKING_UNKNOWN_REASON, NETMATCH_ONLINE_RISK_NO_DIRECT_DEBIT, NETMATCH_ONLINE_RISK_UNKNOWN, NETMATCH_STEP_STATUS_ERROR
} from '../../../config/constants';


export default class OnlineRiskStepModel extends ApiStepModel {

    removeSepa () {
        if (this.isOpen()) {
            return true;
        }
        if (this.isCompleted()) {
            const atts = this.getNetmatchAttributes();
            if (atts) {
                return [NETMATCH_ONLINE_RISK_NO_DIRECT_DEBIT, NETMATCH_ONLINE_RISK_UNKNOWN].includes(atts.code);
            }
        }
        return this.status === NETMATCH_STEP_STATUS_ERROR;
    }

    cannotAdvance () {
        if (this.isOpen()) {
            return true;
        }
        if (this.isCompleted()) {
            const atts = this.getNetmatchAttributes();
            if (atts) {
                return [NETMATCH_ONLINE_RISK_NO_BOOKING_BLACKLISTED, NETMATCH_ONLINE_RISK_NO_BOOKING_UNKNOWN_REASON].includes(atts.code);
            }
        }

        return this.status === NETMATCH_STEP_STATUS_ERROR;
    }

    getNetmatchAttributes () {
        return this.attributes.netMatchAttributes.find((att) => att.key === 'OnlineRisk');
    }
}
