import BaseOverlayView from './base';
import templateWebpack from '../../../../tpl/item/overlay/travel-child.hbs';


export default class TravelChildView extends BaseOverlayView {
    constructor (options = {}) {
        super({
            key: 'overlay-travel-child',
            templateWebpack,
            ...options
        });
    }
}
