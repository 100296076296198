import React, {forwardRef, useState, useImperativeHandle} from 'react';
import ReactModal from 'react-modal';
type Props = {
    data: any
};


const AlertView = (props:Props, ref:any) => {
    const [showModal, setShowModal] = useState(false);

    const { data} = props;

    useImperativeHandle(ref, () => ({
        showModal: showModal,

        show () {
            setShowModal(true);
        }
    }));

    const hide = (runCallbacks: boolean = true) => {
        if (runCallbacks && data.closeCallback) {
            data.closeCallback();
        }
        setShowModal(false);
    };

    const confirm = () => {
        hide(false);
        if (data.confirmCallback) {
            data.confirmCallback();
        }
    };


    const closeButtonText = (data.closeButtonText) ? (data.closeButtonText) : 'Schließen';

    const closable = (data.closable) ? (<a className="alert-close" onClick={() => { hide(); }}></a>) : '';
    const headline = (data.headline) ? (<h3 className="alert-headline" dangerouslySetInnerHTML={{__html: data.headline}}></h3>) : '';
    const confirmClass = (data.confirmClass) ? data.confirmClass : '';
    const confirmButton = (data.confirmButtonText) ? (<a className="button" onClick={confirm}>{data.confirmButtonText}</a>) : '';
    const closeButton = (data.closable) ? (<a className="button button-secondary" onClick={() => { hide(); }}>{closeButtonText}</a>) : '';

    return (
        <ReactModal
            isOpen={showModal}
            className="alert-container"
            overlayClassName="overlay-generell"
            bodyOpenClassName="noscroll"
        >
            <div className="alert confirm overlay">
                {closable}
                {headline}
                <p className="alert-text" dangerouslySetInnerHTML={{__html: data.text}}></p>
                <br/>
                <div className={`alert-buttons ${confirmClass}`}>
                    {closeButton}
                    {confirmButton}
                </div>
            </div>

        </ReactModal>
    );
};

export default forwardRef(AlertView);
