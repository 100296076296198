import ApiInputChoiceBaseView from './basechoice';
import templateWebpack from '../../../../tpl/item/api-input/radiobuttons.hbs';


export default class ApiInputRadiobuttonsView extends ApiInputChoiceBaseView {
    constructor (options) {
        super({
            templateWebpack,
            ...options
        });
    }

    /**
     * View events
     */
    events () {
        return {
            [`change input[type="radio"][name="${this.model.domId}"]`]: 'onValueChange'
        };
    }


    /**
     * Overwritten: gets the value from the checked radio element
     *
     * @return {[type]} [description]
     */
    onValueChange () {
        this.setValue(this.$(`input[type="radio"][name="${this.model.domId}"]`).filter(':checked').val());
    }
}
