import BaseLayout from './base';
import HeaderView from '../item/header/view/layout';
import ModalsView from '../item/modals';

export default class ErrorLayout extends BaseLayout {
    /**
     * Constructor
     */
    constructor (options = {}) {
        super({
            key: 'error',
            ...options
        });
    }


    /**
     * View regions
     */
    regions () {
        return {
            ...super.regions.apply(this),
            'header': {
                el: '.page-header-region',
                replaceElement: true
            },
            'modals': {
                el: '.modal-region',
                replaceElement: true
            }
        };
    }


    onRender () {
        this.showChildView('header', new HeaderView({'noNavigationBar': true}));
        this.showChildView('modals', new ModalsView());
        this.$('.page-header-navigation-bar').hide();
    }
}
