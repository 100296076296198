import * as Constants from '../../../config/constants';
import BaseCollection from '../../../base/collection/base';
import InsuranceModel from '../model/insurance';

export default class InsurancesCollection extends BaseCollection {
    constructor (models, options = {}) {
        // pass the given models as BookingPageModels to the BaseCollection constructor
        super(models, {
            model: InsuranceModel,
            ...options
        });
        this.insuranceSelectorModel = options.insuranceSelectorModel;
    }


    getCompositionValues () {
        return this.reduce((models, model) => {
            if (model.isDummyInsurance()) {
                return models;
            }
            const compositionValues = {
                adults: models.adults + model.get('adults'),
                childs: models.childs + model.get('childs'),
                minTotal: models.minTotal + model.get('minTotal'),
                maxTotal: models.maxTotal + model.get('maxTotal'),
                minAdults: models.minAdults + model.get('minAdults'),
                maxAdults: models.maxAdults + model.get('maxAdults'),
                minChilds: models.minChilds + model.get('minChilds'),
                maxChilds: models.maxChilds + model.get('maxChilds')
            };
            return compositionValues;
        }, {
            adults: 0,
            childs: 0,
            minAdults: 0,
            maxAdults: 0,
            minChilds: 0,
            maxChilds: 0
        });
    }


    canSkipParticipantData () {
        // no, if empty
        if (!this.length) {
            return false;
        }
        // no, if not all are individual insurances
        const allAreIndividual = this.every(insurance => insurance.isIndividualInsurance());
        if (!allAreIndividual) {
            return false;
        }

        const firstInsurance = this.first();

        try {
            // yes, if some values are not set
            const someValuesNotSet = this.some(insurance => !insurance.policyTypeStep.inputs.get(Constants.NETMATCH_INPUT_POLICY).getInputValue());
            if (someValuesNotSet) {
                return true;
            }
            // yes if all policyTypes are same
            const policyType = firstInsurance.policyTypeStep.inputs.get(Constants.NETMATCH_INPUT_POLICY).getInputValue();
            return this.every(insurance => (policyType === insurance.policyTypeStep.inputs.get(Constants.NETMATCH_INPUT_POLICY).getInputValue()));
        } catch (e) {
            // no, if error
            return false;
        }
    }


    swapParticipants (fromInsurance, fromParticipantId, withParticipantId) {
        const fromParticipant = fromInsurance.assignedParticipantSteps.get(fromParticipantId);
        let withInsurance;
        let withParticipant;
        this.find(insurance => {
            withParticipant = insurance.assignedParticipantSteps.get(withParticipantId);
            if (withParticipant) {
                withInsurance = insurance;
                return true;
            }
            return false;
        });

        if (!fromParticipant || !withParticipant) {
            return false;
        }

        // special handling if swapping within one insurance
        if (fromInsurance === withInsurance) {
            const fromIndex = fromInsurance.assignedParticipantSteps.indexOf(fromParticipant);
            const withIndex = fromInsurance.assignedParticipantSteps.indexOf(withParticipant);
            fromInsurance.assignedParticipantSteps.models[fromIndex] = fromInsurance.assignedParticipantSteps.models.splice(withIndex, 1, fromInsurance.assignedParticipantSteps.models[fromIndex])[0];
            fromInsurance.assignedParticipantSteps.trigger('change'); // trigger change as we manipulated the models array directly
            return true;
        }

        // swap between two insurances
        fromInsurance.assignedParticipantSteps.remove(fromParticipant, {
            silent: true
        });
        withInsurance.assignedParticipantSteps.remove(withParticipant, {
            silent: true
        });
        withInsurance.assignedParticipantSteps.add(fromParticipant);
        fromInsurance.assignedParticipantSteps.add(withParticipant);

        return true;
    }


    getIndividualInsurances () {
        return this.filterByType(Constants.NETMATCH_INSURANCE_MEMBERSTYPE_INDIVIDUAL);
    }


    getCoupleInsurances () {
        return this.filterByType(Constants.NETMATCH_INSURANCE_MEMBERSTYPE_COUPLE);
    }


    getFamilyInsurances () {
        return this.filterByType(Constants.NETMATCH_INSURANCE_MEMBERSTYPE_FAMILY);
    }


    getDummyInsurances () {
        return this.filterByType(Constants.NETMATCH_INSURANCE_MEMBERSTYPE_DUMMY);
    }


    filterByType (type) {
        return this.filter((model) => model.get('type') === type);
    }
}
