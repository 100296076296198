import BaseItem from '../../base/view/item';
import SpinnerView from './spinner/view/layout';
import AlertView from './alert/view/layout';
import OverlayView from './overlay/view/layout';
import templateWebpack from '../../../tpl/item/modal/layout.hbs';

export default class ModalsView extends BaseItem {
    constructor (options) {
        super(Object.assign({
            key: 'modal',
            templateWebpack,
            regions: {
                'spinner': '.spinner-view',
                'alert': '.alert-view',
                'overlay': '.overlay-view'
            }
        }, options));
    }


    onRender () {
        this.showChildView('spinner', new SpinnerView());
        this.showChildView('alert', new AlertView());
        this.showChildView('overlay', new OverlayView());
    }
}

