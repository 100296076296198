import BaseItem from '../../../base/view/item';
import templateWebpack from '../../../../tpl/item/insurance-selector/coverage-status.hbs';


export default class InsuranceSelectorCoverageStatusView extends BaseItem {
    constructor (options = {}) {
        super({
            key: 'coverage-status',
            templateWebpack,
            ...options
        });
    }


    get className () {
        return 'insurance-selector-coverage-status';
    }

    /**
     * Add details to the data passed into the template.
     * Will be merged into the model / collection data.
     */
    templateContext () {
        const partyTotalCount = this.model.getPartyTotalCount();
        const insuredTotalCount = this.model.getInsuredTotalCount();

        const iconClass = partyTotalCount === insuredTotalCount ? 'icon-checkmark' : 'icon-exclamation-mark';
        const hasError = !this.model.hasValidInsuranceSelection();

        return {
            partyTotalCount,
            insuredTotalCount,
            iconClass,
            hasError
        };
    }
}
