import React from 'react';
import { FormattedMessage } from 'react-intl';
import app from '../../../app/app';

/**
 * B2B payment-text
 */
export default function B2BPaymentTextView () {

    let isAgentur = false;
    if (app.apiSession && app.apiSession.bookingStatus && app.apiSession.bookingStatus.agency.isAgentur()) {
        isAgentur = false;
    }

    return (
        <div className="b2b-view">
            {isAgentur && (
                <div className="agentur success-box">
                    <div className="headline">
                        <FormattedMessage id="components.sections.b2b.payment-text.headline-agency" />
                    </div>
                    <div className="text">
                        <FormattedMessage id="components.sections.b2b.payment-text.text-agency" />
                    </div>
                </div>
            )}
            <h4 className="headline">
                <FormattedMessage id="components.sections.b2b.payment-text.headline" />
            </h4>
            <div className="second">
                <FormattedMessage id="components.sections.b2b.payment-text.cancel" />
            </div>
            <div className="text">
                <FormattedMessage id="components.sections.b2b.payment-text.text" values={{
                    a1: (chunks: React.ReactNode) => <a href="https://www.meinschiff.com" rel="noopener noreferrer">{chunks}</a>,
                    a2: (chunks: React.ReactNode) => <a href="mailto:reservierung@tuicruises.com">{chunks}</a>
                }} />
            </div>
        </div>
    );
}

