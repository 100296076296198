// TUICUNIT-3170: Aktionscodes
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatPrice } from '../../intl';

type Props = {
    data: {
        headline: string;
        text: string;
        type: string;
        value?: number;
    }
    method: Function;
};

export default function CampaignCodeOverlay (props:Props) {
    const { data, method } = props;

    const closeModal = () => {
        method();
    };

    let displayValue = '';
    if (data.value) {
        displayValue = formatPrice(data.value);
    }

    return (
        <div className="overlay-campaign-code">
            <div className="stupid-extra-for-that"></div>
            <div className={data.type === 'error' ? 'not-ok' : 'ok'}></div>
            <div>
                <h3 className="overlay-headline">{data.headline}</h3>
                <div>{data.text.replace('[Betrag]', displayValue)}</div>
            </div>
            <button className="button" onClick={closeModal}>
                {data.type === 'error'
                    ? (
                        <FormattedMessage id="components.overlay.campaign-code.close" />
                    )
                    : (
                        <FormattedMessage id="components.overlay.campaign-code.accept" />
                    )}
            </button>
        </div>
    );
}
