import _ from 'underscore';
import ApiInputChoiceBaseView from './basechoice';
import templateWebpack from '../../../../tpl/item/api-input/flight-options.hbs';
import app from '../../../app/app';
import BaseModel from '../../../base/model/base';
import BaseCollection from '../../../base/collection/base';
import TravelFlightOptionView from '../../../item/travel-flight-option/view/item';
import TravelFlightOptionListView from '../../../item/travel-flight-option/view/list';
import * as Constants from '../../../config/constants';
import { getIntl } from '../../../intl';


export default class ApiInputFlightOptionsView extends ApiInputChoiceBaseView {
    constructor (options) {
        super({
            templateWebpack,
            ...options
        });

        this.showMoreFlights = false;
    }

    /**
     * View regions
     */
    regions () {
        return {
            'selected-flight': {
                el: '.region-selected-flight',
                replaceElement: true
            },
            'flights': {
                el: '.region-flights',
                replaceElement: true
            }
        };
    }


    /**
     * View events
     */
    events () {
        return {
            'click .js-toggle-more-flights': 'onToggleMoreFlights'
        };
    }


    templateContext () {
        const isShipbound = this.options.isShipbound;
        const flights = [...this.model.get('options')];
        const selectedFlight = flights.find(flight => flight.selected);

        return {
            isShipbound: this.options.isShipbound,
            mainTitle: selectedFlight
                ? ''
                : getIntl().formatMessage({
                    id: isShipbound ? 'js.item.api-input.view.flight-options.to' : 'js.item.api-input.view.flight-options.back'
                })
        };
    }


    onRender () {
        const flights = this.prepareFlightsData();
        const selectedFlight = flights.find(flight => flight.selected);
        const unselectedFlights = flights.filter(flight => !flight.selected);
        const { formatMessage } = getIntl();

        let moreFlightsButtonText = formatMessage({
            id: 'js.item.api-input.view.flight-options.alternatives'
        });
        if (!this.showMoreFlights) {
            moreFlightsButtonText = formatMessage({
                id: this.options.isShipbound ? 'js.item.api-input.view.flight-options.alternative-to' : 'js.item.api-input.view.flight-options.alternative-back'
            }, {
                amount: unselectedFlights.length
            });
        }

        if (selectedFlight) {
            this.showChildView('selected-flight', new TravelFlightOptionView({
                model: new BaseModel(selectedFlight),
                hasMoreFlights: !!unselectedFlights.length,
                showMoreFlights: this.showMoreFlights,
                moreFlightsButtonText
            }));
        }

        if (this.showMoreFlights && unselectedFlights.length) {
            this.showChildView('flights', new TravelFlightOptionListView({
                collection: new BaseCollection(unselectedFlights)
            }));
        }
    }


    prepareFlightsData () {
        const isShipbound = this.options.isShipbound;

        // handle flight segments
        let flights = [...this.model.get('options')].map(flight => {
            let flightSegments;
            const feederSegments = flight.feederSegments || [];
            if (isShipbound) { // hin -> feeder before segments
                flightSegments = [...feederSegments, ...flight.segments];
            } else { // back -> feeder after segments
                flightSegments = [...flight.segments, ...feederSegments];
            }
            return {
                ...flight,
                flightSegments
            };
        });

        // TUICIBE-373: filter isBlocked flights
        flights = flights.filter(flight => !flight.isBlocked);

        // group flights by its flightIds field
        const groupedFlights = Object.values(_.groupBy(flights, 'flightIds'));

        // loop over groups
        flights = groupedFlights.map(flightClasses => {
            const selectedFlight = flightClasses.find(flightClass => flightClass.selected);
            const flightToDisplay = selectedFlight || flightClasses[0];
            return {
                selected: !!selectedFlight,
                isShipbound,
                isRoundTrip: app.ibeController.ibeStorage.get(Constants.IS_ROUNDTRIP),
                flightClasses,
                displayedClassIndex: flightClasses.indexOf(flightToDisplay),
                isCompletedWithDefault: this.model.collection.parentModel.isCompletedWithDefault(),
                apiInputModel: this.model
            };
        });

        return flights;
    }


    onToggleMoreFlights (e) {
        // console.log(this.showMoreFlights);
        if (this.showMoreFlights === false) {
            app.trigger('scrollTo', this.$el.find(e.currentTarget));
        } else {
            app.trigger('scrollTo', this.$(e.currentTarget).parents('.api-step'));
        }
        this.showMoreFlights = !this.showMoreFlights;
        this.render();
    }
}
