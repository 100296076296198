import React from 'react';
import { createRoot } from 'react-dom/client';
import TravelIndividuallyView from '../../../components/sections/travel-individually';
import B2BCabinDetailView from '../../../components/sections/b2b/cabin-detail';

import BookingPageContent from '../../booking-page/base/content';
import app from '../../../app/app';
import templateWebpack from '../../../../tpl/page/booking-page/content/travel.hbs';
import * as Constants from '../../../config/constants';
import { renderReact } from '../../../react';


export default class TravelPageContent extends BookingPageContent {
    constructor (options = {}) {
        super({
            key: 'page-travel-content',
            templateWebpack,
            ...options
        });
    }

    /**
     * View regions.
     * Any region defined under the key of a step id will be filled with the
     * corresponding step view when calling the 'renderApiStepsToRegions' method.
     */
    regions () {
        return {
            [Constants.NETMATCH_STEP_SHIPBOUNDTRANSPORTTYPE]: {
                el: '.api-step-region-transporttype-shipbound',
                replaceElement: true
            },
            [Constants.NETMATCH_STEP_HOMEBOUNDTRANSPORTTYPE]: {
                el: '.api-step-region-transporttype-homebound',
                replaceElement: true
            }
        };
    }

    childViewEvents () {
        return {
            ...super.childViewEvents.apply(this)
        };
    }

    templateContext () {
        return {
            stepHeadline: Constants.INFO_TRAVEL_STEP_HEADLINE,
            stepSubHeadline: Constants.INFO_TRAVEL_STEP_SUB_HEADLINE
        };
    }

    /**
     * Any object defined under the key of a step id will be passed as options
     * into the step views created when calling the 'renderApiStepsToRegions' method
     */
    apiStepViewOptions () {
        return {
            [Constants.NETMATCH_STEP_SHIPBOUNDTRANSPORTTYPE]: {
                autoSubmit: true,
                additionalTemplateContext: {}
            },
            [Constants.NETMATCH_STEP_HOMEBOUNDTRANSPORTTYPE]: {
                autoSubmit: true,
                additionalTemplateContext: {}
            }
        };
    }

    /**
     * Auto-render the steps to the regions
     */
    onRender () {
        this.renderApiStepsToRegions(this.model.apiSteps);
        this.initPager();

        if (this.$el.find('.react-region-travel-individually')[0]) {
            const container = this.$el.find('.react-region-travel-individually')[0];
            const root = createRoot(container);
            renderReact(root, <TravelIndividuallyView />);
        }

        if (app.apiSession.bookingStatus.agency.isB2B()) {
            if (this.$el.find('.react-region-b2b-cabin-detail')[0]) {
                const container = this.$el.find('.react-region-b2b-cabin-detail')[0];
                const root = createRoot(container);
                renderReact(root, <B2BCabinDetailView />);
            }
        }
    }
}
