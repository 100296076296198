import $ from 'jquery';
import _ from 'underscore';
import ApiStepBaseView from './base';
import NavigationUtil from '../../../util/navigation';
import app from '../../../app/app';
import templateWebpack from '../../../../tpl/item/api-step/confirm.hbs';
import * as Constants from '../../../config/constants';
import { getIntl } from '../../../intl';


export default class ConfirmPageApiStep extends ApiStepBaseView {
    constructor (options = {}) {
        super({
            templateWebpack,
            ...options
        });
        this.counter = 0;
    }

    events () {
        return {
            'click .js-tracking': 'onClickTracking'
        };
    }

    /**
     * Add details to the data passed into the template.
     * Will be merged into the model / collection data.
     */
    templateContext () {
        /* const templateContext = super.templateContext.apply(this);
        let bookingNumberMerge = `${app.apiSession.bookingStatus.trip.get('auftragsnummer')}_${Date.now()}`;

        const initialSubmitData = _.findWhere(this.model.get('netMatchAttributes'), {key: Constants.NETMATCH_STEP_CONFIRM_RESULT_CREATE_RESERVATION});
        // yeah we receive data from netMatch

        if (initialSubmitData && initialSubmitData.status === Constants.NETMATCH_STEP_STATUS_COMPLETED) {
            bookingNumberMerge = initialSubmitData.bookingNumber;
        }

        if (app.ibeController.ibeStorage.get(Constants.CONFIRM_STATUS) !== 'true') {
            app.trackController.confirmTracking({
                'id': bookingNumberMerge,
                'revenue': app.apiSession.bookingStatus.get('totalBookingPrice')
            });
        }
        // app.ibeController.ibeStorage.save(Constants.CONFIRM_STATUS, 'true');
        // app.ibeController.ibeStorage.unsetInitialParameter();
        */

        const templateContext = super.templateContext.apply(this);
        const bookingStatus = this.getBookingStatus();
        const invoicePerson = app.apiSession.bookingStatus.get('invoice') || {};
        const isFemale = invoicePerson.salutation === Constants.NETMATCH_SESSION_INVOICE_FEMALE;
        return {
            ...templateContext,
            bookingStatus,
            invoicePerson,
            isFemale,
            ship: app.contentApi.tripInfo.get('ship'),
            shipImage: app.contentApi.tripInfo.get('shipImage'),
            tripName: app.contentApi.tripInfo.get('tripName'),
            contentAPI: {
                meta: app.contentApi.messages.prepareMessages('meta')
            },
            isB2B: app.apiSession.bookingStatus.agency.isB2B(),
            isB2BOperator: app.apiSession.bookingStatus.agency.isOperator(),
            isB2BAgentur: app.apiSession.bookingStatus.agency.isAgentur()
        };
    }

    getBookingStatus () {
        const { formatMessage } = getIntl();

        // TUICIBE-272
        const result = {
            bookingNumber: null,
            bookingIsSuccess: false,
            bookingIsFailed: false,
            bookingIsRunning: false,
            bookingIsBlocked: false,
            bookingTimeOut: false
        };

        const stepStatus = this.model.status;
        const stepAttributes = _.findWhere(this.model.get('netMatchAttributes'), {key: Constants.NETMATCH_STEP_CONFIRM_RESULT_CREATE_RESERVATION});

        // if maximum check reached and status not Completed: overwrite stepAttributes.status with TimeOut
        if (this.counter >= Constants.NETMATCH_STEP_CONFIRM_CHECK_MAXIMUM && stepAttributes && stepAttributes.status !== Constants.NETMATCH_STEP_STATUS_COMPLETED) {
            stepAttributes.status = Constants.CELLULAR_STEP_STATUS_CONFIRM_TIMEOUT;
        }

        if (stepStatus !== Constants.NETMATCH_STEP_STATUS_BLOCKED) {
            if (stepAttributes && stepAttributes.status === Constants.NETMATCH_STEP_STATUS_COMPLETED) {
                // ******************
                // all is good
                // ******************
                result.bookingIsSuccess = true;
                result.bookingNumber = stepAttributes.bookingNumber;
                if (app.ibeController.ibeStorage.get(Constants.CONFIRM_STATUS) !== 'true') {
                    app.trackController.confirmTracking({
                        'id': result.bookingNumber,
                        'revenue': app.apiSession.bookingStatus.get('totalBookingPrice')
                    });
                }
                app.ibeController.ibeStorage.save(Constants.CONFIRM_STATUS, 'true');
                app.ibeController.ibeStorage.unsetInitialParameter();
            } else if (stepAttributes && stepAttributes.status === Constants.NETMATCH_STEP_STATUS_FAILED) {
                // ******************
                // all is bad
                // ******************
                result.bookingIsFailed = true;
                app.ibeController.ibeStorage.save(Constants.CONFIRM_STATUS, 'true');
                app.ibeController.ibeStorage.unsetInitialParameter();
            } else if (stepAttributes && stepAttributes.status === Constants.CELLULAR_STEP_STATUS_CONFIRM_TIMEOUT) {
                // ******************
                // TimeOut after getStepsAgain()
                // ******************
                result.bookingTimeOut = true;
                app.ibeController.ibeStorage.save(Constants.CONFIRM_STATUS, 'true');
                app.ibeController.ibeStorage.unsetInitialParameter();
            } else {
                // ******************
                // we have no idea what's going on
                // ******************
                result.bookingIsRunning = true;
                if (this.counter <= Constants.NETMATCH_STEP_CONFIRM_CHECK_MAXIMUM) {
                    this.getStepsAgain();
                }
            }
        } else {
            result.bookingIsBlocked = true;
            const errorDetails = _.findWhere(this.model.get('netMatchAttributes'), {key: Constants.NETMATCH_STEP_CONFIRM_RESULT_SUCCESSOR_SESSION}) || {};

            const arrayToDisplay = [];
            let stringToDisplay = '';
            let savePath = null;
            if (errorDetails && errorDetails.openSteps && Array.isArray(errorDetails.openSteps)) {
                errorDetails.openStepsPre = [];
                errorDetails.openSteps.forEach(item => {
                    const tmp = item.split('-');
                    errorDetails.openStepsPre.push(tmp[0]);
                });
            }
            _.each(app.bookingPages.models, (pages) => {
                const pagesPre = [];
                const pageSteps = pages.get('api-steps');
                if (pageSteps && Array.isArray(pageSteps)) {
                    pageSteps.forEach(item => {
                        const tmp = item.split('-');
                        pagesPre.push(tmp[0]);
                    });
                }
                const findOpenSteps = _.intersection(pagesPre, errorDetails.openStepsPre);

                if (!_.isEmpty(findOpenSteps)) {
                    if (savePath === null) {
                        savePath = pages.get('route');
                        arrayToDisplay.push(pages.get('page-title'));
                    }
                }
            });
            stringToDisplay = arrayToDisplay.toString().split(',').join(', ');

            app.trigger('alert:start', {
                headline: formatMessage({
                    id: 'tpl.item.api-step.view.confirm.error.headline'
                }),
                text: formatMessage({
                    id: 'tpl.item.api-step.view.confirm.error.text'
                }, {
                    stringToDisplay
                }),
                confirmButtonText: formatMessage({
                    id: 'tpl.item.api-step.view.confirm.error.confirm'
                }),
                confirmCenter: 'center',
                closeButtonText: null,
                closable: false,
                closeOnBgClick: false,
                confirmCallback: () => {
                    this.onErrorHandling(errorDetails, savePath);
                }
            });
        }
        return result;
    }

    getStepsAgain () {
        setTimeout(() => {
            $.ajaxSetup({
                noSpinner: true
            });
            app.apiSession.fetchSteps(true);
            this.render();
        }, 5000);

        this.counter = this.counter + 1;
    }

    onErrorHandling (errorDetails, savePath) {
        if (errorDetails.sessionId) {
            app.ibeController.ibeStorage.saveApiSessionId(errorDetails.sessionId);
        }
        NavigationUtil.redirect(savePath, true);
    }

    onClickTracking (e) {
        app.trackController.eventTracking({
            action: 'Buchungsbestätigung',
            label: `Download|${this.$(e.currentTarget).children('.link-text').text()}`,
            ga4eventName: 'download',
            ga4eventDownload: `${this.$(e.currentTarget).children('.link-text').text()}.pdf`
        });
    }
}
