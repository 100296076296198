import React from 'react';
import {createRoot} from 'react-dom/client';
import CabinTabView from '../../../components/sections/cabin-tab';
import CabinBottomNavigationView from '../../../components/sections/cabin-bottom-nav';
import B2BPersonalTextView from '../../../components/sections/b2b/personal-text';
import B2BCabinDetailView from '../../../components/sections/b2b/cabin-detail';

import _ from 'underscore';
import BookingPageContent from '../../booking-page/base/content';
import app from '../../../app/app';
import fecha from '../../../util/fecha-german';
import templateWebpack from '../../../../tpl/page/booking-page/content/personal.hbs';
import * as Constants from '../../../config/constants';
import {renderReact} from '../../../react';
import {getIntl} from '../../../intl';
import String from '../../../util/string';


export default class PersonalPageContent extends BookingPageContent {
    reactTapView = null;
    reactBottomNavigation = null;

    constructor (options = {}) {
        super({
            key: 'page-personal-content',
            templateWebpack,
            ...options
        });
        this.selectedCabin = 1;
        this.firstRender = true;

        const apiSteps = this.model.apiSteps;
        this.invoiceStepModel = apiSteps.get(Constants.NETMATCH_STEP_INVOICE);
        this.adultStepModels = apiSteps.filter((apiStep) => this.isAdultId(apiStep.id));
        this.childStepModels = apiSteps.filter((apiStep) => this.isChildId(apiStep.id));

        this.mirroredInputIds = [
            Constants.NETMATCH_INPUT_INVOICE_SALUTATIONCODE,
            Constants.NETMATCH_INPUT_INVOICE_TITLE,
            Constants.NETMATCH_INPUT_INVOICE_FIRSTNAME,
            Constants.NETMATCH_INPUT_INVOICE_LASTNAME,
            Constants.NETMATCH_INPUT_INVOICE_DATEOFBIRTH
        ];


        if (!app.apiSession.bookingStatus.agency.isAgentur()) {
            this.selectedInvoicePerson = this.getInitialInvoicePerson();
            this.invoiceStepModel.set({
                'isInvoice': true
            });
        }

        this.listenTo(this.model, 'stepInvalidNeedCheckReactCabinTab', (firstInvalidStep) => {
            if (firstInvalidStep && firstInvalidStep.id) {
                const splitCabinFromPerson = firstInvalidStep.id.split(Constants.NETMATCH_STEP_OCCUPANT_SPLIT_BY);
                const parts = splitCabinFromPerson[0].split(Constants.NETMATCH_STEP_CABIN_SPLIT_BY);
                if (parts[1] && parseInt(parts[1], 10) !== this.selectedCabin) {
                    this.renderCabinSection(parseInt(parts[1], 10));
                }
            }

        });

        // TUICIBE-341: re-render invoice step when the birthdate of an adult changed
        // And Salution, Firstname, Lastname
        this.adultStepModels.forEach(adultStepModel => {
            this.listenTo(adultStepModel.inputs.get(Constants.NETMATCH_INPUT_ADULT_DATEOFBIRTH), 'change', () => {
                if (this.invoiceStepModel) {
                    this.renderApiStepsToRegions([this.invoiceStepModel]);
                }
            });
            this.listenTo(adultStepModel.inputs.get(Constants.NETMATCH_INPUT_ADULT_SALUTATIONCODE), 'change', () => {
                if (this.invoiceStepModel) {
                    this.renderApiStepsToRegions([this.invoiceStepModel]);
                }
            });
            this.listenTo(adultStepModel.inputs.get(Constants.NETMATCH_INPUT_ADULT_FIRSTNAME), 'change', () => {
                if (this.invoiceStepModel) {
                    this.renderApiStepsToRegions([this.invoiceStepModel]);
                }
            });
            this.listenTo(adultStepModel.inputs.get(Constants.NETMATCH_INPUT_ADULT_LASTNAME), 'change', () => {
                if (this.invoiceStepModel) {
                    this.renderApiStepsToRegions([this.invoiceStepModel]);
                }
            });
            this.listenTo(adultStepModel.inputs, 'change', (input) => {
                if (input && input.apiStep) {
                    const step = input.apiStep;
                    if (step.inputs.get(Constants.NETMATCH_INPUT_ADULT_SALUTATIONCODE).hasInputError()) {
                        step.inputs.get(Constants.NETMATCH_INPUT_ADULT_SALUTATIONCODE).trigger('change');
                    }
                }
            });
        });

        this.childStepModels.forEach(childStepModel => {
            this.listenTo(childStepModel.inputs, 'change', (input) => {
                if (input && input.apiStep) {
                    const step = input.apiStep;

                    if (step.inputs.get(Constants.NETMATCH_INPUT_CHILD_GENDER).hasInputError()) {
                        step.inputs.get(Constants.NETMATCH_INPUT_CHILD_GENDER).trigger('change');
                    }
                }
            });
        });
    }


    /**
     * Add details to the data passed into the template.
     * Will be merged into the model / collection data.
     */

    templateContext () {
        const stepRegions = {};
        const cabinCount = app.apiSession.bookingStatus.get('cabinCount');
        // const cabins = app.apiSession.bookingStatus.get('cabin');
        let invoiceStep;
        this.model.apiSteps.forEach(apiStep => {
            if (apiStep.id !== Constants.NETMATCH_STEP_INVOICE) {
                const splitCabinFromPerson = apiStep.id.split(Constants.NETMATCH_STEP_OCCUPANT_SPLIT_BY);
                const parts = splitCabinFromPerson[0].split(Constants.NETMATCH_STEP_CABIN_SPLIT_BY);

                if (!stepRegions[`part-${parts[1]}`]) {
                    // const cabin = cabins.find((item) => item.cabinIndex === parseInt(parts[1], 10));

                    stepRegions[`part-${parts[1]}`] = {
                        'number': parts[1],
                        'apistep': []
                    };
                }
                stepRegions[`part-${parts[1]}`].apistep.push({
                    'name': parts[0],
                    'step': apiStep.sanitizedId
                });
            } else {
                invoiceStep = apiStep.sanitizedId;
            }
        });

        this.stepRegions = stepRegions;
        return {
            apiSteps: stepRegions,
            invoiceStep,
            cabinCount,
            moreThanOne: cabinCount !== 1,
            contentAPITexte: app.contentApi.messages.prepareMessages('personalData'),
            isB2BAgentur: app.apiSession.bookingStatus.agency.isAgentur(),
            isInvoice: (this.invoiceStepModel && this.invoiceStepModel.get('isInvoice')) ? this.invoiceStepModel.get('isInvoice') : null
        };
    }


    /**
     * Listen to events of child views
     */
    childViewEvents () {
        return {
            ...super.childViewEvents.apply(this), // 'invoice:change:person': 'onChangeInvoicePerson',
            'step:changed': 'onStepInputDataChange'
        };
    }


    /**
     * Any object defined under the key of a step id will be passed as options
     * into the step views created when calling the 'renderApiStepsToRegions' method
     */
    apiStepViewOptions () {
        const apiSteps = this.model.apiSteps;
        const {formatMessage} = getIntl();
        const viewOptions = apiSteps.reduce((context, apiStep) => {
            let title = apiStep.get('title');
            if (apiStep.get('adultIndex')) {
                title = `${String.formatNumberWithOrdinalSuffix(apiStep.get('adultIndex'))} ${String.capitalize(formatMessage({id: 'general.adult'}))}`;
            }
            if (apiStep.get('childIndex')) {
                title = `${String.formatNumberWithOrdinalSuffix(apiStep.get('childIndex'))} ${String.capitalize(formatMessage({id: 'general.child'}))}`;
            }
            context[apiStep.id] = {
                additionalTemplateContext: {
                    title
                }
            };
            return context;
        }, {});
        // console.log(this.getAdultsForInvoice());
        const adultsForInvoice = this.getAdultsForInvoice();
        if (!app.apiSession.bookingStatus.agency.isAgentur()) {
            viewOptions[Constants.NETMATCH_STEP_INVOICE] = {
                darkStyling: true,
                additionalTemplateContext: {
                    otherSelected: !this.adultStepModels.find(adultStepModel => adultStepModel.id === this.selectedInvoicePerson),
                    adults: adultsForInvoice,
                    selectedId: this.selectedInvoicePerson
                    // TUICIBE-460: maybe no more necessary
                    // showPersonSelect: !this.invoiceStepModel.get('hasSubmits') || !!this.selectedInvoicePerson
                }
            };
        }

        return viewOptions;
    }


    /**
     * Auto-render the steps to the regions
     */
    onRender () {
        if (this.$el.find('.react-region-cabin-tab-view')[0]) {
            const container = this.$el.find('.react-region-cabin-tab-view')[0];
            this.reactTapView = createRoot(container);
        }
        if (this.$el.find('.react-region-cabin-bottom-navigation')[0]) {
            const container1 = this.$el.find('.react-region-cabin-bottom-navigation')[0];
            this.reactBottomNavigation = createRoot(container1);
        }

        this.renderReact(this.selectedCabin);


        this.createApiStepRegions();
        this.renderApiStepsToRegions(this.model.apiSteps);
        this.initPager();
        if (app.apiSession.bookingStatus.agency.isB2B()) {
            if (this.$el.find('.react-region-b2b-personal-text')[0]) {
                const container = this.$el.find('.react-region-b2b-personal-text')[0];
                const root = createRoot(container);
                renderReact(root, <B2BPersonalTextView/>);
            }
            if (this.$el.find('.react-region-b2b-cabin-detail')[0]) {
                const container = this.$el.find('.react-region-b2b-cabin-detail')[0];
                const root = createRoot(container);
                renderReact(root, <B2BCabinDetailView/>);
            }
        }
        // TUICIBE-542: aktive bottom Button, so User can check if all complete
        if (this.$('.button-next-page').hasClass('disabled') === true) {
            this.$('.button-next-page').removeClass('disabled');
        }
    }

    renderReact (number) {
        /* if (this.model.apiSteps.at(0).get('hasSubmits') === true && this.firstRender === true) {
            this.firstRender = false;
        } */
        if (this.reactTapView) {
            renderReact(this.reactTapView, <CabinTabView count={app.apiSession.bookingStatus.get('cabinCount')}
                isFirst={this.firstRender} isFromOut={number}
                stepmodels={this.model.apiSteps}
                callback={this.renderCabinSection.bind(this)}/>);
        }

        if (app.apiSession.bookingStatus.get('cabinCount') > 1) {
            if (this.reactBottomNavigation) {
                renderReact(this.reactBottomNavigation, <CabinBottomNavigationView
                    count={app.apiSession.bookingStatus.get('cabinCount')} isFromOut={number}
                    callback={this.renderCabinSection.bind(this)}/>);
            }
        }
        this.firstRender = false;
    }

    renderCabinSection (number) {
        this.renderReact(number);
        const all = this.$el.find('.js-parts');
        all.each((key, item) => {
            item.className = item.className.replace('show', '').trim();
            item.setAttribute('style', 'display: none');
        });

        const part = this.$el.find(`.js-part-${number}`);
        part.fadeIn('fast', () => {
            part.addClass('show');
        });

        if (this.stepRegions && this.stepRegions[`part-${number}`]) {
            if (this.stepRegions[`part-${number}`].isFlex) {
                this.$el.find('.js-part-cabin-choice').removeClass('show');
            } else {
                this.$el.find('.js-part-cabin-choice').addClass('show');
            }

        }

        this.selectedCabin = number;
    }


    createApiStepRegions () {
        this.model.apiSteps.forEach(apiStep => {
            this.addRegion(apiStep.id, {
                el: `.api-step-region-${apiStep.sanitizedId}`,
                replaceElement: true
            });
        });
    }


    getAdultsForInvoice () {
        const {formatMessage} = getIntl();

        return this.adultStepModels.reduce((adults, adultStepModel) => {
            // TUICIBE-341: allow only if age 18+
            const birthDateString = adultStepModel.inputs.get(Constants.NETMATCH_INPUT_ADULT_DATEOFBIRTH).getInputValue();
            const birthDate = birthDateString ? fecha.parse(birthDateString, 'germanShortDate') : false;
            if (birthDate) {
                const today = new Date();
                let age = today.getFullYear() - birthDate.getFullYear();
                const monthDiff = today.getMonth() - birthDate.getMonth();
                if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
                }
                if (age < 18) {
                    return adults;
                }
            }

            let name = this.getSalutationFromAdultModel(adultStepModel);
            if (name === null) {
                name = `${adultStepModel.get('adultIndex')}. ${formatMessage({id: 'general.adult'})}`;
            }

            adults.push({
                id: adultStepModel.id,
                selected: adultStepModel.id === this.selectedInvoicePerson,
                name
            });
            return adults;
        }, []);
    }


    getInitialInvoicePerson () {
        let invoicePerson = '';
        if (!this.invoiceStepModel || !this.invoiceStepModel.get('hasSubmits')) {
            if (!this.adultStepModels.length) {
                return invoicePerson;
            }
            return this.adultStepModels[0].id;
        }

        const matchedStep = this.adultStepModels.find((apiStepModel) => {
            if (!apiStepModel.get('hasSubmits')) {
                return false;
            }
            return _.every(this.mirroredInputIds, (inputId) => {
                const adultInput = apiStepModel.inputs.get(inputId);
                const invoiceInput = this.invoiceStepModel.inputs.get(inputId);
                // console.log(invoiceInput.get('inputValue'));
                if (!adultInput || !invoiceInput) {
                    return false;
                }
                // console.log(inputId, adultInput.get('inputValue'), invoiceInput.get('inputValue'));
                return adultInput.get('inputValue') === invoiceInput.get('inputValue');
            });
        });
        if (matchedStep) {
            invoicePerson = matchedStep.id;
        }

        // console.log(invoicePerson);
        return invoicePerson;
    }


    isAdultId (id) {
        return !!id.match(new RegExp(Constants.NETMATCH_STEP_ADULT));
    }

    isChildId (id) {
        return !!id.match(new RegExp(Constants.NETMATCH_STEP_CHILD));
    }

    getSalutationFromAdultModel (stepModel) {
        const firstName = stepModel.inputs.get(Constants.NETMATCH_INPUT_INVOICE_FIRSTNAME) ? stepModel.inputs.get(Constants.NETMATCH_INPUT_INVOICE_FIRSTNAME).getInputValue() : '';
        const lastName = stepModel.inputs.get(Constants.NETMATCH_INPUT_INVOICE_LASTNAME) ? stepModel.inputs.get(Constants.NETMATCH_INPUT_INVOICE_LASTNAME).getInputValue() : '';
        if (!firstName || !lastName) {
            return null;
        }

        let salutation = `${firstName} ${lastName}`;

        const titleInput = stepModel.inputs.get(Constants.NETMATCH_INPUT_INVOICE_TITLE);
        if (titleInput) {
            const selectedTitleOption = titleInput.get('options').find(option => option.id === titleInput.getInputValue());
            if (selectedTitleOption) {
                salutation = `${selectedTitleOption.displayText} ${salutation}`;
            }
        }

        const salutationCodeInput = stepModel.inputs.get(Constants.NETMATCH_INPUT_INVOICE_SALUTATIONCODE);
        if (salutationCodeInput) {
            const selectedSalutationOption = salutationCodeInput.get('options').find(option => option.id === salutationCodeInput.getInputValue());
            if (selectedSalutationOption) {
                salutation = `${selectedSalutationOption.displayText} ${salutation}`;
            }
        }

        return salutation;
    }


    onStepInputDataChange (stepId, changedData, stepView, stepModel) {
        if (app.apiSession.bookingStatus.agency.isB2B() && app.apiSession.bookingStatus.agency.isAgentur()) {
            return;
        }

        if (this.isAdultId(stepId)) {
            const invoiceStepView = this.apiStepViews[Constants.NETMATCH_STEP_INVOICE];
            invoiceStepView.setAdultSalutation(stepId, this.getSalutationFromAdultModel(stepModel));
        }
    }
}
