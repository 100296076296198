import Base from '../../../base/collection/base';
import ibeApiConfig from '../../../config/ibe-api';
import ApiStaticInputOptionModel from '../model/static-input-option';
import {getLocaleAPI} from '../../../intl';


export default class ApiStaticInputOptionsCollection extends Base {
    /**
     * Getter fot the view model
     */
    get model () {
        return ApiStaticInputOptionModel;
    }


    get url () {
        return `${ibeApiConfig.staticInputOptionsUrl}/${getLocaleAPI()}`;
    }


    /**
     * @see: http://backbonejs.org/#Collection-parse
     */
    parse (data) {
        return data;
    }
}
