var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"overlay-close-wrapper-bottom\">\n                    <a class=\"button button-secondary js-close\"><span class=\"icon-arrow-left\"></span> "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"closeButtonText") || (depth0 != null ? lookupProperty(depth0,"closeButtonText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"closeButtonText","hash":{},"data":data,"loc":{"start":{"line":20,"column":102},"end":{"line":20,"column":121}}}) : helper)))
    + "</a>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div class=\"row\">\n    <div class=\"col-lg-8\">\n\n        <div class=\"overlay overlay-travel-flight\">\n\n            <a class=\"js-close overlay-close\"></a>\n            <h3 class=\"overlay-headline\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.overlay.travel-flight-without-details.headline",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":8,"column":41},"end":{"line":8,"column":120}}})) != null ? stack1 : "")
    + "</h3>\n            <div class=\"row bordered-row\">\n                <div class=\"col-sm-6\">\n                    "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.overlay.travel-flight-without-details.text1",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":11,"column":20},"end":{"line":11,"column":96}}})) != null ? stack1 : "")
    + "\n                </div>\n                <div class=\"col-sm-6\">\n                    "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.overlay.travel-flight-without-details.text2",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":14,"column":20},"end":{"line":14,"column":96}}})) != null ? stack1 : "")
    + "\n                </div>\n            </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"closeButtonText") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":12},"end":{"line":22,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"useData":true});