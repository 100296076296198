var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1 class=\"booking-page-headline headline\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.page.booking-page.content.overview.headline",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":1,"column":43},"end":{"line":1,"column":114}}})) != null ? stack1 : "")
    + "</h1>\n\n<div class=\"align-right print-trigger-top hidden-sm-up\">\n    <a class=\"js-trigger-print-ow print-details\">\n        "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"general.print",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":45}}})) != null ? stack1 : "")
    + "\n        <span class=\"icon-print\"></span>\n    </a>\n</div>\n\n<div class=\"rro-c\"></div>\n\n<div class=\"align-right print-trigger-bottom\">\n    <a class=\"js-trigger-print-ow print-details\">\n        "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"general.print",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":14,"column":45}}})) != null ? stack1 : "")
    + "\n        <span class=\"icon-print\"></span>\n    </a>\n</div>\n<br>\n<div class=\"booking-page-section without-border booking-page-section-payment hidden-for-print\">\n    <div class=\"row\">\n        <div class=\"col-lg-12\">\n            <div class=\"api-step-region-payment-type\"></div>\n\n            <div class=\"rro-a\"></div>\n        </div>\n    </div>\n</div>\n\n<div class=\"rro-b2b-cd\"></div>\n\n<div class=\"online-risk-placeholder\"></div>\n\n<div class=\"booking-pager bottom\"></div>\n<div class=\"booking-pager sticky\"></div>\n";
},"useData":true});