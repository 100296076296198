import React from 'react';
import app from '../../app/app';

import BabiesOverlay from './babies';
import BenefitHintOverlay from './benefit-hint';
import CabinImageGallery from './cabin-image-gallery-js';
import CabinOnlyByPhoneOverlay from './cabin-only-by-phone';
import CabinPanoramOverlay from './cabin-panorama';
import DefaultOverlay from './default';
import InsuranceCanelOverlay from './insurance-cancel';
import NoMatchingCabinOverlay from './no-matching-cabin';
import RouteMapOverlay from './route-map';
import TrainChildOverlay from './train-child';
import TrainInfoOverlay from './train-info';
import TUICardOverlay from './tuicard';
import CampaignCodeOverlay from './campaign-code';

/*
import TrainToPlanOverlay from './train-to-plan';
 */


const getById = (overlayId: string, data: any | null, method: Function) => {
    const isB2B: boolean = (app.apiSession && app.apiSession.bookingStatus && app.apiSession.bookingStatus.agency) ? app.apiSession.bookingStatus.agency.isB2B() : false;
    let componentDetail:any;
    switch (overlayId) {
        case 'babies':
            componentDetail = (<BabiesOverlay isB2B={isB2B}/>);
            break;
        case 'benefit':
            componentDetail = (<BenefitHintOverlay/>);
            break;
        case 'cabin-image-gallery':
            componentDetail = (<CabinImageGallery data={data}/>);
            break;
        case 'cabin-panorama':
            componentDetail = (<CabinPanoramOverlay data={data}/>);
            break;
        case 'cabin-only-by-phone':
            componentDetail = (<CabinOnlyByPhoneOverlay isB2B={isB2B}/>);
            break;
        case 'insurance-cancel':
            componentDetail = (<InsuranceCanelOverlay data={data}/>);
            break;
        case 'no-matching-cabin':
            componentDetail = (<NoMatchingCabinOverlay isB2B={isB2B}/>);
            break;
        case 'route-map':
            componentDetail = (<RouteMapOverlay data={data}/>);
            break;
        case 'train-child':
            componentDetail = (<TrainChildOverlay/>);
            break;
        case 'train-info':
            componentDetail = (<TrainInfoOverlay/>);
            break;
        case 'tuicard':
            componentDetail = (<TUICardOverlay/>);
            break;
        case 'campaign-code':
            componentDetail = (<CampaignCodeOverlay data={data} method={method}/>);
            break;
        default:
            componentDetail = (<DefaultOverlay/>);
            break;
    }
    return componentDetail;
};

export {
    getById
};
