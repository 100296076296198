import React, {useState, useEffect} from 'react';
import {FormattedMessage} from 'react-intl';
import app from '../../app/app';
import CabinSearchModel from '../../entities/netmatch-api/model/cabin-search';
import {getIntl} from '../../intl';

export default function SearchView (props: { cabinIndex: number, searchNumber: string, callback: Function }) {
    const {formatMessage} = getIntl();

    const {cabinIndex, searchNumber, callback} = props;
    const [buttonState, setButtonState] = useState(true);
    const [input, setInput] = useState(searchNumber);
    const [apiState, setApiState] = useState({running: false, success: false, error: false});


    // TUICUNIT-1452: android Work'a'round
    const enterInput = () => {
        const mobile: any = document.querySelector('.cabin-number-overlay');
        if (mobile) {
            mobile.className = `${mobile.className} input`;
        }
    };
    // TUICUNIT-1452: android Work'a'round
    const leaveInput = () => {
        const mobile: any = document.querySelector('.cabin-number-overlay');
        if (mobile) {
            mobile.className = mobile.className.replace('input', '').trim();
        }
    };

    const keyEventHandler = (e: any) => {
        if (e) {
            e.stopPropagation();
        }
        if (e.target.value.length > 3) {
            const value = parseInt(e.target.value, 10);
            if (value && value > 2000) {
                const state = {running: false, success: false, error: false};
                setApiState(state);
                setInput(e.target.value);
                setButtonState(true);
            }
        } else {
            setButtonState(false);
        }

        if (e.keyCode === 13 && e.type === 'keyup') {
            e.preventDefault();
            if (!apiState.running) {
                checkCabinAvailability(e.target.value);
            }
        }
    };

    const goToCheckCabinAvailability = () => {
        if (input !== '') {
            const value = parseInt(input, 10);
            if (value && value > 2000) {
                checkCabinAvailability(input);
                if (app && app.trackController) {
                    app.trackController.eventTracking({
                        action: 'Wunschkabine',
                        label: `${input}_Prüfen`,
                        ga4clickLabel: `content.button.wunschkabine-${input}-pruefen`
                    });
                }
            }
        }
    };

    const checkCabinAvailability = (value: string) => {
        leaveInput();
        const checkValue = parseInt(value, 10);
        if (checkValue && checkValue > 2000) {
            const cabinSearchModel = new CabinSearchModel();
            const checkResult = cabinSearchModel.checkNumber(cabinIndex, checkValue);
            let state = {running: true, success: false, error: false};
            setApiState(state);
            callback(state);
            checkResult.then((response) => {
                if (response) {
                    state = {running: false, success: true, error: false};
                    setApiState(state);
                } else {
                    state = {running: false, success: false, error: true};
                    setApiState(state);
                }
                callback(state, response);

            }).catch(() => {
                state = {running: false, success: false, error: true};
                setApiState(state);
                callback(state);
            });
        }
        // this.toTrackingEvent('Check-Wunschkabine');

    };

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        goToCheckCabinAvailability();
    }, []);
    /* eslint-enable react-hooks/exhaustive-deps */


    return (
        <div className="search-wrapper">
            <div>
                <div className="input-group">
                    <input className="form-element is-light cabin-search-input" id="CabinNumberValidate" placeholder={formatMessage({id: 'components.cabin.cabin-search.placeholder'})} type="number" min="1" onKeyPress={keyEventHandler} onKeyUp={keyEventHandler} onFocus={enterInput} onBlur={leaveInput} defaultValue={input} disabled={apiState.running} pattern="\d*"/>
                    <div className="input-group-addon">
                        <a className={`button ${!buttonState || apiState.running ? 'disabled' : ''}`} onClick={() => {
                            if (!apiState.running) {
                                goToCheckCabinAvailability();
                            }
                        }}>
                            <FormattedMessage id="components.cabinnumber.search.check"/>
                        </a>
                    </div>
                </div>
            </div>
            <div className="search-status">
                {!!apiState.running && (
                    <div className="running">
                        <div className="wrapper-loader">
                            <div className="loader"></div>
                            <div className="icon-cabin-type"></div>
                        </div>
                        <FormattedMessage id="components.cabinnumber.search.checking"/>
                    </div>
                )}
                {!!apiState.success && (
                    <div className="success">
                        <FormattedMessage id="components.cabinnumber.search.available"/>
                    </div>
                )}
                {!!apiState.error && (
                    <div className="error">
                        <FormattedMessage id="components.cabinnumber.search.not-available" values={{
                            input
                        }}/>
                    </div>
                )}
            </div>
        </div>
    );
};
