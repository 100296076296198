import Error404Page from '../page/error/404';
import Error503Page from '../page/error/503';

import CabinPage from '../page/booking-page/cabin/layout';
import TravelPage from '../page/booking-page/travel/layout';
import InsurancePage from '../page/booking-page/insurance/layout';
import PersonalPage from '../page/booking-page/personal/layout';
import OverviewPage from '../page/booking-page/overview/layout';
import PaymentPage from '../page/booking-page/payment/layout';
import ConfirmPage from '../page/booking-page/confirm/layout';
import PrintOfferPage from '../page/booking-page/print-offer/layout';

import B2BPage from '../page/examples/b2b';
import ChangePage from '../page/examples/change';

export default {

    create: (pageId, ...args) => {
        let PageViewClass;
        switch (pageId) {
            case 'cabin':
                PageViewClass = CabinPage;
                break;
            case 'travel':
                PageViewClass = TravelPage;
                break;
            case 'insurance':
                PageViewClass = InsurancePage;
                break;
            case 'personal':
                PageViewClass = PersonalPage;
                break;
            case 'overview':
                PageViewClass = OverviewPage;
                break;
            case 'payment':
                PageViewClass = PaymentPage;
                break;
            case 'confirm':
                PageViewClass = ConfirmPage;
                break;
            case 'print-offer':
                PageViewClass = PrintOfferPage;
                break;
            case 'error404':
                PageViewClass = Error404Page;
                break;
            case 'error503':
                PageViewClass = Error503Page;
                break;
            case 'examples-b2b':
                PageViewClass = B2BPage;
                break;
            case 'examples-change':
                PageViewClass = ChangePage;
                break;

            default:
                throw new Error(`Unknown page "${pageId}"`);
        }
        return new PageViewClass(...args);
    }

};
