import _ from 'underscore';
import ApiInputCheckboxView from './checkbox';
import templateWebpack from '../../../../tpl/item/api-input/approval.hbs';


export default class ApprovalCheckboxView extends ApiInputCheckboxView {
    constructor (options = {}) {
        super({
            templateWebpack,
            ...options
        });
    }

    /**
     * Add details to the data passed into the template.
     * Will be merged into the model / collection data.
     */
    templateContext () {
        const templateContext = super.templateContext.apply(this);
        return {
            ...templateContext,
            // overwrite in order for onRender to display the proper state
            checked: this.model.getInputValue()
        };
    }

    /**
     * Handler called when the value of the input changes due to user interaction
     * Overwriting base item view in order to use 'checked' state as value
     */
    onValueChange (e) {
        // use !! boolean conversion, to avoid interpretation as true for undefined
        const checked = !!_.property('checked')(e.currentTarget);
        this.setValue(checked);
    }
}
