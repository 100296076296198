import ApiStepBaseView from './base';
import templateWebpack from '../../../../tpl/item/api-step/transporttype.hbs';
import * as Constants from '../../../config/constants';


export default class TransportTypeApiStep extends ApiStepBaseView {
    constructor (options = {}) {
        super({
            templateWebpack,
            ...options
        });
        this.listenTo(this.model.inputs, 'change', this.render);
    }

    childViewEvents () {
        return {
            'step:did:change': () => {
                this.triggerMethod('step:did:change');
            }
        };
    }

    /**
     * Getter for the views class name
     */
    get className () {
        return `${super.className} api-step-transporttype`;
    }


    /**
     * View regions.
     * Any region defined under the key of an input id will be filled with the
     * corresponding input view when calling the 'renderApiInputsToRegions' method..
     */
    regions () {
        return {
            [Constants.NETMATCH_INPUT_TRANSPORTTYPE_TYPE]: {
                el: '.api-input-type',
                replaceElement: true
            }
        };
    }


    /**
     * Any object defined under the key of an input id will be passed as options
     * into the input views created when calling the 'renderApiInputsToRegions' method.
     */
    apiInputViewOptions () {
        return {
            [Constants.NETMATCH_INPUT_TRANSPORTTYPE_TYPE]: {
                displayType: 'transporttype-options',
                displayTitle: this.model.get('title'),
                additionalTemplateContext: {}
            }
        };
    }


    onRender () {
        this.renderApiInputsToRegions(this.model.inputs);
    }
}
