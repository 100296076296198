import BaseModel from '../../../../base/model/base';
import app from '../../../../app/app';


export default class TripModel extends BaseModel {
    // Overwritten method to include the summary
    toJSON () {
        return {
            tripCode: this.get('auftragsNummer'),
            ...super.toJSON(),
            ...app.contentApi.tripInfo.toJSON()
        };
    }
}
