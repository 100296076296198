import React, {useState} from 'react';
import { FormattedMessage } from 'react-intl';
import * as Constants from '../../config/constants';

type Props = {
    count: number;
    isFirst: boolean;
    isFromOut: number;
    stepmodels: any;
    callback: Function;
}

export default function CabinTabView (props:Props) {
    const { count, isFirst, isFromOut, stepmodels, callback } = props;
    const [select, setSelect] = useState(isFromOut);

    const cabinStatus:any = [];
    if (isFirst !== true) {
        stepmodels.forEach((apiStep:any) => {
            if (apiStep.id !== Constants.NETMATCH_STEP_INVOICE) {
                const splitCabinFromPerson = apiStep.id.split(Constants.NETMATCH_STEP_OCCUPANT_SPLIT_BY);
                let parts: any;
                if (splitCabinFromPerson) {
                    parts = splitCabinFromPerson[0].split(Constants.NETMATCH_STEP_CABIN_SPLIT_BY);
                } else {
                    parts = apiStep.id.split(Constants.NETMATCH_STEP_CABIN_SPLIT_BY);
                }

                if (typeof cabinStatus[`cabin-${parts[1]}`] === 'undefined') {
                    cabinStatus[`cabin-${parts[1]}`] = true;
                }
                // console.log(apiStep.hasInputErrors());

                const setpStatus = apiStep.hasValidInputData();

                if (setpStatus === false) {
                    cabinStatus[`cabin-${parts[1]}`] = false;
                }
            }
        });
    }


    // console.log(cabinStatus);
    if (isFromOut !== select) {
        setSelect(isFromOut);
    }

    const cabinCountIndicatorView = (count:number) => {
        // console.log(cabinStatus);
        const part = [];
        if (count > 1) {
            const showTestMAster = (count <= 2);
            for (let i = 1; i <= count; i++) {
                const is = (select === i) ? 'is-select' : '';
                let showTest = true;

                if (select !== i && !showTestMAster) {
                    showTest = false;
                }

                const isNotCompleted = (!is && cabinStatus[`cabin-${(i)}`] === false) ? 'is-not-completed' : '';
                part.push(<div key={i} onClick={() => {
                    setSelect(i);
                    callback(i);
                }} className={`cabin-c ${is} ${isNotCompleted}`}>
                    <span><span className={`${showTest ? '' : 'none-text'}`}>{!!showTest && <FormattedMessage id="general.cabin" />}</span> {i}</span>
                </div>);
            }
        } else {
            part.push(<div key='none' className="only-on-cabin"></div>);
        }
        return part;
    };


    return (
        <div className="cabin-main">
            <div className="cabin-tab-choice">
                {cabinCountIndicatorView(count)}
            </div>
        </div>
    );

}
