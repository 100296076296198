import BaseModel from '../../../base/model/base';


export default class CabinTypeModel extends BaseModel {
    /**
     * @see: http://backbonejs.org/#Model-idAttribute
     */
    get idAttribute () {
        return 'categoryTypeCode';
    }


    /**
     * @see: http://backbonejs.org/#Collection-parse
     */
    parse (response) {
        // WATCH OUT
        // the parse method will be called before (!!) the constructor because
        // attributes will be passed to this model.
        // So we need to create the cabinCategories collection here when used first
        if (!this.cabinCategories) {
            this.cabinCategories = new BaseModel([], {
                parentModel: this
            });
        }
        const cabinCategoriesData = response.cabinCategories || [];
        this.cabinCategories.set(cabinCategoriesData, {
            parse: true
        });
        delete response.cabinCategories;

        return response;
    }
}
