import app from './app';
import routes from './routes';

const configURL = '/api/config/env/ibe/config.json';
const ibeHealthcheckURL = '/api-status';
// eslint-disable-next-line no-undef
export const isDev = typeof DEV === 'boolean';

export default {
    ...app,
    routes,
    configURL,
    ibeHealthcheckURL
};
