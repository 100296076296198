import React from 'react';
import { FormattedMessage } from 'react-intl';
import NavigationUtil from '../../util/navigation';


export default function TrustBarView () {
    return (
        <div className="page-footer-trust-bar">
            <div className="container container-with-padding">
                <h3 className="headline trust-headline">
                    <FormattedMessage id="components.footer.trust-bar.headline" />
                </h3>
                <div className="row">
                    <div className="col-sm-6 col-xs-12 trust-item">
                        <div className="row">
                            <div className="col-lg-4 col-sm-12">
                                <img alt="SSL" width="125" height="125" src={`${NavigationUtil.normalizePath('images/footer/trust-thawte@2x.png')}`} />
                            </div>
                            <div className="col-lg-8 col-sm-12">
                                <h4><FormattedMessage id="components.footer.trust-bar.ssl.headline" /></h4>
                                <p><FormattedMessage id="components.footer.trust-bar.ssl.text" /></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xs-12 trust-item">
                        <div className="row">
                            <div className="col-lg-4 col-sm-12">
                                <img className="pay" alt="Sichere Bezahlung" width="120" height="158" src={`${NavigationUtil.normalizePath('images/footer/trust-payment1.png')}`} />
                            </div>
                            <div className="col-lg-8 col-sm-12">
                                <h4><FormattedMessage id="components.footer.trust-bar.payment.headline" /></h4>
                                <p><FormattedMessage id="components.footer.trust-bar.payment.text" /></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
