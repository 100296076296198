import BaseItem from '../../../base/view/item';


export default class ApiInputBaseView extends BaseItem {
    constructor (options) {
        super({
            ...options,
            key: 'api-input'
        });

        this.listenTo(this.model, 'change', this.render);
        this.listenTo(this.model, 'hide:validation:error', this.hideValidationError);
    }


    /**
     * Getter for the views class name
     */
    get className () {
        return `api-input api-input-${this.model.sanitizedId}`;
    }


    /**
     * Add details to the data passed into the template.
     * Will be merged into the model / collection data.
     */
    templateContext () {
        return {
            // disabled at this position to allow additionalTemplateContext to overwrite
            disabled: this.model.apiStep.isPending() || this.model.apiStep.isBlocked() || this.model.apiStep.isNotApplicable(),
            darkStyling: !!this.getOption('darkStyling'),
            ...this.options.additionalTemplateContext,
            required: this.model.requiresInput(),
            domId: this.model.domId,
            error: this.isAttached() ? this.model.getInputError() : this.model.getSubmitError(),
            // error: this.model.hasSubmitError() ? this.model.getSubmitError() : null,
            showValid: !!this.getOption('showValidationSuccess') && this.model.hasValue() && !this.model.hasInputError()
        };
    }


    /**
     * View events triggered by the user
     */
    events () {
        return {
            [`change #${this.model.domId}`]: 'onValueChange',
            [`blur #${this.model.domId}`]: 'onValueChange' // TUICIBE-535: Safari has a otherwise Problem with autocomplete="on" to realize that the value exist
        };
    }


    /**
     * Handler called when the value of the input changes due to user interaction
     */
    onValueChange (e) {
        const $element = this.$(e.currentTarget);
        this.setValue(this.cleanTextFinal($element.val()));
    }

    cleanTextFinal (value) {
        if (typeof value === 'string') {
            // clean all script tags
            const forbiddenMatchCount = (value.match(/</g) || []).length;
            for (let i = 0; i < forbiddenMatchCount; i = i + 1) {
                if (value.indexOf('<') < value.indexOf('>')) {
                    const sliceValue = value.slice(value.indexOf('<'), value.indexOf('>') + 1);
                    value = value.replace(sliceValue, '');
                }
            }
            // const sliceValue = value.slice(value.indexOf('<'), value.indexOf('>') + 1);
            value = value.replace(/</g, '').replace(/>/g, '').replace(/&/g, '').replace(/%/g, '').replace(/#/g, '').replace(/"/g, '').replace(/\//g, '').replace(/\$/g, '');
            if (value.substr(value.length - 1) !== ' ') {
                value = value.trim();
            }
        }
        return value;
    }

    /**
     * Set the trimmed value on the model.
     * Force 'change' event when the value actually did not change
     *
     * @param {[type]} value [description]
     */
    setValue (value) {
        if (typeof value !== 'boolean') {
            // trim would convert booleans into strings otherwise
            value = `${value}`.trim();
        }

        if (this.model.getInputValue() === value) {
            // forced change event for unchanged value
            this.model.trigger('change');
            return;
        }
        this.model.setInputValue(value);
    }


    /**
     * Displays the given error message
     */
    showValidationError (errorMessage) {
        this.$('.form-error').html(errorMessage);
        this.$('.form-group').addClass('has-errors');
    }


    /**
     * Hides any error message
     */
    hideValidationError () {
        this.$('.form-error').html('');
        this.$('.form-group').removeClass('has-errors');
    }
}
