import Base from '../../../base/model/base';


export default class ApiStaticInputOptionModel extends Base {
    /**
     * @see: http://backbonejs.org/#Model-idAttribute
     */
    get idAttribute () {
        return 'id';
    }
}

