import React from 'react';
import * as Constants from '../../config/constants';
import app from '../../app/app';
import { FormattedMessage, useIntl } from 'react-intl';

/**
 * Content Box
 */
export default function TravelIndividuallyView () {
    const { formatMessage } = useIntl();
    const isB2B: boolean = (app.apiSession && app.apiSession.bookingStatus && app.apiSession.bookingStatus.agency) ? app.apiSession.bookingStatus.agency.isB2B() : false;

    const textTeam = formatMessage({
        id: isB2B ? 'general.team.sales' : 'general.team.service'
    });
    const textNumber = isB2B
        ? (
            <a className="action-link" href={`tel:${Constants.VIEW_B2B_SERVICE_NUMBER_DISPLAY_CTA}`}>{Constants.VIEW_B2B_SERVICE_NUMBER_DISPLAY_INT}</a>
        )
        : (
            <a className="action-link" href={`tel:${Constants.VIEW_SERVICE_NUMBER_DISPLAY_CTA}`}>{Constants.VIEW_SERVICE_NUMBER_DISPLAY_INT}</a>
        );
    const textMail = isB2B
        ? (
            <a className="action-link" href="mailto:reservierung@tuicruises.com">reservierung@tuicruises.com</a>
        )
        : (
            <a className="action-link" href="mailto:info@tuicruises.com">info@tuicruises.com</a>
        );


    return (
        <div className="travel-individually">
            <h3 className="headline">
                <FormattedMessage id="components.sections.travel-individually.headline" />
            </h3>
            <div className="row t-i-c">
                <div className="col-sm-6 t-i-c1">
                    <FormattedMessage id="components.sections.travel-individually.text1" values={{
                        textTeam
                    }} />
                </div>
                <div className="col-sm-6 t-i-c2">
                    <div className="bold">
                        <FormattedMessage id="components.sections.travel-individually.text2" values={{
                            textTeam
                        }} />
                    </div>
                    {textNumber}
                    <p className="action-link-details">
                        <FormattedMessage id="general.opening-hours" />
                    </p>
                    {textMail}
                </div>
            </div>
        </div>
    );
}
