var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"row\">\n            <div class=\"col-sm-6\">\n                <div class=\"form-group\">\n                    <label>"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":13,"column":27},"end":{"line":13,"column":36}}}) : helper)))
    + "</label>\n                    <div class=\"select-element\">\n                        <select class=\"form-element js-participant-select\" data-participant-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":15,"column":96},"end":{"line":15,"column":102}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"otherParticipants") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":16,"column":28},"end":{"line":19,"column":37}}})) != null ? stack1 : "")
    + "                        </select>\n                    </div>\n                </div>\n            </div>\n            <form>\n                <div class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"participantRegionClass") || (depth0 != null ? lookupProperty(depth0,"participantRegionClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"participantRegionClass","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":25,"column":28},"end":{"line":25,"column":54}}}) : helper)))
    + "\"></div>\n            </form>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <option value=\""
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"stepId") : stack1), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"selected") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":17,"column":76},"end":{"line":18,"column":55}}})) != null ? stack1 : "")
    + ">"
    + alias2(alias1(((stack1 = blockParams[0][0]) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "</option>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "selected\n                                        disabled";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"insurance-blocked-alert\">\n            <div class=\"insurance-blocked-header\">"
    + alias3((lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"components.insurance.blocked.header",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":34,"column":50},"end":{"line":35,"column":55}}}))
    + ":\n            </div>\n            <div class=\"insurance-blocked-copy\">"
    + alias3((lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"components.insurance.blocked.copy",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":37,"column":48},"end":{"line":38,"column":53}}}))
    + "\n            </div>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"booking-page-section\">\n    <div class=\"headline insurance-assignment-headline\">\n        <span class=\"icon "
    + alias4(((helper = (helper = lookupProperty(helpers,"insuranceIcon") || (depth0 != null ? lookupProperty(depth0,"insuranceIcon") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"insuranceIcon","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":3,"column":26},"end":{"line":3,"column":43}}}) : helper)))
    + "\"></span> "
    + alias4(((helper = (helper = lookupProperty(helpers,"insuranceName") || (depth0 != null ? lookupProperty(depth0,"insuranceName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"insuranceName","hash":{},"data":data,"blockParams":blockParams,"loc":{"start":{"line":3,"column":53},"end":{"line":3,"column":70}}}) : helper)))
    + "\n        <span class=\"button button-secondary icon icon-ic-edit js-button-edit-insurances\"></span>\n    </div>\n\n    <div class=\"api-step-region-policy-type\"></div>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"participantDetails") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 1, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":9,"column":4},"end":{"line":28,"column":13}}})) != null ? stack1 : "")
    + "\n    <div class=\"api-step-region-policy-type\"></div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isBlocked") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams),"inverse":container.noop,"data":data,"blockParams":blockParams,"loc":{"start":{"line":32,"column":4},"end":{"line":41,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true,"useBlockParams":true});