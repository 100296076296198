import React from 'react';
import { createRoot } from 'react-dom/client';
import Error503View from '../../components/error/503';

import BasePage from '../../base/view/page';
import templateWebpack from '../../../tpl/page/error/wrapper.hbs';
import { renderReact } from '../../react';

export default class Error503Page extends BasePage {
    constructor (options) {
        super(Object.assign({
            key: 'error503',
            templateWebpack
        }, options));
    }

    get appLayoutType () {
        return 'error';
    }


    get pageLayoutType () {
        return 'error';
    }

    onRender () {
        // react region error 503
        if (this.$el.find('.rre-503')[0]) {
            const container = this.$el.find('.rre-503')[0];
            const root = createRoot(container);
            renderReact(root, <Error503View />);
        }
    }
}
