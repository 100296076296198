import Base from '../../../base/collection/base';
import ApiInputModel from '../model/input';


export default class ApiInputsCollection extends Base {
    /**
     * Getter fot the view model
     */
    get model () {
        return ApiInputModel;
    }
}
