import BasePage from '../../../base/view/page';
import config from '../../../config/config';
import app from '../../../app/app';
import BookingStatus from '../../../item/booking-status/view/layout';
import TransitionRegion from '../../../base/region/transition';
import basicLayoutTpl from '../../../../tpl/page/booking-page/layout/basic.hbs';
import defaultLayoutTpl from '../../../../tpl/page/booking-page/layout/default.hbs';
import printLayoutTpl from '../../../../tpl/page/booking-page/layout/print.hbs';


export default class BookingPage extends BasePage {
    constructor (options = {}) {
        super({
            ...options
        });
        /* eslint no-unused-expressions:0 */
        this.contentView;

        // this.listenTo(app.apiSession.steps, 'sync', this.setBookingStatus);
    }


    /**
     * Getter for the handlebars template
     */
    get template () {
        let defaultTemplate = defaultLayoutTpl;
        if (this.getOption('pageLayoutType')) {
            switch (this.getOption('pageLayoutType')) {
                case 'basic': defaultTemplate = basicLayoutTpl;
                    break;
                case 'print': defaultTemplate = printLayoutTpl;
                    break;
            }
        }
        return defaultTemplate;
    }

    events () {
        return {
            'click .js-sidebar-tracking-layout': (e) => {
                const label = e.currentTarget.dataset.totrack;
                if (app && app.trackController && label) {
                    app.trackController.eventTracking({
                        ga4clickLabel: `sidebar.custom.${label}`
                    });
                }
            }
        };
    }


    /**
     * View regions.
     * Any region defined under the key of a step id will be filled with the
     * corresponding step view when calling the 'renderApiStepsToRegions' method.
     */
    regions () {
        return {
            'page-content': config.transition.page ?
                new TransitionRegion({
                    el: '.page-content'
                }) :
                '.page-content'
        };
    }

    templateContext () {
        return {
            isB2B: app.apiSession.bookingStatus.agency.isB2B()
        };
    }

    onAttach () {
        this.initContent();
        if (this.options.hideBookingStatus !== true) {
            this.initBookingStatusRegions();
        }
    }

    /**
     * clean ReactModalPortal div's onBeforeDestroy Pages
     */
    checkReactModalPortalDivSpam () {
        const clean = document.querySelectorAll('.ReactModalPortal');
        if (clean && window.NodeList && window.NodeList.prototype.forEach) {
            clean.forEach((item) => {
                if (item.parentNode) {
                    item.parentNode.removeChild(item);
                }
            });
        }
    }

    /**
     * Renders the page content
     */
    initContent () {
        const PageContentViewClass = this.contentViewClass;
        this.contentView = new PageContentViewClass({
            model: this.model,
            key: this.options.key
        });
        this.showChildView('page-content', this.contentView);
    }


    /* setBookingStatus () {
        const content = app.apiSession.bookingStatus.toJSON();
        ReactDOM.render(<BookingSideBarView content={content}/>, this.$el.find('.booking-status-sidebar')[0]);
        ReactDOM.render(<BookingStatusBarView content={content}/>, this.$el.find('.booking-status-bar')[0]);

    } */
    /**
     * Renders the 2 booking status views into their regions if matching dom element is found
     */
    initBookingStatusRegions () {
        if (this.$('.booking-status-sidebar').length) {
            this.addRegion('booking-status-sidebar', '.booking-status-sidebar');
            this.showChildView('booking-status-sidebar', new BookingStatus({
                model: app.apiSession.bookingStatus
            }));
        }
        if (this.$('.booking-status-bar').length) {
            this.addRegion('booking-status-bar', '.booking-status-bar');
            this.showChildView('booking-status-bar', new BookingStatus({
                model: app.apiSession.bookingStatus,
                key: 'booking-status-bar'
            }));
        }
    }
}
