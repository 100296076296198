import _ from 'underscore';
import BaseModel from '../../../base/model/base';
import contentApiConfig from '../../../config/content-api';
import * as Constants from '../../../config/constants';

export default class MessagesModel extends BaseModel {
    prepareUrlAndFetch () {
        // this.url = contentApiConfig.messagesURL;
        this.url = contentApiConfig.i18nUrlMessages();

        return this.fetch();
    }

    prepareMessages (key) {
        const texte = this.get(key);
        let result = {};
        switch (key) {
            case 'b2blight':
            case 'header':
                result = this.someMessages(texte);
                break;
            case 'desiredCabin':
                result = this.desiredCabinMessages(texte);
                break;
            case Constants.YOURSTRULY_PRICEMODEL_FLEX:
                result = this.newPriceModelMessages(texte);
                break;
            case Constants.YOURSTRULY_PRICEMODEL_FEELGOOD:
                result = this.newPriceModelMessages(texte);
                break;
            case Constants.YOURSTRULY_PRICEMODEL_FEELGOOD_PLUS:
                result = this.newPriceModelMessages(texte);
                break;
            case Constants.YOURSTRULY_PRICEMODEL_PRO:
                result = this.newPriceModelMessages(texte);
                break;
            case Constants.YOURSTRULY_PRICEMODEL_PLUS:
                result = this.newPriceModelMessages(texte);
                break;
            case Constants.YOURSTRULY_PRICEMODEL_PUR:
                result = this.newPriceModelMessages(texte);
                break;
            case Constants.YOURSTRULY_PRICEMODEL_PROVIP1:
                result = this.newPriceModelMessages(texte);
                break;
            case Constants.YOURSTRULY_PRICEMODEL_PROVIP2:
                result = this.newPriceModelMessages(texte);
                break;
            case 'transport':
                result = this.transportMessages(texte);
                break;
            case 'insurances':
                result = this.insurancesMessages(texte);
                break;
            case 'personalData':
                result = this.personalDataMessages(texte);
                break;
            case 'payment':
                result = this.paymentMessages(texte);
                break;
            case 'onlineRisk':
                result = this.onlineRisk(texte);
                break;
            case 'confirmation':
                result = this.confirmationMessages(texte);
                break;
            case 'meta':
                result = this.metaMessages(texte);
                break;
            case 'footer':
                result = this.footerMessages(texte);
                break;
            case 'ctatexts':
                result = this.ctaMessages(texte);
                break;
            case 'vippopup':
            case Constants.YOURSTRULY_VIP1_TEXT:
            case Constants.YOURSTRULY_VIP2_TEXT:
            case Constants.YOURSTRULY_PROVIP1_TEXT:
            case Constants.YOURSTRULY_PROVIP2_TEXT:
                result = this.vipMessages(texte);
                break;
            case 'cabinCategory':
                result = this.cabinCategoryMessages(texte);
                break;
            case Constants.YOURSTRULY_CAMPAIGN_CODE_BORD_DEPOSIT_TEXT:
            case Constants.YOURSTRULY_CAMPAIGN_CODE_ALREADY_APPLIED_TEXT:
            case Constants.YOURSTRULY_CAMPAIGN_CODE_INVALID_TEXT:
            case Constants.YOURSTRULY_CAMPAIGN_CODE_NOT_COMPATIBLE_TEXT:
            case Constants.YOURSTRULY_CAMPAIGN_CODE_CONFIGURATION_CHANGE_TEXT:
                result = this.campaignCodeMessages(texte);
                break;
            default:
                break;
        }

        return result;
    }

    someMessages (messages = {}) {
        return Object.keys(messages).reduce((acc, key) => {
            acc[key] = messages[key].value;
            return acc;
        }, {});
    }

    desiredCabinMessages (messages = {}) {
        return Object.keys(messages).reduce((acc, key) => {
            messages[key].value = this.inject(messages[key].value);
            acc[key] = messages[key].value;
            return acc;
        }, {});
    }

    //  YoursTruly ContentAPI struct is so strange
    pricemodelMessages (texte) {
        const resultTmp = {};
        let resultTmpPunch = '';
        _.each(texte, (item, itemKey) => {
            let sortKey;
            if (itemKey.indexOf('punchline') !== -1) {
                resultTmpPunch = item.value;
            }

            if (itemKey.indexOf('headline') !== -1) {
                sortKey = itemKey.replace('headline.', 'sort');
                if (!resultTmp[sortKey]) {
                    resultTmp[sortKey] = {};
                }
                resultTmp[sortKey].sortKey = sortKey;
                resultTmp[sortKey].headline = item.value;
            }
            if (itemKey.indexOf('text') !== -1) {
                sortKey = itemKey.replace('text.', 'sort');
                if (!resultTmp[sortKey]) {
                    resultTmp[sortKey] = {};
                }
                resultTmp[sortKey].sortKey = sortKey;
                resultTmp[sortKey].text = item.value;
            }
        });

        const result = {};
        result.punch = resultTmpPunch;
        result.list = _.sortBy(resultTmp, item => (item.sortKey));

        return result;
    }

    newPriceModelMessages (texte) {
        const resultTmp = [];
        let resultTmpPunch = '';
        let restulTmpDaily = null;
        const meta = {
            name: '', intro: '', vipHint: '', cta: ''
        };
        _.each(texte, (item, itemKey) => {
            if (itemKey.indexOf('punchline') !== -1) {
                resultTmpPunch = item.value;
            }
            if (itemKey.indexOf('dailyPrice') !== -1) {
                restulTmpDaily = item.value;
            }
            if (itemKey.indexOf('bullet-') !== -1) {
                resultTmp.push({
                    itemKey: parseInt(itemKey.replace('bullet-', ''), 10), text: item.value
                });
            }
            if (itemKey.indexOf('name') !== -1) {
                meta.name = item.value;
            }
            if (itemKey.indexOf('intro') !== -1) {
                meta.intro = item.value;
            }
            if (itemKey.indexOf('vipHint') !== -1) {
                meta.vipHint = this.inject(item.value);
            }
            if (itemKey.indexOf('cta') !== -1) {
                meta.cta = item.value;
            }
        });

        const result = {};
        result.punch = resultTmpPunch;
        result.daily = restulTmpDaily;
        result.meta = meta;
        result.list = this.sortTextArray(resultTmp);

        return result;
    }

    vipMessages (texte) {
        let resultTmpTitel = '';
        let resultTmpMore = '';
        const resultTmp = [];
        _.each(texte, (item, key) => {
            let sortKey;
            if (key.indexOf('headline-') !== -1) {
                sortKey = parseInt(key.replace('headline-', ''), 10);
                if (!resultTmp[sortKey]) {
                    resultTmp[sortKey] = {};
                }
                resultTmp[sortKey].itemKey = sortKey;
                resultTmp[sortKey].headline = item.value;
            }
            if (key.indexOf('text-') !== -1) {
                sortKey = parseInt(key.replace('text-', ''), 10);
                if (!resultTmp[sortKey]) {
                    resultTmp[sortKey] = {};
                }
                resultTmp[sortKey].itemKey = sortKey;
                resultTmp[sortKey].text = this.inject(item.value, 'target="__blank"');
            }
            if (key.indexOf('titel') !== -1) {
                resultTmpTitel = item.value;
            }
            if (key.indexOf('more') !== -1) {
                resultTmpMore = this.inject(item.value);
            }
        });
        if (!resultTmp[0] && resultTmp[1]) {
            resultTmp.shift();
        }

        const result = {};
        result.title = resultTmpTitel;
        result.more = resultTmpMore;
        // result.punch = resultTmpPunch;
        result.list = this.sortTextArray(resultTmp);

        return result;
    }

    cabinCategoryMessages (texte) {
        const result = {};
        if (texte) {
            result.subline = texte.subline && texte.subline.value ? texte.subline.value : '';
            result.decksPerCategory = texte.decksPerCategory && texte.decksPerCategory.value ? texte.decksPerCategory.value : '';
            result.textCategorySelection = texte.textCategorySelection && texte.textCategorySelection.value ? texte.textCategorySelection.value : '';
            result.advantagesCategorySelection = texte.advantagesCategorySelection && texte.advantagesCategorySelection.value ? texte.advantagesCategorySelection.value : '';
            result.advantagesText = texte.advantagesText && texte.advantagesText.value ? texte.advantagesText.value : '';
        }
        return result;
    }

    //  YoursTruly ContentAPI struct is so strange
    pricemodelMessagesAddOn (shoreex, internet) {
        const result = [];
        if (shoreex && shoreex.headline && shoreex.punchline) {
            result[0] = {};
            result[0].type = 'shoreex';
            result[0].headline = shoreex.headline.value;
            result[0].text = shoreex.punchline.value;
        }
        if (internet && internet.headline) {
            result[1] = {};
            result[1].type = 'internet';
            result[1].headline = internet.headline.value;
        }

        return result;
    }

    /* pricemodelMessages (texte) {
        const result = {};
        _.each(texte, (item, itemKey) => {
            if (!result.flexPrice) {
                result.flexPrice = [];
            }
            if (!result.feelgoodPrice) {
                result.feelgoodPrice = [];
            }
            if (itemKey.indexOf('flexPrice') !== -1) {
                item.sortKey = itemKey;
                result.flexPrice.push(item);
            }
            if (itemKey.indexOf('feelgoodPrice') !== -1) {
                item.sortKey = itemKey;
                result.feelgoodPrice.push(item);
            }
        });

        if (result.flexPrice) {
            result.flexPrice = _.sortBy(result.flexPrice, 'sortKey');
        }
        if (result.feelgoodPrice) {
            result.feelgoodPrice = _.sortBy(result.feelgoodPrice, 'sortKey');
        }

        return result;
    } */

    transportMessages (texte) {
        const result = {
            advantage: [], train: [], flight: [], bus: [], trainToPlanePurHint: '', trainTarif: [], popUp: {}
        };
        const fareTmp = [];
        const saveTmp = [];
        const railTmp = [];
        _.each(texte, (item, itemKey) => {
            if (itemKey.indexOf('advantage.headline') !== -1) {
                result.headline = item;
            } else if (itemKey.indexOf('train.advantage') !== -1) {
                item.sortKey = itemKey;
                result.train.push(item);
            } else if (itemKey.indexOf('flight.advantage') !== -1) {
                item.sortKey = itemKey;
                result.flight.push(item);
            } else if (itemKey.indexOf('bus.advantage') !== -1) {
                item.sortKey = itemKey;
                result.bus.push(item);
            } else if (itemKey.indexOf('advantage') !== -1) {
                item.sortKey = itemKey;
                result.advantage.push(item);
            } else if (itemKey.indexOf('trainToPlanePurHint') !== -1) {
                result.trainToPlanePurHint = item.value;
            } else if (itemKey.indexOf(`${Constants.NETMATCH_TRAIN_OPTION_FLEXIBLEFARE}-`) !== -1) {
                fareTmp.push({
                    itemKey: parseInt(itemKey.replace(`${Constants.NETMATCH_TRAIN_OPTION_FLEXIBLEFARE}-`, ''), 10),
                    text: item.value
                });
            } else if (itemKey.indexOf(`${Constants.NETMATCH_TRAIN_OPTION_SAVERFARE}-`) !== -1) {
                saveTmp.push({
                    itemKey: parseInt(itemKey.replace(`${Constants.NETMATCH_TRAIN_OPTION_SAVERFARE}-`, ''), 10),
                    text: item.value
                });
            } else if (itemKey.indexOf(`${Constants.NETMATCH_TRAIN_OPTION_MAP_RAILCRUISE}-`) !== -1) {
                railTmp.push({
                    itemKey: parseInt(itemKey.replace(`${Constants.NETMATCH_TRAIN_OPTION_MAP_RAILCRUISE}-`, ''), 10),
                    text: item.value
                });
            } else if (itemKey.indexOf('popUpHeadline') !== -1) {
                result.popUp.head = item.value;
            } else if (itemKey.indexOf('popUpText') !== -1) {
                result.popUp.text = this.inject(item.value);
            } else if (itemKey.indexOf('trainBlocked') !== -1) {
                result.trainBlocked = this.inject(item.value);
            }
        });
        result.train = _.sortBy(result.train, item => (item.sortKey));
        result.flight = _.sortBy(result.flight, item => (item.sortKey));
        result.bus = _.sortBy(result.bus, item => (item.sortKey));
        result.advantage = _.sortBy(result.advantage, item => (item.sortKey));

        result.trainTarif[Constants.NETMATCH_TRAIN_OPTION_FLEXIBLEFARE] = this.sortTextArray(fareTmp);
        result.trainTarif[Constants.NETMATCH_TRAIN_OPTION_SAVERFARE] = this.sortTextArray(saveTmp);
        result.trainTarif[Constants.NETMATCH_TRAIN_OPTION_MAP_RAILCRUISE] = this.sortTextArray(railTmp);

        return result;
    }

    insurancesMessages (texte) {
        let baseNs; // base namespace
        const result = {
            travelCancelation: {
                descriptionList: []
            },
            premium: {
                descriptionList: []
            },
            basic: {
                descriptionList: []
            },
            infoTexts: {
                storno: {}
            }
        };

        _.each(texte, (item, itemKey) => {
            baseNs = itemKey.substr(0, itemKey.indexOf('.'));
            let itemKeyListMatches;
            let listItemIndex;
            let listItemProperty;

            item.value = this.inject(item.value);
            switch (baseNs) {
                case 'travelCancelation':
                case 'basic':
                case 'premium':
                    itemKeyListMatches = itemKey.match(/\.(([0-9]+)\..*)/);

                    if (itemKey.indexOf('descriptionList') !== -1 && itemKeyListMatches) {
                        listItemIndex = parseInt(itemKeyListMatches[2], 10);
                        listItemProperty = itemKeyListMatches[1].replace(`${listItemIndex}.`, '');

                        if (!result[baseNs].descriptionList[listItemIndex - 1]) {
                            result[baseNs].descriptionList[listItemIndex - 1] = {};
                        }
                        result[baseNs].descriptionList[listItemIndex - 1][listItemProperty] = item.value;
                    } else {
                        result[baseNs][itemKey.replace(`${baseNs}.`, '')] = item.value.split('”').join('"');
                    }
                    break;
                // TODO ContentAPI: should provide camel-case format here (`infoTexts`). remove case when fixed.
                case 'infotexts':
                case 'infoTexts':
                    if (itemKey.indexOf('storno') !== -1) {
                        result.infoTexts.storno[itemKey.replace(`${baseNs}.storno.`, '')] = item.value.split('’').join('"');
                    } else {
                        result.infoTexts[itemKey.replace(`${baseNs}.`, '')] = item.value.split('’').join('"');
                    }
                    break;
                default:
                    result[itemKey] = _.isString(item.value) ? item.value.split('’').join('"') : item.value;
                    break;
            }
        });

        return result;
    }

    personalDataMessages (texte) {
        const result = {};
        _.each(texte, (item, itemKey) => {
            item.value = this.inject(item.value);

            // 2017.01.11: yoursTruly use '”' for inline a-href, but html does not like that for a-href, so we have to replace it
            result[itemKey] = item.value.split('”').join('"');
        });
        return result;
    }

    paymentMessages (texte) {
        const result = {};
        _.each(texte, (item, itemKey) => {
            item.value = this.inject(item.value);

            // 2017.01.13: yoursTruly use '’' for inline a-href, but html does not like that for a-href, so we have to replace it
            if (!result.paymentMethod) {
                result.paymentMethod = {};
            }
            if (itemKey.indexOf('paymentMethod') !== -1) {
                result.paymentMethod[itemKey.replace('paymentMethod.', '')] = item.value.split('’').join('"');
            }
            if (!result.pauschalreiserichtlinie) {
                result.pauschalreiserichtlinie = {};
            }
            if (itemKey.indexOf('pauschalreiserichtlinie') !== -1) {
                result.pauschalreiserichtlinie[itemKey.replace('pauschalreiserichtlinie.', '')] = item.value;
            }
            if (!result.overlayInvoiceAddress) {
                result.overlayInvoiceAddress = {};
            }
            if (!result.overlayInvoiceAddressB2b) {
                result.overlayInvoiceAddressB2b = {};
            }
            if (itemKey.indexOf('overlayInvoiceAddress') !== -1) {
                result.overlayInvoiceAddress[itemKey.replace('overlayInvoiceAddress.', '')] = item.value.split('’').join('"');
            }
            if (itemKey.indexOf('overlayInvoiceAddressB2b') !== -1) {
                result.overlayInvoiceAddressB2b[itemKey.replace('overlayInvoiceAddressB2b.', '')] = item.value.split('’').join('"');
            }

            if (itemKey.indexOf('overlayInvoiceAddress') === -1 && itemKey.indexOf('paymentMethod') === -1 && itemKey.indexOf('pauschalreiserichtlinie') === -1) {
                result[itemKey] = item.value.split('’').join('"');
            }
        });
        return result;
    }

    onlineRisk (texte) {
        const result = {};
        _.each(texte, (item, itemKey) => {
            result[itemKey] = item.value;
        });
        return result;
    }

    confirmationMessages (texte) {
        const result = {};
        _.each(texte, (item, itemKey) => {
            if (!result.top) {
                result.top = {};
            }
            if (!result.bottom) {
                result.bottom = {};
            }
            if (itemKey.indexOf('top') !== -1) {
                result.top[itemKey.replace('top.', '')] = item.value;
            }
            if (itemKey.indexOf('bottom') !== -1) {
                result.bottom[itemKey.replace('bottom.', '')] = item.value;
            }
        });
        return result;
    }

    metaMessages (texte) {
        const result = {};
        _.each(texte, (item, itemKey) => {
            if (!result.agb) {
                result.agb = {};
            }
            if (!result.imprint) {
                result.imprint = {};
            }
            if (!result.boardAbc) {
                result.boardAbc = {};
            }
            if (!result.insurance) {
                result.insurance = {};
            }
            if (!result.cancelHint) {
                result.cancelHint = '';
            }
            if (itemKey.indexOf('agb') !== -1) {
                result.agb[itemKey.replace('agb.', '')] = item.value;
            }
            if (itemKey.indexOf('imprint') !== -1) {
                result.imprint[itemKey] = item.value;
            }
            if (itemKey.indexOf('boardAbc') !== -1) {
                result.boardAbc[itemKey.replace('boardAbc.', '')] = item.value;
            }
            if (itemKey.indexOf('insurance') !== -1) {
                result.insurance[itemKey.replace('insurance.', '')] = item.value;
            }
            if (itemKey.indexOf('cancellationHint') !== -1) {
                result.cancelHint = item.value;
            }
        });
        return result;
    }


    footerMessages (texte) {
        const result = {};
        _.each(texte, (item, itemKey) => {
            if (!result.agb) {
                result.agb = {};
            }
            if (!result.privacy) {
                result.privacy = {};
            }
            if (!result.imprint) {
                result.imprint = {};
            }

            if (itemKey.indexOf('agb') !== -1) {
                result.agb = item.value;
            }
            if (itemKey.indexOf('datenschutz') !== -1) {
                result.privacy = item.value;
            }
            if (itemKey.indexOf('impressum') !== -1) {
                result.imprint = item.value;
            }
        });
        return result;
    }

    ctaMessages (texte) {
        const result = {};
        _.each(texte, (item, itemKey) => {
            result[itemKey] = item.value ? item.value.replace(/Mein Schiff/g, '<nobr><i>Mein Schiff</i> <sup>&reg;</sup></nobr>') : '';
        });
        return result;
    }

    sortTextArray (list) {
        return list.sort((a, b) => {
            if (a.itemKey > b.itemKey) {
                return 1;
            }
            if (a.itemKey < b.itemKey) {
                return -1;
            }
            return 0;
        });
    }

    // TUICUNIT-3170: Aktionscodes
    campaignCodeMessages (messages = {}) {
        return Object.keys(messages).reduce((acc, key) => {
            acc[key.toLowerCase()] = this.cleanText(messages[key].value);
            return acc;
        }, {});
    }

    // inject rel="noopener noreferrer" in yoursTruly Messages href-links
    inject (text, option = '') {
        if (_.isString(text)) {
            if (text.indexOf('rel="noopener noreferrer"') === -1) {
                text = text.split('href').join(`rel="noopener noreferrer" class="js-tracking-extern" ${option} href`);
            }
        }
        return text;
    }

    /**
     * remove all html tags
     *
     * @param {string} text
     * @returns clean String
     */
    cleanText (text) {
        let cText = text || '';
        cText = cText.replace(/(<([^>]+)>)/gi, '');
        cText = cText.replace(/([\n\t])/gi, ' ');
        cText = cText.split('  ').join(' ');
        return cText;
    }
}
