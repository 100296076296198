import fecha, {setGlobalDateI18n, setGlobalDateMasks} from 'fecha/lib/fecha';
import {getLocaleAPI} from '../intl';


if (getLocaleAPI() === 'de') {
    setGlobalDateI18n({
        dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
        dayNamesShort: ['So.', 'Mo.', 'Di.', 'Mi.', 'Do.', 'Fr.', 'Sa.'],
        monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
        monthNamesShort: ['Jan.', 'Febr.', 'Mrz.', 'Apr.', 'Mai', 'Jun.', 'Jul.', 'Aug.', 'Sept.', 'Okt.', 'Nov.', 'Dez.'],
        DoFn: '%d.',
        amPm: ['am', 'pm']
    });
}


setGlobalDateMasks({
    edmDateTimeOffset: 'YYYY-MM-DDTHH:mm:ssZZ',
    edmDate: 'YYYY-MM-DD',
    germanExtraShortDate: 'DD.MM.YY', // ?
    germanShortDate: 'DD.MM.YYYY',
    germanMediumDate: 'ddd, DD.MM.YYYY',
    germanLongDate: 'ddd, D. MMM YYYY', // ?
    germanExtraLongDate: 'dddd, D. MMMM YYYY', // ?
    germanDateWithDay: 'dddd DD.MM.YYYY',
    germanShortTime: 'HH:mm',
    germanMediumTime: 'HH:mm', // ?
    germanLongTime: 'HH:mm:ss', // ?
    germanShortDatetime: 'DD.MM.YY HH:mm', // ?
    germanMediumDatetime: 'ddd, DD.MM.YYYY, HH:mm', // ?
    germanLongDatetime: 'dddd, D. MMMM YYYY, HH:mm:ss' // ?
});

export default fecha;
