import BaseItem from './item';

/**
 * Represents a base item (view) as a base page (view)
 */
export default class BasePage extends BaseItem {
    /**
     * construct the base item with templateReal and className set up for a base page view
     *
     * @param options
     */
    constructor (options = {}) {
        super({
            className: `page page-${options.key}`,
            ...options
        });
    }


    get appLayoutType () {
        return 'default';
    }


    get pageLayoutType () {
        return 'default';
    }
}

