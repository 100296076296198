import _ from 'underscore';
import BaseModel from '../../../../base/model/base';
import * as Constants from '../../../../config/constants';

export default class AgencyModel extends BaseModel {
    isB2B () {
        if (!this.checkParam()) {
            return false;
        }
        if (this.isOperator() || this.isAgentur()) {
            return true;
        }
        return false;
    }

    checkParam () {
        if (this.get('paymentType')) {
            if (_.isString(this.get('paymentType'))) {
                return true;
            }
        }
        return false;
    }

    isOperator () {
        if (!this.checkParam()) {
            return false;
        }
        if (this.get('paymentType').toLowerCase() === Constants.NETMATCH_STEP_AGENCY_API_RESULT_PAYMENT_TYPE_OPERATOR) {
            return true;
        }
        return false;
    }

    isAgentur () {
        if (!this.checkParam()) {
            return false;
        }
        if (this.get('paymentType').toLowerCase() === Constants.NETMATCH_STEP_AGENCY_API_RESULT_PAYMENT_TYPE_AGENTUR) {
            return true;
        }
        return false;
    }

    getNumber () {
        return this.get('number') || '';
    }
}
