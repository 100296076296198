var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"overlay-close-wrapper-bottom\">\n                    <a class=\"button button-secondary js-close\"><span class=\"icon-arrow-left\"></span> "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"closeButtonText") || (depth0 != null ? lookupProperty(depth0,"closeButtonText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"closeButtonText","hash":{},"data":data,"loc":{"start":{"line":12,"column":102},"end":{"line":12,"column":121}}}) : helper)))
    + "</a>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <div class=\"col-lg-8\">\n        <div class=\"overlay overlay-route-map\">\n            <a class=\"js-close overlay-close\"></a>\n\n            <h3 class=\"overlay-headline\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"tripName") || (depth0 != null ? lookupProperty(depth0,"tripName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tripName","hash":{},"data":data,"loc":{"start":{"line":6,"column":41},"end":{"line":6,"column":55}}}) : helper)))
    + "</h3>\n\n            <img alt=\"Route\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"routeMapURL") || (depth0 != null ? lookupProperty(depth0,"routeMapURL") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"routeMapURL","hash":{},"data":data,"loc":{"start":{"line":8,"column":34},"end":{"line":8,"column":51}}}) : helper)))
    + "\" class=\"responsive-img\"/>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"closeButtonText") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":14,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"useData":true});