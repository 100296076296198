import React from 'react';
import { FormattedMessage } from 'react-intl';
import app from '../../../app/app';

/**
 * B2B detail Box
 */
export default function B2BCabinDetailView () {
    const code: any = (app.apiSession && app.apiSession.bookingStatus) ? app.apiSession.bookingStatus.bookingCode.findCode() : {};
    const tmp:any = (app.contentApi) ? app.contentApi.messages.prepareMessages('b2blight') : null;

    let text:string | null = null;
    if (tmp && tmp.importantHint) {
        text = tmp.importantHint;
    }

    return (
        <div className="b2b-view">
            <h4 className="headline"><FormattedMessage id="components.sections.b2b.cabin-detail.headline" />
                <a className="print button button-secondary" href="print-offer">
                    <FormattedMessage id="components.sections.b2b.cabin-detail.print-offer" />
                    <span className="icon-print"></span>
                </a>
            </h4>
            <div className="second">
                <FormattedMessage id="components.sections.b2b.cabin-detail.crs-booking-codes" />
            </div>
            <div>
                <FormattedMessage id="components.sections.b2b.cabin-detail.booking-details" />
            </div>
            {code ?
                <div className="code-table">
                    <div className="code-td">
                        <div className="code-head">
                            <FormattedMessage id="components.sections.b2b.cabin-detail.booking-code" />
                        </div>
                        {code.typeCode
                            ? code.typeCode.map((item:any, key:number) => {
                                return (<div key={key} className="code-value">{item}</div>);
                            })
                            : ''}
                    </div>
                    <div className="code-td">
                        <div className="code-head">
                            <FormattedMessage id="components.sections.b2b.cabin-detail.service-code" />
                        </div>
                        {code.serviceCode
                            ? code.serviceCode.map((item:any, key:number) => {
                                return (<div key={key} className="code-value">{item}</div>);
                            })
                            : ''}
                    </div>
                    <div className="code-td">
                        <div className="code-head">
                            <FormattedMessage id="components.sections.b2b.cabin-detail.accommodation" />
                        </div>
                        {code.serviceTypeCode
                            ? code.serviceTypeCode.map((item:any, key:number) => {
                                return (<div key={key} className="code-value">{item}</div>);
                            })
                            : ''}
                    </div>

                    <div className="code-td">
                        <div className="code-head">
                            <FormattedMessage id="components.sections.b2b.cabin-detail.participants" />
                        </div>
                        {code.occupancy
                            ? code.occupancy.map((item:any, key:number) => {
                                return (<div key={key} className="code-value">{item}</div>);
                            })
                            : ''}
                    </div>

                    <div className="code-td">
                        <div className="code-head">
                            <FormattedMessage id="components.sections.b2b.cabin-detail.count" />
                        </div>
                        {code.count
                            ? code.count.map((item:any, key:number) => {
                                return (<div key={key} className="code-value">{item}</div>);
                            })
                            : ''}
                    </div>

                    <div className="code-td">
                        <div className="code-head">
                            <FormattedMessage id="components.sections.b2b.cabin-detail.from-date" />
                        </div>
                        {code.fromDate
                            ? code.fromDate.map((item:any, key:number) => {
                                return (<div key={key} className="code-value">{item}</div>);
                            })
                            : ''}
                    </div>

                    <div className="code-td">
                        <div className="code-head">
                            <FormattedMessage id="components.sections.b2b.cabin-detail.to-date" />
                        </div>
                        {code.toDate
                            ? code.toDate.map((item:any, key:number) => {
                                return (<div key={key} className="code-value">{item}</div>);
                            })
                            : ''}
                    </div>

                    <div className="code-td">
                        <div className="code-head">
                            <FormattedMessage id="components.sections.b2b.cabin-detail.participant-assignment" />
                        </div>
                        {code.participantAssignment
                            ? code.participantAssignment.map((item:any, key:number) => {
                                return (<div key={key} className="code-value">{item}</div>);
                            })
                            : ''}
                    </div>
                </div>
                : ''}
            {text ?
                <>
                    <div className="second">
                        <FormattedMessage id="components.sections.b2b.cabin-detail.important-hint" />
                    </div>
                    <div>{ text }</div>
                </>
                : ''}
        </div>
    );
};

