import React from 'react';
import app from '../../app/app';

import TrustBarView from './trust-bar';
import LinkBarView from './link-bar';

export interface TypeLinkBarProps {
    isB2B: boolean;
    url:{
        privacy: string,
        imprint: string,
        agb: string
    } ;
}

export default function FooterView () {

    const isB2B: TypeLinkBarProps['isB2B'] = (app && app.apiSession && app.apiSession.bookingStatus && app.apiSession.bookingStatus.agency) ? app.apiSession.bookingStatus.agency.isB2B() : false;
    const url = {} as TypeLinkBarProps['url'];
    if (app && app.contentApi && app.contentApi.messages) {
        const temp: any = app.contentApi.messages.prepareMessages('footer');
        url.privacy = temp.privacy;
        url.imprint = temp.imprint;
        url.agb = temp.agb;
    }

    return (
        <footer className="page-footer">
            <TrustBarView />
            <LinkBarView isB2B={isB2B} url={url} />
        </footer>
    );
}
