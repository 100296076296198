import _ from 'underscore';
import ApiInputBaseView from './base';
import templateWebpack from '../../../../tpl/item/api-input/participant-select.hbs';


export default class ApiInputParticipantSelectView extends ApiInputBaseView {
    constructor (options) {
        super({
            templateWebpack,
            ...options
        });
    }

    /**
     * combine changed value with value of all other options
     */
    onValueChange (e) {
        const $element = this.$(e.currentTarget);
        const newValue = $element.val();
        const currentValues = this.currentValues;

        if (!currentValues.includes(newValue)) {
            currentValues[this.options.participantRank] = newValue;
        }

        this.setValue(currentValues.toString());
    }

    /**
     * View events triggered by the user
     */
    events () {
        return {
            [`change #${this.options.additionalTemplateContext.participantDomId}`]: 'onValueChange'
        };
    }

    /**
     * The input value of the policy assignment step is preset with a current selection for each participant.
     * Each value of this selection, except for the current participant must be blocked, as it would lead to a duplicate
     * selection of that participant
     *
     * @returns {Array.<T>}
     */
    get blockedValues () {
        const newValues = this.currentValues;
        newValues.splice(this.options.participantRank, 1);
        return newValues;
    }

    get currentValues () {
        const values = `${this.model.getInputValue()}`;
        return values.split(',');
    }

    /**
     * Add details to the data passed into the template.
     * Will be merged into the model / collection data.
     */
    templateContext () {
        _.each(
            this.options.additionalTemplateContext.options,
            (option) => {
                option.selected = (option.id === this.options.additionalTemplateContext.participantSelection);
                // block exchange of participants for the current assignment to reduce UX noise
                option.isBlocked = this.blockedValues.includes(option.id.toString());
            }
        );

        return {
            // disabled at this position to allow additionalTemplateContext to overwrite
            disabled: this.model.apiStep.isPending() || this.model.apiStep.isBlocked() || this.model.apiStep.isNotApplicable(),
            darkStyling: !!this.getOption('darkStyling'),
            ...this.options.additionalTemplateContext,
            required: this.model.requiresInput(),
            domId: this.model.domId,
            error: this.isAttached() ? this.model.getInputError() : this.model.getSubmitError()
        // error: this.model.hasSubmitError() ? this.model.getSubmitError() : null,
        };
    }
}
