import $ from 'jquery';
import Backbone from 'backbone';
import Marionette from 'backbone.marionette';

import Config from '../config/config';
import Router from '../router';
import PageController from '../controller/page';
import bookingPageConfig from '../config/booking-pages';
import BookingPagesCollection from '../entities/ibe/collection/booking-pages';
import ApiSessionModel from '../entities/netmatch-api/model/session';
import IbeController from '../controller/ibe';
import ContentAPI from '../entities/content-api';
import TrackController from '../controller/track';
import Url from '../util/url';
import * as Constants from '../config/constants';
import {WebpIsSupported} from '../components/util/helper';


class Application extends Marionette.Application {
    get region () {
        return '#app';
    }

    /* init router and layout */
    onBeforeStart () {
        this.router = new Router();

        this.apiSession = new ApiSessionModel();

        this.contentApi = new ContentAPI();

        this.bookingPages = new BookingPagesCollection(bookingPageConfig);

        this.ibeController = new IbeController();

        this.pageController = new PageController();

        this.trackController = new TrackController();

        const currentUrl = new Url();
        if (currentUrl.queryParameters[Constants.NATIVE_APP_TRIGGER_PARAMETER] !== undefined) {
            this.getRegion().$el.addClass(Constants.NATIVE_APP_CLASS_NAME);
        }

        this.WebpIsSupported = false;
        (async () => {
            if (await WebpIsSupported()) {
                this.canWebp = true;
            }
        })();
    }


    /* init history */
    onStart () {
        if (Backbone.history) {
            Backbone.history.start({
                pushState: Config.pushState,
                hashChange: !Config.pushState, // don't use hashes on activated push state
                root: Config.root
            });
        }
    }
}

const app = new Application();

const $window = Backbone.$(window);
const $document = Backbone.$(document);

/* event listeners */
app.on('scrollTop', () => {
    $window.scrollTop(0);
});

app.on('scrollTo', ($element, onComplete) => {
    if (!$element.length || !$element.offset) {
        return;
    }
    const offset = $element.offset();

    let buffer = 0;
    if ($element.hasClass('booking-page-section')) {
        buffer = 50;
    }
    if ($element.hasClass('section-personal')) {
        buffer = 200;
    }
    if ($element.hasClass('travel-individually')
        || $element.hasClass('api-step-transporttype')
        || $element.hasClass('api-step-flight')) {
        buffer = 90;
    }
    if ($element.hasClass('js-toggle-more-flights')) {
        buffer = 120;
    }

    if (offset && offset.top) {
        Backbone.$('html, body').stop().animate({
            scrollTop: offset.top - buffer
        }, parseInt(Config.transition.scroll, 10), 'swing', onComplete);
    }
});

app.on('scrollToFromReact', (element, onComplete) => {
    const offset = $(element).offset();
    const buffer = 80;
    if (offset && offset.top) {
        Backbone.$('html, body').stop().animate({
            scrollTop: offset.top - buffer
        }, parseInt((Config.transition.scroll), 10), 'swing', onComplete);
    }
});

/* event broadcasters */
$document.ajaxSend((e, jqXHR, ajaxOptions) => {
    if (!ajaxOptions.noSpinner) {
        app.trigger('loading:start', jqXHR, ajaxOptions);
    }
});

$document.ajaxStop(() => {
    app.trigger('loading:stop');
});

//
// export app object
//
export default app;
