import Marionette from 'backbone.marionette';
import InsuranceAssignmentView from './insurance-assignment';
import * as Constants from '../../../config/constants';
// import app from '../../../app/app';


export default class InsuranceAssignmentsView extends Marionette.CollectionView {
    get className () {
        return 'insurance-selector-insurance-assignments';
    }


    get childView () {
        return InsuranceAssignmentView;
    }


    childViewOptions () {
        return {
            insuranceSelectorModel: this.getOption('insuranceSelectorModel')
        };
    }


    /**
     * Listen to events of child views
     */
    childViewEvents () {
        return {
            'edit:insurances': () => {
                this.triggerMethod('edit:insurances');
            }
        };
    }


    /**
     * filter out modles that shall not be rendered
     *
     * @see  https://marionettejs.com/docs/master/marionette.collectionviewadvanced.html#collectionviews-filter
     */
    filter (insurance) {
        return insurance.get('type') !== Constants.NETMATCH_INSURANCE_MEMBERSTYPE_DUMMY;
    }
}
