import ApiInputChoiceBaseView from './basechoice';
import templateWebpack from '../../../../tpl/item/api-input/combobox.hbs';


export default class ApiInputComboboxView extends ApiInputChoiceBaseView {
    constructor (options) {
        super({
            templateWebpack,
            ...options
        });

        this.filter = options.filter;
        this.inputMode = options.inputMode;
        this.listenTo(this.filter, 'change:options', this.applyFilter);
    }

    serializeData () {
        const data = super.serializeData();

        data.filter = this.filter.toJSON();
        data.inputMode = this.inputMode;

        if (data.options) {
            data.options.forEach((item) => {
                // the template value-field will always return a string, in order to match by ===, we convert here
                item.id = item.id.toString();
            });
        }

        return data;
    }

    /**
     * Add a custom change event to view events
     */
    events () {
        const superEvents = super.events.apply(this);

        // TODO JOK: onTermValueChange needs to be triggered by keypress
        return {
            ...superEvents,
            [`change #${this.model.domId}_search`]: 'onTermValueChange',
            [`keypress #${this.model.domId}`]: 'keyAction'
        };

    // HINT: super uses `change #${this.model.domId}`: onValueChange to update the select-Input
    //       the combobox uses onTermValueChange to update the text-Input
    }

    keyAction () {
        this.toggleSearchMode();
    }

    toggleSearchMode () {
        this.inputMode = !this.inputMode;

        this.model.trigger('change');

        if (this.inputMode) {
            this.$(`#${this.model.domId}_search`).focus();
        } else {
            this.$(`#${this.model.domId}`).focus();
        }
    }

    onTermValueChange (e) {
        // TODO JOK: when this will be triggered by keypress, we need to wait an execute by timeout
        //           in order to execute after user has edited the field and waited some milliseconds
        const $element = this.$(e.currentTarget);

        this.filter.set('term', $element.val());
    }

    applyFilter () {
        const options = this.filter.get('options');

        this.setValue(options[0].id);
        this.model.set('options', options);

        this.toggleSearchMode();
    }
}
