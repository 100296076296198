import _ from 'underscore';
import BaseModel from '../../../base/model/base';
import contentApiConfig from '../../../config/content-api';


export default class IbeTeaserModel extends BaseModel {
    prepareUrlAndFetch () {
        this.url = contentApiConfig.ibeTeaserURL;
        return this.fetch();
    }

    groupByCategory (data) {
        return _.groupBy(data, 'category');
    }
}
