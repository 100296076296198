import NavigationUtil from '../../util/navigation';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function NoDeckPlanView () {
    return (
        <div className="cabin-deckplan">
            <div className="deck-plan-hint">
                <FormattedMessage id="general.position.bug" />
            </div>
            <div className="no-deck-plan">
                <img alt="noDeck" src={`${NavigationUtil.normalizePath('images/noDeck.svg')}`}/>
            </div>
            <div className="deck-plan-hint">
                <FormattedMessage id="general.position.heck" />
            </div>
        </div>
    );
}
