import React, { useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import NoDeckPlanView from './no-deckplan';

import app from '../../app/app';
import * as Constants from '../../config/constants';
import Device from '../../util/device';
import { FormattedMessage } from 'react-intl';


type DeckPlanProps = {
    cabinIndex: number;
    deckNumber:string;
    stepModelCabinSelection: any;
    deckLegendTrigger:Function;
    callback: Function;
    searchState: boolean;
    searchDetails:any;
};
type StepDataType = {id: string, content:{}};

type DeckPlanCabinType = {
    cabinInterior:[string];
    cabinSpecifics: [string] | null;
    deck: string;
    deckplan: string;
    exteriorSizeInfo: string;
    exteriorType:string;
    interiorSizeInfo: string;
    minOccupation: string;
    maxOccupation: string;
    number: string;
}

type CabinDetailsType = {
    top?: number,
    left?: number,
    alignX?: string,
    alignY?: string,
    cabin?: DeckPlanCabinType,
    cabinTextAddOn?: string | null,
    visable: boolean
}

export default function DeckPlanView (props:DeckPlanProps) {
    const { cabinIndex, deckNumber, stepModelCabinSelection, deckLegendTrigger, callback, searchState, searchDetails} = props;
    const [deckPlan, setDeckPlan] = useState<{cabins:Array<any>, deckplanImageURL: string} | null>();
    const [cabinDetails, setCabinDetails] = useState<CabinDetailsType>({visable: false});
    const [cabinDetailsVisible, setCabinDetailsVisable] = useState(false);
    const plainSelection = stepModelCabinSelection.toJSON();
    let availableCabins:Array<string> = [];
    const availableCabinsDetail:Array<DeckPlanCabinType> = [];
    let selected:string;

    useEffect(() => {
        let isSubscribed = true;
        if (app.contentApi && deckNumber) {
            // @ts-ignore
            app.contentApi.loadDeckInformation(deckNumber).then(() => {
                if (app.contentApi && isSubscribed) {
                    // @ts-ignore
                    const deckData = app.contentApi.deckInformation.toJSON();
                    // @ts-ignore
                    setDeckPlan(deckData);
                    deckLegendTrigger(deckData.deckplanLegend);
                }
            }).catch(() => {
                reportDeckplanError(deckNumber);
            });
        }
        // cleanup function
        return () => {
            isSubscribed = false;
        };
    }, [deckNumber, deckLegendTrigger]);


    const reportDeckplanError = (deckNumber:string) => {
        const tmpIbeStorage = (app.ibeController && app.ibeController.ibeStorage);
        // @ts-ignore
        const initParamas = (tmpIbeStorage && tmpIbeStorage) ? tmpIbeStorage.get(Constants.INITIAL_PARAMETER) : '';

        if (app.trackController) {
            app.trackController.eventTracking({
                error: 'deckplan-error',
                label: initParamas ? `${initParamas.tripCode}_${initParamas.ePackageCode}_Deck${deckNumber}` : '',
                ga4clickLabel: `content.custom.deckplan-error-${initParamas.tripCode}_${initParamas.ePackageCode}_Deck${deckNumber}`
            });
        }
    };

    if (searchDetails) {
        plainSelection.inputs = null;
        selected = searchDetails.number;
        availableCabins.push(searchDetails.number);
        if (deckPlan && deckPlan.cabins) {
            const findDetail = deckPlan.cabins.find((cabinDetail:DeckPlanCabinType) => cabinDetail.number === selected.toString());
            if (findDetail) {
                availableCabinsDetail.push(findDetail);
            }
        }
    }

    if (plainSelection.inputs) {
        const selectedItem = plainSelection.inputs.find((item:any) => item.inputValue);
        selected = selectedItem ? selectedItem.inputValue : null;
        plainSelection.inputs.forEach((input:any) => {
            if (input.options) {
                availableCabins = input.options.map((cabinNumberOption:{displayText: string, id: string, isBlocked: boolean}) => {
                    if (cabinNumberOption.isBlocked !== true) {
                        return cabinNumberOption.id;
                    }
                    return null;
                });
            }
        });
        availableCabins.forEach((cabin: string) => {
            if (deckPlan && deckPlan.cabins) {
                const findDetail = deckPlan.cabins.find((cabinDetail:DeckPlanCabinType) => cabinDetail.number === cabin);
                if (findDetail) {
                    availableCabinsDetail.push(findDetail);
                }
            }
        });

        if (!selected) {
            selected = availableCabins[0];
        }
    }

    const multiCabin: any = (app.apiSession && app.apiSession.bookingStatus) ? app.apiSession.bookingStatus.getCabinData() : false;
    const selectedOther:Array<any> = [];
    if (multiCabin) {
        multiCabin.forEach((item:any) => {
            if (item.cabinIndex !== cabinIndex) {
                selectedOther.push(item);
            }
        });
    }

    const toSubmitStepCabinNumber = (value:string) => {
        const stepsToSubmit = [];
        const stepData:StepDataType = {
            'id': stepModelCabinSelection.get('id'),
            'content': {
                [Constants.NETMATCH_INPUT_CABINNUMBER_CABINNUMBER]: value
            }
        };
        if (stepModelCabinSelection && stepModelCabinSelection.validateData(stepData).valid) {
            stepsToSubmit.push(stepData);
            callback(stepsToSubmit);
        }
    };


    const onEnter = (e:any) => {
        if (Device.supportsTouch()) {
            return true;
        }

        const cabinNumber = e.currentTarget.id.split('-').pop();

        //
        const cabinBoundingRect = e.currentTarget.getBoundingClientRect();
        // @ts-ignore
        const svgBoundingRect = document.querySelector('.deck-plan-svg').getBoundingClientRect();

        let left = cabinBoundingRect.right - 65;
        let alignX = 'right';
        let alignY = 'top';

        if (cabinBoundingRect.left > svgBoundingRect.left + (svgBoundingRect.width / 2)) {
            left = Math.round(cabinBoundingRect.left - 10);
            alignX = 'right';
        }
        if (cabinBoundingRect.bottom > window.innerHeight / 2) {
            alignY = 'bottom';
        }

        /* if (left <= 740) {
            left = 500;
        } */
        // TUICUNIT-1297
        let top = Math.round(cabinBoundingRect.top);
        if (alignY === 'top') {
            top = top - 50;
        }
        if (alignY === 'bottom') {
            top = top + 100;
        }
        //

        let cabinTextAddOn = null;
        if (cabinNumber === selected) {
            cabinTextAddOn = `Kabine ${cabinIndex}`;
        }
        let cabin = availableCabinsDetail.find((detail:DeckPlanCabinType) => detail.number === cabinNumber);

        if (selectedOther) {
            selectedOther.forEach((item:any) => {
                if (cabinNumber === item.cabinNumber) {
                    cabinTextAddOn = `Kabine ${item.cabinIndex}`;

                    if (!cabin && deckPlan && deckPlan.cabins) {
                        cabin = deckPlan.cabins.find((cabinDetail:DeckPlanCabinType) => cabinDetail.number === item.cabinNumber);

                    }
                }
            });
        }


        if (cabin) {
            if (!cabinDetailsVisible) {
                setCabinDetails({
                    top: top,
                    left: left,
                    alignX: alignX,
                    alignY: alignY,
                    cabin: cabin,
                    cabinTextAddOn: cabinTextAddOn,
                    visable: true
                });
                setCabinDetailsVisable(true);
            }
        }
    };

    const onLeave = () => {
        if (Device.supportsTouch()) {
            return true;
        }
        setCabinDetails({
            visable: false
        });
        setCabinDetailsVisable(false);
    };

    const onClick = (e:any) => {
        if (Device.supportsTouch()) {
            return true;
        }
        const cabinNumber = e.currentTarget.id.split('-').pop();

        setCabinDetails({
            visable: false
        });

        toSubmitStepCabinNumber(cabinNumber);
        if (app && app.trackController) {
            app.trackController.eventTracking({
                action: 'Wunschkabine_Plan',
                label: cabinNumber,
                ga4clickLabel: `content.image.wunschkabine-${cabinNumber}`
            });
        }
    };

    return (
        <>
            {deckPlan && deckPlan.deckplanImageURL ?
                <div className="cabin-deckplan">
                    <div className="deck-plan-hint">
                        <FormattedMessage id="general.position.bug" />
                    </div>
                    <span id="js-cabin-detail"></span>
                    <CabinDetail cabinDetails={cabinDetails}></CabinDetail>
                    <ReactSVG src={deckPlan.deckplanImageURL}
                        className="wrapper-deckplan"
                        beforeInjection={svg => {
                            svg.setAttribute('class', `${svg.getAttribute('class')} deck-plan-svg`);
                        }}
                        afterInjection={(svg) => {
                            if (svg) {
                                availableCabins.forEach((cabinNumber) => {
                                    // @ts-ignore
                                    const tmp = svg.querySelector(`#KABINE-${cabinNumber}`);
                                    const tmpText = svg.querySelector(`#CABIN-NR-${cabinNumber}`);

                                    if (tmp) {
                                        tmp.setAttribute('class', `${tmp.getAttribute('class')} available`);
                                        if (searchState) {
                                            tmp.addEventListener('mouseenter', onEnter, false);
                                            tmp.addEventListener('mouseleave', onLeave, false);
                                        }
                                        if (cabinNumber !== selected && searchState) {
                                            tmp.addEventListener('click', onClick, false);
                                        }
                                        if (cabinNumber === selected) {
                                            tmp.setAttribute('class', `${tmp.getAttribute('class')} selected`);
                                        }
                                    }
                                    if (tmpText) {
                                        tmpText.setAttribute('class', `${tmpText.getAttribute('class')} available`);
                                        if (cabinNumber === selected) {
                                            tmpText.setAttribute('class', `${tmpText.getAttribute('class')} selected`);
                                        }
                                    }
                                });
                                if (selectedOther) {
                                    selectedOther.forEach((item) => {
                                        const tmp = svg.querySelector(`#KABINE-${item.cabinNumber}`);
                                        const tmpText = svg.querySelector(`#CABIN-NR-${item.cabinNumber}`);
                                        if (tmp) {
                                            if (searchState) {
                                                tmp.addEventListener('mouseenter', onEnter, false);
                                                tmp.addEventListener('mouseleave', onLeave, false);
                                            }
                                            tmp.removeEventListener('click', onClick, false);
                                            tmp.setAttribute('class', `${tmp.getAttribute('class')} selected-other`);
                                        }
                                        if (tmpText) {
                                            tmpText.setAttribute('class', `${tmpText.getAttribute('class')} selected-other`);
                                        }
                                    });
                                }
                                try {
                                    // @ts-ignore
                                    const boundingBox = svg.getBBox();
                                    const viewBox = [Math.max(0, boundingBox.x - 5), Math.max(0, boundingBox.y - 5), boundingBox.width + 10, boundingBox.height + 10].join(' ');
                                    svg.setAttribute('viewBox', viewBox);
                                } catch (e) {}
                            } else {
                                console.error('SVG');
                                return false;
                            }
                        }} />
                    <div className="deck-plan-hint">
                        <FormattedMessage id="general.position.heck" />
                    </div>
                </div>
                :
                <NoDeckPlanView />}
        </>
    );
}


const CabinDetail = (props: { cabinDetails: CabinDetailsType}) => {
    const { top, left, alignX, alignY, cabin, cabinTextAddOn, visable} = props.cabinDetails;
    if (visable === false || !cabin) {
        return <span></span>;
    }
    const cStyle = {
        top: `${top}px`,
        left: `${(left)}px`
    };

    return (
        <div className={`cabin-overlay-content line-up-${alignX} line-up-${alignY}`} style={cStyle}>
            <div className={`kd-dreieck ${alignY}`}></div>
            <div className="headline">
                <FormattedMessage id="general.cabin" /> {cabin.number}{cabinTextAddOn ? <span> - {cabinTextAddOn}</span> : ''}
            </div>

            <div className="cabin-info">
                <div className="cabin-info-item">
                    <span className="icon icon-roomsize"></span>
                    {cabin.interiorSizeInfo ? (`ca. ${cabin.interiorSizeInfo}m²${cabin.exteriorType ? ', ' : ''}`) : ''}
                    {cabin.exteriorType ? (`${cabin.exteriorType} `) : ''}
                    {cabin.exteriorSizeInfo ? (`${cabin.exteriorSizeInfo}m²`) : ''}
                </div>
                {cabin.minOccupation ?
                    <div className="cabin-info-item">
                        <span className="cabin-info-item-pipe"></span>
                        <span className="icon icon-insurance-single"></span> {cabin.minOccupation}-{cabin.maxOccupation} <FormattedMessage id="components.cabinnumber.deckplan.persons" />
                    </div>
                    : ''}
            </div>
            {cabin.cabinSpecifics ?
                <>
                    <span className="bold"><FormattedMessage id="general.specials" />:</span><br/>
                    <ul className="ct-inter">
                        {cabin.cabinSpecifics.map((value: string, keyCT:number) => {
                            return (<li key={keyCT}>{value}</li>);
                        })}
                    </ul>
                </>
                : ''}
        </div>
    );
};
