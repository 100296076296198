import React from 'react';
import {createRoot} from 'react-dom/client';
import B2BPaymentTextView from '../../../components/sections/b2b/payment-text';

import BookingPageContent from '../../booking-page/base/content';
import app from '../../../app/app';
import ApiStepFactory from '../../../item/api-step/factory';
import templateWebpack from '../../../../tpl/page/booking-page/content/payment.hbs';
import * as Constants from '../../../config/constants';
import {renderReact} from '../../../react';
import {getIntl} from '../../../intl';

export default class PaymentPageContent extends BookingPageContent {
    constructor (options = {}) {
        super({
            key: 'page-payment-content', templateWebpack, ...options
        });

        this.listenTo(this.model.apiSteps.get(Constants.NETMATCH_STEP_PAYMENT).inputs, 'change:previouslySubmitted', () => {
            // this.model.trigger('change');
            this.render();
        });
    }

    /**
     * Listen to events of child views
     */

    childViewEvents () {
        return {
            ...super.childViewEvents.apply(this),
            'payment:subviewpageisvalid': 'onSubViewPageIsValid',
            'payment:subviewpageisinvalid': 'onSubViewPageIsInValid',
            'payment:trigger:butons': 'onTriggerButtons',
            'step:did:change': () => {
                // TUICIBE-475 catch event and do nothing, because this page (sepa) has a Input - Checkbox, this ist not a part of the steps
            }

        };
    }

    /**
     * Getter for the views class name
     */
    get className () {
        return `${super.className} api-step-payment`;
    }


    /**
     * View regions.
     * Any region defined under the key of an input id will be filled with the
     * corresponding input view when calling the 'renderApiInputsToRegions' method..
     */
    regions () {
        return {
            'substep': {
                el: '.api-step-region-substep', replaceElement: true
            }
        };
    }

    /**
     * Add details to the data passed into the template.
     * Will be merged into the model / collection data.
     */
    templateContext () {
        return {
            apiSteps: this.model.apiSteps.models, priceModelInfo: this.getPaydownInfo()
        };
    }

    getPaydownInfo () {
        const type = app.apiSession.bookingStatus.getPriceModel();
        let text;
        const messages = app.contentApi.messages.prepareMessages('payment');
        switch (type) {
            case 'feel':
            case 'feelP':
                text = messages.feelgoodPriceInfo;
                break;
            case 'flex':
                text = messages.flexPriceInfo;
                break;
            case 'plus':
                text = messages.plusInfo;
                break;
            case 'pro':
                text = messages.proInfo;
                break;
            case 'pur':
                text = messages.purInfo;
                break;
            default:
                text = messages.mixedInfo;
                break;
        }
        return text;
    }

    onRender () {
        const apiSubStepView = this.getPaymentStepView();
        if (apiSubStepView) {
            this.showChildView('substep', apiSubStepView);

            if (apiSubStepView.options.additionalTemplateContext.pageInitButton === true) {
                this.initPager();
            }

            if (apiSubStepView.options.additionalTemplateContext.showSuccessBox === false) {
                this.$('.js-success-box').hide();
            }
        }
        if (app.apiSession.bookingStatus.agency.isB2B()) {
            if (this.$el.find('.react-region-b2b-region-payment-text')[0]) {
                const container = this.$el.find('.react-region-b2b-region-payment-text')[0];
                const root = createRoot(container);
                renderReact(root, <B2BPaymentTextView/>);
            }

            if (app.apiSession.bookingStatus.agency.isAgentur()) {
                this.$('.js-success-box').hide();
            }
        }
    }

    getPaymentStepView () {
        let apiStepModel = '';
        let thisAutoSubmit = false;
        let pageInitButtonAutoEnable = false;
        let showSuccessBox = true;
        let pageInitButton = true;
        const paymentStepModel = this.model.apiSteps.get(Constants.NETMATCH_STEP_PAYMENT);
        // console.log(this.model, this.model.get('previouslySubmitted'));
        // console.log('paymentStepModel', paymentStepModel);
        // console.log('getInputData', paymentStepModel.getInputData());
        // console.log('getApiSteps', this.model.apiSteps);
        if (paymentStepModel.isCompleted()) {
            if (paymentStepModel.getInputData()[Constants.NETMATCH_INPUT_PAYMENT_OPTION] === Constants.NETMATCH_STEP_PAYMENT_API_RESULT_BANK_TRANFER) {
                apiStepModel = this.model.apiSteps.get(Constants.NETMATCH_STEP_PAYMENT_BANK_TRANFER);
                pageInitButtonAutoEnable = true;
                showSuccessBox = false;
                app.trackController.pageView(this.model, Constants.NETMATCH_STEP_PAYMENT_BANK_TRANFER);
            }
            if (paymentStepModel.getInputData()[Constants.NETMATCH_INPUT_PAYMENT_OPTION] === Constants.NETMATCH_STEP_PAYMENT_API_RESULT_SEPA) {
                apiStepModel = this.model.apiSteps.get(Constants.NETMATCH_STEP_PAYMENT_SEPA);
                pageInitButtonAutoEnable = true;
                app.bookingPages.get(Constants.PAGE_CONFIRM).set({
                    'button-title': getIntl().formatMessage({id: 'components.payment.button-label'})
                });
                app.trackController.pageView(this.model, Constants.NETMATCH_STEP_PAYMENT_SEPA);
            }
            if (paymentStepModel.getInputData()[Constants.NETMATCH_INPUT_PAYMENT_OPTION] === Constants.NETMATCH_STEP_PAYMENT_API_RESULT_CREDITCARD) {
                apiStepModel = this.model.apiSteps.get(Constants.NETMATCH_STEP_PAYMENT_CREDITCARD);
                thisAutoSubmit = true;
                pageInitButton = false;
                app.trackController.pageView(this.model, Constants.NETMATCH_STEP_PAYMENT_CREDITCARD);
            }
        } else {
            apiStepModel = paymentStepModel;
            thisAutoSubmit = true;
        }
        return ApiStepFactory.create(apiStepModel.id, {
            model: apiStepModel,
            autoSubmit: thisAutoSubmit,
            darkStyling: true,
            additionalTemplateContext: {
                pageInitButton, pageInitButtonAutoEnable, showSuccessBox
            }
        });
    }

    onSubViewPageIsValid () {
        if (this.$('.button-next-page').hasClass('disabled') === true) {
            app.triggerMethod('bookingPageStatus:change', true);
        }
    }

    onSubViewPageIsInValid () {
        if (this.$('.button-next-page').hasClass('disabled') === false) {
            app.triggerMethod('bookingPageStatus:change', false);
        }
    }

    onTriggerButtons () {
        this.initPager();
        this.$('.js-success-box').hide();
    }
}
