import Backbone from 'backbone';

/**
 *  Represents a backbone collection as base collection for this project
 */
export default class BaseCollection extends Backbone.Collection {
    initialize (models, options = {}) {
        this.parentModel = options.parentModel || null;
    }
}

