import * as Constants from '../../../config/constants';
import {getIntl} from '../../../intl';

export const session = {
    parse (raw: any) {

        const agency = this.parseAgency(raw.agency) || null;
        const bookingCodes = this.parseBookingCodes(raw.bookingCodes) || null;
        const cabin = this.parseMultiCabin(raw.cabins) || null;
        const ePackageCode = raw.ePackageCode || null;
        const errorMessage = raw.errorMessage || null;
        const homeBoundTransport = this.parseTransport(raw.homeBoundTransport) || null;
        const insurances: Array<any> = this.parseInsurances(raw.insurances) || [];
        const invoice = this.parseInvoice(raw.invoice) || null;
        const participantPrices: Array<any> = this.parseParticipantPrices(raw.participantPrices) || [];
        const party = this.parseParty(raw.party) || null;
        const payment = this.parsePayment(raw.payment) || null;
        const shipBoundTransport = this.parseTransport(raw.shipBoundTransport) || null;
        const totalBookingPrice = raw.totalBookingPrice || null;
        const trip = this.parseTrip(raw.trip) || null;
        const campaignCodes = this.parseCampaignCodes(raw.campaignCodes) || null;
        const preparePriceInformations = (cabin && participantPrices) ? this.preparePriceInformations(participantPrices, party) : null;
        let cabinCount = 1;
        // inject price into cabin
        if (cabin && preparePriceInformations) {
            cabin.forEach((item: any, key: number) => {
                if (preparePriceInformations[item.cabinIndex]) {
                    cabin[key].participantPrices = preparePriceInformations[item.cabinIndex];
                    cabin[key].totalPricepPerCabin = preparePriceInformations[item.cabinIndex][0].totalPricepPerCabin;
                }
                if (participantPrices) {
                    cabin[key].participantPricesDetail = participantPrices.filter((value) => (value.cabinIndex === item.cabinIndex));
                    cabin[key].hasDiscounts = participantPrices.some(participant => participant.hasDiscounts);

                }
                // erstmal nicht
                /* if (insurances) {
                    cabin[key].insurances = this.prepareInsuranceInformations(item.participants, insurances);
                } */
            });
            cabinCount = cabin.length;
        }

        const result = {
            agency,
            bookingCodes,
            cabin,
            cabinCount,
            ePackageCode,
            errorMessage,
            homeBoundTransport,
            insurances,
            invoice,
            participantPrices,
            party,
            payment,
            shipBoundTransport,
            totalBookingPrice,
            trip,
            preparePriceInformations,
            campaignCodes
        };

        console.log('%c parseSession ', 'background: #00FF00; color: #000000', result);
        return result;
    },

    parseAgency (raw: any) {
        return {
            addressLine1: raw.addressLine1 || null,
            addressLine2: raw.addressLine2 || null,
            city: raw.city || null,
            country: raw.country || null,
            email: raw.email || null,
            name: raw.name || null,
            number: raw.number || null,
            paymentType: raw.paymentType || null,
            postCode: raw.postCode || null,
            telephone: raw.telephone || null
        };
    },

    parseBookingCodes (raw: any) {
        let result = null;
        if (Array.isArray(raw)) {
            result = [];
            raw.forEach(item => {
                const part = {
                    count: item.count || null,
                    fromDate: item.fromDate || null,
                    occupancy: item.occupancy || null,
                    participantAssignment: item.participantAssignment || null,
                    serviceCode: item.serviceCode || null,
                    serviceTypeCode: item.serviceTypeCode || null,
                    toDate: item.toDate || null,
                    typeCode: item.typeCode || null
                };
                result.push(part);
            });
        }
        return result;
    },

    /**
     * @param raw
     */
    parseSingelCabin (raw: any) {
        const part = {
            cabinCategoryTypeCode: null,
            cabinCategoryTypeName: null,
            details: null,
            priceModel: null
        };

        if (Array.isArray(raw)) {
            part.cabinCategoryTypeCode = (raw[0].categoryType) ? raw[0].categoryType.code : null;
            part.cabinCategoryTypeName = (raw[0].categoryType) ? raw[0].categoryType.name : null;
            part.priceModel = raw[0].priceModel || null;
        }
        return part;
    },

    /**
     * @param raw
     */
    parseMultiCabin (raw: any) {
        const result: Array<{
            cabinIndex: number,
            cabinCategoryTypeCode: string,
            cabinCategoryTypeName: string,
            details: Array<{
                code: string,
                name: string
            }>,
            priceModel: string,
            cabinNumber: string,
            deckNumber: string,
            deckName: string | null,
            vip: string | null,
            participants: Array<any>
            participantPrices?: any,
            participantPricesDetail?: any,
            hasDiscounts?: boolean,
            totalPricepPerCabin?: number,
            insurances?: any,

        }> = [];

        if (Array.isArray(raw)) {
            raw.forEach(item => {
                const part = {
                    cabinIndex: item.cabinIndex || null,
                    cabinCategoryTypeCode: (item.categoryType) ? item.categoryType.code : null,
                    cabinCategoryTypeName: (item.categoryType) ? item.categoryType.name : null,
                    details: item.category || null,
                    priceModel: item.priceModel || null,
                    cabinNumber: item.cabinNumber || null,
                    vip: item.vip || null,
                    deckNumber: item.deckNumber || null,
                    deckName: item.deckName || null,
                    participants: item.participants || []
                };
                result.push(part);
            });
        }
        return result;
    },

    /**
     *
     * @param raw
     */
    parseTransport (raw: any) {
        let part: {
            additionalDetails: string | null,
            class: string | null,
            departureDate: Date | null,
            departureTime: Date | null,
            destination: string | null,
            origin: string | null,
            type: string | null,
            typeDisplayName: string | null
        } | null = null;
        if (raw) {
            part = {
                additionalDetails: raw.additionalDetails || null,
                class: raw.class || null,
                departureDate: (raw.departureDateTime) ? new Date(raw.departureDateTime) : null,
                departureTime: (raw.departureDateTime) ? new Date(raw.departureDateTime) : null,
                destination: raw.destination || null,
                origin: raw.origin || null,
                type: raw.type || null,
                typeDisplayName: raw.typeDisplayName || null
            };
        }
        return part;
    },

    /**
     *
     * @param raw
     */
    parseInsurances (raw: any) {
        const result: Array<{}> = [];
        if (Array.isArray(raw)) {
            raw.forEach(item => {
                const part = {
                    insurance: item.insurance || null,
                    memberType: item.memberType || null,
                    participantsIndexes: item.participantsIndexes || null,
                    policyType: item.policyType || null
                };
                result.push(part);
            });
        }
        return result;
    },

    /**
     *
     * @param raw
     */
    parseInvoice (raw: any | null) {
        let part: {
            additionalAddress: string | null,
            city: string | null,
            country: string | null,
            dateOfBirth: string | null,
            email: string | null,
            firstName: string | null,
            lastName: string | null,
            mobileNumber: string | null,
            postalCode: string | null,
            salutation: string | null,
            streetAndHouseNumber: string | null,
            telephone: string | null,
            title: string | null
        } | null = null;
        if (raw) {
            part = {
                additionalAddress: raw.additionalAddress || null,
                city: raw.city || null,
                country: raw.country || null,
                dateOfBirth: raw.dateOfBirth || null,
                email: raw.email || null,
                firstName: raw.firstName || null,
                lastName: raw.lastName || null,
                mobileNumber: raw.mobileNumber || null,
                postalCode: raw.postalCode || null,
                salutation: raw.salutation || null,
                streetAndHouseNumber: raw.streetAndHouseNumber || null,
                telephone: raw.telephone || null,
                title: raw.title || null
            };
        }
        return part;
    },

    parseParticipantPrices (raw: any) {
        const result: Array<any> = [];
        const {formatMessage} = getIntl();

        const translateMap: any = {
            [Constants.NETMATCH_SESSION_AGECATEGORY_ADULT]: formatMessage({id: 'general.adult'}),
            [Constants.NETMATCH_SESSION_AGECATEGORY_CHILD]: formatMessage({id: 'general.child'})
        };
        let adultIndex = 0;
        let childIndex = 0;
        if (Array.isArray(raw)) {
            raw.forEach(item => {
                const prices: Array<any> = [];
                // von services, zu 16.07.(prices)
                if (item.prices || Array.isArray(item.prices)) {
                    item.prices.forEach((value: any) => {
                        const price = {
                            discounts: (value.discounts) ? this.parseDiscounts(value.discounts) : [],
                            displayName: value.serviceName || null,
                            price: value.price || null,
                            serviceCategory: value.serviceCategory || null,
                            serviceCode: value.serviceCode || null,
                            originalPrice: value.originalPrice || null, // new
                            serviceTypeCode: value.serviceTypeCode || null // new
                        };
                        prices.push(price);
                    });
                }

                let displayName = '';
                if (item.ageCategory === Constants.NETMATCH_SESSION_AGECATEGORY_ADULT) {
                    adultIndex = adultIndex + 1;
                    displayName = `${adultIndex}. ${translateMap[item.ageCategory]}`;
                }
                if (item.ageCategory === Constants.NETMATCH_SESSION_AGECATEGORY_CHILD) {
                    childIndex = childIndex + 1;
                    displayName = `${childIndex}. ${translateMap[item.ageCategory]}`;
                }

                if (item.firstName && item.lastName) {
                    displayName = `${item.salutation ? `${item.salutation} ` : ''}${item.title ? `${item.title} ` : ''}${item.firstName} ${item.lastName}`;
                }

                const hasDiscounts = (item.prices || []).some((price: any) => price.discounts && price.discounts.length);
                const part = {
                    ageCategory: item.ageCategory || null,
                    cabinIndex: item.cabinIndex || null,
                    dateOfBirth: item.dateOfBirth || null,
                    extraNote: item.extraNote || null,
                    firstName: item.firstName || null,
                    index: item.participantId || null,
                    lastName: item.lastName || null,
                    nationality: item.nationality || null,
                    participantTotalPrice: item.participantTotalPrice || null,
                    salutation: item.salutation || null,
                    title: item.title || null,
                    displayName,
                    hasDiscounts,
                    prices,
                    participantTotalOriginalPrice: this.getParticipantTotalOriginalPrice(prices)
                };
                result.push(part);
            });
        }

        return result;
    },


    parseDiscounts (raw: Array<{ discountPrice: string, discountText: string }>) {
        const result: Array<{ discount: string, name: string }> = [];
        if (Array.isArray(raw)) {
            raw.forEach(item => {
                const part = {
                    discount: item.discountPrice,
                    name: item.discountText
                };
                result.push(part);
            });
        }

        return result;
    },

    // TUICUNIT-1745: contrary to all agreements must be added here
    getParticipantTotalOriginalPrice (raw: Array<any>) {
        let result: number = 0;
        if (Array.isArray(raw)) {
            raw.forEach(item => {
                result = result + item.originalPrice;
            });
        }

        return result;
    },

    parseParty (raw: { adults: Number, children: Number }) {
        const part: { adultCount: Number, childCount: Number } = {
            adultCount: raw.adults || 0,
            childCount: raw.children || 0
        };
        return part;
    },

    parsePayment (raw: any) {
        return {
            arePaymentDetailsCaptured: raw.arePaymentDetailsCaptured || null,
            captureTiming: raw.captureTiming || null,
            isCaptureRequired: raw.isCaptureRequired || null,
            paymentType: raw.paymentType || null
        };
    },

    parseTrip (raw: any) {
        if (!raw) {
            return null;
        }
        return {
            auftragsnummer: raw.auftragsNummer || null,
            cruiseEndDate: raw.cruiseEndDate || null,
            cruiseStartDate: raw.cruiseStartDate || null,
            durationNights: raw.durationInNights || null,
            name: raw.name || null,
            packageEndDate: raw.packageEndDate || null,
            packageStartDate: raw.packageStartDate || null,
            shipCode: raw.shipCode || null
        };
    },

    /**
     * Helper filter, group, participantPrices per Cabin
     * @param raw
     * @param party
     */
    preparePriceInformations (raw: Array<any>, party: { adultCount: Number, childCount: Number }) {
        // prepared participantPrices list to flat list with necessary data
        const rawList = raw.reduce((list: Array<any>, item: any) => {
            const discountList: Array<{ category: string, price: string, shortCategory: boolean }> = [];
            item.prices.forEach((all: any) => {
                if (all.discounts.length !== 0) {
                    all.discounts.forEach((part: { name: string, discount: string }) => {
                        discountList.push({
                            category: part.name,
                            price: part.discount,
                            shortCategory: (all.serviceCategory && all.serviceCategory === 'Cabin') // TUICUNIT-1299
                        });
                    });
                }
            });
            // ageCategory is filled first after personal Step, so we will find his information over the session - party-count
            if (item.ageCategory === null) {
                if (item.index <= party.adultCount) {
                    item.ageCategory = Constants.NETMATCH_SESSION_AGECATEGORY_ADULT;
                } else {
                    item.ageCategory = Constants.NETMATCH_SESSION_AGECATEGORY_CHILD;
                }
            }
            list.push({
                cabinIndex: item.cabinIndex,
                category: item.ageCategory,
                discount: discountList,
                price: item.participantTotalPrice,
                extraNote: item.extraNote,
                key: item.index
            });
            return list;
        }, []);

        // console.log(rawList);

        const groupByCabin = rawList.reduce((list, item) => {
            list[item.cabinIndex] = (list[item.cabinIndex] || []).concat(item);
            return list;
        }, {});
        // console.log('is: ', groupByCabin);
        const {formatMessage} = getIntl();

        const translateMap: any = {
            [Constants.NETMATCH_SESSION_AGECATEGORY_ADULT]: formatMessage({id: 'general.adult'}),
            [Constants.NETMATCH_SESSION_AGECATEGORY_CHILD]: formatMessage({id: 'general.child'})
        };

        const groupCabinByPrice: any = {};
        // group participant by price per cabin
        Object.keys(groupByCabin).forEach((index) => {
            const singleResult: Array<any> = [];
            let totalPricepPerCabin: number = 0;
            const groupByAgePerCabin = groupByCabin[index].reduce((list: any, item: any) => {
                list[item.category] = (list[item.category] || []).concat(item);
                return list;
            }, {});

            const groupByAgeAndPricePerCabin: any = {};
            Object.keys(groupByAgePerCabin).forEach((ageKey: any) => {
                groupByAgeAndPricePerCabin[ageKey] = (groupByAgeAndPricePerCabin[ageKey] || {});
                groupByAgePerCabin[ageKey].forEach((item: any) => {
                    groupByAgeAndPricePerCabin[ageKey][item.price] = (groupByAgeAndPricePerCabin[ageKey][item.price] || []).concat(item);
                    totalPricepPerCabin = totalPricepPerCabin + item.price;
                });
            });
            // console.log(groupByAgeAndPricePerCabin);

            Object.keys(groupByAgePerCabin).forEach((ageKey: any) => {
                Object.keys(groupByAgeAndPricePerCabin[ageKey]).forEach((priceKey: any) => {
                    // console.log(priceKey, groupByAgeAndPricePerCabin[ageKey][priceKey][0].key);
                    singleResult.push({
                        key: groupByAgeAndPricePerCabin[ageKey][priceKey][0].key,
                        category: (translateMap[groupByAgeAndPricePerCabin[ageKey][priceKey][0].category]) ? (translateMap[groupByAgeAndPricePerCabin[ageKey][priceKey][0].category]) : groupByAgeAndPricePerCabin[ageKey][priceKey][0].category,
                        price: groupByAgeAndPricePerCabin[ageKey][priceKey][0].price,
                        extraNote: groupByAgeAndPricePerCabin[ageKey][priceKey][0].extraNote,
                        count: groupByAgeAndPricePerCabin[ageKey][priceKey].length,
                        discount: groupByAgeAndPricePerCabin[ageKey][priceKey][0].discount,
                        totalPricepPerCabin: totalPricepPerCabin
                    });
                });
            });
            /* const groupByPricePerCabin = groupByCabin[index].reduce((list:any, item:any) => {
                list[item.price] = (list[item.price] || []).concat(item);
                totalPricepPerCabin = totalPricepPerCabin + item.price;
                return list;
            }, {}); */
            /* Object.keys(groupByPricePerCabin).forEach((priceKey) => {
                singleResult.push({
                    key: groupByPricePerCabin[priceKey][0].key,
                    category: (translateMap[groupByPricePerCabin[priceKey][0].category]) ? (translateMap[groupByPricePerCabin[priceKey][0].category]) : groupByPricePerCabin[priceKey][0].category,
                    price: groupByPricePerCabin[priceKey][0].price,
                    extraNote: groupByPricePerCabin[priceKey][0].extraNote,
                    count: groupByPricePerCabin[priceKey].length,
                    discount: groupByPricePerCabin[priceKey][0].discount,
                    totalPricepPerCabin: totalPricepPerCabin
                });
            }); */

            // console.log(singleResult);
            singleResult.sort((a, b) => {
                return (b.price) - (a.price);
            });
            groupCabinByPrice[index] = (groupCabinByPrice[index] || []).concat(singleResult);
        });
        // console.log(groupCabinByPrice);
        let result: any | null = null;
        if (Object.keys(groupCabinByPrice).length !== 0) {
            result = groupCabinByPrice;
        }
        return result;
    },

    /**
     *
     * @param participants
     * @param insurances
     */
    prepareInsuranceInformations (participants: Array<number>, insurances: Array<any>) {
        // console.log(participants, insurances);
        // const groupName = _.groupBy(raw, 'insurance');
        const result: Array<any> = [];
        insurances.forEach((item: any) => {
            if (item.participantsIndexes) {
                item.participantsIndexes.forEach((value: number) => {
                    const hasThisInsurances = participants.find(participant => participant === value);

                    if (hasThisInsurances) {
                        result.push(item);
                    }
                });
            }
        });
        // console.log(result);
        /* Object.keys(groupName).forEach((index) => {
            if (groupName[index][0].memberType !== Constants.NETMATCH_SESSION_INSURANCE_MEMBERSTYPE_DUMMY) {
                result.push({
                    insurance: groupName[index][0].insurance,
                    count: groupName[index].length
                });
            }
        }); */

        return result;
    },

    // API result z.B. -> campaignCodes: [{name: "Board deposit", code: "C2"}]
    /* new campaignCodes: [{
        automatic: false,
        code: "C2",
        name: "Board deposit campaign",
        type: "BoardDeposit",
        value: 400
    */
    parseCampaignCodes (raw: any) {
        let result = null;
        if (Array.isArray(raw)) {
            result = [];
            raw.forEach(item => {
                const part = {
                    automatic: item.automatic || false,
                    code: item.code || '',
                    name: item.name || '',
                    type: item.type || '',
                    value: item.value || 0
                };
                result.push(part);
            });
        }
        return result;
    }
};
