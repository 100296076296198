import React, { useRef } from 'react';
import OverlayView from '../overlay';
import app from '../../app/app';


export default function OverlayCancelView () {
    const content = app.contentApi;
    const texte:any = content ? content.messages.prepareMessages('insurances') : null;

    const data = {
        headlineText: texte.infoTexts.storno.description,
        contentText: texte.infoTexts.storno.overlaytext
    };

    const overlayRef = useRef();
    const onOverlay = () => {
        // @ts-ignore
        if (overlayRef.current && overlayRef.current.showModal === false) {
            // @ts-ignore
            overlayRef.current.handleOpenModal();
        }
    };

    return (
        <>
            <p className="hint help-hint"><a onClick={onOverlay}>{ data.headlineText }</a></p>
            <OverlayView overlayId={'insurance-cancel'} closeOnBgClick={true} hasBackButton={true} data={data} ref={overlayRef}></OverlayView>
        </>
    );
}
