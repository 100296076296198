import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Constants from '../../config/constants';

type Props = {
    isB2B: boolean
};

export const CabinOnlyByPhoneOverlay = ({ isB2B }: Props) => {
    const { formatMessage } = useIntl();

    const textNumber = isB2B
        ? (
            <a className="action-link" href={`tel:${Constants.VIEW_B2B_SERVICE_NUMBER_DISPLAY_CTA}`}>{Constants.VIEW_B2B_SERVICE_NUMBER_DISPLAY_INT}</a>
        )
        : (
            <a className="action-link" href={`tel:${Constants.VIEW_SERVICE_NUMBER_DISPLAY_CTA}`}>{Constants.VIEW_SERVICE_NUMBER_DISPLAY_INT}</a>
        );

    return (
        <div className="overlay-cabin-by-phne">
            <h3 className="overlay-headline">
                <FormattedMessage id="" />
            </h3>
            <p className="action-link-description">
                <FormattedMessage id="components.overlay.cabin-only-by-phone.text" values={{
                    textTeam: formatMessage({
                        id: isB2B ? 'general.team.sales' : 'general.team.service'
                    })
                }} />
            </p>
            {textNumber}
            <p className="action-link-details">
                <FormattedMessage id="general.opening-hours" />
            </p>
        </div>
    );
};

export default CabinOnlyByPhoneOverlay;
