import React from 'react';
import { createRoot } from 'react-dom/client';
import FooterView from '../components/footer';

import $ from 'jquery';
import { controller as scrollMagicController, createScene as createScrollMagicScene } from '../util/scrollmagic';
import viewSize from '../util/viewport';
import BaseLayout from './base';
import app from '../app/app';
// jshint unused:true
// import ScrollMagicIndicators from 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
// jshint unused:false

import HeaderView from '../item/header/view/layout';
import ModalsView from '../item/modals';
import { renderReact } from '../react';
import { getIntl } from '../intl';
// import ContactView from '../item/contact/view/layout';

export default class DefaultLayout extends BaseLayout {
    /**
     * Constructor
     */
    constructor (options = {}) {
        super({
            key: 'default',
            ...options
        });
    }


    /**
     * View regions
     */
    regions () {
        return {
            ...super.regions.apply(this),
            'header': {
                el: '.page-header-region',
                replaceElement: true
            },
            'footer': {
                el: '.page-footer-region',
                replaceElement: true
            },
            'modals': {
                el: '.modal-region',
                replaceElement: true
            }
            /* 'contact': {
                el: '.contact-region',
                replaceElement: true
            } */
        };
    }


    onRender () {
        this.showChildView('header', new HeaderView());
        if (this.$el.find('.page-footer-region')[0]) {
            const container = this.$el.find('.page-footer-region')[0];
            const root = createRoot(container);
            renderReact(root, <FooterView />);
        }
        // this.showChildView('footer', new FooterView());
        this.showChildView('modals', new ModalsView());
        // this.showChildView('contact', new ContactView());
        document.getElementsByTagName('title')[0].text = getIntl().formatMessage({
            id: 'js.layout.default.title'
        }, {
            tripName: app.contentApi.tripInfo.get('tripName') ? app.contentApi.tripInfo.get('tripName') : ''
        });
    }


    /**
     * init scrollmagic only after the view is in the dom
     */
    onAttach () {
        this.initScrollMagic();
    }


    /**
     * Clean up taks
     */
    onBeforeDetach () {
        this.fixedTriggerScene.remove();
        this.collapsedTriggerScene.remove();
    }


    /** inits the ScrollMagic plugin which handles the scroll effects */
    initScrollMagic () {
        const $body = $('body');
        const $navigationBar = this.$('.page-header-navigation-bar');
        // this scene handles the fixed state of the navigation
        this.fixedTriggerScene = createScrollMagicScene({
            triggerElement: $navigationBar.get(0),
            triggerHook: 'onLeave'
        });

        this.fixedTriggerScene.addTo(scrollMagicController)
            // .addIndicators()
            .setClassToggle('body', 'navigation-fixed')
            .on('enter', () => {
                setTimeout(() => {
                    $body.addClass('booking-status-bar-animated');
                }, 0);
            })
            .on('leave', () => {
                $body.removeClass('booking-status-bar-animated');
            });

        let navigationOffset = 40;
        if (viewSize.getViewportSize() && viewSize.getViewportSize()[0] >= 768) {
            navigationOffset = 110;
        }
        // this scene handles the collapsed state of the navigation
        this.collapsedTriggerScene = createScrollMagicScene({
            triggerElement: $navigationBar.get(0),
            triggerHook: 'onLeave',
            offset: navigationOffset
        });

        this.collapsedTriggerScene.addTo(scrollMagicController)
            // .addIndicators()
            // .setClassToggle('body', 'booking-status-fixed')
            .on('update', () => {
                const scrollDirection = scrollMagicController.info('scrollDirection');
                const collapsed = scrollDirection === 'FORWARD';

                /* if (viewSize.getViewportSize()[0] >= 1200) {
                    app.triggerMethod('scroll-event:status-bar', scrollDirection);
                } */

                if (this.collapsedTriggerScene.state() !== 'DURING') {
                    return;
                }

                // console.log(this.collapsedTriggerScene.scrollOffset(), this.collapsedTriggerScene.triggerPosition());

                if (this.collapsedTriggerScene.scrollOffset() === 170) {
                    if (collapsed || scrollMagicController.info('scrollPos') < 170) {
                        $body.toggleClass('navigation-collapsed', collapsed);
                    }
                } else {
                    $body.toggleClass('navigation-collapsed', collapsed);
                }
            });
    }
}
