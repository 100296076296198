import _ from 'underscore';
import BaseModel from '../../../base/model/base';
import contentApiConfig from '../../../config/content-api';
import app from '../../../app/app';

export default class DeckInformationModel extends BaseModel {
    prepareUrlAndFetch (tripCode, deckNumber) {
        // this.url = contentApiConfig.deckInformationByeTripCodeAndDeckURL.replace('{tripCode}', tripCode).replace('{deck}', deckNumber);
        this.url = contentApiConfig.i18nUrlDeckInfo(tripCode, deckNumber);

        return this.fetch();
    }


    parse (response) {
        if (response.deckplanImageURL) {
            if (app.ibeController.getSpecialMediaRouteURL() !== false) {
                response.deckplanImageURL = response.deckplanImageURL.split(app.ibeController.getSpecialMediaRouteURL()).join(contentApiConfig.mediaProxyURL);
            }
        }
        return response;
    }

    getCabinDetail (cabinNumber) {
        const cabins = this.get('cabins');
        let result = _.where(cabins, {number: String(cabinNumber)});
        if (result.length > 1) {
            result = _.first(result);
        }
        return result;
    }
}
