import React from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * B2B payment-overview
 */
export default function B2BPaymentOverviewView () {
    return (
        <>
            <FormattedMessage id="components.sections.b2b.payment-overview.text" />
        </>
    );
}
