import React from 'react';
import {createRoot} from 'react-dom/client';
import B2BCabinDetailView from '../../../components/sections/b2b/cabin-detail';
import OverlayHintView from '../../../components/insurance/overlay-hint';

import BookingPageContent from '../../booking-page/base/content';
import InsuranceOverviewView from '../../../item/insurance-overview/view/layout';
import InsuranceSelectorModel from '../../../item/insurance-selector/model/insurance-selector';
import InsuranceSelectorView from '../../../item/insurance-selector/view/layout';
import app from '../../../app/app';
import templateWebpack from '../../../../tpl/page/booking-page/content/insurance.hbs';
import * as Constants from '../../../config/constants';
import {renderReact} from '../../../react';


export default class InsurancePageContent extends BookingPageContent {
    constructor (options = {}) {
        super({
            key: 'page-insurance-content',
            templateWebpack,
            ...options
        });

        this.insuranceSelectorModel = new InsuranceSelectorModel({}, {
            apiSteps: this.model.apiSteps
        });
    }


    /**
     * View regions.
     * Any region defined under the key of a step id will be filled with the
     * corresponding step view when calling the 'renderApiStepsToRegions' method.
     */
    regions () {
        return {
            'insurance-overview': {
                el: '.insurance-overview-region',
                replaceElement: true
            },
            'insurance-selector': {
                el: '.insurance-selector-region',
                replaceElement: true
            }
        };
    }


    /**
     * Add details to the data passed into the template.
     * Will be merged into the model / collection data.
     */
    templateContext () {
        const isB2B = app.apiSession.bookingStatus.agency.isB2B();
        const b2bMessages = app.contentApi.messages.prepareMessages('b2blight');

        return {
            stepHeadline: Constants.INFO_INSURANCE_POLICIES_STEP_HEADLINE,
            b2bSubline: isB2B ? b2bMessages.insuranceHint : null,
            insurer: {
                claim: Constants.INFO_INSURER_CLAIM,
                logoClass: Constants.INFO_INSURER_LOGOCLASS
            }
        };
    }

    /**
     * Auto-render the steps to the regions
     */
    onRender () {
        this.showChildView('insurance-overview', new InsuranceOverviewView({
            model: this.insuranceSelectorModel.getApiStepInsurancePolicies()
        }));

        this.showChildView('insurance-selector', new InsuranceSelectorView({
            model: this.insuranceSelectorModel
        }));

        this.initPager();

        // special handling insurances TUICIBE-554
        this.getChildView('booking-pager-bottom').triggerMethod('step:did:change');

        // react-region-insurance-overview-hint
        if (this.$el.find('.rrio-hint')[0]) {
            const container = this.$el.find('.rrio-hint')[0];
            const root = createRoot(container);
            renderReact(root, <OverlayHintView/>);
        }


        if (app.apiSession.bookingStatus.agency.isB2B()) {
            if (this.$el.find('.react-region-b2b-cabin-detail')[0]) {
                const container = this.$el.find('.react-region-b2b-cabin-detail')[0];
                const root = createRoot(container);
                renderReact(root, <B2BCabinDetailView/>);
            }
            //  ReactDOM.render(<B2BCabinDetailView/>, this.$el.find('.react-region-b2b-cabin-detail')[0]);
        }
    }

    get apiStepsToCheckBeforeNextPage () {

        const adultRegex = new RegExp(Constants.NETMATCH_STEP_ADULT);
        const childRegex = new RegExp(Constants.NETMATCH_STEP_CHILD);

        let assignedParticipantSteps = [];
        if (this.insuranceSelectorModel.isParticipantsDataRequired()) {
            assignedParticipantSteps = this.insuranceSelectorModel.insurances.reduce((steps, insurance) => {
                if (insurance.isDummyInsurance()) {
                    return steps;
                }
                steps.push(...insurance.assignedParticipantSteps.models);
                return steps;
            }, []);
        }
        return this.model.apiSteps.filter(step => {
            // always skip insurance policies step, as it was submitted before
            if (step.id === Constants.NETMATCH_STEP_INSURANCEPOLICIES) {
                return false;
            }
            // add assigned participants
            if (step.id.match(adultRegex) || step.id.match(childRegex)) {
                return assignedParticipantSteps.includes(step);
            }
            // skip terms if no insurances are wanted
            if (this.insuranceSelectorModel.get('showInsurancesWaiver')) {
                if (step.id === Constants.NETMATCH_STEP_INSURANCETERMSANDCONDITIONS) {
                    return false;
                }
            }
            return true;
        });
    }
}
