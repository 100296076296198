import React from 'react';
import app from '../../app/app';

export default function BenefitHintOverlay () {
    const content = app.contentApi;
    const texte:any = content ? content.messages.prepareMessages('cabinCategory') : null;

    return (
        <div className="overlay-benefit-hint">
            <h3 className="overlay-headline">{texte ? texte.advantagesCategorySelection : ''}</h3>
            <div className="row bordered-row">
                {texte ? <div dangerouslySetInnerHTML={{__html: texte.advantagesText}}/> : ''}
            </div>
        </div>
    );
}
