import Base from '../../../base/collection/base';
import ibeApiConfig from '../../../config/ibe-api';
import ApiStepModel from '../model/step';


export default class ApiStepsCollection extends Base {
    constructor (models, options = {}) {
        super(models, options);

        this.urlPath = ibeApiConfig.stepsURL;
    }


    /**
     * Getter fot the view model
     */
    get model () {
        return ApiStepModel;
    }


    /**
     * Returns an array of all steps matching the given regEx
     */
    filterByRegEx (pattern) {
        try {
            const regEx = new RegExp(pattern);
            // convert the model.id into a string and match against the regEx
            // the result will be an array or null, which is converted to boolean
            // by using !-operator twice, which is a fast and primitive boolean
            // alternative to Boolean(), but more obscure)
            return this.filter((model) => !!String(model.id).match(regEx));
        } catch (e) {
            return [];
        }
    }


    /**
     * Helper method to retrieve all steps with a given status
     */
    getOpenSteps () {
        return this.filterByStatus(this.model.STATUS_OPEN);
    }


    /**
     * Helper method to retrieve all steps with a given status
     */
    getPendingSteps () {
        return this.filterByStatus(this.model.STATUS_PENDING);
    }


    /**
     * Helper method to retrieve all steps with a given status
     */
    getOptionalSteps () {
        return this.filterByStatus(this.model.STATUS_OPTIONAL);
    }


    /**
     * Helper method to retrieve all steps with a given status
     */
    getCompletedSteps () {
        return this.filterByStatus(this.model.STATUS_COMPLETED);
    }


    /**
     * Helper method to retrieve all steps with a given status
     */
    getAutomaticallyCompletedSteps () {
        return this.filterByStatus(this.model.STATUS_COMPLETED_AUTOMATICALLY);
    }


    /**
     * Helper method to retrieve all steps with a given status
     */
    getNotApplicableSteps () {
        return this.filterByStatus(this.model.STATUS_NOT_APPLICABLE);
    }


    /**
     * Helper method to retrieve all steps with a given status
     */
    getBlockedSteps () {
        return this.filterByStatus(this.model.STATUS_BLOCKED);
    }


    /**
     * Helper method to retrieve all steps with a given status
     */
    filterByStatus (stat) {
        return this.where({
            stat
        });
    }


    /**
     * @see: http://backbonejs.org/#Collection-parse
     */
    parse (data) {
        // console.log('%c parse ', 'background: #222; color: #ffff00', data);
        // orginal
        // return data.value;
        this.set(data);
    }
}
