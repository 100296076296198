import React from 'react';
import { createRoot } from 'react-dom/client';
import Error404View from '../../components/error/404';

import BasePage from '../../base/view/page';
import templateWebpack from '../../../tpl/page/error/wrapper.hbs';
import { renderReact } from '../../react';

export default class Error404Page extends BasePage {
    constructor (options) {
        super(Object.assign({
            key: 'error404',
            templateWebpack
        }, options));
    }

    onRender () {
        // react region error 404
        if (this.$el.find('.rre-404')[0]) {
            const container = this.$el.find('.rre-404')[0];
            const root = createRoot(container);
            renderReact(root, <Error404View />);
        }
    }
}
