import React from 'react';
import {createRoot} from 'react-dom/client';
import OverviewView from '../../../components/overview/index';
import ApprovalOverviewView from '../../../components/overview/approval';
import B2BCabinDetailView from '../../../components/sections/b2b/cabin-detail';

import BookingPageContent from '../../booking-page/base/content';
import app from '../../../app/app';
import templateWebpack from '../../../../tpl/page/booking-page/content/overview.hbs';
import * as Constants from '../../../config/constants';
import {renderReact} from '../../../react';
import OnlineRiskStepModel from '../../../entities/netmatch-api/model/onlineRiskStep';


export default class OverviewPageContent extends BookingPageContent {
    constructor (options = {}) {
        super({
            key: 'page-overview-content',
            templateWebpack,
            ...options
        });
        this.listenTo(app, 'sessionData:only:sync', () => {
            if (this.$el.find('.rro-c')[0]) {
                const onlineRiskStep = app.apiSession.steps.get(Constants.NETMATCH_STEP_ONLINE_RISK);
                const onlineRiskStepAsserted = onlineRiskStep ? new OnlineRiskStepModel(onlineRiskStep.attributes) : null;
                const hideEdit = onlineRiskStepAsserted.isPending() === false;
                const container = this.$el.find('.rro-c')[0];
                const root = createRoot(container);
                renderReact(root, <OverviewView hideEdit={hideEdit}/>);
            }
        });
    }

    /**
     * View regions.
     * Any region defined under the key of a step id will be filled with the
     * corresponding step view when calling the 'renderApiStepsToRegions' method.
     */
    regions () {
        return {
            [Constants.NETMATCH_STEP_PAYMENT]: {
                el: '.api-step-region-payment-type',
                replaceElement: true
            },
            [Constants.NETMATCH_STEP_ONLINE_RISK]: {
                el: '.online-risk-placeholder',
                replaceElement: true
            }
            /* [Constants.NETMATCH_STEP_APPROVAL]: {
                el: '.api-step-region-approval',
                replaceElement: true
            } */
        };
    }


    events () {
        return {
            'click .js-trigger-print-ow': 'onTriggerPrint'
            // 'click .edit-link': 'onClickEditLink'
        };
    }


    /**
     * Any object defined under the key of a step id will be passed as options
     * into the step views created when calling the 'renderApiStepsToRegions' method
     */
    apiStepViewOptions () {
        return {
            [Constants.NETMATCH_STEP_PAYMENT]: {
                autoSubmit: false,
                additionalTemplateContext: {}
            },
            [Constants.NETMATCH_STEP_ONLINE_RISK]: {
                autoSubmit: false,
                additionalTemplateContext: {}
            }
        };
    }


    templateContext () {
        return {
            apiSteps: this.model.apiSteps.models
        };
    }

    /**
     * Auto-render the steps to the regions
     */
    onRender () {
        this.renderApiStepsToRegions(this.model.apiSteps);
        const onlineRiskStep = app.apiSession.steps.get(Constants.NETMATCH_STEP_ONLINE_RISK);
        const onlineRiskStepAsserted = onlineRiskStep ? new OnlineRiskStepModel(onlineRiskStep.attributes) : null;
        const hideEdit = onlineRiskStepAsserted.isPending() === false;

        /* react region overview check */
        if (this.$el.find('.rro-c')[0]) {
            const container = this.$el.find('.rro-c')[0];
            const root = createRoot(container);
            renderReact(root, <OverviewView hideEdit={hideEdit}/>);
        }

        /* react region overview check */
        if (this.$el.find('.rro-a')[0]) {
            const container = this.$el.find('.rro-a')[0];
            const root = createRoot(container);
            renderReact(root, <ApprovalOverviewView/>);
        }

        this.initPager();
        if (app.apiSession.bookingStatus.agency.isB2B()) {
            // react region overview b2b cabin detail
            if (this.$el.find('.rro-b2b-cd')[0]) {
                const container = this.$el.find('.rro-b2b-cd')[0];
                const root = createRoot(container);
                renderReact(root, <B2BCabinDetailView/>);
            }
        }

        if (onlineRiskStepAsserted && onlineRiskStepAsserted.isPending() === false) {
            // TCEC-148 if online risk came to any conclusion, editing of previous data and such redirecting to previous steps is prohibited so hide all relevant cta
            document.querySelectorAll('.main-navigation li.done a').forEach((navElement) => navElement.classList.add('deactivated'));
        }
    }

    onTriggerPrint () {
        setTimeout(window.print, 500);
        if (app.trackController) {
            app.trackController.eventTracking({
                action: 'Übersicht',
                label: 'Drucken',
                ga4clickLabel: 'content.icon.uebersicht-drucken'
            });
        }
    }
}
