import config from '../../config/config';
import BaseModel from '../../base/model/base';

export default class TUICConfig {
    constructor () {
        this.configModel = new BaseModel();
    }

    /**
     *
     */
    loadConfig () {
        this.configModel.url = config.configURL;
        return new Promise((resolve, reject) => {
            this.configModel.fetch()
                .then((item) => {
                    resolve(item);
                })
                .catch((error) => {
                    reject(console.log('config: ', error));
                });
        });
    }
}
