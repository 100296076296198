import React, {useEffect} from 'react';
import * as Constants from '../../config/constants';
import app from '../../app/app';
import { FormattedMessage } from 'react-intl';

export default function Error404View () {

    useEffect(() => {
        if (!app?.ibeController?.ibeInited && app?.ibeController) {
            app.ibeController.initIbeStorage()
                .then(app.ibeController.initConfig)
                .then(() => {
                    errorTracking();
                })
                .catch(() => {
                });
        } else {
            errorTracking();
        }
    });

    const errorTracking = () => {
        const ibeStorage:any = app?.ibeController?.ibeStorage;
        const initParamas = (ibeStorage) ? (ibeStorage.get(Constants.INITIAL_PARAMETER)) : false;
        let label = '';
        if (initParamas) {
            label = `${initParamas.tripCode}_${initParamas.ePackageCode}`;
        }
        if (app?.trackController) {
            app.trackController.eventTracking({
                error: '404-url',
                path: window.location.pathname,
                label,
                ga4eventType: '404'
            });
        }
    };

    return (
        <div className="container container-with-padding">
            <h1>
                <FormattedMessage id="navigation.routes.error404" />
            </h1>
        </div>
    );

}
