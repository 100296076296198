import '@babel/polyfill';
import $ from 'jquery';
import Backbone from 'backbone';
import app from './app/app';

// clean up global namespace
$.noConflict(true);
Backbone.noConflict();

//
$(document).ready(() => {
    app.start();
});


$.ajaxSetup({
    // WATCH OUT!!
    // The NetMacth API does return an empty string on a successful step submit.
    // Backbone calls jquery.ajax with dataType: "json", so proper JSON is expected.
    // An empty string fails to be decoded from JSON , but 'null' will work.
    dataFilter: (data, dataType) => {
        if (dataType === 'json' && data === '') {
            data = null;
        }
        return data;
    }

// cache: false,
// timeout: 60000,
});
