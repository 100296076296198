var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"b2bSubline") || (depth0 != null ? lookupProperty(depth0,"b2bSubline") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"b2bSubline","hash":{},"data":data,"loc":{"start":{"line":3,"column":7},"end":{"line":3,"column":23}}}) : helper)))
    + "</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1 class=\"booking-page-headline\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.page.booking-page.content.insurance.headline",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":1,"column":34},"end":{"line":1,"column":106}}})) != null ? stack1 : "")
    + "</h1>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"b2bSubline") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":4,"column":7}}})) != null ? stack1 : "")
    + "\n<h2 class=\"api-step-headline\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.page.booking-page.content.insurance.headline2",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":6,"column":30},"end":{"line":6,"column":103}}})) != null ? stack1 : "")
    + "</h2>\n<div class=\"booking-page-section without-border\">\n    <div class=\"insurance-selector-region\"></div>\n    <div class=\"rrio-hint\"></div>\n</div>\n\n<div class=\"spacer-top\">\n    "
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"insurer") : depth0)) != null ? lookupProperty(stack1,"claim") : stack1), depth0))
    + "\n    <svg width=\"220\"\n         height=\"54\"\n         class=\"logo-"
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"insurer") : depth0)) != null ? lookupProperty(stack1,"logoClass") : stack1), depth0))
    + "\">\n        <use xmlns:xlink=\"http://www.w3.org/1999/xlink\"\n             xlink:href=\"#icon-logo-"
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"insurer") : depth0)) != null ? lookupProperty(stack1,"logoClass") : stack1), depth0))
    + "\"></use>\n</div>\n\n\n<div class=\"booking-page-section without-border\">\n    <div class=\"insurance-overview-region\"></div>\n</div>\n\n<div class=\"react-region-b2b-cabin-detail\"></div>\n\n<div class=\"booking-pager bottom\"></div>\n<div class=\"booking-pager sticky\"></div>\n";
},"useData":true});