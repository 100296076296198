import { getIntl } from '../intl';

export default [

    {
        key: 'examples-b2b',
        title: 'b2b',
        routes: [
            'examples-b2b'
        ]
    },
    {
        key: 'examples-change',
        title: 'change',
        routes: [
            'examples-change'
        ]
    },
    {
        key: 'index',
        title: getIntl().formatMessage({
            id: 'navigation.routes.index'
        }),
        routes: [
            ''
        ]
    },
    {
        key: 'cabin',
        title: getIntl().formatMessage({
            id: 'navigation.routes.cabin'
        }),
        routes: [
            'cabin'
        ]
    },

    {
        key: 'travel',
        title: getIntl().formatMessage({
            id: 'navigation.routes.travel'
        }),
        routes: [
            'travel'
        ]
    },
    {
        key: 'events',
        title: getIntl().formatMessage({
            id: 'navigation.routes.events'
        }),
        routes: [
            'events'
        ]
    },
    {
        key: 'insurance',
        title: getIntl().formatMessage({
            id: 'navigation.routes.insurance'
        }),
        routes: [
            'insurance'
        ]
    },
    {
        key: 'personal',
        title: getIntl().formatMessage({
            id: 'navigation.routes.personal'
        }),
        routes: [
            'personal'
        ]
    },
    {
        key: 'overview',
        title: getIntl().formatMessage({
            id: 'navigation.routes.overview'
        }),
        routes: [
            'overview'
        ]
    },
    {
        key: 'payment',
        title: getIntl().formatMessage({
            id: 'navigation.routes.payment'
        }),
        routes: [
            'payment'
        ]
    },
    {
        key: 'confirm',
        title: getIntl().formatMessage({
            id: 'navigation.routes.confirm'
        }),
        routes: [
            'confirm'
        ]
    },
    {
        key: 'print-offer',
        title: getIntl().formatMessage({
            id: 'navigation.routes.print-offer'
        }),
        newTab: true,
        routes: [
            'print-offer'
        ]
    },
    {
        key: 'error503',
        title: getIntl().formatMessage({
            id: 'navigation.routes.error503'
        }),
        routes: [
            'error503'
        ]
    },
    {
        key: 'error404',
        title: getIntl().formatMessage({
            id: 'navigation.routes.error404'
        }),
        routes: [
            '*error'
        ]
    }
];
