import Marionette from 'backbone.marionette';
import NavigationUtil from '../util/navigation';
import Url from '../util/url';
import defaultLayoutTpl from '../../tpl/layout/default.hbs';
import errorLayoutTpl from '../../tpl/layout/error.hbs';
import printLayoutTpl from '../../tpl/layout/print.hbs';


export default class BaseLayout extends Marionette.View {
    /**
     * Getter for the handlebars template
     */
    get template () {
        let layoutTemplate;
        switch (this.options.key) {
            case 'error': layoutTemplate = errorLayoutTpl;
                break;
            case 'print': layoutTemplate = printLayoutTpl;
                break;
            default:
                layoutTemplate = defaultLayoutTpl;
                break;
        }

        return layoutTemplate;
    }


    /**
     * Getter for the views class name
     */
    get className () {
        return 'page-viewport';
    }


    /**
     * View regions
     */
    regions () {
        return {
            'main': '.page-main'
        };
    }


    /**
     * View events
     */
    events () {
        return {
            'click a[href]': 'linkClickHandler'
        };
    }


    /**
     * Routes external links through the NavigationUtil
     * and internal ones through the ibe controller
     * but leaves special links (mailto:, tel:, etc) untouched.
     *
     * @param  {Event} e
     */
    linkClickHandler (e) {
        const link = e.currentTarget;
        const $link = this.$(link);
        const href = $link.attr('href');
        // prevented capture by data attribute
        if ($link.data('js-capture-link') === false) {
            return true;
        }
        const url = new Url(link);
        // special links mailto:, tel:
        if (url.isSpecial) {
            return true;
        }
        e.preventDefault();
        NavigationUtil.redirect(href);
        return false;
    }
}
