import $ from 'jquery';
import app from '../../app/app';
import CabinTypesCollection from './collection/cabin-types';
// import CabinDetailsModel from './model/cabin-details';
import DeckInformationModel from './model/deck-information';
import TripInfoModel from './model/trip-info';
import AirportsModel from './model/airports';
import AirlinesModel from './model/airlines';
import MessagesModel from './model/messages';
import IbeTeaserModel from './model/ibe-teaser';
import { ContentApiError } from '../../error/errors';


export default class ContentAPI {
    /**
     *
     */
    constructor () {
        this.defaultTripCode = null;

        // currently only used for decks
        this.cache = {
            decks: {}
        };
        // this.callcenterEmployee = new CallcenterEmployeeModel();
        this.cabinTypesAndCategories = new CabinTypesCollection();
        // this.cabinDetails = new CabinDetailsModel();
        this.deckInformation = new DeckInformationModel();
        this.tripInfo = new TripInfoModel();
        this.airports = new AirportsModel();
        this.airlines = new AirlinesModel();
        this.messages = new MessagesModel();
        this.ibeTeaser = new IbeTeaserModel();
    }


    /**
     *
     */
    init (tripCode) {
        this.defaultTripCode = tripCode;

        return Promise.all([
            this.loadTripInfo(),
            this.loadCabinTypesAndCategories(),
            this.loadAirports(),
            this.loadAirlines(),
            this.loadMessages()
            // this.loadCallcenterEmployee()
        ]);
    }

    /**
     *
     */
    loadTripInfo (tripCode = false) {
        if (tripCode === false) {
            tripCode = app.apiSession.bookingStatus.trip.get('auftragsnummer');
        }

        return this.fetchData(this.tripInfo, tripCode);
    }


    loadCallcenterEmployee () {
        return this.fetchData(this.callcenterEmployee);
    }

    /**
     *
     */
    loadCabinTypesAndCategories (tripCode = false) {
        if (tripCode === false) {
            tripCode = app.apiSession.bookingStatus.trip.get('auftragsnummer');
        }
        return this.fetchData(this.cabinTypesAndCategories, tripCode);
    }

    /**
     *
     */
    loadDeckInformation (deckNumber, tripCode = false) {
        if (tripCode === false) {
            tripCode = app.apiSession.bookingStatus.trip.get('auftragsnummer');
        }
        $.ajaxSetup({
            noSpinner: true
        });

        return new Promise((resolve, reject) => {
            if (this.cache.decks[tripCode] && this.cache.decks[tripCode][deckNumber]) {
                this.deckInformation.clear({
                    silent: true
                });
                this.deckInformation.set(this.cache.decks[tripCode][deckNumber]);
                resolve(this.deckInformation);
                return;
            }

            this.fetchData(this.deckInformation, tripCode, deckNumber)
                .then(() => {
                    this.cache.decks[tripCode] = this.cache.decks[tripCode] || {};
                    this.cache.decks[tripCode][deckNumber] = this.deckInformation.toJSON();
                    resolve(this.deckInformation);
                })
                .catch(reject);
        });
    }

    /**
     *
     */
    loadAirports () {
        return this.fetchData(this.airports);
    }

    /**
     *
     */
    loadAirlines () {
        return this.fetchData(this.airlines);
    }

    /**
     *
     */
    loadMessages () {
        return this.fetchData(this.messages);
    }

    /**
     *
     */
    loadIbeTeaser () {
        return this.fetchData(this.ibeTeaser);
    }

    /**
     *
     */
    fetchData (entity, ...params) {
        return new Promise((resolve, reject) => {
            entity.prepareUrlAndFetch(...params)
                .then(resolve)
                .catch((error) => {
                    app.ibeController.ibeStatus.httpStatus = `${error.status}`;
                    app.ibeController.ibeStatus.httpStatusText = error.statusText;
                    app.ibeController.ibeStatus.apiUrl = entity.url;
                    reject(new ContentApiError(`Content API Error: (${error.status}): ${error.statusText}`));
                });
        });
    }
}
