let isInAppView =
    document.location.search.indexOf('inAppView') !== -1 || document.location.search.indexOf('X-TUIC-Webview') !== -1;

if (document.location.search.indexOf('inAppView=0') !== -1) {
    sessionStorage.removeItem('inAppView');

    window.history.replaceState({}, '', window.location.href.replace('inAppView=0', ''));
    isInAppView = false;
} else if (isInAppView) {
    sessionStorage.setItem('inAppView', '1');
}

const isInAppViewStorage = sessionStorage.getItem('inAppView') === '1';

module.exports = !!isInAppView || !!isInAppViewStorage || false;
