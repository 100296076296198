// TUICUNIT-3170: Aktionscodes
import React, {forwardRef, useState, useImperativeHandle} from 'react';
import { FormattedMessage } from 'react-intl';
import ReactModal from 'react-modal';
import app from '../../app/app';
import * as Constants from '../../config/constants';

type Props = {
    data: {
        confirmCallback: Function,
        closeOnBgClick: boolean
    }
};

type MessageType = {
    headline?: string;
    text?: string;
};

const CampaingCodeExitView = (props:Props, ref:any) => {
    const [showModal, setShowModal] = useState(false);
    const { data } = props;
    const msg: MessageType = app?.contentApi?.messages.prepareMessages(Constants.YOURSTRULY_CAMPAIGN_CODE_CONFIGURATION_CHANGE_TEXT) || {};

    useImperativeHandle(ref, () => ({
        showModal: showModal,

        show () {
            setShowModal(true);
        }
    }));

    const hide = () => {
        setShowModal(false);
    };

    const confirm = () => {
        hide();
        if (data.confirmCallback) {
            data.confirmCallback();
        }
    };

    const headline = (<h3 className="alert-headline">{msg.headline}</h3>);
    const confirmButton = (
        <a className="button" onClick={confirm}>
            <FormattedMessage id="components.overlay.campaign-code-exit.confirm" />
        </a>
    );
    const closeButton = (
        <a className="button button-secondary" onClick={() => { hide(); }}>
            <FormattedMessage id="components.overlay.campaign-code-exit.close" />
        </a>
    );

    return (
        <ReactModal
            isOpen={showModal}
            className="alert-container overlay-campaign-code-exit"
            overlayClassName="overlay-generell"
            bodyOpenClassName="noscroll"
        >
            <div className="click-wrapper" onClick={() => {
                if (data.closeOnBgClick) {
                    hide();
                }
            }}></div>
            <div className="alert confirm overlay">
                {headline}
                <p className="alert-text">{msg.text}</p>
                <br/>
                <div className="alert-buttons">
                    {closeButton}
                    {confirmButton}
                </div>
            </div>

        </ReactModal>
    );
};

export default forwardRef(CampaingCodeExitView);
