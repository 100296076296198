import React from 'react';

type Props = {
    data: {
        tripName: string;
        routeMapURL: string;
    }
};
export default function RouteMapOverlay (props:Props) {
    const { data } = props;

    return (
        <div className="overlay-route-map">
            <h3 className="overlay-headline">{data ? data.tripName : ''}</h3>
            <img alt="Route" src={data ? data.routeMapURL : ''} className="responsive-img"/>
        </div>
    );
}
