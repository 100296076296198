import React from 'react';
import CabinsView from './cabin-view';
import app from '../../app/app';
import * as Constants from '../../config/constants';

type StepModelType = {
    get: Function;
    validateData: Function;
    inputs: {
        get: Function;
    }
}
type CabinListProps = {
    stepModel: StepModelType;
    cabinIndex: number;
    callback: Function;
    apiError: boolean;
    searchSubmit: Function;
}

type InputOptionType = {
    categoryTypeCode: string;
    displayText: string;
    fromPriceCruiseOnly: number;
    hasVipTariffAvailable: boolean;
    highlight: boolean;
    id: string;
    isBlocked: boolean;
    isCapacityExceeded: boolean;
    model: string;
    sort: number;
    vipTariff: string | null;
};

type CabinsByCategoryAdvancedType = {
    item: InputOptionType;
    id: string;
    priceModel: Array<InputOptionType>;
    content: any;
    isBlocked: boolean;
    isCapacityExceeded: boolean;
    vipAvailable: boolean;
    lowestPrice: number;
    selected: boolean;
    smallView: boolean;
    selectedValue: string | null;
    edgeCaseCabinDeckBlocked?: boolean;
    sortKey: number;
};
type StepDataType = { id: string, content: {} };

export default function CabinListView (props: CabinListProps) {
    const {stepModel, cabinIndex, callback, apiError, searchSubmit} = props;

    const thisInput = stepModel.inputs.get(Constants.NETMATCH_INPUT_CABINTYPE_CABINTYPE);
    let thisInputOptions = thisInput.get('options') || [];
    const thisInputValue = thisInput.get('inputValue');
    // in some edge Case
    if (thisInput.get('stepStatus') === Constants.NETMATCH_STEP_STATUS_ERROR) {
        thisInputOptions = [];
    }

    const cabinTypes = prepareCabinTypes(thisInputOptions, thisInputValue);

    const allUnSelected = (cabinTypes ? cabinTypes.every((cabin: CabinsByCategoryAdvancedType) => cabin.selected === false) : true);

    const toSubmitStepCabinType = (cabinType: string) => {
        const stepsToSubmit = [];
        const stepData: StepDataType = {
            'id': stepModel.get('id'),
            'content': {
                [Constants.NETMATCH_INPUT_CABINTYPE_CABINTYPE]: cabinType
            }
        };
        if (stepModel && stepModel.validateData(stepData).valid) {
            stepsToSubmit.push(stepData);
            callback(stepsToSubmit);
        }
    };

    return (
        <CabinsView cabinList={cabinTypes}
            isSomeSelected={!allUnSelected}
            cabinIndex={cabinIndex}
            callbackCabin={toSubmitStepCabinType}
            callback={callback}
            apiError={apiError}
            searchSubmit={searchSubmit}></CabinsView>
    );
}

const prepareCabinTypes = (thisInputOptions: Array<InputOptionType>, thisInputValue: string) => {

    let contentCabinInformation: any = {};
    if (thisInputOptions && app.contentApi) {
        contentCabinInformation = app.contentApi.cabinTypesAndCategories.getJSON();
    }
    if (!thisInputOptions) {
        return null;
    }

    // groupBy categoryTypeCode
    // ATTENTION: somehow the result is sorted differently in Firefox vs. Chrome/Safrai/Edge/IE11
    const gruppByCabinsByCategoryType = thisInputOptions.reduce((list: any, raw: InputOptionType) => {
        // TUICUNIT-3084
        const maybeHasVIP = `${raw.model}${raw.vipTariff ? raw.vipTariff : ''}`;
        switch (maybeHasVIP) {
            case Constants.NETMATCH_PRICEMODEL_HIDDENPROVIP1:
                raw.sort = 1;
                break;
            case Constants.NETMATCH_PRICEMODEL_HIDDENPROVIP2:
                raw.sort = 2;
                break;
            case Constants.NETMATCH_PRICEMODEL_PRO:
                raw.sort = 3;
                break;
            case Constants.NETMATCH_PRICEMODEL_FEELGOODPLUS:
                raw.sort = 4;
                break;
            case Constants.NETMATCH_PRICEMODEL_FEELGOOD:
                raw.sort = 5;
                break;
            case Constants.NETMATCH_PRICEMODEL_PLUS:
                raw.sort = 6;
                break;
            case Constants.NETMATCH_PRICEMODEL_PUR:
                raw.sort = 7;
                break;
            case Constants.NETMATCH_PRICEMODEL_FLEX:
                raw.sort = 8;
                break;
            default:
                break;
        }
        list[raw.categoryTypeCode] = (list[raw.categoryTypeCode] || []).concat(raw);
        return list;
    }, {});

    // inject Content, and sort list
    const cabinsByCategoryType: Array<CabinsByCategoryAdvancedType> = [];
    Object.keys(gruppByCabinsByCategoryType).forEach((key: string) => {
        let items = gruppByCabinsByCategoryType[key];
        items.sort((a: InputOptionType, b: InputOptionType) => {
            return (a.sort > b.sort) ? +1 : -1;
        });

        // TUICUNIT-1587
        // more than one model
        if (items.length !== 1) {
            const checkIfSomeBlocked = items.filter((cabin: InputOptionType) => cabin.isBlocked === true);

            // if any is blocked
            if (checkIfSomeBlocked.length !== 0) {
                // filter all not blocked Items
                const allNotBlockedItems = items.reduce((list: Array<InputOptionType>, cabin: InputOptionType) => {
                    if (cabin.isBlocked !== true) {
                        list.push(cabin);
                    }
                    return list;
                }, []);

                // if not all are blocked
                if (allNotBlockedItems.length !== 0) {
                    items = allNotBlockedItems;
                }
            }
        }

        const item = items[0];
        const advanced: CabinsByCategoryAdvancedType = {
            item,
            id: item.categoryTypeCode,
            priceModel: items,
            content: contentCabinInformation.filter((part: any) => part.categoryTypeCode === item.categoryTypeCode).shift(),
            isBlocked: items.every((cabin: InputOptionType) => cabin.isBlocked === true),
            vipAvailable: items.some((cabin: InputOptionType) => cabin.hasVipTariffAvailable === true),
            isCapacityExceeded: items.every((cabin: InputOptionType) => cabin.isCapacityExceeded === true),
            lowestPrice: lowestPrice(items),
            selected: false,
            smallView: false,
            selectedValue: null,
            sortKey: 0
        };
        if (thisInputValue) {
            if (items.find((cabin: InputOptionType) => cabin.id === thisInputValue)) {
                advanced.selected = true;
                advanced.smallView = true;
                advanced.selectedValue = thisInputValue;
            }
        }
        if (advanced.isCapacityExceeded) {
            advanced.sortKey = 1;
        } else if (advanced.isBlocked) {
            advanced.sortKey = 2;
        }

        cabinsByCategoryType.push(advanced);
    });

    cabinsByCategoryType.sort((a: CabinsByCategoryAdvancedType, b: CabinsByCategoryAdvancedType) => ((a.sortKey) < (b.sortKey) ? -1 : 1));

    // sort by lowestPrice
    cabinsByCategoryType.sort((a: CabinsByCategoryAdvancedType, b: CabinsByCategoryAdvancedType) => {
        if (a.isBlocked === false && b.isBlocked === false) {
            return a.lowestPrice - b.lowestPrice;
        }
        return 0;
    });

    return cabinsByCategoryType;
};

const lowestPrice = (items: Array<InputOptionType>) => {
    let result = 0;
    items.forEach((value) => {
        // value.isBlocked === false &&
        if (result === 0) {
            result = value.fromPriceCruiseOnly;
        }
        // value.isBlocked === false &&
        if (result > value.fromPriceCruiseOnly) {
            result = value.fromPriceCruiseOnly;
        }
    });
    return result;
};

/**
 *  TUICIBE-383, TUICIBE-530: Edge-Case Fehlermeldung Deck blocked
 **/
const edgeCaseCabinDeckBlocked = (cabinIndex: number) => {
    const cabinDeckStep = (app.apiSession && app.apiSession.steps) ? app.apiSession.steps.filterByRegEx(new RegExp(Constants.NETMATCH_STEP_CABINDECK)) : [];
    const cabinDeckStepStatus = cabinDeckStep.map((step: any) => step.get('status'));
    const apiStepCabinDeck = cabinDeckStepStatus[(cabinIndex - 1)];

    if (apiStepCabinDeck && apiStepCabinDeck === Constants.NETMATCH_STEP_STATUS_BLOCKED) {
        // @ts-ignore Backbone Event
        app.triggerMethod('bookingPageStatus:change', false);
    }

    return apiStepCabinDeck && apiStepCabinDeck === Constants.NETMATCH_STEP_STATUS_BLOCKED;
};
