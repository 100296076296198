import BaseItem from '../../../../base/view/item';
import app from '../../../../app/app';
import AlertModel from '../model/alert';
import templateWebpack from '../../../../../tpl/item/modal/alert/layout.hbs';


export default class AlertView extends BaseItem {
    constructor (options = {}) {
        super({
            key: 'modal/alert',
            templateWebpack,
            ...options
        });

        this.listenTo(app, 'alert:start', this.start);
        this.model = new AlertModel();
    }

    /**
     * Getter for the views class name
     */
    get className () {
        return 'alert-container is-hidden';
    }

    /**
     * View events
     */
    events () {
        return {
            'click .js-close': 'hideAlert',
            'click .js-confirm': 'confirmAlert',
            'click': 'clickOnBackground'
        };
    }

    start (data) {
        this.model.set(data);
        this.render();
        this.showAlert();
    }


    showAlert () {
        this.$el.removeClass('is-hidden');
    }


    hideAlert (runCallbacks = true) {
        if (runCallbacks && this.model.get('closeCallback')) {
            this.model.get('closeCallback')();
        }
        this.$el.addClass('is-hidden');
    }


    confirmAlert () {
        this.hideAlert(false);
        if (this.model.get('confirmCallback')) {
            this.model.get('confirmCallback')();
        }
    }


    clickOnBackground (e) {
        if (!this.model.get('closeOnBgClick') || !this.model.get('closable')) {
            return;
        }
        if (e.target === this.el) {
            this.hideAlert();
        }
    }
}

