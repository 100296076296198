var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.overlay.inactivity.description.sales",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":16,"column":28},"end":{"line":16,"column":97}}})) != null ? stack1 : "")
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.overlay.inactivity.description.service",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":18,"column":28},"end":{"line":18,"column":99}}})) != null ? stack1 : "")
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <a class=\"action-link\" href=\"tel:"
    + alias3((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||alias2).call(alias1,"VIEW_B2B_SERVICE_NUMBER_DISPLAY_CTA",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":22,"column":57},"end":{"line":22,"column":111}}}))
    + "\">"
    + alias3((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||alias2).call(alias1,"VIEW_B2B_SERVICE_NUMBER_DISPLAY_INT",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":22,"column":113},"end":{"line":22,"column":167}}}))
    + "</a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <a class=\"action-link\" href=\"tel:"
    + alias3((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||alias2).call(alias1,"VIEW_SERVICE_NUMBER_DISPLAY_CTA",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":24,"column":57},"end":{"line":24,"column":107}}}))
    + "\">"
    + alias3((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||alias2).call(alias1,"VIEW_SERVICE_NUMBER_DISPLAY_INT",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":24,"column":109},"end":{"line":24,"column":159}}}))
    + "</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <div class=\"col-lg-8\">\n\n        <div class=\"overlay overlay-inactivity\">\n            <a class=\"js-close overlay-close\"></a>\n\n            <h3 class=\"overlay-headline\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.overlay.inactivity.headline",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":7,"column":41},"end":{"line":7,"column":101}}})) != null ? stack1 : "")
    + "</h3>\n\n            <div class=\"row\">\n                <div class=\"col-md-6 col-md-push-6\">\n                    <img alt=\"Verkaufsteam\" class=\"responsive\" src=\""
    + container.escapeExpression((lookupProperty(helpers,"normalizePath")||(depth0 && lookupProperty(depth0,"normalizePath"))||alias2).call(alias1,"images/overlays/inactivity2.png",{"name":"normalizePath","hash":{},"data":data,"loc":{"start":{"line":11,"column":68},"end":{"line":11,"column":119}}}))
    + "\"/>\n                </div>\n                <div class=\"col-md-6 col-md-pull-6\">\n                    <p class=\"action-link-description\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isB2B") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":19,"column":31}}})) != null ? stack1 : "")
    + "                    </p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isB2B") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":21,"column":20},"end":{"line":25,"column":27}}})) != null ? stack1 : "")
    + "                    <p class=\"action-link-details\">\n                        "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"general.opening-hours",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":27,"column":24},"end":{"line":27,"column":69}}})) != null ? stack1 : "")
    + "\n                    </p>\n\n                    <a class=\"button js-close\"><span>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.overlay.inactivity.continue",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":30,"column":53},"end":{"line":30,"column":113}}})) != null ? stack1 : "")
    + "</span><span class=\"icon-arrow-right\"></span></a>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});