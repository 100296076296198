import React, {useState} from 'react';
import app from '../../app/app';
import * as Constants from '../../config/constants';
import {CabinPrice} from './cabin-price';
import {FormattedMessage} from 'react-intl';
import fecha from 'fecha/lib/fecha';

type Props = {
    model: any;
    callback: Function
}

export default function TripAlternativeView (props: Props) {
    const {model} = props;
    const [value, setValue] = useState();
    const alternatives = model.toJSON() || {};

    let isSelected: any = {};
    const options = alternatives.netMatchAttributes || [];
    options.forEach((item: any) => {
        item.dStartDate = (item.startDate) ? (new Date(item.startDate)) : null;
        item.dEndDate = (item.endDate) ? (new Date(item.endDate)) : null;
        item.price = item.priceCruiseOnly || item.priceWithFlight;

        if (item.selected === true) {
            isSelected = item;
        }
    });
    options.sort((a: any, b: any) => {
        return a.dStartDate - b.dStartDate;
    });


    if (isSelected.key && !value) {
        setValue(isSelected.key);
    }

    const onValueChange = (event: any) => {
        if (options && event) {
            const option = options.find((item: any) => item.key === event.target.value);
            if (option) {
                submitStepBySelect(option);
            }
        }
    };

    const submitStepBySelect = (data: any) => {
        const stepsToSubmit = [];
        let stepData: any = {};
        let step: any = '';
        const apiSessionStep: any = (app.apiSession && app.apiSession.steps) ? app.apiSession.steps : {};

        stepData = {
            'id': Constants.NETMATCH_STEP_TRIP,
            'content': {
                [Constants.NETMATCH_INPUT_TRIP_AUFTRAGSNUMMER]: data.tripCode,
                [Constants.NETMATCH_INPUT_TRIP_EPACKAGECODE]: data.ePackageCode
            }
        };

        step = (apiSessionStep) ? apiSessionStep.get(stepData.id) : {};
        if (step && step.validateData(stepData).valid) {
            stepsToSubmit.push(stepData);
        }

        if (app.apiSession) {
            if (app.ibeController && app.ibeController.ibeStorage) {
                app.ibeController.ibeStorage.saveInitialParameter(data.tripCode, data.ePackageCode);
            }

            app.apiSession.submitSteps(stepsToSubmit, true).then(() => {
                if (app.contentApi) {
                    const trip = app.contentApi.loadTripInfo(data.tripCode);

                    trip.then(() => {
                        if (app.contentApi) {
                            const cabin = app.contentApi.loadCabinTypesAndCategories(data.tripCode);

                            cabin.then(() => {
                                props.callback();
                                // setValue(data.key);
                                // @ts-ignore Backbone Event
                                app.trigger('trip:has:change:need:update');
                                if (app && app.trackController) {
                                    app.trackController.eventTracking({
                                        action: 'Kabinenwahl_Zeitraum',
                                        label: 'Dropdown',
                                        ga4clickLabel: 'content.button.kabinenwahl-zeitraum'
                                    });
                                }
                            });
                        }
                    });
                    /* setTimeout(() => {
                        // @ts-ignore Backbone Event
                        app.trigger('loading:start');
                    }, 10); */
                }
            }).catch(() => {
                // @ts-ignore Backbone Event
                app.trigger('overlay:start', {
                    text: Constants.NM_SAVE_ERROR_TXT
                });
            });
        }
    };

    const disabled = false;
    return (
        <>
            <h2 className="api-step-headline alternative">
                <FormattedMessage id="components.cabin.trip-alternative.headline"/>
            </h2>
            <div className="booking-page-section without-border">
                <div className="row">
                    <div className="col-sm-8">
                        <div className="form-group trip-alternative select-element element">
                            <label htmlFor="trip-alternative-select-form" className="selected">
                                {isSelected.dStartDate ? `${fecha.format(isSelected.dStartDate, 'germanMediumDate')} - ` : ''}
                                {isSelected.dEndDate ? `${fecha.format(isSelected.dEndDate, 'germanMediumDate')} ` : ''}
                                {isSelected.price ? (<span>&nbsp;&nbsp;&nbsp;<span className="bold price"><CabinPrice price={isSelected.price} mode="from"/></span></span>) : ''}
                            </label>
                            <select className="form-element select" id="trip-alternative-select-form" disabled={disabled} onChange={onValueChange} value={value}>
                                {options.map((item: any, key: number) => {
                                    return (
                                        <option key={key} value={item.key} className={`o-${item.key}}`}>
                                            {item.dStartDate ? `${fecha.format(item.dStartDate, 'germanMediumDate')} - ` : ''}
                                            {item.dEndDate ? `${fecha.format(item.dEndDate, 'germanMediumDate')} ` : ''}
                                            &nbsp;&nbsp;&nbsp;
                                            {item.price ? (<CabinPrice price={item.price} mode="from"/>) : ''}
                                        </option>);
                                })}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
