export const getEnvirnoment = () => {
    const host = window.location.hostname;
    let env = 'production';

    if (host === 'ibe.test.meinschiff.com') {
        env = 'test';
    } else if (host === 'tuic-ibe-web.dev.cellular.de') {
        env = 'dev';
    } else if (host === 'localhost' || host === 'tuic-ibe') {
        env = 'local';
    }

    return env;
};
