import ApiInputTextView from './text';
import TypeAheadResultsView from './typeahead-result';
import { ApiFetchError, ApiResultError } from '../../../error/errors';
import templateWebpack from '../../../../tpl/item/api-input/typeahead.hbs';


/**
 *  This view provides an input field with an attached type-ahead suggestion list.
 *
 *  It must be set up with a typeAheadModel, which is able to fetch and represent key:value pairs of
 *  suggestions based on a given search term.
 *
 *  The trigger for fetching suggestion may be configured by a custom debounce timeout and a custom
 *  minimal search term length.
 *
 */
export default class ApiInputTypeAheadView extends ApiInputTextView {
    constructor (options) {
        super({
            templateWebpack,
            ...options
        });

        this.model = options.model;
        this.typeAheadModel = options.typeAheadModel;
        this.termTypeDebounceTimeout = options.termTypeDebounceTimeout;
        this.minTermLength = options.minTermLength;
    }

    /**
     * View regions
     */
    regions () {
        return {
            results: {
                el: '.typeahead-results',
                replaceElement: true
            }
        };
    }

    onRender () {
        this.showChildView(
            'results',
            new TypeAheadResultsView({
                templateRealm: 'item',
                key: 'api-input',
                model: this.typeAheadModel
            })
        );
    }

    /**
     * Overwrite view events
     */
    events () {
        return {
            [`keydown #${this.model.domId}`]: 'onKeydown',
            [`input #${this.model.domId}`]: 'onTypeInput',
            [`focusout #${this.model.domId}`]: 'onLeave',
            'click .typeahead-result': 'onSelect'
        };
    }

    /**
     * In case the model is already setup with a selection, it must not be possible to leave the input field
     * without setting it back to that previous selection
     *
     * @param e
     */
    onLeave (e) {
        const $element = this.$(e.currentTarget);
        const inputValue = $element.val();
        const modelInput = this.model.getInputValue();

        if (inputValue === '' && modelInput !== inputValue) {
            $element.val(modelInput);
        }
    }

    /**
     * Using backspace or delete while inside the input field has to reset the input field.
     *
     * @param e
     */
    onKeydown (e) {
        if (e.key === 'Backspace' || e.key === 'Delete') {
            this.$(e.currentTarget).val('');
            // using .clear had breaking sideeffects, therefor term is reset and suggestion are unset
            this.typeAheadModel.set('term', '');
            this.typeAheadModel.unset('suggestions');
        }
    }

    /**
     * When typing into the input field the typeAhead results will be fetched.
     *
     * Fetch execution is debounced and requires a minimal search term length,
     * both params are passed in through this views properties
     *
     * @param e
     */
    onTypeInput (e) {
        clearInterval(this.handleTypeDebounceTimeout);
        this.handleTypeDebounceTimeout = setTimeout(() => {
            const $element = this.$(e.currentTarget);
            const term = $element.val();

            // console.log('handleTypetDebounceTimeout', e, $element, term, e.key);

            // TODO JOK comparison by config const
            if (term && term.length >= this.minTermLength) {
                // try to update model data, changes to the model will trigger view updates
                this.fetchResults(term)
                    .then(() => {
                        this.hideValidationError();
                    })
                    .catch((error) => {
                        // the API call might provide an error message, in case no data was found
                        if (error instanceof ApiResultError) {
                            this.showValidationError(error.message);
                        }
                    });
            }
        }, this.termTypeDebounceTimeout);
    }

    /**
     * Selecting a typeAhead suggestions yields submitting this value to the step model and
     * triggering any events that listen to changes on the typeAheadModel,
     * specifically the selected property
     *
     * @param e
     */
    onSelect (e) {
        const $element = this.$(e.currentTarget);
        const id = $element[0].attributes['data-id'].value;
        const value = $element[0].attributes['data-value'].value;

        this.setValue(value);
        this.typeAheadModel.set({
            'selectedValue': value,
            'selected': id
        });
    }

    /**
     * Try to fetch suggestions for a given search term through the typeAheadModel
     *
     * @param term
     * @returns {Promise}
     */
    fetchResults (term) {
        return new Promise((resolve, reject) => {
            this.typeAheadModel.prepareUrlAndFetch(term)
                .then(resolve)
                .catch((error) => {
                    const responseJSON = 'responseJSON' in error ? error.responseJSON : {};
                    if ('value' in responseJSON) {
                        // use ApiResultError when API has provided a detailed user friendly error description
                        reject(new ApiResultError(responseJSON.value));
                    } else {
                        reject(new ApiFetchError(`Netmatch API Error: (${error.status}): ${error.statusText}`));
                    }
                });
        });
    }

    serializeData () {
        const data = super.serializeData();

        data.typeAhead = this.typeAheadModel.toJSON();
        data.inputMode = this.inputMode;
        data.termTypeDebounceTimeout = this.termTypeDebounceTimeout;
        data.minTermLength = this.minTermLength;
        return data;
    }

    onDestroy () {
        clearInterval(this.termTypeDebounceTimeout);
    }
}
