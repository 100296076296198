import BaseCollection from '../../../../base/collection/base';

import * as Constants from '../../../../config/constants';


const insuranceMapping = {
    [Constants.NETMATCH_INSURANCE_MEMBERSTYPE_INDIVIDUAL]: 'Einzelversicherung',
    [Constants.NETMATCH_INSURANCE_MEMBERSTYPE_COUPLE]: 'Paarversicherung',
    [Constants.NETMATCH_INSURANCE_MEMBERSTYPE_FAMILY]: 'Familienversicherung'
};

// TODO shall we put additional logic here?
export default class InsurancesCollection extends BaseCollection {
    constructor (models = null, options = {}) {
        super(models, options);
        this.bookingStatusModel = options.bookingStatusModel;
    }

    /**
     * @override
     */
    toJSON () {
        const participantPrices = this.bookingStatusModel.participantPrices;
        return this.models.map(model => {
            return {
                displayName: insuranceMapping[((model.get('memberType') || '')).toLowerCase()] || 'Versicherung',
                participantNames: (model.get('participantsIndexes') || []).map(participantsIndex => participantPrices.getSalutationByIndex(participantsIndex)).join(', '),
                participantNameArray: (model.get('participantsIndexes') || []).map(participantsIndex => participantPrices.getSalutationByIndex(participantsIndex)),
                ...model.toJSON()
            };
        });
    }
}
