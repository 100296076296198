import app from '../../../app/app';
import BaseOverlayView from './base';
import templateWebpack from '../../../../tpl/item/overlay/payment-invoice.hbs';


export default class PaymentInvoiceOverlayView extends BaseOverlayView {
    constructor (options = {}) {
        super({
            key: 'overlay-payment-invoice',
            templateWebpack,
            ...options
        });
    }

    templateContext () {
        return {
            ...super.templateContext.apply(this),
            contentAPITexte: app.contentApi.messages.prepareMessages('payment'),
            isB2B: app.apiSession.bookingStatus.agency.isB2B()
        };
    }
}
