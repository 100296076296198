import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatPrice } from '../../intl';

export type CabinPriceProps = {
    mode: 'from' | 'from_pp' | 'exact_pp' | 'simple_pp' | 'simple';
    price: number;
    decimal?: number;
};

export const CabinPrice = ({ mode, price, decimal }: CabinPriceProps) => {
    const formattedPrice = formatPrice(price, {
        maximumFractionDigits: decimal
    });

    if (mode === 'from') {
        /*
            ab {StringUtil.formatPrice(isSelected.price)}
        */
        return (
            <FormattedMessage id="components.cabin.cabin-price.from" values={{
                price: formattedPrice
            }} />
        );
    } else if (mode === 'from_pp') {
        /*
            <span className="p1">ab </span>
            <span className="p2">{StringUtil.formatPrice(price)}</span>
            <span> p.P.</span>
        */
        return (
            <FormattedMessage id="components.cabin.cabin-price.from_pp" values={{
                p1: (chunks) => <span className="p1">{chunks}</span>,
                p2: (chunks) => <span className="p2">{chunks}</span>,
                p: (chunks) => <span>{chunks}</span>,
                price: formattedPrice
            }} />
        );
    } else if (mode === 'exact_pp') {
        /*
            <span className="p2">{StringUtil.formatPrice(price)}</span>
            <span> p.P.</span>
        */
        return (
            <FormattedMessage id="components.cabin.cabin-price.exact_pp" values={{
                p2: (chunks) => <span className="p2">{chunks}</span>,
                p: (chunks) => <span>{chunks}</span>,
                price: formattedPrice
            }} />
        );
    } else if (mode === 'simple_pp') {
        /*
            {StringUtil.formatPrice(price)} p.P.
        */
        return (
            <FormattedMessage id="general.price-pp" values={{
                price: formattedPrice
            }} />
        );
    } else if (mode === 'simple') {
        /*
            {StringUtil.formatPrice(price.totalPrice)}
        */
        return <>{formattedPrice}</>;
    }

    throw new Error('Invalid mode');
};
