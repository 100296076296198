import BaseLayout from './base';


export default class PrintLayout extends BaseLayout {
    constructor (options = {}) {
        super({
            key: 'print',
            ...options
        });
    }


    /**
     * View regions
     */
    regions () {
        return {
            ...super.regions.apply(this)
        };
    }
}
