import React from 'react';
import app from '../../app/app';


export default function ContentAdvantageView (props: {type: string}) {
    const { type } = props;
    const displayText:any = app?.contentApi?.messages.prepareMessages('transport');

    let displayTextFilter = displayText?.advantage;
    if (displayText[type.toLowerCase()]) {
        displayTextFilter = displayText[type.toLowerCase()];
    }
    const contentApiHeadline = (type) ? displayText.headline : '';
    const contentApiAdvantage = (type) ? displayTextFilter : '';

    return (
        <div className="content-advantage">
            <div className="checklist">
                <h4 className="headline checklist-headline">{contentApiHeadline.value}</h4>
                <div className="row">
                    <div className="col-sm-12">
                        <ul className="checkmark-list">
                            {contentApiAdvantage.map((item: any, key: number) => {
                                return (
                                    <li key={key}>{item.value}</li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
