var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"trustpilot-content hidden-for-print\">\n    <div class=\"container container-with-padding\">\n        <div>\n            <h3 class=\"headline\">\n                "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.confirm-trustpilot.headline",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":76}}})) != null ? stack1 : "")
    + "\n            </h3>\n        </div>\n\n        <div class=\"trustpilot-ratings\">\n\n            <!-- TrustBox script -->\n            <script type=\"text/javascript\" src=\"//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js\" async></script>\n            <!-- End TrustBox script -->\n\n            <!-- TrustBox widget - Micro Button -->\n            <div class=\"trustpilot-widget\" data-locale=\"de-DE\" data-template-id=\"5419b757fa0340045cd0c938\" data-businessunit-id=\"63196b55c9997ae971ecad6c\" data-style-height=\"25px\" data-style-width=\"100%\" data-font-family=\"Source Sans Pro\" data-text-color=\"#263e6a\">\n            <a href=\"https://de.trustpilot.com/review/meinschiff.com\" target=\"_blank\" rel=\"noopener\">Trustpilot</a>\n            </div>\n            <!-- End TrustBox widget -->\n\n        </div>\n    </div>\n</div>\n";
},"useData":true});