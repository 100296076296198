import React from 'react';
import { createRoot } from 'react-dom/client';
import ContentAdvantageView from '../../../components/travel/content-advantage';

import _ from 'underscore';
import app from '../../../app/app';
import ApiInputRadiobuttonsView from './radiobuttons';
import templateWebpack from '../../../../tpl/item/api-input/transporttype-options.hbs';
import ApiStepFactory from '../../../item/api-step/factory';
import OverlayFactory from '../../../item/overlay/factory';
import * as Constants from '../../../config/constants';
import { renderReact } from '../../../react';
import { getIntl } from '../../../intl';


export default class ApiInputTransportTypeOptionsView extends ApiInputRadiobuttonsView {
    constructor (options) {
        super({
            templateWebpack,
            ...options,
            // will be filtered in this.serializeData
            filterBlockedOptions: false,
            // excecpt train option, as required by TUICIBE-160
            filterBlockedTrain: false
        });
        this.setRoundTrip();
    }


    events () {
        const superEvents = super.events.apply(this);
        return {
            ...superEvents,
            'click .js-overlay-serviceteam': () => {
                OverlayFactory.show('inactivity');
            },
            'click .transport-option > .transport-option-radio > label': 'onClickTracking'
        };
    }

    childViewEvents () {
        return {
            'step:did:change': () => {
                this.triggerMethod('step:did:change');
            }
        };
    }

    /**
     * View regions
     */
    regions () {
        return {
            'substep': {
                el: '.api-step-region-substep',
                replaceElement: true
            },
            'shipbound': {
                el: '.api-step-region-shipbound',
                replaceElement: false
            },
            'homebound': {
                el: '.api-step-region-homebound',
                replaceElement: false
            },
            'sub-step-shipbound': {
                el: '.region-for-api-sub-step-shipbound',
                replaceElement: true
            },
            'sub-step-homebound': {
                el: '.region-for-api-sub-step-homebound',
                replaceElement: true
            }
        };
    }


    /**
     * Add details to the data passed into the template.
     * Will be merged into the model / collection data.
     */
    templateContext () {
        const templateContext = super.templateContext.apply(this);

        // TUICUNIT-1258: Work'A'Round APi vs Concept
        if (!this.getRoundTrip() && !this.isShipbound()) {
            templateContext.error = '';
            this.triggerMethod('step:did:change');
        }
        const trainBlockedMsg = app.contentApi.messages.prepareMessages('transport')?.trainBlocked || '';
        return {
            ...templateContext,
            displayTitle: this.options.displayTitle,
            trainUnavailableInfo: trainBlockedMsg,
            isShipbound: this.isShipbound(),
            isRoundTrip: this.getRoundTrip(),
            isB2B: app.apiSession.bookingStatus.agency.isB2B()
        };
    }

    /**
     * Overwrites the Marionette method to add the "isFlight", ...
     * properties to the options
     */
    serializeData () {
        const data = super.serializeData();
        const filterBlockedTrain = this.getOption('filterBlockedTrain') !== false;
        let options = data.options || [];
        options.forEach(option => {
            option.isFlight = option.id === Constants.NETMATCH_TRANSPORT_OPTION_FLIGHT;
            option.isTrain = option.id === Constants.NETMATCH_TRANSPORT_OPTION_TRAIN;
            option.isBus = option.id === Constants.NETMATCH_TRANSPORT_OPTION_BUS;
            option.isOwn = option.id === Constants.NETMATCH_TRANSPORT_OPTION_OWN;
        });


        // only train option won't be removed, except it is configured otherwise by constructor options
        options = options.filter(
            option => (!option.isBlocked || (option.isTrain && !filterBlockedTrain))
        );

        data.options = options;

        if (this.getRoundTrip() && this.isShipbound()) {
            options.forEach(option => {
                if (option.id === Constants.NETMATCH_TRANSPORT_OPTION_OWN) {
                    option.displayText = getIntl().formatMessage({
                        id: 'js.item.api-input.view.transporttype-options.displayText'
                    });
                }
            });
        }
        return data;
    }


    onRender () {
        const apiSubStepView = this.getSubStepView();
        if (apiSubStepView) {
            // display substep view in a round-trip style by combining one-way travels
            if (this.getRoundTrip()) {
                // to avoid repeated display, only display for shipBound-steps
                if (this.isShipbound()) {
                    this.showChildView('shipbound', apiSubStepView.shipbound);
                    this.showChildView('homebound', apiSubStepView.homebound);
                }
            } else {
                // display substep view separated into one-way travels
                this.showChildView('substep', apiSubStepView);
            }
        }

        // react region travel content advantage
        if (this.$el.find('.rrt-ca')[0]) {
            const container = this.$el.find('.rrt-ca')[0];
            const root = createRoot(container);
            renderReact(root, <ContentAdvantageView type={this.model.get('inputValue')}/>);
        }
    }

    renderSubviewFromChildShipbound (data) {
        this.showChildView('sub-step-shipbound', data.view);
    }

    renderSubviewFromChildHomebound (data) {
        this.showChildView('sub-step-homebound', data.view);
    }

    isShipbound () {
        return this.model.apiStep.id.indexOf(Constants.SHIPBOUND) !== -1;
    }

    setRoundTrip () {
        if (this.isShipbound()) {
            const firstOption = _.first(this.model.get('options'));
            // why? because: TUICIBE-86 JW comment-101285
            if (firstOption.canRoundTrip === true && app.contentApi.tripInfo.get('transreise') === true) {
                app.options.roundTrip = false;
            } else {
                app.options.roundTrip = firstOption.canRoundTrip;
            }

            // app.options.roundTrip = true;
            // roundTrip information will be necessary in later steps that might loose access to app.options otherwise
            app.ibeController.ibeStorage.save(Constants.IS_ROUNDTRIP, app.options.roundTrip);
        }
    }

    getRoundTrip () {
        return app.options.roundTrip;
    }

    /**
     * Decides which sub step to render based on the selected transportOption.
     */
    getSubStepView () {
        const mapping = {
            [Constants.NETMATCH_TRANSPORT_OPTION_FLIGHT]: {
                [Constants.SHIPBOUND]: Constants.NETMATCH_STEP_SHIPBOUNDAIRPORT,
                [Constants.HOMEBOUND]: Constants.NETMATCH_STEP_HOMEBOUNDAIRPORT
            },
            [Constants.NETMATCH_TRANSPORT_OPTION_TRAIN]: {
                [Constants.SHIPBOUND]: Constants.NETMATCH_STEP_SHIPBOUNDTRAIN,
                [Constants.HOMEBOUND]: Constants.NETMATCH_STEP_HOMEBOUNDTRAIN
            },
            [Constants.NETMATCH_TRANSPORT_OPTION_BUS]: {
                [Constants.SHIPBOUND]: Constants.NETMATCH_STEP_SHIPBOUNDBUS,
                [Constants.HOMEBOUND]: Constants.NETMATCH_STEP_HOMEBOUNDBUS
            },
            [Constants.NETMATCH_TRANSPORT_OPTION_OWN]: {
                [Constants.SHIPBOUND]: null,
                [Constants.HOMEBOUND]: null
            }
        };

        if (this.model.hasError()) {
            return;
        }

        const selectedTransportOption = this.model.getInputValue();
        const direction = this.isShipbound() ? Constants.SHIPBOUND : Constants.HOMEBOUND;

        let nextApiStepId;
        try {
            nextApiStepId = mapping[selectedTransportOption][direction];
        } catch (e) {
            // nothing to do here
        }

        if (!nextApiStepId) {
            // TransportType is Own -> check Button again
            this.triggerMethod('step:did:change');
            return null;
        }

        const nextApiStepModel = app.apiSession.steps.get(nextApiStepId);
        if (!nextApiStepModel) {
            return null;
        }
        if (this.isShipbound()) {
            if (this.getRoundTrip()) {
                return {
                    shipbound: ApiStepFactory.create(app.apiSession.steps.get(mapping[selectedTransportOption][Constants.SHIPBOUND]).id, {
                        model: app.apiSession.steps.get(mapping[selectedTransportOption][Constants.SHIPBOUND]),
                        autoSubmit: true,
                        darkStyling: true,
                        isRoundTrip: this.getRoundTrip(),
                        mainView: this
                    }),
                    homebound: ApiStepFactory.create(app.apiSession.steps.get(mapping[selectedTransportOption][Constants.HOMEBOUND]).id, {
                        model: app.apiSession.steps.get(mapping[selectedTransportOption][Constants.HOMEBOUND]),
                        autoSubmit: true,
                        darkStyling: true,
                        isRoundTrip: this.getRoundTrip(),
                        mainView: this
                    })
                };
            }
        }

        if (!this.isShipbound() && this.getRoundTrip()) {
            return null;
        }

        return ApiStepFactory.create(nextApiStepModel.id, {
            model: nextApiStepModel,
            autoSubmit: true,
            darkStyling: true,
            isRoundTrip: this.getRoundTrip()
        });
    }

    onClickTracking (e) {
        const translate = {
            [Constants.NETMATCH_TRANSPORT_OPTION_FLIGHT]: 'Flug',
            [Constants.NETMATCH_TRANSPORT_OPTION_TRAIN]: 'Bahn',
            [Constants.NETMATCH_TRANSPORT_OPTION_BUS]: 'Bus',
            [Constants.NETMATCH_TRANSPORT_OPTION_OWN]: 'Eigene An- und Abreise'
        };
        const label = this.$(e.currentTarget).parent('.radiobutton-element').find('input').val();

        app.trackController.eventTracking({
            action: 'Transportmittelwahl',
            label: translate[label],
            ga4clickLabel: `content.button.an-abreis-${translate[label]}`
        });
    }
}
