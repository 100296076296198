import BaseItem from '../../../base/view/item';
import app from '../../../app/app';
import templateWebpack from '../../../../tpl/item/content-teaser/layout.hbs';


export default class ContentTeaserView extends BaseItem {
    constructor (options = {}) {
        super({
            key: 'content-teaser',
            templateWebpack,
            className: 'content-teaser',
            ...options
        });
    }

    events () {
        return {
            'click .js-tracking': 'onClickTracking'
        };
    }

    /**
     * Add details to the data passed into the template.
     * Will be merged into the model / collection data.
     */
    templateContext () {
        return {
            teaser: this.model.get('teaser'),
            contentAPITexte: app.contentApi.messages.prepareMessages('confirmation')
        };
    }

    onClickTracking (e) {
        const title = this.$(e.currentTarget).parents('.js-tracking-wrapper').find('.teaser-headline').text();
        app.trackController.eventTracking({
            action: 'Buchungsbestätigung',
            label: `Teaser|${title}`,
            ga4clickLabel: `content.button.buchungsbestaetigung-teaser-${title}`
        });
    }
}
