var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.api-step.airport.to",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":9,"column":68}}})) != null ? stack1 : "")
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.api-step.airport.from",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":11,"column":70}}})) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row airport-row\">\n    <div class=\"col-sm-6\">\n        <div class=\"api-input-airportcode\"></div>\n    </div>\n\n    <div class=\"col-sm-6\">\n        <div class=\"transfer-to-ship\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isShipbound") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":12,"column":19}}})) != null ? stack1 : "")
    + "            <span class=\"icon icon-ship-transfer\"></span>\n        </div>\n    </div>\n</div>\n<div class=\"region-for-api-step-flight\"></div>\n";
},"useData":true});