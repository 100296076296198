var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"booking-page-section\" >\n        <div class=\"js-iframe-wrapper\">\n            <div class=\"credit-card-wrapper\">\n                <div class=\"headline\">\n                    "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.api-step.creditcard.headline",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":81}}})) != null ? stack1 : "")
    + "\n                </div>\n                <div>\n                    "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.api-step.creditcard.owner",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":9,"column":78}}})) != null ? stack1 : "")
    + "\n                </div>\n                <div class=\"invoice-name\">\n                    "
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"invoice") : depth0)) != null ? lookupProperty(stack1,"firstName") : stack1), depth0))
    + " "
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"invoice") : depth0)) != null ? lookupProperty(stack1,"lastName") : stack1), depth0))
    + "\n                    <span class=\"hint help-hint overlay-info-icon js-invoice-overlay\">\n                        <a>&nbsp;</a>\n                    </span>\n                </div>\n                <div class=\"js-iframe-error error-text hidden\"></div>\n                <div class=\"iframe-wrapper\">\n                    <iFrame class=\"js-papagena-iframe papagena-iframe-style\" src=\"\" name=\"papagena-iframe\" frameborder=\"0\" scrolling=\"no\"></iFrame>\n                </div>\n                <div class=\"js-iframe-success success-text hidden\"></div>\n                <div>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.api-step.creditcard.confirm",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":22,"column":21},"end":{"line":22,"column":81}}})) != null ? stack1 : "")
    + "</div>\n                <div class=\"info border-top clean margin-top ssl-hint\">\n                    "
    + ((stack1 = alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"contentAPITexte") : depth0)) != null ? lookupProperty(stack1,"privacy") : stack1), depth0)) != null ? stack1 : "")
    + "\n                </div>\n            </div>\n        </div>\n    </div>\n\n\n\n    <div> \n    <form class=\"js-papagena-form hidden\" target=\"papagena-iframe\" action=\"https://security.papagena-payment.de/register/credit-card-psd2.php\" method=\"post\">\n        haendlerPAP<input type=\"text\" name=\"haendlerPAP\" value=\"\" /><br/>\n        TransID<input type=\"text\" name=\"TransID\" value=\"\" /><br/>\n        Customer<input type=\"text\" name=\"Customer\" value=\""
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"invoice") : depth0)) != null ? lookupProperty(stack1,"firstName") : stack1), depth0))
    + " "
    + alias4(alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"invoice") : depth0)) != null ? lookupProperty(stack1,"lastName") : stack1), depth0))
    + "\" /><br/>\n        returnURL<input type=\"text\" name=\"returnURL\" value=\"\" /><br/>\n        Type<input type=\"text\" name=\"Type\" value=\"register\" /><br/>\n        CCSelect<input type=\"text\" name=\"CCSelect\" value=\"VISA\" /><br/>\n        billToCustomer<input type=\"text\" name=\"billToCustomer\" value=\"\" /><br/>\n        billingAddress<input type=\"text\" name=\"billingAddress\" value=\"\" /><br/>\n        OrderDesc<input type=\"text\" name=\"OrderDesc\" value=\"\" /><br/>\n        <input type=\"hidden\" name=\"Template\" value=\"ct_responsive\" />\n        <input type=\"hidden\" name=\"BGColor\" value=\"ffffff\" />\n        <input type=\"hidden\" name=\"FColor\" value=\"263e6a\" />\n        <input type=\"hidden\" name=\"FFace\" value=\"Verdana\" />\n        <input type=\"hidden\" name=\"FSize\" value=\"2\" />\n        <input type=\"hidden\" name=\"Language\" value=\"de\" />\n        <input type=\"hidden\" name=\"Center\" value=\"1\" />\n        <input type=\"hidden\" name=\"tWidth\" value=\"100%\" />\n        <input type=\"hidden\" name=\"tHeight\" value=\"100%\" />\n    </form>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\" success-box\">\n        <div class=\"headline\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.api-step.creditcard.success.headline",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":56,"column":30},"end":{"line":56,"column":99}}})) != null ? stack1 : "")
    + "</div>\n        <div class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.api-step.creditcard.success.text",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":57,"column":26},"end":{"line":57,"column":91}}})) != null ? stack1 : "")
    + "</div>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isCompleted") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":59,"column":11}}})) != null ? stack1 : "")
    + "\n<div class=\"api-input-TransactionId\"></div>\n<div class=\"api-input-CreditCardBrand\"></div>\n<div class=\"api-input-CreditCardNumber\"></div>\n<div class=\"api-input-CreditCardValid\"></div>\n<div class=\"api-input-CreditCardOwner\"></div>\n<div class=\"api-input-Token\"></div>\n<div class=\"api-input-Hash\"></div>\n<div class=\"api-input-Status\"></div>\n<div class=\"api-input-StatusDescription\"></div>\n<div class=\"api-input-Returncode\"></div>\n<div class=\"api-input-TimeStamp\"></div>\n<div class=\"api-input-Bin\"></div>\n";
},"useData":true});