import {CollectionView} from 'backbone.marionette';

import app from '../../../app/app';
import NavigationItemView from '../view/navigation-item';


export default class NavigationView extends CollectionView {
    constructor (options = {}) {
        options = Object.assign({
            tagName: 'ul',
            className: 'main-navigation',
            collection: app.bookingPages,
            childView: NavigationItemView
        }, options);

        super(options);

        // re-render the navigation when the api steps state changes
        this.listenTo(app, 'stepsData:sync', this.render);
        // this.listenTo(app.apiSession.steps, 'sync', this.render);
        this.listenTo(app.router, 'route', this.render);
    }

    /**
     * Overwritten method that only renders the navigation item
     * if its model has a title
     */
    _addChild (...args) {
        const model = args[0];
        if (model.get('hide-from-menu')) {
            return;
        }
        super._addChild.apply(this, args);
    }
}

