var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"js-success-box success-box\">\n    <div class=\"text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.page.booking-page.content.payment.headline",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":2,"column":22},"end":{"line":2,"column":92}}})) != null ? stack1 : "")
    + "</div>\n</div>\n\n<div class=\"page-payment\">\n    <div class=\"api-step-region-substep\"></div>\n     <div class=\"react-region-b2b-region-payment-text\"></div>\n    <div class=\"info price-model\">\n        <div class=\"title\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.page.booking-page.content.payment.hint",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":9,"column":27},"end":{"line":9,"column":93}}})) != null ? stack1 : "")
    + "</div>\n        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"priceModelInfo") || (depth0 != null ? lookupProperty(depth0,"priceModelInfo") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"priceModelInfo","hash":{},"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":10,"column":28}}}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n</div>\n<div class=\"booking-pager bottom\"></div>\n<div class=\"booking-pager sticky\"></div>\n";
},"useData":true});