import {Model} from 'backbone';
import BookingPageContent from '../../booking-page/base/content';
import app from '../../../app/app';
import ContentTeaserView from '../../../item/content-teaser/view/layout';
import ConfirmTrustpilotView from '../../../item/confirm-trustpilot/view/layout';
import templateWebpack from '../../../../tpl/page/booking-page/content/confirm.hbs';
import * as Constants from '../../../config/constants';
import {getLocale} from '../../../intl';


export default class ConfirmPageContent extends BookingPageContent {
    constructor (options = {}) {
        super({
            key: 'page-confirm-content',
            templateWebpack,
            ...options
        });

        this.isB2B = app.apiSession.bookingStatus.agency.isB2B();
        this.isGerman = getLocale() === 'de';
    }

    /**
     * View regions.
     * Any region defined under the key of a step id will be filled with the
     * corresponding step view when calling the 'renderApiStepsToRegions' method.
     */
    regions () {
        return {
            [Constants.NETMATCH_STEP_CONFIRM]: {
                el: '.api-step-confirm',
                replaceElement: true
            },
            'content-teaser': '.content-teaser-region',
            'confirm-trustpilot': '.confirm-trustpilot-region'
        };
    }

    /**
     * Any object defined under the key of a step id will be passed as options
     * into the step views created when calling the 'renderApiStepsToRegions' method
     */
    apiStepViewOptions () {
        return {
            [Constants.NETMATCH_STEP_CONFIRM]: {}
        };
    }


    /**
     * Add details to the data passed into the template.
     * Will be merged into the model / collection data.
     */
    templateContext () {
        return {
            apiSteps: this.model.apiSteps.models,
            contentAPITexte: app.contentApi.messages.prepareMessages('confirmation'),
            isB2B: this.isB2B,
            isGerman: this.isGerman
        };
    }

    /**
     * UI elements used more than once
     */
    ui () {
        return {};
    }

    /**
     * Auto-render the steps to the regions
     */
    onRender () {
        this.renderApiStepsToRegions(this.model.apiSteps);

        if (!this.isB2B) {
            if (this.isGerman) {
                app.contentApi.loadIbeTeaser().then((teaser) => {
                    this.contentTeaser(teaser);
                }).catch(() => {
                });
            }

            this.showChildView('confirm-trustpilot', new ConfirmTrustpilotView());
        }

        app.inactivitLayer = false;
    }

    contentTeaser (teaser) {
        const contentTeaserModel = new Model({
            teaser: app.contentApi.ibeTeaser.groupByCategory(teaser)
        });
        this.showChildView('content-teaser', new ContentTeaserView({
            model: contentTeaserModel
        }));
    }
}
