import ApiInputBaseView from './base';
import templateWebpack from '../../../../tpl/item/api-input/email-repeat.hbs';


export default class ApiInputEmailRepeatView extends ApiInputBaseView {
    constructor (options) {
        super({
            templateWebpack,
            ...options
        });
    }

    /**
     * View events
     */
    events () {
        const superEvents = super.events.apply(this);
        return {
            ...superEvents,
            'paste input': (e) => {
                e.preventDefault();
            }
        };
    }
}
