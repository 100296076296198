import ApiStepBaseView from './base';
import app from '../../../app/app';
import templateWebpack from '../../../../tpl/item/api-step/child.hbs';
import * as Constants from '../../../config/constants';
import {getIntl} from '../../../intl';


export default class ChildApiStep extends ApiStepBaseView {
    constructor (options = {}) {
        super({
            templateWebpack,
            showValidationSuccess: true,
            ...options
        });

        this.listenTo(this.model.inputs, 'change:inputValue', this.onChangeTracking);
    }


    /**
     * View regions.
     * Any region defined under the key of an input id will be filled with the
     * corresponding input view when calling the 'renderApiInputsToRegions' method..
     */
    regions () {
        return {
            [Constants.NETMATCH_INPUT_CHILD_GENDER]: {
                el: '.api-input-gender',
                replaceElement: true
            },
            [Constants.NETMATCH_INPUT_CHILD_FIRSTNAME]: {
                el: '.api-input-firstname',
                replaceElement: true
            },
            [Constants.NETMATCH_INPUT_CHILD_LASTNAME]: {
                el: '.api-input-lastname',
                replaceElement: true
            },
            [Constants.NETMATCH_INPUT_CHILD_DATEOFBIRTH]: {
                el: '.api-input-dayofbirth',
                replaceElement: true
            },
            [Constants.NETMATCH_INPUT_CHILD_NATIONALITY]: {
                el: '.api-input-nationality',
                replaceElement: true
            }
        };
    }


    /**
     * Any object defined under the key of an input id will be passed as options
     * into the input views created when calling the 'renderApiInputsToRegions' method.
     */
    apiInputViewOptions () {
        const {formatMessage} = getIntl();
        const showValidationSuccess = !!this.getOption('showValidationSuccess');
        const childID = this.model.get('childIndex');

        return {
            [Constants.NETMATCH_INPUT_CHILD_GENDER]: {
                showValidationSuccess,
                displayType: 'radiobuttons',
                additionalTemplateContext: {
                    // darkStyling: true,
                    label: ' ',
                    nothingSubmittedOption: formatMessage({
                        id: 'tpl.item.api-step.view.child.gender'
                    })
                }
            },
            [Constants.NETMATCH_INPUT_CHILD_FIRSTNAME]: {
                showValidationSuccess,
                additionalTemplateContext: {
                    label: formatMessage({
                        id: 'tpl.item.api-step.view.child.firstname'
                    }),
                    // autocomplete: 'given-name',
                    // autocomplete: 'on',
                    autocomplete: `section-child.${childID} shipping given-name`,
                    name: 'fname',
                    maxlength: 34
                }
            },
            [Constants.NETMATCH_INPUT_CHILD_LASTNAME]: {
                showValidationSuccess,
                additionalTemplateContext: {
                    label: formatMessage({
                        id: 'tpl.item.api-step.view.child.lastname'
                    }),
                    // autocomplete: 'family-name',
                    // autocomplete: 'on',
                    autocomplete: `section-child.${childID} shipping family-name`,
                    name: 'lname',
                    maxlength: 34
                }
            },
            [Constants.NETMATCH_INPUT_CHILD_DATEOFBIRTH]: {
                displayType: 'date',
                showValidationSuccess,
                additionalTemplateContext: {
                    label: formatMessage({
                        id: 'tpl.item.api-step.view.child.dateofbirth'
                    }),
                    placeholderValue: formatMessage({
                        id: 'tpl.item.api-step.view.adult.dateofbirth-placeholder'
                    }),
                    maxlength: 10,
                    autocomplete: 'on',
                    name: 'bday'
                }
            },
            [Constants.NETMATCH_INPUT_CHILD_NATIONALITY]: {
                showValidationSuccess,
                displayType: 'select',
                additionalTemplateContext: {
                    nothingSubmittedOption: formatMessage({
                        id: 'tpl.item.api-step.view.child.nationality'
                    }),
                    resetDummyOption: formatMessage({
                        id: 'tpl.item.api-step.view.child.nationality'
                    }),
                    autocomplete: `section-child.${childID} shipping country`,
                    name: 'country'
                }
            }
        };
    }


    onRender () {
        this.renderApiInputsToRegions(this.model.inputs);
    }

    onChangeTracking (inputModelChange) {
        const isChild = inputModelChange.collection.parentModel.get('childIndex');
        const translate = {
            [Constants.NETMATCH_INPUT_CHILD_FIRSTNAME]: 'Vorname(n)',
            [Constants.NETMATCH_INPUT_CHILD_LASTNAME]: 'Nachname'
        };
        let participant = '';
        let forGA4participant = '';
        if (isChild) {
            participant = `|Kind|${isChild}`;
            forGA4participant = `-Kind-${isChild}`;
        }

        if (translate[inputModelChange.id]) {
            app.trackController.eventTracking({
                action: 'Daten',
                label: `${translate[inputModelChange.id]}${participant}`,
                ga4clickLabel: `content.custom.daten-${translate[inputModelChange.id]}${forGA4participant}`
            });
        }
        if (inputModelChange.hasInputError() && inputModelChange.getInputError()) {
            const label = inputModelChange.get('label');
            app.trackController.eventTracking({
                ga4eventName: 'form-error',
                ga4formName: `ibe.persoenliche-daten-${label}-Kind-${isChild}`,
                ga4errorDisplayedMessage: inputModelChange.getInputError()
            });
        }
    }
}
