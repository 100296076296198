import _ from 'underscore';
import BaseModel from '../../../base/model/base';
import BaseCollection from '../../../base/collection/base';
import app from '../../../app/app';
import * as Constants from '../../../config/constants';


export default class BookingPageModel extends BaseModel {
    constructor (attributes, options) {
        super(attributes, options);

        this.apiSteps = new BaseCollection();
        if (app.apiSession.steps.length) {
            this.setApiSteps();
        }
        /* this.listenTo(app.apiSession.steps, 'sync', () => {
            console.log('%c BookingPageModel: sync Event', 'background: #222; color: #ffff00');
            this.setApiSteps();
        }); */
    }


    /**
     * @see: http://backbonejs.org/#Model-idAttribute
     */
    get idAttribute () {
        return 'route';
    }


    getNextOpenPage () {
        let nextOpenPage;
        let modelIndex = this.collection.indexOf(this);
        while (modelIndex < this.collection.length - 1) {
            modelIndex++;
            const bookingPage = this.collection.at(modelIndex);
            if (bookingPage.isOpen()) {
                nextOpenPage = bookingPage;
                break;
            }
        }
        return nextOpenPage;
    }


    getNextApplicablePage () {
        let modelIndex = this.collection.indexOf(this);
        while (modelIndex < this.collection.length - 1) {
            modelIndex++;
            const bookingPage = this.collection.at(modelIndex);
            if (!bookingPage.isNotApplicable()) {
                return bookingPage;
            }
        }
        return null;
    }


    getPreviousApplicablePage () {
        let modelIndex = this.collection.indexOf(this);
        while (modelIndex > 0) {
            modelIndex--;
            const bookingPage = this.collection.at(modelIndex);
            if (!bookingPage.isNotApplicable()) {
                return bookingPage;
            }
        }
        return null;
    }


    getPreviousCompletedPage () {
        let modelIndex = this.collection.indexOf(this);
        while (modelIndex > 0) {
            modelIndex--;
            const bookingPage = this.collection.at(modelIndex);
            if (bookingPage.isDone()) {
                return bookingPage;
            }
        }
        return null;
    }


    setApiSteps () {
        const apiStepPatterns = this.get('api-steps') || [];
        const matchedApiSteps = _.flatten(apiStepPatterns.map(apiStepPattern => app.apiSession.steps.filterByRegEx(apiStepPattern)), true);
        this.apiSteps.reset(matchedApiSteps);
    }


    getRoutes () {
        const routes = this.get('sub-routes') || [];
        return routes.concat([this.get('route')]);
    }


    get status () {
        return this.pageStatus;
    }


    isPending () {
        return this.status === Constants.BOOKING_PAGE_STATUS_PENDING;
    }


    isOpen () {
        return this.status === Constants.BOOKING_PAGE_STATUS_OPEN;
    }


    isDone () {
        return this.status === Constants.BOOKING_PAGE_STATUS_DONE;
    }

    isNotApplicable () {
        return this.status === Constants.BOOKING_PAGE_STATUS_NOT_APPLICABLE;
    }


    allCompleted () {
        if (!this.isDone()) {
            return false;
        }
        const subRoutes = this.get('sub-routes') || [];
        const bookingPages = subRoutes.map(subRoute => this.collection.get(subRoute));
        return _.every(bookingPages, bookingPage => {
            bookingPage.refreshStatus();
            return bookingPage.isDone() || bookingPage.isNotApplicable();
        });
    }


    refreshStatus () {
        this.pageStatus = this.calculateStatus();
    }


    allStepsHaveValidInputs () {
        return this.apiSteps.every(apiStepModel => !apiStepModel.hasInputErrors());
    }


    calculateStatus () {
        // no api steps fetched yet
        if (!app.apiSession.steps.length) {
            return Constants.BOOKING_PAGE_STATUS_PENDING;
        }

        let apiSteps = this.apiSteps;

        // filter out participants for the insurance page
        if (this.id === Constants.PAGE_INSURANCE) {
            const adultRegex = new RegExp(Constants.NETMATCH_STEP_ADULT);
            const childRegex = new RegExp(Constants.NETMATCH_STEP_CHILD);
            apiSteps = new BaseCollection(apiSteps.filter(step => {
                return !(step.id.match(adultRegex) || step.id.match(childRegex));
            }));
        }

        // no api steps available for this booking step
        if (!apiSteps.length) {
            return Constants.BOOKING_PAGE_STATUS_NOT_APPLICABLE;
        }

        // all api steps are not applicable or blocked
        if (apiSteps.every(apiStep => apiStep.isNotApplicable() || apiStep.isBlocked())) {
            return Constants.BOOKING_PAGE_STATUS_NOT_APPLICABLE;
        }

        const modelIndex = this.collection.indexOf(this);

        // all api steps are completed, completed automatically, optional or not applicable
        const allApiStepsAreDone = apiSteps.every(apiStep => apiStep.isDone());
        if (allApiStepsAreDone) {
            // check all previous pages; if one of them is not done, this one is not done as well
            let previousIndex = modelIndex;
            const previousPages = [];
            while (previousIndex > 0) {
                previousIndex--;
                previousPages.push(this.collection.at(previousIndex));
            }
            if (_.every(previousPages, previousPage => previousPage.isDone() || previousPage.isNotApplicable())) {
                return Constants.BOOKING_PAGE_STATUS_DONE;
            }
        }

        // if it is the first page, if it's not completed it must be open
        if (modelIndex === 0) {
            return Constants.BOOKING_PAGE_STATUS_OPEN;
        }

        // if it is NOT the first page but the previous page is completed it must be open
        let previousIndex = modelIndex;
        let previousPage;
        while (previousIndex > 0) {
            previousIndex--;
            const page = this.collection.at(previousIndex);
            if (!page.isNotApplicable()) {
                previousPage = page;
                break;
            }
        }

        if (previousPage && previousPage.isDone()) {
            return Constants.BOOKING_PAGE_STATUS_OPEN;
        }

        // default status pending
        return Constants.BOOKING_PAGE_STATUS_PENDING;
    }
}
