import React, {useMemo} from 'react';
import * as Constants from '../../config/constants';
import app from '../../app/app';
import {FormattedMessage} from 'react-intl';
import isInAppView from '../../util/isInAppView';


export interface TypeLinkBarProps {
    isB2B: boolean;
    url: {
        privacy: string,
        imprint: string,
        agb: string
    };
}

export default function LinkBarView ({
    isB2B,
    url
}: TypeLinkBarProps) {
    const trackEvent = (value: string) => {
        if (app.trackController) {
            app.trackController.eventTracking({
                ga4clickLabel: `footer.${value}`
            });
        }
    };

    const hideFooter = useMemo(() => {
        return isInAppView ? 'hidden' : '';
    }, []);

    const textNumberCta = isB2B ? Constants.VIEW_B2B_SERVICE_NUMBER_DISPLAY_CTA : Constants.VIEW_SERVICE_NUMBER_DISPLAY_CTA;
    const textNumberInt = isB2B ? Constants.VIEW_B2B_SERVICE_NUMBER_DISPLAY_INT : Constants.VIEW_SERVICE_NUMBER_DISPLAY_INT;
    const textNumber = (
        <a href={`tel:${textNumberCta}`} onClick={() => trackEvent('button.anrufen')}>
            <span className="bold">
                <FormattedMessage id="components.footer.link-bar.hotline"/>
            </span>
            {textNumberInt} <span className="icon-telephone icon"></span>
        </a>
    );

    const privacy = (url.privacy)
        ? (
            <li>
                <a href={url.privacy} rel="noopener noreferrer" onClick={() => trackEvent('link.datenschutz')}>
                    <FormattedMessage id="components.footer.link-bar.privacy"/>
                </a>
            </li>
        )
        : '';
    const imprint = (url.imprint)
        ? (
            <li>
                <a href={url.imprint} rel="noopener noreferrer" onClick={() => trackEvent('link.impressum')}>
                    <FormattedMessage id="components.footer.link-bar.legal-notice"/>
                </a>
            </li>
        )
        : '';
    const agb = (url.agb)
        ? (
            <li>
                <a href={url.agb} rel="noopener noreferrer" onClick={() => trackEvent('link.agb')}>
                    <FormattedMessage id="components.footer.link-bar.tos"/>
                </a>
            </li>
        )
        : '';

    return (
        <div className={`page-footer-link-bar ${hideFooter}`}>
            <div className="container container-with-padding">
                <div className="row">
                    <div className="col-sm-5 col-sm-push-7 align-right">
                        <svg width="220" height="54" className="logo-tui-cruises">
                            <use xlinkHref="#icon-logo-tuic"></use>
                        </svg>
                    </div>
                    <div className="col-sm-7 col-sm-pull-5 hidden-for-print page-footer-link-list">
                        <div className="footer-hotline">
                            {textNumber}
                        </div>
                        <ul className="link-list">
                            {privacy}
                            {imprint}
                            {agb}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
