var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p class=\"service-description\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.page.booking-page.layout.b2b.description",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":14,"column":51},"end":{"line":14,"column":119}}})) != null ? stack1 : "")
    + "</p>\n                    <p class=\"service-description\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.page.booking-page.layout.b2b.sales-offices",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":15,"column":51},"end":{"line":15,"column":121}}})) != null ? stack1 : "")
    + ":</p>\n                    <a class=\"service-info-action js-sidebar-tracking-layout\" href=\"tel:"
    + alias3((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||alias2).call(alias1,"VIEW_B2B_SERVICE_NUMBER_DISPLAY_CTA",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":16,"column":88},"end":{"line":16,"column":142}}}))
    + "\" data-totrack=\"anrufen\">"
    + alias3((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||alias2).call(alias1,"VIEW_B2B_SERVICE_NUMBER_DISPLAY_INT",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":16,"column":167},"end":{"line":16,"column":221}}}))
    + "</a>\n                    <p class=\"service-description spacer\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.page.booking-page.layout.b2b.sales-groups",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":17,"column":58},"end":{"line":17,"column":127}}})) != null ? stack1 : "")
    + ":</p>\n                    <a class=\"service-info-action js-sidebar-tracking-layout\" href=\"tel:"
    + alias3((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||alias2).call(alias1,"VIEW_B2B_GROUP_NUMBER_DISPLAY_CTA",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":18,"column":88},"end":{"line":18,"column":140}}}))
    + "\" data-totrack=\"anrufen\">"
    + alias3((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||alias2).call(alias1,"VIEW_B2B_GROUP_NUMBER_DISPLAY_INT",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":18,"column":165},"end":{"line":18,"column":217}}}))
    + "</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p class=\"service-description\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.page.booking-page.layout.description1",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":20,"column":51},"end":{"line":20,"column":116}}})) != null ? stack1 : "")
    + ":</p>\n                    <a class=\"service-info-action js-sidebar-tracking-layout\" href=\"tel:"
    + alias3((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||alias2).call(alias1,"VIEW_SERVICE_NUMBER_DISPLAY_CTA",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":21,"column":88},"end":{"line":21,"column":138}}}))
    + "\" data-totrack=\"anrufen\">"
    + alias3((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||alias2).call(alias1,"VIEW_SERVICE_NUMBER_DISPLAY_INT",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":21,"column":163},"end":{"line":21,"column":213}}}))
    + "</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"booking-status-bar\"></div>\n\n<div class=\"container container-with-padding booking-page\">\n\n    <div class=\"row\">\n\n        <div class=\"col-lg-8 page-content\"></div>\n\n        <div class=\"col-lg-4 is-sticky\">\n            <div class=\"sidebar booking-status-sidebar\"></div>\n            <div class=\"sidebar service-info\">\n                <h3 class=\"service-headline\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.page.booking-page.layout.headline",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":12,"column":45},"end":{"line":12,"column":106}}})) != null ? stack1 : "")
    + "</h3>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isB2B") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":22,"column":23}}})) != null ? stack1 : "")
    + "                <p class=\"service-details\">\n                    "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"general.opening-hours",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":24,"column":20},"end":{"line":24,"column":64}}})) != null ? stack1 : "")
    + "\n                </p>\n            </div>\n        </div>\n\n    </div>\n</div>\n\n";
},"useData":true});