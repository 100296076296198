import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import OverlayView from '../overlay';

export default function OverlayHintView () {
    const overlayRef = useRef();
    const onBabiesOverlay = () => {
        // @ts-ignore
        if (overlayRef.current && overlayRef.current.showModal === false) {
            // @ts-ignore
            overlayRef.current.handleOpenModal();
        }
    };
    const overlayRef2 = useRef();
    const onOverlay = () => {
        // @ts-ignore
        if (overlayRef2.current && overlayRef2.current.showModal === false) {
            // @ts-ignore
            overlayRef2.current.handleOpenModal();
        }
    };

    return (
        <>
            <p className="hint help-hint">
                <a onClick={onBabiesOverlay}>
                    <FormattedMessage id="components.insurange.overlay-hint.babies" />
                </a>
            </p>
            <OverlayView overlayId={'babies'} closeOnBgClick={true} hasBackButton={true} data={null} ref={overlayRef}></OverlayView>

            <p className="hint help-hint">
                <a onClick={onOverlay}>
                    <FormattedMessage id="components.insurange.overlay-hint.tuicard" />
                </a>
            </p>
            <OverlayView overlayId={'tuicard'} closeOnBgClick={true} hasBackButton={true} data={null} ref={overlayRef2}></OverlayView>
        </>
    );
}
