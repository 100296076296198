

const getViewportSize = () => {
    const de = document.documentElement;
    const myWidth = window.innerWidth || (de && de.clientWidth) || document.body.clientWidth;
    const myHeight = window.innerHeight || (de && de.clientHeight) || document.body.clientHeight;
    return [myWidth, myHeight];
};


const elementInViewport = ($el) => {
    const offsetTop = $el.offset().top;
    const offsetLeft = $el.offset().left;
    const height = $el.height();
    const width = $el.width();
    return (
        (
            (offsetTop) >= window.pageYOffset &&
            (offsetLeft) >= window.pageXOffset &&
            (offsetTop) <= (window.pageYOffset + window.innerHeight) &&
            (offsetLeft) <= (window.pageXOffset + window.innerWidth)
        ) || (
            (offsetTop + height) >= window.pageYOffset &&
            (offsetLeft + width) >= window.pageXOffset &&
            (offsetTop + height) <= (window.pageYOffset + window.innerHeight) &&
            (offsetLeft + width) <= (window.pageXOffset + window.innerWidth)
        )
    );
};

const isSmall = () => {
    let isSmall = true;
    if (getViewportSize() && getViewportSize()[0] >= 768) {
        isSmall = false;
    }
    return isSmall;
};


export default {
    getViewportSize,
    elementInViewport,
    isSmall
};

