import ApiStepBaseView from './base';
import templateWebpack from '../../../../tpl/item/api-step/online-risk.hbs';
import app from '../../../app/app';
import {
    NETMATCH_ONLINE_RISK_NO_BOOKING_BLACKLISTED, NETMATCH_ONLINE_RISK_NO_BOOKING_NEGATIVE_CREDIT, NETMATCH_ONLINE_RISK_NO_BOOKING_UNKNOWN_REASON, NETMATCH_ONLINE_RISK_NO_DIRECT_DEBIT, NETMATCH_ONLINE_RISK_UNKNOWN, NETMATCH_STEP_STATUS_ERROR
} from '../../../config/constants';


// component only to show the online risk result popups
export default class OnlineRiskApiStep extends ApiStepBaseView {
    constructor (options = {}) {
        super({
            templateWebpack, ...options
        });

        const messages = app.contentApi.messages.prepareMessages('payment');
        const messagesRisk = app.contentApi.messages.prepareMessages('onlineRisk');
        const isB2B = app.apiSession.bookingStatus.agency.isB2B();
        const b2bSuffix = isB2B ? '.b2b' : '.b2c';

        this.listenTo(app, 'onlineRisk:change', (evt) => {
            if (evt && evt.attributes) {
                let text;

                let redirectAway = false;
                let triggerEditHide = false;

                if (evt.attributes.netMatchAttributes && evt.attributes.netMatchAttributes.some(att => att.key === 'OnlineRisk')) {
                    const riskAttributes = evt.attributes.netMatchAttributes.find(att => att.key === 'OnlineRisk');
                    switch (riskAttributes.code) {
                        case NETMATCH_ONLINE_RISK_NO_BOOKING_NEGATIVE_CREDIT:
                            text = messages['debit.credit-check-deepnegative' + b2bSuffix];
                            redirectAway = true;
                            triggerEditHide = true;
                            break;
                        case NETMATCH_ONLINE_RISK_NO_BOOKING_UNKNOWN_REASON:
                        case NETMATCH_ONLINE_RISK_NO_BOOKING_BLACKLISTED:
                            text = messagesRisk['blacklist.invoiceRecipient' + b2bSuffix];
                            redirectAway = true;
                            triggerEditHide = true;
                            break;
                        case NETMATCH_ONLINE_RISK_UNKNOWN:
                            text = messages['debit.credit-check-failure'];
                            triggerEditHide = true;
                            break;
                        case NETMATCH_ONLINE_RISK_NO_DIRECT_DEBIT:
                            triggerEditHide = true;
                            break;
                    }
                } else if (evt.attributes.status === NETMATCH_STEP_STATUS_ERROR) {
                    text = messages['debit.credit-check-failure'];
                }

                if (redirectAway) {
                    window.redirectTo = 'https://meinschiff.com';
                }

                if (text) {
                    app.trigger('overlay:start', {text});
                }

                if (triggerEditHide) {
                    // if online risk came to any conclusion, editing of previous data and such redirecting to previous steps is prohibited so hide all relevant cta
                    [
                        ...document.querySelectorAll('.edit-link'),
                        ...document.querySelectorAll('.button-previous-page')
                    ].forEach((editElement) => editElement.classList.add('hidden'));

                    document.querySelectorAll('.main-navigation li.done a').forEach((navElement) => navElement.classList.add('deactivated'));
                }
            }
        });

    }
}
