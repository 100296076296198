import ApiInputChoiceBaseView from './basechoice';
import templateWebpack from '../../../../tpl/item/api-input/policy-type.hbs';


export default class ApiInputPolicyTypeView extends ApiInputChoiceBaseView {
    constructor (options) {
        // blocked options are getting shown, they get blocked on a higher level
        super({
            templateWebpack, ...options, filterBlockedOptions: false
        });
    }
}
