import React, { useState } from 'react';
import app from '../../app/app';
import CabinCategoryView from './cabin-category';
import * as Constants from '../../config/constants';
import { FormattedMessage } from 'react-intl';
import { CabinPrice } from './cabin-price';

type CabinPriceModelsProps = {
    data: any;
    cabinIndex: number;
    callbackCabin: Function;
    callback: Function;
    searchSubmit: Function;
    apiError: boolean;
};

export default function CabinPriceModelsView (props:CabinPriceModelsProps) {
    const { data, cabinIndex, callbackCabin, callback, searchSubmit, apiError } = props;
    const [open, setOpen] = useState(!data.selectedValue);
    const [shortOpen, setShortOpen] = useState(false);

    const mapAPItoContentPriceModel:any = {
        [Constants.NETMATCH_PRICEMODEL_FLEX]: Constants.YOURSTRULY_PRICEMODEL_FLEX,
        [Constants.NETMATCH_PRICEMODEL_FEELGOOD]: Constants.YOURSTRULY_PRICEMODEL_FEELGOOD,
        [Constants.NETMATCH_PRICEMODEL_FEELGOODPLUS]: Constants.YOURSTRULY_PRICEMODEL_FEELGOOD_PLUS,
        [Constants.NETMATCH_PRICEMODEL_PUR]: Constants.YOURSTRULY_PRICEMODEL_PUR,
        [Constants.NETMATCH_PRICEMODEL_PLUS]: Constants.YOURSTRULY_PRICEMODEL_PLUS,
        [Constants.NETMATCH_PRICEMODEL_PRO]: Constants.YOURSTRULY_PRICEMODEL_PRO,
        [Constants.NETMATCH_PRICEMODEL_HIDDENPROVIP1]: Constants.YOURSTRULY_PRICEMODEL_PROVIP1,
        [Constants.NETMATCH_PRICEMODEL_HIDDENPROVIP2]: Constants.YOURSTRULY_PRICEMODEL_PROVIP2
    };

    const mapNMvipToYTvipMessageFuckUp:any = {
        [Constants.NETMATCH_OPTION_CABININVIP_VIP1]: Constants.YOURSTRULY_PROVIP1_TEXT,
        [Constants.NETMATCH_OPTION_CABININVIP_VIP2]: Constants.YOURSTRULY_PROVIP2_TEXT
    };

    let bulletPoints:any = {};
    if (app.contentApi) {
        const sortPoints:any = [];
        data.priceModel.forEach((item: any, key:number) => {
            // TUICUNIT-3084: New VIP Handling
            const maybeHasVIP = `${item.model}${item.vipTariff ? item.vipTariff : ''}`;
            const single:any = (app.contentApi) ? app.contentApi.messages.prepareMessages(mapAPItoContentPriceModel[maybeHasVIP]) : {};

            single.list.forEach((part: any, partKey: number) => {
                if (!sortPoints[partKey]) {
                    sortPoints[partKey] = {};
                }
                sortPoints[partKey][key] = part.text ? part.text : part.headline;
            });

            // TUICUNIT-3084: New VIP Handling and new request; add VIP headline
            if (item.vipTariff) {
                const stupidVIPListForAddToList: any = app?.contentApi?.messages.prepareMessages(mapNMvipToYTvipMessageFuckUp[item.vipTariff]) || null;

                if (stupidVIPListForAddToList) {
                    stupidVIPListForAddToList.list.forEach((part: any) => {
                        sortPoints.push({[key]: part.headline});
                    });
                }
            }
        });
        bulletPoints = sortPoints;
    }

    let selectedInfo:any;
    let hasSelectedInclusiveVIP = false;
    if (data.selectedValue) {
        selectedInfo = data.priceModel.find((item:any) => item.id === data.selectedValue);
        // TUICUNIT-3084: New VIP Handling
        const maybeHasVIP = `${selectedInfo.model}${selectedInfo.vipTariff ? selectedInfo.vipTariff : ''}`;
        hasSelectedInclusiveVIP = !!selectedInfo.vipTariff;
        selectedInfo.texte = (app.contentApi) ? app.contentApi.messages.prepareMessages(mapAPItoContentPriceModel[maybeHasVIP]) : '';
    }

    const contentAPITexte:{cancellation?:string} = (app.contentApi && app.contentApi.messages) ? app.contentApi.messages.prepareMessages('desiredCabin') : '';

    const apiSteps:any = (app.apiSession) ? app.apiSession.steps : null;
    const cabinType = apiSteps.get(`cabinType/cabin-${cabinIndex}`);
    let categoryTypeCode = '';
    if (cabinType) {
        const cabinTypeInput = cabinType.inputs.get(Constants.NETMATCH_INPUT_CABINTYPE_CABINTYPE);
        const thisInputOptions = cabinTypeInput.get('options');
        const cabinTypeValue = cabinTypeInput.get('inputValue');
        const selectedOption = thisInputOptions.find((item:any) => item.id === cabinTypeValue) || {};
        categoryTypeCode = selectedOption.categoryTypeCode;
    }

    return (
        <div className="price-model">
            <span className="scroll-point-cabin-price-s"></span>
            <h2 className="api-step-headline expand">
                <FormattedMessage id="components.cabin.cabin-price-models.plan" />
                {data.selectedValue ?
                    <div className="cabin-is-expand" onClick={() => {
                        setOpen(!open);
                        setShortOpen(!open);

                        if (app && app.trackController && !open) {
                            app.trackController.eventTracking({
                                action: 'Kabinenwahl_Preismodell',
                                label: 'Auswahl ändern',
                                ga4clickLabel: 'content.custom.tarif-aendern'
                            });
                        }
                    }}>
                        {!open
                            ? (
                                <FormattedMessage id="general.change-selection" />
                            )
                            : (
                                <FormattedMessage id="general.close" />
                            )} <span className={open ? 'icon-ic-edit' : ''}> </span>
                    </div>
                    : ''}
            </h2>
            {data.selectedValue ?
                <div className={`inner-short ${open ? 'hidden' : 'show'}`}>
                    <div className="selected">
                        <span className="text" dangerouslySetInnerHTML={{__html: selectedInfo.texte.meta.intro}}></span>
                        <span className={`arrow ${shortOpen ? 'open' : ''}`} onClick={() => {
                            setShortOpen(!shortOpen);

                            if (app && app.trackController) {
                                app.trackController.eventTracking({
                                    action: 'Kabinenwahl_Preismodell',
                                    label: `${shortOpen ? 'Einklappen' : 'Ausklappen'}`,
                                    ga4clickLabel: `content.icon.tarif-${shortOpen ? 'Einklappen' : 'Ausklappen'}`
                                });
                            }
                        }}></span>
                    </div>
                    <div className={`inner-short-wrapper ${shortOpen ? 'show' : 'hidden-anim'}`}>
                        <div className={'wrapper-short'}>
                            {data.priceModel.map((value: any, key:any) => {
                                // TUICUNIT-3084: New VIP Handling
                                const maybeHasVIP = `${value.model}${value.vipTariff ? value.vipTariff : ''}`;
                                const texte:any = (app.contentApi) ? app.contentApi.messages.prepareMessages(mapAPItoContentPriceModel[maybeHasVIP]) : '';

                                return (
                                    <div key={key} className={`part has-${data.priceModel.length}${data.selectedValue && data.selectedValue === value.id ? ' part-selected' : ''}`}>
                                        <div className="detail">
                                            <div className="headline">{texte.meta.name}</div>
                                            <div className="intro">{texte.punch}</div>
                                            <div className="cabin-price-cta-wrapper">
                                                <div className="tarif-price nobr">
                                                    <div className="price-daily">{texte.daily}</div>
                                                    <CabinPrice price={value.fromPriceCruiseOnly} mode="from_pp" />
                                                </div>
                                                {data.selectedValue && data.selectedValue === value.id ?
                                                    <span className="cabin-selected">
                                                        <FormattedMessage id="general.selected" />
                                                    </span>
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        {contentAPITexte.cancellation ? (<div className="price-model-hint" ><div data-action="Kabinenwahl_Preismodell" data-label="Preismodellen" className="extern" dangerouslySetInnerHTML={{__html: contentAPITexte.cancellation}}/></div>) : ''}
                    </div>

                    {apiSteps.get(`cabinCategory/cabin-${cabinIndex}`) &&
                    (apiSteps.get(`cabinCategory/cabin-${cabinIndex}`).isSomehowCompleted() || apiSteps.get(`cabinCategory/cabin-${cabinIndex}`).isOpen()) ?
                        <CabinCategoryView
                            key={`cc${cabinIndex}`}
                            cabinIndex={cabinIndex}
                            hasSelectedInclusiveVIP={hasSelectedInclusiveVIP}
                            cabinCategoryTypeCodeValue={categoryTypeCode}
                            stepModelCabinCategory={apiSteps.get(`cabinCategory/cabin-${cabinIndex}`)}
                            stepModelCabinDeck= {apiSteps.get(`cabinDeck/cabin-${cabinIndex}`)}
                            stepModelCabinSelection= {apiSteps.get(`cabinSelection/cabin-${cabinIndex}`)}
                            stepModelFlow= {apiSteps.get(`${Constants.NETMATCH_STEP_CABINFLOW_PURE}${cabinIndex}`)}
                            callback={callback}
                            searchSubmit={searchSubmit}
                            apiError={apiError}></CabinCategoryView>
                        : ''}
                </div>
                : ''}
            <div className={`inner ${open ? 'show' : 'hidden-anim'}`}>
                <div className={'wrapper'}>
                    {data.priceModel.map((value: any, key:any) => {
                        // TUICUNIT-3084: New VIP Handling
                        const maybeHasVIP = `${value.model}${value.vipTariff ? value.vipTariff : ''}`;
                        const texte:any = (app.contentApi) ? app.contentApi.messages.prepareMessages(mapAPItoContentPriceModel[maybeHasVIP]) : {};

                        return (
                            <div key={key} className={`part has-${data.priceModel.length}`}>
                                <div className={`detail ${data.selectedValue && data.selectedValue === value.id ? ' part-selected' : ''}`}>
                                    <div className="headline">{texte.meta.name}</div>
                                    <div className="intro">{texte.punch}</div>
                                    <div className="cabin-price-cta-wrapper">
                                        <div className="tarif-price nobr">
                                            <div className="price-daily">{texte.daily}</div>
                                            <CabinPrice price={value.fromPriceCruiseOnly} mode="from_pp" />
                                        </div>
                                        {data.selectedValue && data.selectedValue === value.id ?
                                            <span className="cabin-selected"><FormattedMessage id="general.selected" /></span>
                                            :
                                            <a className={`button tarif-available typecode-${value.id}`}
                                                onClick={() => {
                                                    callbackCabin(value.id);
                                                    setOpen(!open);
                                                    setShortOpen(!shortOpen);

                                                    if (app && app.trackController) {
                                                        app.trackController.eventTracking({
                                                            action: 'Kabinenwahl_Preismodell',
                                                            label: `Auswahl_${texte.meta.name}`,
                                                            ga4clickLabel: `content.button.tarif-auswaehlen-${texte.meta.name}`
                                                        });
                                                    }
                                                }} >
                                                {texte.meta.cta}
                                            </a>
                                        }
                                    </div>
                                    <BulletsView list={bulletPoints} inicator={key} ></BulletsView>
                                </div>
                                {texte.meta.vipHint ? (<div className="vip-hint" ><div data-action="Kabinenwahl_Preismodell" data-label="Was sind VIP-Tarifoptionen?" className="extern" dangerouslySetInnerHTML={{__html: texte.meta.vipHint}}/></div>) : ''}
                            </div>
                        );
                    })}
                </div>
                <div className="legal">
                    <FormattedMessage id="components.cabin.cabin-price-models.legal" />
                </div>
                {contentAPITexte.cancellation ? (<div className="price-model-hint" ><div data-action="Kabinenwahl_Preismodell" data-label="Preismodellen" className="extern" dangerouslySetInnerHTML={{__html: contentAPITexte.cancellation}}/></div>) : ''}
            </div>
        </div>
    );
}


const BulletsView = (props: {list: any, inicator: number}) => {
    const { list, inicator } = props;
    const [open, setOpen] = useState(false);
    return (
        <div className="bullet-points">
            <div className="bullet-list-small">
                <span>
                    <FormattedMessage id="components.cabin.cabin-price-models.services" />
                </span>
                <span className={`arrow ${open ? 'open' : ''}`} onClick={() => {
                    setOpen(!open);
                }}></span>
            </div>
            <div className={`listing-small ${open ? 'show-small' : 'hidden-small'}`}>
                {list.map((textitem: any, textkey:any) => {
                    return (
                        <div key={textkey} className={`point ${!textitem[inicator] ? 'no-point' : ''}`}>
                            {(textitem[inicator]) ? <span dangerouslySetInnerHTML={{__html: textitem[inicator]}}></span> : '-'}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
