var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1 class=\"booking-page-headline\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.page.booking-page.content.cabin.headline",{"name":"formatMessage","hash":{"tripName":(depth0 != null ? lookupProperty(depth0,"tripName") : depth0)},"data":data,"loc":{"start":{"line":1,"column":34},"end":{"line":1,"column":120}}})) != null ? stack1 : "")
    + "</h1>\n\n<div class=\"region-cabin\"></div>\n\n<div class=\"react-region-b2b-cabin-detail\"></div>\n\n<!--div class=\"b2b-region-cabin-detail-text\"></div-->\n\n<div class=\"booking-pager bottom\"></div>\n\n<div class=\"booking-pager sticky\"></div>\n";
},"useData":true});