import ApiInputChoiceBaseView from './basechoice';
import OverlayFactory from '../../../item/overlay/factory';
import templateWebpack from '../../../../tpl/item/api-input/bus-options.hbs';


export default class ApiInputBusOptionsView extends ApiInputChoiceBaseView {
    constructor (options) {
        super({
            templateWebpack,
            ...options
        });
    }

    /**
     * Add details to the data passed into the template.
     * Will be merged into the model / collection data.
     */
    templateContext () {
        return {
            ...super.templateContext.apply(this),
            selectedBus: this.model.getSelectedOption()
        };
    }

    /**
     * View events
     */
    events () {
        return {
            'click .js-overlay-travel-child': () => {
                OverlayFactory.show('travel-child');
            }
        };
    }
}
