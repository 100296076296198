import ApiStepBaseView from './base';
import app from '../../../app/app';
import templateWebpack from '../../../../tpl/item/api-step/insurance-terms-and-conditions.hbs';
import * as Constants from '../../../config/constants';
import {getIntl} from '../../../intl';


export default class InsurancetTermsAndConditionsApiStep extends ApiStepBaseView {
    constructor (options = {}) {
        super({
            templateWebpack,
            ...options
        });
        // Tracking
        this.listenTo(this.model.inputs, 'change:inputValue', (model, value) => {
            if (value) {
                app.trackController.eventTracking({
                    action: 'Versicherung',
                    label: 'Versicherungsbedingungen',
                    ga4clickLabel: 'content.button.reiseversicherung-versicherungsbedingungen'
                });
            }
        });
    }

    /**
     * View regions.
     * Any region defined under the key of an input id will be filled with the
     * corresponding input view when calling the 'renderApiInputsToRegions' method..
     */
    regions () {
        return {
            [Constants.NETMATCH_INPUT_APPROVAL_OF_TERMS]: {
                el: '.api-input-approvalofterms',
                replaceElement: true
            }
        };
    }

    /**
     * Any object defined under the key of an input id will be passed as options
     * into the input views created when calling the 'renderApiInputsToRegions' method.
     *
     * We need a special display-type for policy assignment
     */
    apiInputViewOptions () {
        const {formatMessage} = getIntl();

        // TUICIBE-534: insurance PDF per API, Fallback old HardCoded URL (TUICIBE-307)
        const contentApiMeta = app.contentApi.messages.prepareMessages('meta');
        let contextLabelUrl = 'https://www.meinschiff.com/media/432/37048683132790/tui_cruises_vs_mit_avb.pdf';
        if (contentApiMeta && contentApiMeta.insurance) {
            if (contentApiMeta.insurance.pdfURL) {
                contextLabelUrl = contentApiMeta.insurance.pdfURL;
            }
        }
        const contextLabel = formatMessage({
            id: 'tpl.item.api-step.view.insurance-terms-and-conditions.label'
        }, {
            a: (chunks) => `<a href="${contextLabelUrl}" rel="noopener noreferrer">${chunks}</a>`
        });

        return {
            [Constants.NETMATCH_INPUT_APPROVAL_OF_TERMS]: {
                displayType: 'approval',
                additionalTemplateContext: {
                    label: contextLabel,
                    darkStyling: true
                }
            }
        };
    }


    onRender () {
        this.renderApiInputsToRegions(this.model.inputs);
    }
}
