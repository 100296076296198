import React, { forwardRef, useState, useImperativeHandle } from 'react';
import ReactModal from 'react-modal';
import CategoryView from './category';
import DeckNumberView from './deck-number';
import DeckPlanView from './deckplan';
import NoDeckPlanView from './no-deckplan';
import LegendeView, { DeckPlanLegendeType } from './legende';
import SearchView from './search';
import SearchDeckNumberView from './search-deck-number';


import * as Constants from '../../config/constants';
import app from '../../app/app';
import { FormattedMessage } from 'react-intl';

ReactModal.setAppElement('#app');
const hash = '#overlay';

type OverlayType = {
    hasBackButton: boolean;
    detail: any;
    cabinIndex: number;
    stepModelFlow: any;
    stepModelCabinCategory: any;
    stepModelCabinDeck:any;
    stepModelCabinSelection: any;
    refreshCallback: Function;
    searchSubmit: Function;
    searchNumber?: string;
};
type StepDataType = {id: string, content:{}};

const CabinNumberView = (props:OverlayType, ref:any) => {
    const { hasBackButton, cabinIndex, stepModelFlow, refreshCallback, searchSubmit, searchNumber} = props;
    let { detail, stepModelCabinCategory, stepModelCabinDeck, stepModelCabinSelection } = props;
    const [showModal, setShowModal] = useState(false);
    const [isRunning, setIsRunning] = useState(false);
    const [thisSearch, setFromThisSearch] = useState(false);
    const [searchDetails, setSearchDetails] = useState<any | null>();
    const [searchState, setSearchState] = useState(true);
    const [deckLegende, setDeckLegende] = useState<Array<{iconURL: string, name: string}> | null>();

    const thisDeckInput = stepModelCabinDeck.inputs.get(Constants.NETMATCH_INPUT_CABINDECK_DECKNUMBER);
    const thisDeckInputValue = thisDeckInput ? thisDeckInput.get('inputValue') : '';


    // richtiger scheiß konzpt design fuckup
    if (searchNumber && !thisSearch) {
        const apiSteps:any = (app.apiSession) ? app.apiSession.steps : null;
        if (apiSteps) {
            stepModelCabinCategory = apiSteps.get(`cabinCategory/cabin-${cabinIndex}`);
            const thisCabinCategoryInput = stepModelCabinCategory ? stepModelCabinCategory.inputs.get(Constants.NETMATCH_INPUT_CABINCATEGORY_CATEGORYCODE) : null;
            const thisCabinCategoryInputValue = thisCabinCategoryInput ? thisCabinCategoryInput.get('inputValue') : null;

            if (thisCabinCategoryInputValue) {
                const categoryInput = thisCabinCategoryInput.toJSON();
                detail = categoryInput && categoryInput.options ? categoryInput.options.find((item:any) => item.id === thisCabinCategoryInputValue) : null;

                const allCabin = (app.apiSession && app.apiSession.bookingStatus) ? app.apiSession.bookingStatus.getCabinData() : {};
                const cabinFromStatus = allCabin ? allCabin.find((item:any) => item.cabinIndex === cabinIndex) : null;
                if (app.contentApi && cabinFromStatus) {
                    const content = app.contentApi.cabinTypesAndCategories.getCabinCabinCategories() || [];
                    const contentCI = content.find((item:any) => item.categoryTypeCode === cabinFromStatus.cabinCategoryTypeCode);
                    console.log(contentCI);

                    if (detail && contentCI) {
                        detail.content = contentCI;
                        detail.cabinTypeName = contentCI.cabinTypeName;

                    }
                }
            }
            stepModelCabinDeck = apiSteps.get(`cabinDeck/cabin-${cabinIndex}`);
            stepModelCabinSelection = apiSteps.get(`cabinSelection/cabin-${cabinIndex}`);
        }
    }


    useImperativeHandle(ref, () => ({
        showModal: showModal,

        handleOpenModal () {
            setShowModal(true);
            // TUICIBE-310: close overlays with back button, so add hash to ensure back button leads to our page
            // @ts-ignore
            history.pushState(null, null, hash);
            window.addEventListener('popstate', handleCloseModal);
        }
    }));

    const handleCloseModal = () => {
        setShowModal(false);
        setSearchDetails(null);
        // setDeckNumber(thisDeckInputValue);
        setSearchState(true);

        // TUICIBE-310: close overlays with back button
        window.removeEventListener('popstate', handleCloseModal);
        if (document.location.hash === hash) {
            history.back();
        }
    };

    const submitStepCabinNumber = (stepsToSubmit:Array<StepDataType>, andClose = false) => {
        if (app && app.apiSession && app.apiSession.steps && stepsToSubmit) {
            if (stepsToSubmit.length === 0) {
                if (stepModelCabinDeck) {
                    const toSubmit = getDefaultInputToSubmit(stepModelCabinDeck, Constants.NETMATCH_INPUT_CABINDECK_DECKNUMBER);
                    if (toSubmit) {
                        stepsToSubmit.push(toSubmit);
                    }
                }
                if (stepModelCabinSelection) {
                    const toSubmit = getDefaultInputToSubmit(stepModelCabinSelection, Constants.NETMATCH_INPUT_CABINNUMBER_CABINNUMBER);
                    if (toSubmit) {
                        stepsToSubmit.push(toSubmit);
                    }
                }
            }

            const thisFlowInput = stepModelFlow.inputs.get(Constants.NETMATCH_INPUT_CABINFLOW);
            const thisFlowInputValue = thisFlowInput ? thisFlowInput.get('inputValue') : '';
            if (thisFlowInputValue !== Constants.NETMATCH_OPTION_CABINFLOW_CABINNUMBER || stepModelFlow.status === Constants.NETMATCH_STEP_STATUS_OPEN) {
                stepsToSubmit.push({
                    'id': stepModelFlow.get('id'),
                    'content': {
                        [Constants.NETMATCH_INPUT_CABINFLOW]: Constants.NETMATCH_OPTION_CABINFLOW_CABINNUMBER
                    }
                });
            }

            if (stepsToSubmit.length !== 0) {
                setIsRunning(true);

                app.apiSession.submitSteps(stepsToSubmit, true, false).then(() => {
                    console.log(stepsToSubmit.find((item:StepDataType) => item.content === Constants.NETMATCH_INPUT_CABINDECK_DECKNUMBER));

                    setIsRunning(false);
                    refreshCallback();
                    if (andClose) {
                        handleCloseModal();
                    }
                }).catch(() => {
                    setIsRunning(false);
                    refreshCallback();
                    // @ts-ignore Backbone Event
                    app.trigger('overlay:start', {
                        text: Constants.NM_SAVE_ERROR_TXT
                    });
                });
            } else {
                handleCloseModal();
            }
        }
    };

    const submitStepCabinNumberFromSearch = () => {
        const stepsToSubmit:Array<StepDataType> = [];
        if (searchDetails) {
            stepsToSubmit.push({
                'id': `${Constants.NETMATCH_STEP_CABINFLOW_PURE}${cabinIndex}`,
                'content': {
                    [Constants.NETMATCH_INPUT_CABINFLOW]: Constants.NETMATCH_OPTION_CABINFLOW_CABINNUMBER
                }
            });

            stepsToSubmit.push({
                'id': `cabinType/cabin-${cabinIndex}`,
                'content': {
                    [Constants.NETMATCH_INPUT_CABINTYPE_CABINTYPE]: searchDetails.cabinCategoryTypeOptionId
                }
            });

            stepsToSubmit.push({
                'id': `cabinCategory/cabin-${cabinIndex}`,
                'content': {
                    [Constants.NETMATCH_INPUT_CABINCATEGORY_CATEGORYCODE]: searchDetails.cabinCategoryCode,
                    [Constants.NETMATCH_INPUT_CABINCATEGORY_EXTENDSHORTLISTWITH]: searchDetails.number
                }
            });

            stepsToSubmit.push({
                'id': `cabinDeck/cabin-${cabinIndex}`,
                'content': {
                    [Constants.NETMATCH_INPUT_CABINDECK_DECKNUMBER]: searchDetails.deckNumber
                }
            });
            stepsToSubmit.push({
                'id': `cabinSelection/cabin-${cabinIndex}`,
                'content': {
                    [Constants.NETMATCH_INPUT_CABINNUMBER_CABINNUMBER]: searchDetails.number
                }
            });
            console.log('%c stepsToSubmit ', 'background: #ff0021; color: #000000', stepsToSubmit);
            if (app && app.apiSession && stepsToSubmit) {
                setTimeout(() => {
                    searchSubmit(stepsToSubmit);
                }, 10);
            }
        }
    };

    const SearchCallback = (resultState:{running: boolean, success: boolean, error: boolean}, result?:any) => {
        setSearchState(false);
        setFromThisSearch(true);
        if (resultState.running) {
            setIsRunning(true);
        } else {
            setIsRunning(false);
            if (resultState.error) {
                setSearchState(false);
            } else {
                const apiSteps:any = (app.apiSession) ? app.apiSession.steps : null;
                if (apiSteps) {
                    if (app.contentApi) {
                        const content = app.contentApi.cabinTypesAndCategories.getCabinCabinCategories() || [];
                        const contentCI = content.find((item:any) => item.categoryTypeCode === result.cabinCategoryTypeOptionId);
                        const tmp = result;
                        tmp.cabinTypeName = result.cabinCategoryTypeOptionName;
                        tmp.id = result.cabinCategoryCode;
                        tmp.displayText = result.cabinCategoryTypeOptionName;
                        if (contentCI) {
                            if (contentCI.cabinCategories) {
                                tmp.content = contentCI.cabinCategories.find((item:any) => item.cabinCategoryCode === result.cabinCategoryCode);
                            }
                            tmp.cabinTypeName = contentCI.cabinTypeName;
                        }
                        setSearchDetails(tmp);
                    }
                }
                setSearchState(true);
            }
        }
    };

    let backButton = null;
    if (hasBackButton) {
        backButton = (
            <div className="overlay-close-wrapper-bottom">
                <button className="button button-secondary back" onClick={handleCloseModal}>
                    <span className="icon-arrow-left"></span> <FormattedMessage id="components.cabinnumber.index.selection" />
                </button>
            </div>);
    }

    const deckPlanLegendeData = (data: Array<DeckPlanLegendeType>) => {
        if (data) {
            setDeckLegende(data);
        }
    };

    return (
        <ReactModal
            isOpen={showModal}
            className="cabin-number-wrapper"
            overlayClassName="cabin-number-overlay"
            bodyOpenClassName="noscroll"
        >
            <div className="container container-with-padding booking-page cabin-main">{isRunning}
                <div className={`spinner-container${isRunning ? ' show' : ' hidden'}`}></div>
                <h1 className="booking-page-headline">
                    <FormattedMessage id={!searchNumber ? 'components.cabinnumber.index.headline' : 'components.cabinnumber.index.available'} />
                </h1>
                <button className="overlay-close" onClick={handleCloseModal}>
                    <FormattedMessage id="components.cabinnumber.index.close" />
                </button>
                <div className="row">
                    <div className="col-lg-8 page-content">
                        <div className="cabin-main">
                            <div className="cabin-tab-choice">
                                <div className="cabin-c is-select">
                                    <span>
                                        <span><FormattedMessage id="general.cabin" /></span> {cabinIndex}
                                    </span>
                                </div>
                                <div className="cabin-c"></div>
                            </div>
                        </div>
                        <div className="cabin-part">
                            <SearchView cabinIndex={cabinIndex} searchNumber={searchNumber || ''} callback={SearchCallback}></SearchView>

                            <div className={`${searchState ? '' : 'disable'}`}>
                                <CategoryView
                                    detail={searchDetails || detail}
                                    stepModelCabinCategory={stepModelCabinCategory}></CategoryView>
                            </div>

                            <div className={`${isRunning ? 'select-running' : ''} ${searchState ? '' : 'disable'}`}>
                                {detail && !searchDetails ?
                                    <DeckNumberView
                                        stepModelCabinDeck={stepModelCabinDeck}
                                        stepModelCabinSelection={stepModelCabinSelection}
                                        callback={submitStepCabinNumber}></DeckNumberView>
                                    : ''}
                                {searchDetails ?
                                    <SearchDeckNumberView
                                        searchDetails={searchDetails}></SearchDeckNumberView>
                                    : ''}
                            </div>
                            {!searchState && !detail && !searchDetails ? <div className="search-error-space"></div> : ''}

                            <div className="cta-wrapper">
                                { backButton }
                                {isRunning ?
                                    <div className="wait"><span className="loader"></span></div>
                                    :
                                    <a className={`button ${searchState ? 'available' : 'disable'} CabinNumberOverlay`}
                                        onClick={() => {
                                            if (detail && !searchDetails) {
                                                submitStepCabinNumber([], true);
                                            } else {
                                                submitStepCabinNumberFromSearch();
                                                handleCloseModal();
                                            }
                                            if (app && app.trackController) {
                                                app.trackController.eventTracking({
                                                    action: 'Wunschkabine',
                                                    label: 'CTA_Kabine übernehmen',
                                                    ga4clickLabel: 'content.button.wunschkabine-uebernehmen'
                                                });
                                            }
                                        }} >
                                        <FormattedMessage id="components.cabinnumber.index.apply" />
                                    </a>
                                }
                            </div>
                            <div className="line"></div>
                        </div>
                        {deckLegende ? <div className="legend-pos1"> <LegendeView deckplanLegend={deckLegende} /></div> : ''}
                    </div>
                    <div className={`col-lg-4 ${searchState ? '' : 'disable'}`}>
                        {searchState ?
                            <DeckPlanView
                                cabinIndex={cabinIndex}
                                deckNumber={(searchDetails && searchDetails.deckNumber) ? searchDetails.deckNumber : thisDeckInputValue}
                                deckLegendTrigger={deckPlanLegendeData}
                                stepModelCabinSelection={stepModelCabinSelection}
                                callback={submitStepCabinNumber}
                                searchState={searchState}
                                searchDetails={searchDetails}></DeckPlanView>
                            :
                            <NoDeckPlanView></NoDeckPlanView>}

                    </div>
                    {deckLegende ? <div className="legend-pos2"> <LegendeView deckplanLegend={deckLegende} /> { backButton }</div> : ''}
                </div>
            </div>
        </ReactModal>
    );
};

export default forwardRef(CabinNumberView);


const getDefaultInputToSubmit = (stepModel:any, inputId:string) => {
    let stepsToSubmit:{id:string, content:{}} | null = null;
    const thisInput = stepModel.inputs.get(inputId);
    const thisInputValue = thisInput ? thisInput.get('inputValue') : '';
    if (stepModel.status === Constants.NETMATCH_STEP_STATUS_OPEN) {
        let value = thisInputValue;
        if (!value) {
            const options = thisInput.get('options');
            let firstOption:any | null = null;
            if (options && Array.isArray(options)) {
                options.forEach(item => {
                    // SUPTUICMRS-2834: isBlocked: false is no more set
                    if (firstOption === null && item?.isBlocked !== true) {
                        firstOption = item;
                    }
                });
            }
            value = (firstOption) ? firstOption.id : null;
        }
        if (value) {
            stepsToSubmit = {
                'id': stepModel.get('id'),
                'content': {
                    [inputId]: value
                }
            };
        }
    }
    return stepsToSubmit;
};
