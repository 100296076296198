import config from '../../config/config';
import BaseModel from '../../base/model/base';

export default class TUICHealthcheck {
    constructor () {
        this.healthcheckModel = new BaseModel();
    }

    /**
     *
     */
    getStatus (statusLevel, checkTopics) {
        this.healthcheckModel.url = config.ibeHealthcheckURL;
        return new Promise((resolve, reject) => {
            this.healthcheckModel.fetch()
                .then((item) => {
                    resolve(this.checkStatus(statusLevel, checkTopics, item));
                })
                .catch((error) => {
                    reject(console.log('healthcheck: ', error));
                });
        });
    }

    checkStatus (statusLevel, checkTopics, items) {
        let relevant = [];

        if (Array.isArray(items)) {
            relevant = items.filter(item => {
                // if api-status->topicName in config healthcheck topics array
                if (checkTopics.indexOf(item.topicName) !== -1) {
                    // if api-status->report.status in config healthcheck status array
                    if (item.report && statusLevel.indexOf(item.report.status) !== -1) {
                        item.sortIndex = checkTopics.indexOf(item.topicName);
                        return item;
                    } else {
                        return null;
                    }
                } else {
                    return null;
                }
            });
        }
        relevant.sort((a, b) => {
            return a.sortIndex - b.sortIndex;
        });

        return relevant;
    }
}
