import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function TrainChildOverlay () {
    return (
        <div className="overlay-travel-child">
            <h3 className="overlay-headline">
                <FormattedMessage id="components.overlay.train-child.headline" />
            </h3>
            <div>
                <FormattedMessage id="components.overlay.train-child.text" />
            </div>
        </div>
    );
}
