import React from 'react';
import B2BPaymentOverviewView from '../sections/b2b/payment-overview';

import app from '../../app/app';
import { FormattedMessage } from 'react-intl';

export default function PaymentTextView () {
    const isB2B: boolean = (app.apiSession && app.apiSession.bookingStatus && app.apiSession.bookingStatus.agency) ? app.apiSession.bookingStatus.agency.isB2B() : false;
    const priceModel = (app.apiSession && app.apiSession.bookingStatus) ? app.apiSession.bookingStatus.getPriceModel() : '';
    const paymentText = paymentHint(priceModel);
    const hint = cancelHint(priceModel);
    return (
        <>
            <h2 className="api-step-headline">
                <FormattedMessage id="components.overview.payment-text.headline" />
            </h2>

            {!!isB2B && (<B2BPaymentOverviewView/>)}

            {!!paymentText && (
                <div className="hint payment-hint-text help-hint js-overlay-payment-paydown">
                    <a dangerouslySetInnerHTML={{ __html: paymentText }} />
                </div>
            )}
            {!!hint.show && (
                <div className="cancel-hint-payment"> {hint.text}</div>
            )}
        </>
    );
}

const paymentHint = (type: string) => {
    const messages:any = (app.contentApi) ? app.contentApi.messages.prepareMessages('payment') : null;
    let result;

    switch (type) {
        case 'feel':
        case 'feelP':
            result = messages ? messages.feelgoodPriceHint : '';
            break;
        case 'flex':
            result = messages ? messages.flexPriceHint : '';
            break;
        case 'plus':
            result = messages ? messages.plusHint : '';
            break;
        case 'pro':
            result = messages ? messages.proHint : '';
            break;
        case 'pur':
            result = messages ? messages.purHint : '';
            break;
        default:
            result = messages ? messages.mixedHint : '';
            break;
    }
    return result;
};

/**
 *  findbooking cancel Hint Text by priceModel
**/
const cancelHint = (type :string) => {
    const trip = (app.apiSession && app.apiSession.bookingStatus && app.apiSession.bookingStatus.trip) ? app.apiSession.bookingStatus.trip.toJSON() : '';
    const value = {
        show: false,
        text: ''
    };
    if (type === 'feel' || type === 'feelP' || type === 'pro') {
        value.show = true;
    }
    if (value.show === true) {
        const texte:any = (app.contentApi) ? app.contentApi.messages.prepareMessages('meta') : '';
        value.text = texte.cancelHint;
        if (trip && trip.travelDates && trip.travelDates.from) {
            if (trip.travelDates.daysToStart < 50) {
                value.show = false;
            }
        }
    }
    return value;
};
