import BaseItem from '../../../base/view/item';
import templateWebpack from '../../../../tpl/item/insurance-selector/family-insurance.hbs';


export default class InsuranceSelectorFamilyInsuranceStepperView extends BaseItem {
    constructor (options = {}) {
        super({
            templateWebpack,
            key: 'insuranceSelectorFamily',
            ...options
        });
    }

    get className () {
        return 'insurance-selector-family-insurance';
    }

    /**
     * Add details to the data passed into the template.
     * Will be merged into the model / collection data.
     */
    templateContext () {
        return {
            index: this.options.familyCollection.indexOf(this.model) + 1
        };
    }


    events () {
        return {
            'click .js-number-input-adults > .button': (e) => {
                const insuranceSelectorModel = this.model.collection.insuranceSelectorModel;
                const $button = this.$(e.currentTarget);
                const amount = $button.hasClass('button-plus') ? 1 : -1;
                const min = this.model.get('minAdults');
                const max = Math.min(this.model.get('maxAdults'), insuranceSelectorModel.getPartyAdultCount());
                let newValue = this.model.get('adults') + amount;
                newValue = Math.max(min, Math.min(max, newValue));
                this.model.set('adults', newValue);
                insuranceSelectorModel.trigger('change');
            },
            'click .js-number-input-childs > .button': (e) => {
                const insuranceSelectorModel = this.model.collection.insuranceSelectorModel;
                const $button = this.$(e.currentTarget);
                const amount = $button.hasClass('button-plus') ? 1 : -1;
                const min = this.model.get('minChilds');
                const max = Math.min(this.model.get('maxChilds'), insuranceSelectorModel.getPartyTotalCount() - 1);
                let newValue = this.model.get('childs') + amount;
                newValue = Math.max(min, Math.min(max, newValue));
                this.model.set('childs', newValue);
                insuranceSelectorModel.trigger('change');
            }
        };
    }
}
