import _ from 'underscore';
import $ from 'jquery';
import app from '../../app/app';
import OverlayFactory from '../../item/overlay/factory';


export default {
    init (timeoutSeconds = 180) {
        let timeout;
        if (app.inactivitLayer === false || app.apiSession.bookingStatus.agency.isB2B()) {
            return;
        }
        const refresh = _.throttle(() => {
            clearTimeout(timeout);
            if (app.inactivitLayer === false) {
                return;
            }
            timeout = setTimeout(() => {
                app.trigger('overlay:start', OverlayFactory.create('inactivity'));
            }, timeoutSeconds * 1000);
        }, 100);
        $('body').on('mousemove touchstart', refresh);
        $(window).on('scroll', refresh);
        refresh();

        // TUICMA-7: CustomEvent close and deactive inactivitLayer because Cloud IQ will display a layer
        // document.dispatchEvent(new CustomEvent('ciqOverlayEvent', {detail: {active: true}}));
        // document.dispatchEvent(new CustomEvent('ciqOverlayEvent', {detail: {active: false}}));
        document.addEventListener('ciqOverlayEvent', function (e) {
            if (e.detail) {
                if (e.detail.active === true) {
                    app.inactivitLayer = false;
                    app.trigger('overlay:close');
                    refresh();
                }
                if (e.detail.active === false) {
                    app.inactivitLayer = true;
                    refresh();
                }
            }
        }, false);
    }
};
