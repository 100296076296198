import BaseCollection from '../../../../base/collection/base';
import {getIntl} from '../../../../intl';

export default class ParticipantPricesCollection extends BaseCollection {
    constructor (models = null, options = {}) {
        super(models, options);
        this.bookingStatusModel = options.bookingStatusModel;
    }

    getSalutationByIndex (index) {
        const {formatMessage} = getIntl();

        const participantModel = this.find(model => model.get('index') === index);
        if (!participantModel) {
            return `${index}. Person`;
        }
        const partyModel = this.bookingStatusModel.party;
        const participantData = participantModel.toJSON();

        const salutation = participantData.salutation || '';
        const title = participantData.title || '';
        const firstName = participantData.firstName || '';
        const lastName = participantData.lastName || '';
        let isAdult;
        if (participantData.ageCategory) {
            isAdult = participantData.ageCategory === 'Adult';
        } else {
            isAdult = this.indexOf(participantModel) < +partyModel.get('adultCount');
        }

        if (!firstName || !lastName) {
            let participantIndex;
            if (participantData.ageCategory) {
                const sameAgeParticipants = this.where({
                    ageCategory: participantData.ageCategory
                });
                participantIndex = sameAgeParticipants.indexOf(participantModel) + 1;
            } else {
                participantIndex = isAdult ? index : index - partyModel.get('adultCount');
            }
            return `${participantIndex}. ${isAdult ? formatMessage({id: 'general.adult'}) : formatMessage({id: 'general.child'})}`;
        }
        return isAdult ? `${salutation} ${title} ${firstName} ${lastName}`.replace(/ +/g, ' ') : `${firstName} ${lastName}`;
    }
}
