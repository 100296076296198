import BaseModel from '../../../base/model/base';
import contentApiConfig from '../../../config/content-api';

export default class AirlinesModel extends BaseModel {
    prepareUrlAndFetch () {
        // this.url = contentApiConfig.airlinesURL;
        this.url = contentApiConfig.i18nUrlAirlines();

        return this.fetch();
    }

    parse (response) {
        return Object.entries(response).reduce((prev, [key, curr]) => ({
            ...prev,
            [key]: {
                code: curr.code,
                name: curr.name,
                logoURL: curr.logoURL
            }
        }), {});
    }
}

/*
@TODO: Remove this later
NEW:
{
    "PR": {
        "code": "PR",
        "name": "Philippine Airlines",
        "logoURL": null
    },
    "PS": {
        "code": "PS",
        "name": "Ukraine International Airlines",
        "logoURL": null
    }
}
OLD:
{
    "PR": {
        "code": "PR",
        "name": "Philippine Airlines",
        "logoURL": null
    },
    ...
    "KM": {
        "code": "KM",
        "name": "Air Malta",
        "logoURL": "https://test.meinschiff.com/media/31/12236347865387/airmalta.svg"
    },
    ...
}
*/
