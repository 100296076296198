import BookingPage from '../../booking-page/base/page';
import PaymentPageContentView from './content';


export default class PaymentPage extends BookingPage {
    constructor (options = {}) {
        super({
            key: 'payment',
            hideBookingStatus: true,
            ...options
        });
    }


    get contentViewClass () {
        return PaymentPageContentView;
    }
}
