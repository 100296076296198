var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "cabin-tab-content";
},"3":function(container,depth0,helpers,partials,data) {
    return "personal-only-one-cabin";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"parts js-parts js-part-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":11,"column":43},"end":{"line":11,"column":53}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"first")),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":54},"end":{"line":11,"column":79}}})) != null ? stack1 : "")
    + "\">\n\n        "
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"apistep") : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":17,"column":17}}})) != null ? stack1 : "")
    + "\n        </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "show";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " \n                <div class=\"booking-page-section section-personal\">\n                    <div class=\"api-step-region-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"step") || (depth0 != null ? lookupProperty(depth0,"step") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"step","hash":{},"data":data,"loc":{"start":{"line":15,"column":48},"end":{"line":15,"column":58}}}) : helper)))
    + "\"></div>\n                </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"react-region-b2b-personal-text\"></div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"react-region-b2b-personal-text\"></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1 class=\"booking-page-headline\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.page.booking-page.content.personal.headline",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":1,"column":34},"end":{"line":1,"column":105}}})) != null ? stack1 : "")
    + "</h1>\n<div>\n    "
    + ((stack1 = alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"contentAPITexte") : depth0)) != null ? lookupProperty(stack1,"yourData") : stack1), depth0)) != null ? stack1 : "")
    + "\n</div>\n\n<div class=\"react-region-cabin-tab-view\"></div>\n\n<div class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"moreThanOne") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":8,"column":86}}})) != null ? stack1 : "")
    + "\">\n    <form>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"apiSteps") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":20,"column":13}}})) != null ? stack1 : "")
    + "    </form>\n    <div class=\"react-region-cabin-bottom-navigation\"></div>\n</div>\n<br>\n\n<form>\n    <div class=\"section-personal\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isInvoice") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":8},"end":{"line":30,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"api-step-region-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"invoiceStep") || (depth0 != null ? lookupProperty(depth0,"invoiceStep") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"invoiceStep","hash":{},"data":data,"loc":{"start":{"line":31,"column":36},"end":{"line":31,"column":53}}}) : helper)))
    + "\"></div>\n    </div>\n</form>\n\n<div class=\"ssl-hint\">"
    + ((stack1 = alias3(((stack1 = (depth0 != null ? lookupProperty(depth0,"contentAPITexte") : depth0)) != null ? lookupProperty(stack1,"privacy") : stack1), depth0)) != null ? stack1 : "")
    + "</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isB2BAgentur") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":0},"end":{"line":39,"column":7}}})) != null ? stack1 : "")
    + "\n<div class=\"react-region-b2b-cabin-detail\"></div>\n\n<div class=\"booking-pager bottom\"></div>\n<div class=\"booking-pager sticky\"></div>\n";
},"useData":true});