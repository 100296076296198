import _ from 'underscore';
import ApiStepBaseView from './base';
import app from '../../../app/app';
import templateWebpack from '../../../../tpl/item/api-step/policy-type.hbs';
import * as Constants from '../../../config/constants';
import {getIntl} from '../../../intl';


export default class PolicyTypeApiStep extends ApiStepBaseView {
    constructor (options = {}) {
        super({
            templateWebpack, ...options, filterBlockedOptions: false
        });
        // Tracking
        this.listenTo(this.model.inputs, 'change:inputValue', (changed) => {
            this.onChangeTracking(changed);
            app.trigger('change:insurance-selector', changed);
        });
    }

    /**
     * View regions.
     * Any region defined under the key of an input id will be filled with the
     * corresponding input view when calling the 'renderApiInputsToRegions' method..
     */
    regions () {
        return {
            [Constants.NETMATCH_INPUT_POLICY]: {
                el: '.api-input-policy', replaceElement: true
            }
        };
    }

    /**
     * Any object defined under the key of an input id will be passed as options
     * into the input views created when calling the 'renderApiInputsToRegions' method.
     *
     * We need a special display-type for policy assignment
     */
    apiInputViewOptions () {
        const {formatMessage} = getIntl();
        const priceLabel = this.options.priceLabel;
        return {
            [Constants.NETMATCH_INPUT_POLICY]: {
                displayType: 'policy-type',
                additionalTemplateContext: {
                    darkStyling: true,
                    label: '',
                    priceLabel,
                    nothingSubmittedOption: `${formatMessage({id: 'general.please-select'})} ...`
                }
            }
        };
    }


    onRender () {
        this.renderApiInputsToRegions(this.model.inputs);
    }


    // Tracking
    onChangeTracking (inputModelChange) {
        const selectedOption = _.findWhere(inputModelChange.get('options'), {
            id: inputModelChange.get('inputValue')
        });
        // ignore possibly set empty option 'Bitte wählen...'
        if (selectedOption) {
            const policyName = selectedOption.displayText;
            const retention = selectedOption.withRetention ? ' mit Selbstbeteiligung' : ' ohne Selbstbeteiligung';
            const coronaCoverage = selectedOption.includesCoronaCoverage ? ' + Corona Zusatzschutz' : '';

            this.trackEvent(`Empfehlung|${policyName}${retention}${coronaCoverage}`);
        }
    }

    trackEvent (label) {
        app.trackController.eventTracking({
            action: 'Versicherung', label, ga4clickLabel: `content.button.reiseversicherung-${label.replace('|', ' ')}`
        });
    }
}
