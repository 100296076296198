import $ from 'jquery';
import View from '../../../../base/view/item';
import app from '../../../../app/app';
import ContentView from './content';
import templateWebpack from '../../../../../tpl/item/modal/overlay/layout.hbs';
import {getIntl} from '../../../../intl';

const hash = '#overlay';


export default class OverlayView extends View {
    constructor (options = {}) {
        super({
            key: 'modal/overlay', templateWebpack, closeOnBgClick: true, ...options
        });

        this.listenTo(app, 'overlay:start', this.showOverlay);
        this.listenTo(app, 'overlay:close', this.hideOverlay);

        this.onPopState = () => {
            this.hideOverlay();
        };
    }

    /**
     * Getter for the views class name
     */
    get className () {
        return 'overlay-container is-hidden';
    }


    /**
     * View regions
     */
    regions () {
        return {
            content: {
                el: '.overlay-content', replaceElement: true
            }
        };
    }


    /**
     * View events
     */
    events () {
        return {
            'click .js-close': 'hideOverlay', 'click': 'clickOnBackground'
        };
    }


    showOverlay (data) {
        const {formatMessage} = getIntl();
        const headline = formatMessage({id: 'js.item.modals.overlay.view.layout.headline'});
        const text = formatMessage({id: 'js.item.modals.overlay.view.layout.text'});

        if (!data) {
            data = {
                headline, text
            };
        } else if (!data.headline) {
            data.headline = headline;
        }


        $('body').addClass('noscroll-modal');
        this.$el.removeClass('is-hidden');
        this.$el.find('.overlay').scrollTop(0);

        let view;

        if (data instanceof View) {
            view = data;
        } else {
            view = new ContentView(data);
        }

        this.showChildView('content', view);

        // TUICIBE-310: close overlays with back button, so add hash to ensure back button leads to our oage
        history.pushState(null, null, hash);
        $(window).on('popstate', this.onPopState);
    }


    hideOverlay () {
        if (typeof window.redirectTo !== 'undefined') {
            window.location.href = window.redirectTo;
        } else {
            $('body').removeClass('noscroll-modal');
            $('body').removeClass('noscroll');
            this.$el.addClass('is-hidden');
            this.getRegion('content').empty();

            // TUICIBE-310: close overlays with back button
            $(window).off('popstate', this.onPopState);
            if (document.location.hash === hash) {
                history.back();
            }
        }
    }


    clickOnBackground (e) {
        if (!this.getOption('closeOnBgClick') || $(e.target).closest('.overlay').length) {
            return;
        }
        this.hideOverlay();
    }
}

