import ApiInputBaseView from './base';
import templateWebpack from '../../../../tpl/item/api-input/text.hbs';
import * as Constants from '../../../config/constants';


export default class ApiInputEmailView extends ApiInputBaseView {
    constructor (options) {
        super({
            templateWebpack,
            ...options
        });
    }

    events () {
        const superEvents = super.events.apply(this);
        return {
            ...superEvents,
            'change input': () => {
                if (this.model.get('id') === Constants.NETMATCH_INPUT_INVOICE_EMAIL) {
                    if (this.model.collection.get(Constants.NETMATCH_INPUT_INVOICE_EMAILREPEAT).getInputValue()) {
                        this.model.collection.get(Constants.NETMATCH_INPUT_INVOICE_EMAILREPEAT).trigger('change');
                    }
                }
            }
        };
    }
}
