import React from 'react';
import { createRoot } from 'react-dom/client';
import TrainOptionsView from '../../../components/travel/train-options';

import ApiStepBaseView from './base';
import app from '../../../app/app';
import templateWebpack from '../../../../tpl/item/api-step/trainservice-options.hbs';
import * as Constants from '../../../config/constants';
import { renderReact } from '../../../react';


export default class TrainServiceOptionsContent extends ApiStepBaseView {
    constructor (options = {}) {
        super({
            templateWebpack,
            ...options
        });
        this.listenTo(this, 'step:changed', this.render);
    }

    /**
     * Getter for the views class name
     */
    get className () {
        return 'api-step-train';
    }

    onRender () {
        // react region travel train options
        if (this.$el.find('.rrt-to')[0]) {
            const container = this.$el.find('.rrt-to')[0];
            const root = createRoot(container);
            setTimeout(() => {
                renderReact(root, <TrainOptionsView
                    stepModelTrain={this.model}
                    isShipbound={this.options.isShipbound}
                    isRoundTrip={app.ibeController.ibeStorage.get(Constants.IS_ROUNDTRIP)}/>);
            }, 10);
        }
    }

    /**
     * clean ReactModalPortal div's onBeforeDestroy Pages
     */
    onBeforeDestroy () {
        const clean = document.querySelectorAll('.ReactModalPortal');
        if (clean && window.NodeList && window.NodeList.prototype.forEach) {
            clean.forEach((item) => {
                if (item.parentNode) {
                    item.parentNode.removeChild(item);
                }
            });
        }
    }
}
