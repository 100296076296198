import Backbone from 'backbone';
import _ from 'underscore';
import app from '../app/app';
import LayoutFactory from '../layout/factory';
import PageFactory from '../page/factory';


class PageController {
    constructor () {
        _.extend(this, Backbone.Events);
        this._currentLayout = null;
        this._currentPageName = null;
        this._previousPageName = null;
        this._currentPage = null;
    }

    get current () {
        return this._currentPageName;
    }

    get previous () {
        return this._previousPageName;
    }

    get currentPage () {
        return this._currentPage;
    }


    show (pageName, bookingPageModel) {
        if (this._currentPageName === pageName) {
            return true;
        }
        const page = PageFactory.create(pageName, {
            model: bookingPageModel
        });
        if (!page) {
            return false;
        }
        this._currentPage = page;
        this._previousPageName = this._currentPageName;
        this._currentPageName = pageName;

        const appLayoutType = page.getOption('appLayoutType');
        if (appLayoutType !== this._currentLayout) {
            this._appLayout = LayoutFactory.create(appLayoutType);
            app.showView(this._appLayout);
            this._currentLayout = appLayoutType;
        }

        this._appLayout.showChildView('main', page);
        // app.pageManager.show('main', page);
        return true;
    }


    showPrevious () {
        if (this._previousPageName) {
            // app.router.navigate(this._previousPageName, false);
            window.history.back();
            return true;
        }
        return false;
    }
}

export default PageController;
