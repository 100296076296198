import BookingPage from '../../booking-page/base/page';
import OverviewPageContentView from './content';

export default class OverviewPage extends BookingPage {
    constructor (options = {}) {
        super({
            key: 'overview',
            hideBookingStatus: true,
            ...options
        });
    }

    get contentViewClass () {
        return OverviewPageContentView;
    }

    onBeforeDestroy () {
        this.checkReactModalPortalDivSpam();
    }
}
