import React from 'react';
import { FormattedMessage } from 'react-intl';
import app from '../../../app/app';

/**
 * B2B personal-text
 */
export default function B2BPersonalTextView () {

    let isOperator = true;
    if (app.apiSession && app.apiSession.bookingStatus && app.apiSession.bookingStatus.agency.isAgentur()) {
        isOperator = false;
    }

    return (
        <div className={`b2b-view${isOperator ? ' is-invoice' : ''}`}>
            {isOperator ?
                <>
                    <h4 className="headline">
                        <FormattedMessage id="components.sections.b2b.personal-text.headline-operator" />
                    </h4>
                    <div className="second">
                        <FormattedMessage id="components.sections.b2b.personal-text.second-operator" />
                    </div>
                    <div>
                        <FormattedMessage id="components.sections.b2b.personal-text.text-operator" />
                    </div>
                </>
                :
                <>
                    <h4 className="headline">
                        <FormattedMessage id="components.sections.b2b.personal-text.headline-agency" />
                    </h4>
                    <div className="second">
                        <FormattedMessage id="components.sections.b2b.personal-text.second-agency" />
                    </div>
                    <div>
                        <FormattedMessage id="components.sections.b2b.personal-text.text-agency" />
                    </div>
                    <br />
                    <br />
                </>}
        </div>

    );
}

