import BaseModel from '../../../../base/model/base';
import * as Constants from '../../../../config/constants';


export default class TransportModel extends BaseModel {
    get details () {
        return {
            isHomeBound: this.isHomeBound,
            isShipBound: this.isShipBound,
            isFlight: this.isFlight,
            isTrain: this.isTrain,
            isBus: this.isBus,
            isOwn: this.isOwn
        };
    }

    get isHomeBound () {
        return this.get('direction') === Constants.HOMEBOUND;
    }

    get isShipBound () {
        return this.get('direction') === Constants.SHIPBOUND;
    }

    get isFlight () {
        return this.type === Constants.NETMATCH_TRANSPORT_OPTION_FLIGHT;
    }

    get isTrain () {
        return this.type === Constants.NETMATCH_TRANSPORT_OPTION_TRAIN;
    }

    get isBus () {
        return this.type === Constants.NETMATCH_TRANSPORT_OPTION_BUS;
    }

    get isOwn () {
        return this.type === Constants.NETMATCH_TRANSPORT_OPTION_OWN;
    }

    get type () {
        if (!this.get('type')) {
            return '';
        }
        return this.get('type').toLowerCase();
    }
}
