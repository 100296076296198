import BaseOverlayView from './base';
import templateWebpack from '../../../../tpl/item/overlay/route-map.hbs';


export default class RouteMapView extends BaseOverlayView {
    constructor (options = {}) {
        super({
            key: 'overlay-route-map',
            templateWebpack,
            ...options
        });
    }
}
