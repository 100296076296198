import Marionette from 'backbone.marionette';
import Config from '../../config/config';


export default class TransitionRegion extends Marionette.Region {
    constructor (options = {}) {
        super(options);

        this.animationOutTime = Config.transition.page.out;
        this.animationInTime = Config.transition.page.in;
    }

    // transition in (2nd)
    attachHtml (view) {
        if (this.$el && (typeof this.$el.html === 'function')) {
            this.$el.stop().fadeTo(0, 0, () => {
                this.$el.html(view.el);
                this.$el.stop().fadeTo(this.animationInTime, 1);
            });
        }
        super.attachHtml(view);
    }

    // transition out (1st)
    show (view, options) {
        if (this.currentView) {
            this.currentView.$el.stop().fadeTo(this.animationOutTime, 0, () => {
                super.show(view, options);
            });

            return;
        }

        super.show(view, options);
    }
}

