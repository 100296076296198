import app from '../../../app/app';
import BaseOverlayView from './base';
import templateWebpack from '../../../../tpl/item/overlay/payment-paydown.hbs';


export default class PaymentInvoiceOverlayView extends BaseOverlayView {
    constructor (options = {}) {
        super({
            key: 'overlay-payment-paydown',
            templateWebpack,
            ...options
        });
    }

    templateContext () {
        return {
            ...super.templateContext.apply(this),
            text: this.getPaydownInfo()
        };
    }

    getPaydownInfo () {
        const type = app.apiSession.bookingStatus.getPriceModel();
        let text;
        const messages = app.contentApi.messages.prepareMessages('payment');
        switch (type) {
            case 'feel':
            case 'feelP':
                text = messages.feelgoodPriceInfo;
                break;
            case 'flex':
                text = messages.flexPriceInfo;
                break;
            case 'plus':
                text = messages.plusInfo;
                break;
            case 'pro':
                text = messages.proInfo;
                break;
            case 'pur':
                text = messages.purInfo;
                break;
            default:
                text = messages.mixedInfo;
                break;
        }
        return text;
    }
}
