import $ from 'jquery';
import ibeApiConfig from '../../../config/ibe-api';
import Base from '../../../base/model/base';
import { ApiFetchError } from '../../../error/errors';

export default class IbanModel extends Base {
    constructor (models, options = {}) {
        super(models, options);
    }

    getBankInformation (iban) {
        this.url = ibeApiConfig.resolveBicFromDeIbanURL.replace('{iban}', iban);
        $.ajaxSetup({
            noSpinner: true
        });
        return new Promise((resolve, reject) => {
            this.fetch()
                .then(resolve)
                .catch((error) => {
                    reject(new ApiFetchError(`API Error: (${error.status}): ${error.statusText}`));
                });
        });
    }
}
