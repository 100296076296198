import React from 'react';

type Props = {
    data: {
        headlineText: string;
        contentText: string;
    }
};
export default function InsuranceCanelOverlay (props:Props) {
    const { data } = props;

    return (
        <div className="overlay-insurance-cancelation">
            <h3 className="overlay-headline">{data ? data.headlineText : ''}</h3>
            <p>{data ? data.contentText : ''}</p>
        </div>
    );
}
