import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatPrice } from '../../intl';

type PriceTotalProps = {
    totalBookingPrice: string | null;
}

/**
 * View: Gesamtpreis
 *
 * @param props PriceTotalProps
 */
export default function PriceTotalView (props:PriceTotalProps) {
    const { totalBookingPrice } = props;

    return (
        <section className="check-section">
            <div className="row">
                <div className="col-xs-12 align-righ total-price">
                    {totalBookingPrice
                        ? (
                            <FormattedMessage id="components.overview.price-total.incl" values={{
                                price: formatPrice(parseInt(totalBookingPrice))
                            }} />
                        )
                        : (
                            <FormattedMessage id="components.overview.price-total.not-available" />
                        )}
                </div>
            </div>
        </section>
    );
}

