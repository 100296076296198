// TUICUNIT-3170: Aktionscodes
import React, { useState, useRef, useEffect } from 'react';
import app from '../../app/app';
import ibeApiConfig from '../../config/ibe-api';
import { campaignCode } from '../../entities/netmatch-api/model/campaign-code';
import OverlayView from '../overlay';
import StringUtil from '../../util/string';

import * as Constants from '../../config/constants';
import { FormattedMessage, useIntl } from 'react-intl';
import { CabinPrice } from '../cabin/cabin-price';

const CampaignCodeMinLength = 3;

type MessageType = {
    headline?: string;
    text?: string;
    type?: string;
    value?: number;
};
type StatusCampaignCodeType = {
    automatic: boolean;
    name: string;
    code: string;
    type: string;
    value: number;
};

/**
 * Campaign code
 */
export default function CampaignCodeView () {
    const { formatMessage } = useIntl();
    const [state, setButtonState] = useState(false);
    const [loading, setLoadingState] = useState(false);
    const [loadingCode, setLoadingCodeState] = useState(false);
    const [input, setInput] = useState('');
    const [overlayData, setOverlayData] = useState({});
    const [existedCodes, setExistedCodes] = useState(app?.apiSession?.bookingStatus?.campaignCodes.getExistedCodes() || []);

    const mapNMcampaignCodeToYTMessageFuckUp:any = {
        [Constants.NETMATCH_CAMPAIGN_CODE_INVALID]: Constants.YOURSTRULY_CAMPAIGN_CODE_INVALID_TEXT,
        [Constants.NETMATCH_CAMPAIGN_CODE_COMPATIBLE]: Constants.YOURSTRULY_CAMPAIGN_CODE_NOT_COMPATIBLE_TEXT,
        [Constants.NETMATCH_CAMPAIGN_CODE_BORD_DEPOSITION_TYPE]: Constants.YOURSTRULY_CAMPAIGN_CODE_BORD_DEPOSIT_TEXT
    };

    // get exited codes
    const isCodes = existedCodes.length !== 0;
    const codesOnly: string[] = [];
    if (isCodes) {
        existedCodes.forEach((item: StatusCampaignCodeType) => {
            if (item.automatic === false) {
                codesOnly.push(item.code);
            }
        });
    }

    // TUICUNIT-3371: event Tracking
    const trackEvents = (lable: string) => {
        if (app.trackController) {
            app.trackController.eventTracking({
                action: 'Übersicht_CampaignCode',
                label: lable
            });
        }
    };


    const checkCode = () => {
        setLoadingState(true);
        trackEvents('CTA_Anwenden');

        // maybe an already existed Code
        const alreadyExistedCode = codesOnly.filter((item) => {
            if (item && typeof item === 'string' && input && typeof input === 'string') {
                return item === input || item.toLowerCase() === input.toLowerCase();
            } else {
                return false;
            }
        });


        if (alreadyExistedCode.length !== 0) {
            const errorContent: MessageType = app?.contentApi?.messages.prepareMessages(Constants.YOURSTRULY_CAMPAIGN_CODE_ALREADY_APPLIED_TEXT) || {};
            errorContent.type = 'error';
            setOverlayData(errorContent);
            onCampaignCodeOverlay();
            setInput('');
            setLoadingState(false);
            setButtonState(false);

            if (app.trackController && errorContent) {
                trackEvents(`Form_${codesOnly.join('|')}|${input}_error`);
                trackEvents(`Window_${errorContent.text}`);
            }
            return true;
        };

        codesOnly.push(input);

        campaignCode.set(codesOnly)?.then(() => {
            const checkResult = campaignCode.get() || null;
            if (checkResult) {
                checkResult.then((response:any) => {
                    // app.apiSession?.reload();
                    // console.log(response.inputs[0].validators);
                    setLoadingState(false);
                    const hasError = campaignCode.hasError(response.inputs) || false;
                    if (hasError) {
                        trackEvents(`Form_${codesOnly.join('|')}_error`);

                        console.log(hasError);
                        let errorContent: MessageType = app?.contentApi?.messages.prepareMessages(mapNMcampaignCodeToYTMessageFuckUp[hasError.type]) || {};
                        // if unkown Error
                        if (Object.keys(errorContent).length === 0) {
                            errorContent = app?.contentApi?.messages.prepareMessages(Constants.YOURSTRULY_CAMPAIGN_CODE_INVALID_TEXT) || {};
                        }
                        errorContent.type = 'error';

                        setOverlayData(errorContent);
                        onCampaignCodeOverlay();
                        setInput('');
                        setButtonState(false);
                        trackEvents(`Window_${errorContent.text}`);

                    } else {
                        console.log('alles fine', input);
                        trackEvents(`Form_${codesOnly.join('|')}_ok`);

                        const result = (app.apiSession && app.apiSession.bookingStatus) ? app.apiSession.bookingStatus.campaignCodes.getByValue(input) : {};

                        const usefullResult = result ? result.toJSON() : {};
                        if (usefullResult.type === Constants.NETMATCH_CAMPAIGN_CODE_BORD_DEPOSITION_TYPE) {
                            const successContent: MessageType = app?.contentApi?.messages.prepareMessages(mapNMcampaignCodeToYTMessageFuckUp[usefullResult.type]) || {};
                            successContent.type = 'ok';
                            successContent.value = usefullResult.value;

                            setOverlayData(successContent);
                            onCampaignCodeOverlay();

                            // TUICUNIT-3371
                            if (successContent && successContent.text) {
                                let trackText = successContent.text;
                                if (usefullResult.value) {
                                    const displayValue = StringUtil.formatPrice(usefullResult.value);
                                    trackText = successContent.text.replace('[Betrag]', displayValue);
                                }
                                trackEvents(`Window_${trackText}`);

                            }
                        }
                        setExistedCodes(app?.apiSession?.bookingStatus?.campaignCodes.getExistedCodes());
                        setInput('');
                        setButtonState(false);
                    }
                })
                    .catch((err) => {
                        if (app && app.ibeController && app.ibeController.ibeStatus && app.trackController) {
                            app.ibeController.ibeStatus.httpStatus = `${err.status}`;
                            app.ibeController.ibeStatus.httpStatusText = err.statusText;
                            app.ibeController.ibeStatus.apiUrl = ibeApiConfig.campaignCodeURL;
                            app.trackController.eventTracking({
                                error: '503-ibe',
                                ga4clickLabel: `content.custom.error-campain-code-url-${err.status}`
                            });
                        }
                        setLoadingState(false);
                        setInput('');
                        setButtonState(false);
                    });
            }
        });
    };

    const deleteCode = (value:string) => {
        setLoadingCodeState(true);
        const newData = codesOnly.filter((item) => {
            return item !== value;
        });

        trackEvents(`CTA_delete_${value}`);

        campaignCode.set(newData)?.then(() => {
            campaignCode.get()?.then(() => {
                setExistedCodes(app?.apiSession?.bookingStatus?.campaignCodes.getExistedCodes());
                setLoadingCodeState(false);
            });
        });
    };

    const keyEventHandler = (e:any) => {

        if (e) {
            e.stopPropagation();
        }
        const cleanValue = cleanInput(e.target.value);
        setInput(cleanValue);
        if (cleanValue.length > CampaignCodeMinLength) {
            setButtonState(true);
        } else {
            setButtonState(false);
        }

        if (e.keyCode === 13 && e.type === 'keyup' && state === true) {
            e.preventDefault();
            checkCode();
        }
    };

    const cleanInput = (targetValue: string) => {
        let value = targetValue || '';
        value = value.replace(/<script>/g, '').replace(/<\/script>/g, '').replace(/</g, '').replace(/>/g, '').replace(/&/g, '').replace(/%/g, '').replace(/#/g, '').replace(/"/g, '').replace(/\//g, '').replace(/\$/g, '');
        if (value.substring(value.length - 1) !== ' ') {
            value = value.trim();
        }
        return value;
    };

    const overlayRef = useRef();
    const onCampaignCodeOverlay = () => {
        // @ts-ignore
        if (overlayRef.current && overlayRef.current.showModal === false) {
            // @ts-ignore
            overlayRef.current.handleOpenModal();
        }
    };

    /* codesOnly.length === 0: only one code possible at the moment, conclusion from the meeting 18.01.2023 */
    //  const onlyOneCode = codesOnly.length === 1 || false;
    const onlyOneCode = false;
    return (
        <section className="check-section campaign-code-view">
            <div className="row">
                {isCodes ?
                    (
                        <CampaignCodeDetailView data={existedCodes} loadingCode={loadingCode} loading={loading} deleteCallback={deleteCode} />
                    )
                    : null}
                <div className="col-xs-12 col-sm-12 text-wrapper hidden-for-print">
                    {!onlyOneCode ?
                        <div>
                            {!isCodes ?
                                <div className="section-headline">
                                    <FormattedMessage id="components.overview.campaign-code.headline" />
                                </div>
                                :
                                <div className="section-headline smaller">
                                    <FormattedMessage id="components.overview.campaign-code.add" />
                                </div>
                            }

                            <div className="box">
                                <div className="input-group">
                                    <input className="form-element is-light"
                                        id="campaignCode"
                                        placeholder={formatMessage({
                                            id: 'components.overview.campaign-code.enter'
                                        })}
                                        type="text"
                                        value={input}
                                        onKeyPress = {keyEventHandler}
                                        onKeyUp = {keyEventHandler}
                                        onFocus = {keyEventHandler}
                                        onChange = {keyEventHandler}/>
                                    <div className="input-group-addon">
                                        {loading ?
                                            (
                                                <div className="button submit wait">
                                                    <span className="loader"></span>
                                                </div>
                                            )
                                            : (
                                                <a className={`button submit ${!state ? 'disabled' : ''}`} onClick={checkCode}>
                                                    <FormattedMessage id="components.overview.campaign-code.apply" />
                                                </a>
                                            )}

                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null}
                </div>
            </div>
            <OverlayView overlayId={'campaign-code'} closeOnBgClick={true} hasBackButton={false} data={overlayData} ref={overlayRef}></OverlayView>
        </section>
    );
}


type CampaignCodeDetailViewProps = {
    data : [StatusCampaignCodeType],
    loading: boolean;
    loadingCode: boolean;
    deleteCallback: Function
}

const CampaignCodeDetailView = (props: CampaignCodeDetailViewProps) => {
    const { data, deleteCallback, loadingCode, loading} = props;
    const [trashState, setTrashState] = useState('dummy');

    const someAreInteraction = loading || loadingCode;

    useEffect(() => {
        setTrashState('dummy');
    }, [data]);

    const onDelete = (item:string) => {
        deleteCallback(item);
    };

    return (
        <div className="col-xs-12 col-sm-12 text-wrapper">
            <div className="section-headline">
                <FormattedMessage id="components.overview.campaign-code.headline" />
            </div>
            <div>
                <FormattedMessage id="components.overview.campaign-code.info" />
            </div>
            <div className="details">
                {data.map((item: StatusCampaignCodeType, key: number) => {
                    return (
                        <div key={key} className="detail">
                            <div>
                                <div className="bold">
                                    <FormattedMessage id={
                                        item.type === Constants.NETMATCH_CAMPAIGN_CODE_BORD_DEPOSITION_TYPE ? 'components.overview.campaign-code.credit' : 'components.overview.campaign-code.action'
                                    } />
                                </div>
                                <div>{item.name}</div>
                            </div>
                            <div className="left-side">
                                <div className="item-value nobr">
                                    <CabinPrice price={item.value} mode='simple' />
                                </div>
                                {item.automatic === false ?
                                    <div>
                                        {trashState && trashState === item.code ?
                                            <div className="delete-waiting ">
                                                <span className="loader"></span>
                                            </div>
                                            :
                                            <div className={`delete${trashState !== 'dummy' || someAreInteraction ? ' disabled' : ''}`} onClick={() => { setTrashState(item.code); onDelete(item.code); } }></div>
                                        }
                                    </div>
                                    :
                                    <div className="delete disabled"></div>
                                }
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
