import BookingPage from '../../booking-page/base/page';
import CabinPageContentView from './content';


export default class CabinPage extends BookingPage {
    constructor (options = {}) {
        super({
            key: 'cabin',
            ...options
        });
    }


    get contentViewClass () {
        return CabinPageContentView;
    }

    onBeforeDestroy () {
        this.checkReactModalPortalDivSpam();
    }
}
