/* eslint-disable */
import React, { useState, useRef } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
    Navigation,Thumbs
  } from 'swiper/core';

import OverlayView from '../overlay';
import app from '../../app/app';
import { FormattedMessage } from 'react-intl';

SwiperCore.use([Navigation,Thumbs]);

export default function CabinImageGallery (props) {
    const { data } = props;
    const [overlay, setOverlay] = useState(false);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    const imageWidth = 430;
    const teaserWidth = 140;

    const prepareImages = () => {
        const images = {
            original: [],
            thumbnail: []
        };

        if (data.content.images) {
            data.content.images.forEach((item) => {
                images.original.push({
                    src: `${item.imageURL}?w=${imageWidth}`
                });
                images.thumbnail.push({
                    sizes: teaserWidth,
                    src: `${item.imageURL}?w=${teaserWidth}`
                });
            });
        }

        if (data.content.floorplanImageURLs) {
            data.content.floorplanImageURLs.forEach((item) => {
                images.original.push({
                    src: `${item}?w=${imageWidth}`
                });
                images.thumbnail.push({
                    sizes: teaserWidth,
                    src: `${item}?w=${teaserWidth}`
                });
            });
        }

        if (data.content.panoramas) {
            data.content.panoramas.forEach((item) => {
                images.original.push({
                    panorama: true,
                    src: `${item.previewImageURL}?w=${imageWidth}`
                });
                images.thumbnail.push({
                    sizes: teaserWidth,
                    src: `${item.previewImageURL}?w=${teaserWidth}`
                });
            });
        }
        return images;
    };


    const params = {
        spaceBetween: 5,
        slidesPerView: 1,
        simulateTouch: false,
        initialSlide: data?.activeIndex || 0,
        navigation: true,
        modules: [Navigation, Thumbs],
    };

    const thumbnailSwiperParams = {
        spaceBetween: 15,
        slidesPerView: 4,
        preloadImages: false,
        initialSlide:  data?.activeIndex || 0,
        breakpoints: {
            768: {
                spaceBetween: 5
            }
        },
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        modules: [Navigation, Thumbs],
    };


    const overlayRef = useRef();
    const onSetOverlay = (key) => {
        if (app && app.trackController) {
            app.trackController.eventTracking({
                action: 'Kabinenauswahl',
                label: 'Kabine-Lupe',
                ga4clickLabel: `content.icon.kabine-lupe`
            });
        }
        data.activeIndex = key;
        setOverlay(true);
        if ((overlayRef && overlayRef.current) && overlayRef.current.showModal === false) {
            overlayRef.current.handleOpenModal();
        }
    };

    const overlayPanoramaRef = useRef();
    const onSetPanoramaOverlay = (key) => {
        if (app && app.trackController) {
            app.trackController.eventTracking({
                action: 'Kabinenauswahl',
                label: 'Kabine-Panorama',
                ga4clickLabel: `content.icon.kabine-panorama`
            });
        }
        data.activeIndex = key;
        setOverlay(true);
        if ((overlayPanoramaRef && overlayPanoramaRef.current) && overlayPanoramaRef.current.showModal === false) {
            overlayPanoramaRef.current.handleOpenModal();
        }
    };


    const preparedImages = prepareImages();

    return (
        <div className="cabin-type-image-gallery">
            <div className="image-gallery-images">
                <Swiper {...params} thumbs={{ swiper: thumbsSwiper }}>
                    {preparedImages.original.map((item, key) => {
                        return (
                            <SwiperSlide key={key} className="swiper-slide">
                                <div className={`image-wrapper${item.panorama ? ' panorama' : ''}`} onClick={() => {
                                    if (item.panorama) {
                                        onSetPanoramaOverlay(key);
                                    } else {
                                        onSetOverlay(key);
                                    }
                                }}>
                                    <img alt="Kabine" src={item.src} width={imageWidth} loading='lazy'/>
                                    {item.panorama ?
                                        (
                                            <a className="button p">
                                                <FormattedMessage id="components.cabin.image-gallery-js.360" />
                                            </a>
                                        )
                                        :
                                        <a className="enlarge-button"></a>}
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
            <div className="image-gallery-thumbs">
                <Swiper {...thumbnailSwiperParams} onSwiper={setThumbsSwiper}>
                    {preparedImages.thumbnail.map((item, key) => {
                        return (
                            <SwiperSlide key={`t_${key}`} className="swiper-slide">
                                <div className="image-wrapper">
                                    <img alt="Kabine" src={item.src} width={teaserWidth} loading='lazy'/>
                                </div>
                            </SwiperSlide>
                        );
                    })}
                    <div key='t_fillup1' className="swiper-slide fillup"></div>
                    <div key='t_fillup2' className="swiper-slide fillup"></div>
                    <div key='t_fillup3' className="swiper-slide fillup"></ div>
                </Swiper>
            </div>
            <OverlayView overlayId={'cabin-image-gallery'} closeOnBgClick={true} hasBackButton={true} data={data} ref={overlayRef}></OverlayView>
            <OverlayView overlayId={'cabin-panorama'} closeOnBgClick={true} hasBackButton={false} data={data} ref={overlayPanoramaRef}></OverlayView>
        </div>
    );
}
/* eslint-enable */
