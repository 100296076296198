import app from '../../../app/app';
import BaseOverlayView from './base';
import templateWebpack from '../../../../tpl/item/overlay/inactivity.hbs';


export default class InactivityOverlayView extends BaseOverlayView {
    constructor (options = {}) {
        super({
            key: 'overlay-inactivity',
            templateWebpack,
            ...options
        });
    }

    templateContext () {
        return {
            ...super.templateContext.apply(this),
            isB2B: app.apiSession.bookingStatus.agency.isB2B()
        };
    }
}
