import _ from 'underscore';
import ApiInputRadiobuttonsView from './radiobuttons';
import templateWebpack from '../../../../tpl/item/api-input/payment-options.hbs';
import app from '../../../app/app';
import * as Constants from '../../../config/constants';
import {
    NETMATCH_INPUT_PAYMENT_CREDIT_CARD_BRAND, NETMATCH_STEP_PAYMENT_API_RESULT_CREDITCARD
} from '../../../config/constants';
import {createRoot} from 'react-dom/client';
import {renderReact} from '../../../react';
import React from 'react';
import AcknowledgedOverviewView from '../../../components/overview/acknowledged';
import {getIntl} from '../../../intl';


const TYPE_SEPA = 'Lastschrift';
const TYPE_TRANSFER = 'Überweisung';
const TYPE_MASTERCARD = 'MasterCard';
const TYPE_VISACARD = 'VisaCard';
const TYPE_AMERICANEXPRESS = 'AmericanExpress';
const TYPE_TUICARD = 'TUICard';
const TYPE_GUTEREISECARD = 'GuteReiseCard';


export default class ApiInputPaymentOptionsView extends ApiInputRadiobuttonsView {

    constructor (options) {
        super({
            templateWebpack, ...options, filterBlockedOptions: false
        });
    }


    templateContext () {
        const templateContext = super.templateContext.apply(this);
        return {
            ...templateContext, paymentTypeNote: this.getPaymentTypeNote()
        };
    }

    onRender () {

        const acknowledgedPlaceholder = this.$el.find('#sepa-acknowledged-wrapper')[0];

        if (acknowledgedPlaceholder) {
            const root = createRoot(acknowledgedPlaceholder);
            renderReact(root, <AcknowledgedOverviewView/>);
        }

        const sepaErrorPlaceholder = this.$el.find('#error-message-placeholder')[0];

        if (sepaErrorPlaceholder) {
            sepaErrorPlaceholder.innerHTML = this.getSepaErrorMessage();
        }


        // set model value based on stored value
        const selectedPayment = app.ibeController.ibeStorage.get('selectedPaymentMethod');
        const selectedPaymentId = selectedPayment ? selectedPayment.id : null;
        this.model.setInputValue(selectedPaymentId, {
            silent: true
        });

        const brandInput = this.model.apiStep.inputs.get(NETMATCH_INPUT_PAYMENT_CREDIT_CARD_BRAND);

        // TUICUNIT-4617 brand is now necessary as a value in inputs
        if (brandInput) {
            if (selectedPaymentId && selectedPaymentId === NETMATCH_STEP_PAYMENT_API_RESULT_CREDITCARD) {
                brandInput.setInputValue(selectedPayment.type, {silent: true});
            } else {
                brandInput.setInputValue(TYPE_MASTERCARD, {silent: true});
            }
        }

        // TUICUNIT-4534 preselect first option
        if (!selectedPayment) {
            const radio = this.$('input[type="radio"]');
            if (radio && radio[0]) {
                radio[0].click();
                this.onValueChange();
            }
        }
    }

    getSepaErrorMessage () {
        const isB2B = app.apiSession.bookingStatus.agency.isB2B();
        const b2bSuffix = isB2B ? '.b2b' : '.b2c';

        return app.contentApi.messages.prepareMessages('payment')['debit.credit-check-negative' + b2bSuffix];
    }

    getPaymentTypeNote () {
        const selectedPayment = app.ibeController.ibeStorage.get('selectedPaymentMethod');
        if (!selectedPayment) {
            return '';
        }
        const selectedPaymentType = selectedPayment.type;
        const contentAPITexts = app.contentApi.messages.prepareMessages('payment');

        switch (selectedPaymentType) {
            case TYPE_SEPA:
                return contentAPITexts.sepa;
            case TYPE_TRANSFER:
                return contentAPITexts.nonSepa;
            case TYPE_TUICARD:
            case TYPE_GUTEREISECARD:
                return contentAPITexts.TuiCard;
            case TYPE_MASTERCARD:
            case TYPE_VISACARD:
            case TYPE_AMERICANEXPRESS:
                return contentAPITexts.creditCard;
            default:
                return '';
        }
    }


    /**
     *
     */
    onValueChange () {
        const $checkedRadio = this.$('input[type="radio"]:checked');
        app.ibeController.ibeStorage.save('selectedPaymentMethod', {
            id: $checkedRadio.val(), type: $checkedRadio.data('type')
        });
        const brandInput = this.model.apiStep.inputs.get(NETMATCH_INPUT_PAYMENT_CREDIT_CARD_BRAND);

        if (brandInput) {
            if ($checkedRadio.val() === NETMATCH_STEP_PAYMENT_API_RESULT_CREDITCARD) {
                brandInput.setInputValue($checkedRadio.data('type'), {silent: true});
            } else {
                brandInput.setInputValue(TYPE_MASTERCARD, {silent: true});
            }
        }

        super.onValueChange.apply(this);

        const currentRoute = app.router.getCurrentFragment();
        if (currentRoute === Constants.PAGE_OVERVIEW) {
            app.trackController.eventTracking({
                action: 'Übersicht',
                label: `${$checkedRadio.data('type')}`,
                ga4clickLabel: `content.button.uebersicht-${$checkedRadio.data('type')}`
            });
        }
    }


    events () {
        const events = super.events.apply(this);
        return {
            ...events, 'click .js-css-deco': 'onClickItem'
        };
    }


    onClickItem (e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.$(e.currentTarget).hasClass('js-css-deco')) {
            this.$(e.currentTarget).parents('.js-item').click();
        }
    }


    /**
     *
     * @return {Object} The extended serialized model data
     */
    serializeData () {
        const {formatMessage} = getIntl();

        const data = super.serializeData();
        const options = data.options || [];
        const suggestedOptions = [];
        const furtherOptions = [];
        const selectedType = app.ibeController.ibeStorage.get('selectedPaymentMethod') ? app.ibeController.ibeStorage.get('selectedPaymentMethod').type : null;

        options.forEach((option) => {
            if (option.id === 'creditCard') {
                let type;
                type = TYPE_MASTERCARD;
                furtherOptions.push({
                    id: option.id,
                    position: 3,
                    displayText: 'Master&shy;Card',
                    type,
                    image: '/images/payment/logo-payment-mastercard.svg',
                    selected: type === selectedType
                });

                type = TYPE_VISACARD;
                furtherOptions.push({
                    id: option.id,
                    position: 4,
                    displayText: 'Visa&shy;Card',
                    type,
                    image: '/images/payment/logo-payment-visa.svg',
                    selected: type === selectedType
                });

                type = TYPE_AMERICANEXPRESS;
                furtherOptions.push({
                    id: option.id,
                    position: 5,
                    displayText: type,
                    type,
                    image: '/images/payment/logo-payment-amex.svg',
                    selected: type === selectedType
                });

                type = TYPE_TUICARD;
                furtherOptions.push({
                    id: option.id,
                    position: 6,
                    displayText: type,
                    type,
                    image: '/images/payment/logo-payment-tuicard.svg',
                    selected: type === selectedType
                });

                type = TYPE_GUTEREISECARD;
                furtherOptions.push({
                    id: option.id,
                    position: 7,
                    displayText: 'Gute&shy;Reise Card',
                    type,
                    image: '/images/payment/logo-payment-gutereise-card.svg',
                    selected: type === selectedType
                });
            } else {
                switch (option.id) {
                    case 'SEPA':
                        option.displayText = formatMessage({id: 'payment.type.sepa'});
                        option.type = TYPE_SEPA;
                        option.position = 1;
                        option.selected = option.type === selectedType;
                        option.image = '/images/payment/logo-payment-sepa.svg';
                        option.showAcknowledgment = true;
                        option.isBlocked = !!option.isBlocked;
                        option.isAvailable = !option.isBlocked;
                        break;
                    case 'non-SEPA':
                        option.displayText = formatMessage({id: 'payment.type.bank-transfer'});
                        option.type = TYPE_TRANSFER;
                        option.position = 2;
                        option.selected = option.type === selectedType;
                        option.image = '/images/payment/logo-payment-sepa.svg';
                        break;
                    default:
                    // nix
                }

                suggestedOptions.push(option);
            }
        });
        // console.log('######## serializeData', options, enrichedOptions);
        data.suggestedOptions = _.sortBy(suggestedOptions, 'position');
        data.furtherOptions = _.sortBy(furtherOptions, 'position');
        data.hasSuggestedOptions = suggestedOptions.length > 0;
        data.hasFurtherOptions = furtherOptions.length > 0;

        return data;
    }
}
