import wrapper from 'handlebars/runtime';

import NavigationUtil from './navigation';
import StringUtil from './string';
import * as Constants from '../config/constants';
// import { getIntl } from '../intl';
import {formatDate, formatPrice, getIntl} from '../intl';
import fecha from 'fecha/lib/fecha';

const handlebarsWrapper = wrapper.default;

// normalize path
handlebarsWrapper.registerHelper('normalizePath', (path) => NavigationUtil.normalizePath(path));

// find a path by route
handlebarsWrapper.registerHelper('getPath', (key) => NavigationUtil.getPath(key));

// format a given date
handlebarsWrapper.registerHelper('formatDate', (date, format) => formatDate(date, format));

// format a given fecha date
handlebarsWrapper.registerHelper('fechaDate', (date, format) => {
    if (typeof date === 'string') {
        return fecha.format(fecha.parse(date, 'YYYY-MM-DD'), format);
    }
    return fecha.format(date, format);
});

// format a given date
handlebarsWrapper.registerHelper('getConstants', (key) => Constants[key]);

// format a given date-string
handlebarsWrapper.registerHelper('formatDateString', (dateString, format) => formatDate(new Date(dateString), format));

// format a given price
handlebarsWrapper.registerHelper('formatPrice', (price) => formatPrice(price));

// url encoding of a string
handlebarsWrapper.registerHelper('urlencode', (string) => encodeURIComponent(string));

// simple addition
handlebarsWrapper.registerHelper('add', (value, summand) => value + summand);

// format string to lowercase
handlebarsWrapper.registerHelper('lowercase', (string) => StringUtil.lowercase(string));

// translate
/*
    e.g.
    {{{ formatMessage 'key' foo='bar' }}}

    or with function:
    {{{ formatMessage 'key' foo='bar' a='#fnc#<a href="xyz">$chunk$</a>' }}}
    */
handlebarsWrapper.registerHelper('formatMessage', (...options) => {
    const [id, formatMessageParams] = options;
    const values = {
        ...formatMessageParams.hash
    };

    // Special case for replacing e.g. anchor-links
    // #fnc#<a href="xyz">$chunk$</a> => (chunks) => `<a href="xyz">${chunks}</a>`
    if (Object.keys(values)) {
        for (const key in values) {
            const value = values[key];
            if (String(value).startsWith('#fnc#')) {
                values[key] = (chunks) => {
                    return value.replace('#fnc#', '').replace('$chunk$', chunks);
                };
            }
        }
    }

    return getIntl().formatMessage({
        id
    }, values);
});

// price
// handlebarsWrapper.registerHelper('price', (price) => formatPrice(price));

export default handlebarsWrapper;
