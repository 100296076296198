// TUICUNIT-3170: Aktionscodes
import BaseCollection from '../../../../base/collection/base';

export default class CampaignCodesModel extends BaseCollection {
    constructor (models = null, options = {}) {
        super(models, options);
        this.bookingStatusModel = options.bookingStatusModel;
    }

    getByValue (value) {
        // console.log(value);
        const findInput = this.find(model => model.get('code') === value || this.valueToLowerCase(model.get('code')) === this.valueToLowerCase(value));
        return findInput;
    }

    valueToLowerCase (value) {
        const checkValue = `${value}`;
        return checkValue.toLowerCase();
    }

    // API result z.B. -> campaignCodes: [{name: "Board deposit", code: "C2"}]
    /* new campaignCodes: [{
            automatic: false,
            code: "C2",
            name: "Board deposit campaign",
            type: "BoardDeposit",
            value: 400
    */
    getExistedCodes () {
        return this.models.map(model => {
            return ({
                automatic: model.get('automatic'),
                code: model.get('code'),
                name: model.get('name'),
                type: model.get('type'),
                value: model.get('value')
            });
        });
    }

    hasEnteredCode () {
        const allCodes = this.getExistedCodes() || [];
        return allCodes.some(item => item.automatic === false);
    }

    getAllCodesOnly () {
        const allCodes = this.getExistedCodes() || [];
        const codesOnly = allCodes.map(item => {
            return item.code;
        });
        return codesOnly.join(',');
    }
}
