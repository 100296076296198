import BookingPage from '../../booking-page/base/page';
import InsurancePageContentView from './content';


export default class InsurancePage extends BookingPage {
    constructor (options = {}) {
        super({
            key: 'insurance',
            ...options
        });
    }


    get contentViewClass () {
        return InsurancePageContentView;
    }

    onBeforeDestroy () {
        this.checkReactModalPortalDivSpam();
    }
}

