import React, { forwardRef, useState, useImperativeHandle } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactModal from 'react-modal';
import app from '../../app/app';
import { getById } from './detail';

ReactModal.setAppElement('#app');

const hash = '#overlay';

type OverlayType = {
    overlayId: string,
    closeOnBgClick: boolean,
    hasBackButton: boolean,
    data: Object | null,
    ref: any
};


const Overlay = (props: OverlayType, ref:any) => {
    const { overlayId, closeOnBgClick, hasBackButton, data } = props;
    const [showModal, setShowModal] = useState(false);

    useImperativeHandle(ref, () => ({
        showModal: showModal,

        handleOpenModal () {
            setShowModal(true);
            // TUICIBE-310: close overlays with back button, so add hash to ensure back button leads to our page
            // @ts-ignore
            history.pushState(null, null, hash);
            window.addEventListener('popstate', handleCloseModal);
        }
    }));

    const handleCloseModal = () => {
        setShowModal(false);
        // TUICIBE-310: close overlays with back button
        window.removeEventListener('popstate', handleCloseModal);
        if (document.location.hash === hash) {
            history.back();
        }
    };

    const backButtonText = () => {
        let backButtonText;

        try {
            // @ts-ignore
            const pageModel = (app.pageController && app.pageController.currentPage) ? app.pageController.currentPage.model : {};
            // @ts-ignore
            backButtonText = pageModel.get('button-back-title')
                ? (
                    <FormattedMessage id="components.overlay.index.back-title" values={{
                        title: pageModel.get('button-back-title')
                    }} />
                )
                : (
                    <FormattedMessage id="components.overlay.index.back-page-title" values={{
                        title: pageModel.get('page-title')
                    }} />
                );
        } catch (e) {
            backButtonText = <FormattedMessage id="components.overlay.index.back" />;
        }

        return backButtonText;
    };


    let backButton = null;
    if (hasBackButton) {
        backButton = (
            <div className="overlay-close-wrapper-bottom">
                <button className="button button-secondary" onClick={handleCloseModal}>
                    <span className="icon-arrow-left"></span> {backButtonText()}
                </button>
            </div>);
    }

    return (
        <ReactModal
            isOpen={showModal}
            className="overlay-container"
            onRequestClose={handleCloseModal}
            overlayClassName="overlay-generell"
            bodyOpenClassName="noscroll"
            contentLabel={overlayId}
            portalClassName={`ReactModalPortal ${overlayId}`}
        >
            <div className="click-wrapper" onClick={() => {
                if (closeOnBgClick) {
                    handleCloseModal();
                }
            }}></div>
            <div className="container container-with-padding">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="overlay">
                            <button className="overlay-close" onClick={handleCloseModal}></button>
                            {getById(overlayId, data, handleCloseModal)}
                            {backButton}
                        </div>
                    </div>
                </div>
            </div>
        </ReactModal>
    );
};

export default forwardRef(Overlay);
