import React, { useState, useRef } from 'react';
import CabinNumberView from '../cabinnumber';
import app from '../../app/app';
import * as Constants from '../../config/constants';
import { FormattedMessage, useIntl } from 'react-intl';

export default function CabinSearchView (props:{cabinIndex:number, searchSubmit: Function}) {
    const { formatMessage } = useIntl();
    const { cabinIndex, searchSubmit } = props;
    const [state, setButtonState] = useState(false);
    const [input, setInput] = useState('');
    const contentAPITexte:{whishcabinIntro?:string, whishcabinInputheadline?:string} = (app.contentApi && app.contentApi.messages) ? app.contentApi.messages.prepareMessages('desiredCabin') : {};

    //  const shipName = (app.apiSession && app.apiSession.bookingStatus && app.apiSession.bookingStatus.trip) ? app.apiSession.bookingStatus.trip.get('name') : null;
    // console.log(shipName);

    const apiSteps:any = (app.apiSession) ? app.apiSession.steps : null;
    const stepModelFlow = apiSteps.get(`${Constants.NETMATCH_STEP_CABINFLOW_PURE}${cabinIndex}`);
    const stepModelCabinCategory = apiSteps.get(`cabinCategory/cabin-${cabinIndex}`);
    const stepModelCabinDeck = apiSteps.get(`cabinDeck/cabin-${cabinIndex}`);
    const stepModelCabinSelection = apiSteps.get(`cabinSelection/cabin-${cabinIndex}`);


    const keyEventHandler = (e:any) => {
        if (e) {
            e.stopPropagation();
        }
        if (e.target.value.length > 3) {
            const value = parseInt(e.target.value, 10);
            if (value && value > 2000) {
                setInput(value.toString());
                setButtonState(true);
            }
        } else {
            setButtonState(false);
        }

        if (e.keyCode === 13 && e.type === 'keyup') {
            e.preventDefault();
            goToCheckCabinAvailability();
        }
    };

    const goToCheckCabinAvailability = () => {
        const value = parseInt(input, 10);
        if (value && value > 2000) {
            onCabinNumberOverlay();
            if (app && app.trackController) {
                app.trackController.eventTracking({
                    action: 'Kabinenwahl_Kabinennummer',
                    label: `${value}_Prüfen`,
                    ga4clickLabel: 'content.button.kabine-pruefen'
                });
            }
        }
    };

    const cabinNumberRef = useRef();
    const onCabinNumberOverlay = () => {
        // @ts-ignore
        if (cabinNumberRef.current && cabinNumberRef.current.showModal === false) {
            // @ts-ignore
            cabinNumberRef.current.handleOpenModal();
        }
    };

    return (
        <div className="cabin-search">
            <h2 className="api-step-headline">
                <FormattedMessage id="components.cabin.cabin-search.headline" />
            </h2>
            <div className="search-wrapper">
                {contentAPITexte.whishcabinIntro ? <div dangerouslySetInnerHTML={{__html: contentAPITexte.whishcabinIntro}}></div> : ''}
                <div className="box">
                    <label htmlFor="CabinNumberValidate" className="cs-h">{contentAPITexte.whishcabinInputheadline}</label>
                    <div className="input-group">
                        <input className="form-element is-light"
                            id="CabinNumberValidate"
                            placeholder={formatMessage({
                                id: 'components.cabin.cabin-search.placeholder'
                            })}
                            type="number"
                            min="1"
                            onKeyPress = {keyEventHandler}
                            onKeyUp = {keyEventHandler}
                            onFocus = {keyEventHandler}
                            pattern="\d*"/>
                        <div className="input-group-addon">
                            <a className={`button ${!state ? 'disabled' : ''}`} onClick={goToCheckCabinAvailability}>
                                <FormattedMessage id="general.check" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <CabinNumberView
                hasBackButton={true}
                ref={cabinNumberRef}
                cabinIndex={cabinIndex}
                detail={null}
                stepModelFlow={stepModelFlow}
                stepModelCabinCategory= {stepModelCabinCategory}
                stepModelCabinDeck={stepModelCabinDeck}
                stepModelCabinSelection={stepModelCabinSelection}
                refreshCallback={searchSubmit}
                searchSubmit={searchSubmit}
                searchNumber={input}></CabinNumberView>
        </div>
    );
}
