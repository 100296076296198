import ApiInputTextView from './text';
import templateWebpack from '../../../../tpl/item/api-input/checkbox.hbs';


export default class ApiInputCheckboxView extends ApiInputTextView {
    constructor (options) {
        super({
            templateWebpack,
            ...options
        });
    }
}

