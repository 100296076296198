/* eslint-disable */
import React, {useState} from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {
    Navigation, Thumbs
} from 'swiper/core';
import {getIntl} from '../../intl';


SwiperCore.use([Navigation, Thumbs]);
export default function CabinImageOverlay(props) {
    const {formatMessage} = getIntl();
    const {data} = props;
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const imageWidth = 950;
    const teaserWidth = 230;

    const prepareImages = () => {

        const images = {
            original: [], thumbnail: []
        };

        data.content.images.forEach((item) => {
            images.original.push({
                src: `${item.imageURL}?w=${imageWidth}`
            });
            images.thumbnail.push({
                sizes: teaserWidth, src: `${item.imageURL}?w=${teaserWidth}`
            });
        });

        data.content.floorplanImageURLs.forEach((item) => {
            images.original.push({
                src: `${item}?w=${imageWidth}`
            });
            images.thumbnail.push({
                sizes: teaserWidth, src: `${item}?w=${teaserWidth}`
            });
        });

        return images;
    };

    const params = {
        spaceBetween: 5,
        slidesPerView: 1,
        simulateTouch: false,
        preloadImages: false,
        initialSlide: data?.activeIndex || 0,
        navigation: true,
        lazy: {
            loadPrevNext: true
        }
    };

    const thumbnailSwiperParams = {
        spaceBetween: 15,
        slidesPerView: 4,
        preloadImages: false,
        initialSlide: data?.activeIndex || 0,
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,

    };


    const preparedImages = prepareImages();
    return (<div className="swiper-overlay overlay-cabin-type-image-gallery">
        <h3 className="overlay-headline">{formatMessage({id: 'components.cabin.images-of'})} {data.content.cabinTypeName}</h3>
        <Swiper className="swiper-wrapper" {...params} thumbs={{swiper: thumbsSwiper}}>
            {preparedImages.original.map((item, key) => {
                return (<SwiperSlide key={key} className="swiper-slide">
                    <div className="image-wrapper">
                        <img alt="Kabine" src={item.src} width={imageWidth} loading='lazy'/>
                    </div>
                </SwiperSlide>);
            })}
        </Swiper>
        <div className="swiper-wrapper-thumbnail">
            <Swiper {...thumbnailSwiperParams} onSwiper={setThumbsSwiper}>
                {preparedImages.thumbnail.map((item, key) => {
                    return (<SwiperSlide key={`t_${key}`} className='swiper-slide'>
                        <div className="image-wrapper">
                            <img alt="Kabine" src={item.src} width={teaserWidth} loading='lazy'/>
                        </div>
                    </SwiperSlide>);
                })}
                <div key='t_fillup1' className="swiper-slide fillup"></div>
                <div key='t_fillup2' className="swiper-slide fillup"></div>
                <div key='t_fillup3' className="swiper-slide fillup"></div>
            </Swiper>
        </div>
    </div>);
}
/* eslint-enable */
