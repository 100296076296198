import ApiInputBaseView from './base';
import templateWebpack from '../../../../tpl/item/api-input/text.hbs';


export default class ApiInputTextView extends ApiInputBaseView {
    constructor (options = {}) {
        super({
            templateWebpack,
            ...options
        });
    }

    events () {
        const superEvents = super.events.apply(this);
        return {
            ...superEvents,
            [`keyup #${this.model.domId}`]: 'cleanText'
        };
    }

    cleanText (e) {
        const $input = this.$(e.currentTarget);
        let value = $input.val() || '';
        value = value.replace(/<script>/g, '').replace(/<\/script>/g, '').replace(/</g, '').replace(/>/g, '').replace(/&/g, '').replace(/%/g, '').replace(/#/g, '').replace(/"/g, '').replace(/\//g, '').replace(/\$/g, '');
        if (value.substr(value.length - 1) !== ' ') {
            value = value.trim();
        }
        $input.val(value);
    }

    templateContext () {
        const templateContext = super.templateContext.apply(this);

        return {
            ...templateContext,
            inputType: templateContext.inputType ? templateContext.inputType : 'text',
            inputPattern: templateContext.inputTypeAs === 'number' ? '[0-9]*' : '' // SUPTUICMRS-2516: Firefox eliminates a zero at prefix for the input type=number
        };
    }
}
