import './interface';
import React from 'react';
import {FormattedMessage} from 'react-intl';

type PremiumProps = {
    moodImage: string,
    isB2B: boolean,
}


/**
 * View: Premium: now Mein Schiff ® Inklusivleistungen
 * TUICUNIT-2678
 * @param props PremiumProps
 */
export default function PremiumView (props: PremiumProps) {
    const {moodImage, isB2B} = props;

    return (
        <section className="check-section hidden-for-print">
            <div className="row">
                {!isB2B ?
                    <div className="col-xs-12 col-sm-4 ">
                        <div className="check-image">
                            <img alt="Inklusivleistungen" className="hidden-xs-down" src={moodImage} width="500" loading="lazy"/>
                        </div>
                    </div>
                    : ''}
                <div className="col-xs-12 col-sm-8 text-wrappe premium-headline">
                    <div className="section-headline">
                        <i className="bold"><FormattedMessage id="general.mein-schiff"/></i> <sup>&reg;</sup> <FormattedMessage id="components.overview.premium.headline-sub"/>
                    </div>
                    <ul className="premium">
                        <li><span className="icon icon-genuss"></span><span className="text"><i><FormattedMessage id="general.mein-schiff"/></i> <sup>&reg;</sup> <FormattedMessage id="components.overview.premium.genussvielfalt"/></span></li>
                        <li><span className="icon icon-zeitplan"></span><span className="text"><FormattedMessage id="components.overview.premium.geniessen"/></span></li>
                        <li><span className="icon icon-getraenke"></span><span className="text"><FormattedMessage id="components.overview.premium.markengetraenke"/></span></li>
                        <li><span className="icon icon-kaffee"></span><span className="text"><FormattedMessage id="components.overview.premium.kaffee"/></span></li>
                        <li><span className="icon icon-eis"></span><span className="text"><FormattedMessage id="components.overview.premium.eis"/></span></li>
                        <li><span className="icon icon-allergiker"></span><span className="text"><FormattedMessage id="components.overview.premium.allergiker"/></span></li>
                        <li><span className="icon icon-espresso"></span><span className="text"><FormattedMessage id="components.overview.premium.espressomaschine"/></span></li>
                        <li><span className="icon icon-kabinenservice"></span><span className="text"><FormattedMessage id="components.overview.premium.kabinenservice"/></span></li>
                        <li><span className="icon icon-kofferservice"></span><span className="text"><FormattedMessage id="components.overview.premium.kofferservice"/></span></li>
                        <li><span className="icon icon-spa"></span><span className="text"><FormattedMessage id="components.overview.premium.spa"/></span></li>
                        <li><span className="icon icon-sport"></span><span className="text"><FormattedMessage id="components.overview.premium.sport"/></span></li>
                        <li><span className="icon icon-programm"></span><span className="text"><FormattedMessage id="components.overview.premium.programm"/></span></li>
                        <li><span className="icon icon-entertainment"></span><span className="text"><FormattedMessage id="components.overview.premium.entertainment"/></span></li>
                        <li><span className="icon icon-kinderbetreuung"></span><span className="text"><FormattedMessage id="components.overview.premium.kinderbetreuung"/></span></li>
                        <li><span className="icon icon-trinkgeld"></span><span className="text"><FormattedMessage id="components.overview.premium.trinkgeld"/></span></li>
                    </ul>
                </div>
            </div>
        </section>
    );
}
