/* eslint-disable */
import React from 'react';
//import Swiper from 'react-id-swiper';
import StringUtil from '../../util/string';

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
    Pagination, Navigation
  } from 'swiper/core';
import { CabinPrice } from '../cabin/cabin-price';

SwiperCore.use([Pagination]);
const params = {
    spaceBetween: 19,
    slidesPerView: 3.5,
    breakpoints: {
        768: {
            slidesPerView: 1.75
        },
        318: {
            slidesPerView: 1.25,
            spaceBetween: 0,
        }
    },
    simulateTouch: true,
    loop: false,
    centeredSlides: true,
    roundLengths: true,
    navigation: true,
    pagination: {
        clickable: true
    },
    initialSlide: 0
};
export default function InsuranceCarousel (props) {
    const travelCancelation = props.insurances.findIndex((item) => item.isTypeTravelCancelation === true);
    params.initialSlide = travelCancelation;

    return (
        <div className="insurance-carousel swiper-inited">
            <Swiper className="swiper-wrapper" {...params}>
                {props.insurances.map((item, key) => {
                    const price = (item.price) ? (<span className="price"><CabinPrice price={item.price} mode="simple" decimal={0} /></span>) : ('');
                    const retention = (!item.isTypeBasic && item.retention) ? (item.retention.displayText): ('');

                    return (
                        <SwiperSlide key={`${key}`} className="swiper-slide">
                            <div className={`insurance-item ${(item.badge) ? ('badge') : ('')}`}>
                                <div>{item.badge}</div>
                                <div className="headline" dangerouslySetInnerHTML={{__html: item.name}}></div>
                                <p className="bold">
                                    {price}
                                    {item.membersTypeLabel}
                                    <br/>
                                    {retention}
                                </p>
                                <ul className="checkmark-list">
                                    {item.descriptionList.map((decsItem, descKey) => {
                                        return (
                                            <li key={`${descKey}`} className={`${(!decsItem.active) ? ('unchecked') : ('')}`}>{decsItem.name}</li>
                                        );
                                    })}
                                </ul>
                                <a className="button button-secondary js-button-details" href={item.pdfURLnew } data-type={item.typeId} target="_blank" rel="noopener noreferrer" >
                                    Details
                                    <span className="icon-small-arrow-right"></span>
                                </a>
                            </div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    );
}
/* eslint-enable */
