import React, {useState} from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
    count: number;
    isFromOut: number;
    callback: Function;
}

export default function CabinBottomNavigationView (props:Props) {
    const { count, isFromOut, callback } = props;
    const [select, setSelect] = useState(isFromOut);

    if (isFromOut !== select) {
        setSelect(isFromOut);
    }

    const cabinNavigationView = (count:number) => {
        const part = [];
        for (let i = 1; i <= count; i++) {
            // Create the parent and add the children
            if (select === i) {
                const decreas = i - 1;
                const increas = i + 1;

                part.push(
                    <div key={i} className="c-navigation">
                        {(i !== 1)
                            ? (
                                <div className="c-n-right link" onClick={() => {
                                    setSelect(decreas);
                                    callback(decreas);
                                    window.scrollTo(100, 0);
                                }}>
                                    <span className="icon-small-arrow-left" /><FormattedMessage id="components.sections.cabin-bottom-nav.to-cabin" /> {decreas}
                                </div>
                            )
                            : (
                                <div />
                            )}
                        {(i !== count)
                            ? (
                                <div className="c-n-left link" onClick={() => {
                                    setSelect(increas);
                                    window.scrollTo(100, 0);
                                    callback(increas);
                                }}>
                                    <FormattedMessage id="components.sections.cabin-bottom-nav.to-cabin" /> {increas}<span className="icon-small-arrow-right" />
                                </div>
                            )
                            : ''}
                    </div>
                );
            }
        }
        return part;
    };

    return (
        <div className="cabin-main">
            <div className="c-bottom-navigation">
                {cabinNavigationView(count)}
            </div>
        </div>
    );

}

