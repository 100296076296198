import ApiStepBaseView from './base';
import app from '../../../app/app';
import templateWebpack from '../../../../tpl/item/api-step/bank-transfer.hbs';
import * as Constants from '../../../config/constants';


export default class PaymentBankTransferApiStep extends ApiStepBaseView {
    constructor (options = {}) {
        super({
            templateWebpack,
            ...options
        });
    }


    regions () {
        return {
            [Constants.NETMATCH_INPUT_PAYMENT_BANK_TRANFER_ACKNOWLEDGED]: {
                el: '.api-input-Acknowledged',
                replaceElement: true
            }
        };
    }

    apiInputViewOptions () {
        return {
            [Constants.NETMATCH_INPUT_PAYMENT_BANK_TRANFER_ACKNOWLEDGED]: {
                additionalTemplateContext: {
                    hidden: true
                }
            }
        };
    }

    /**
     * Add details to the data passed into the template.
     * Will be merged into the model / collection data.
     */
    templateContext () {
        const templateContext = super.templateContext.apply(this);
        return {
            ...templateContext,
            contentAPITexte: app.contentApi.messages.prepareMessages('payment'),
            label: Constants.NETMATCH_INPUT_PAYMENT_BANK_TRANFER_ACKNOWLEDGED
        };
    }

    onRender () {
        this.renderApiInputsToRegions(this.model.inputs);
        if (this.apiInputViews[Constants.NETMATCH_INPUT_PAYMENT_BANK_TRANFER_ACKNOWLEDGED]) {
            this.apiInputViews[Constants.NETMATCH_INPUT_PAYMENT_BANK_TRANFER_ACKNOWLEDGED].model.setInputValue(true);
        }
    }
}
