import $ from 'jquery';
import app from '../../../app/app';
import ibeApiConfig from '../../../config/ibe-api';
import Base from '../../../base/model/base';
import { ApiFetchError } from '../../../error/errors';

export default class CabinSearchModel extends Base {
    constructor (models, options = {}) {
        super(models, options);
    }

    checkNumber (index, number) {
        this.url = ibeApiConfig.cabinSearchURL.replace('{apiSession}', app.apiSession.get('id')).replace('{index}', index).replace('{number}', number);
        $.ajaxSetup({
            noSpinner: true
        });
        return new Promise((resolve, reject) => {
            this.fetch()
                .then(resolve)
                .catch((error) => {
                    reject(new ApiFetchError(`API Error: (${error.status}): ${error.statusText}`));
                });
        });
    }
}
