
import $ from 'jquery';
import _ from 'underscore';
import app from '../../../app/app';
import BaseItem from '../../../base/view/item';
import BaseModel from '../../../base/model/base';
import OverlayFactory from '../../../item/overlay/factory';
import tplStatus from '../../../../tpl/item/booking-status/layout.hbs';
import tplStatusBar from '../../../../tpl/item/booking-status-bar/layout.hbs';
import * as Constants from '../../../config/constants';
import { getIntl } from '../../../intl';


export default class BookingStatusView extends BaseItem {
    constructor (options = {}) {
        let templateWebpack = tplStatus;
        if (options.key && options.key === 'booking-status-bar') {
            templateWebpack = tplStatusBar;
        }

        super({
            key: 'booking-status',
            templateWebpack,
            className: 'booking-status',
            ...options
        });

        this.listenTo(app, 'stepsData:sync', this.render);
        // this.listenTo(app.apiSession.steps, 'sync', this.render);

        // this.listenTo(app, 'scroll-event:status-bar', this.onScroll);
        this.listenTo(app, 'trip:has:change:need:update', () => {
            this.render();
        });

        // TUICIBE-229: handle iOS scrolling issue (see touch-event handlers)
        this._touchStartPosY = null;

        this.onPopState = () => {
            // TUICIBE-432 if booking-status open and browser back
            if ($('body').hasClass('booking-status-bar-open')) {
                $('body').find('.js-close-booking-status').trigger('click');
            }
            $('body').removeClass('booking-status-bar-open');
        };

        this.fixedStatusSideBarStore = {
            triggerScroll: null
        };
    }

    get modelEvents () {
        return {
            'change': 'render'
        };
    }

    onRender () {
        this.fixedStatusSideBarStore = {
            triggerScroll: null
        };
    }

    /**
     * View events
     */
    events () {
        return {
            'click .js-toggle-booking-status': (e) => {
                e.preventDefault();
                this.$el.scrollTop(0);
                // TUICIBE-329 gefriemel
                if ($('body').hasClass('booking-status-bar-open') && $('body').hasClass('js-handel-top')) {
                    $('body').removeClass('js-handel-top navigation-fixed booking-status-bar-animated navigation-collapsed');
                } else if (this.$el.css('position') === 'absolute') {
                    $('body').addClass('js-handel-top navigation-fixed booking-status-bar-animated navigation-collapsed');
                }
                setTimeout(() => {
                    const hash = '#booking-status';
                    if ($('body').hasClass('booking-status-bar-open')) {
                        // TUICIBE-310: close overlays with back button
                        $(window).off('popstate', this.onPopState);
                        if (document.location.hash === hash) {
                            history.back();
                        }
                    } else {
                        history.pushState(null, null, hash);
                        $(window).on('popstate', this.onPopState);
                    }
                    $('body').toggleClass('booking-status-bar-open');
                }, 10);
            },
            'click .js-close-booking-status': (e) => {
                e.preventDefault();
                // TUICIBE-329 gefriemel
                if ($('body').hasClass('js-handel-top')) {
                    $('body').removeClass('js-handel-top navigation-fixed booking-status-bar-animated navigation-collapsed');
                }
                this.$el.scrollTop(0);
                $('body').removeClass('booking-status-bar-open');
            },
            // TUICIBE-229: handle iOS scrolling issue
            'touchstart': (e) => {
                // detect single touch
                if (e.targetTouches.length === 1) {
                    this._touchStartPosY = e.targetTouches[0].clientY;
                }
            },
            'click .js-route-map-overlay': 'openRouteMapImageOverlay',
            // TUICIBE-229: handle iOS scrolling issue
            'touchmove': (e) => {
                // detect single touch and handle position:fixed issue if applicable
                if (e.targetTouches.length === 1 && this.$el.css('position') === 'fixed') {
                    const clientY = e.targetTouches[0].clientY - this._touchStartPosY;
                    const overlayEl = this.$el.get(0); // get DOM node
                    if (overlayEl.scrollTop === 0 && clientY > 0) {
                        // element is at the top of its scroll
                        e.preventDefault();
                    }

                    // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight#Problems_and_solutions
                    const isTotallyScrolled = overlayEl.scrollHeight - overlayEl.scrollTop <= overlayEl.clientHeight;

                    if (isTotallyScrolled && clientY < 0) {
                        // element is at the top of its scroll
                        e.preventDefault();
                    }
                }
            },

            'click .js-premium-button': this.onPremiumList,
            'click .js-cabin-button': this.onCabinList,
            'click .js-sidebar-tracking': (e) => {
                const label = e.currentTarget.dataset.totrack;
                if (label) {
                    this.eventTracking(`sidebar.custom.${label}`);
                }
            }
        };
    }


    serializeData () {
        const data = this.model.toJSON();

        // defaults that may be overwritten
        data.isRoundTrip = app.ibeController.ibeStorage.get(Constants.IS_ROUNDTRIP);

        data.showSectionCabin = !_.isEmpty(data.cabin);
        /* data.showSectionDiscounts = false;
        if (!_.isEmpty(data.participantPrices)) {
            _.each(data.participantPrices, (item) => {
                _.each(item.prices, (price) => {
                    if (data.showSectionDiscounts === false) {
                        data.showSectionDiscounts = !_.isEmpty(price.discounts);
                    }
                    item.hasDiscounts = !_.isEmpty(price.discounts);
                });
            });
        } */
        if (data.shipBoundTransport && data.shipBoundTransport.type) {
            data.shipBoundTransport.flag = this.transportTypeFlag(data.shipBoundTransport.type);
        }
        if (data.homeBoundTransport && data.homeBoundTransport.type) {
            data.homeBoundTransport.flag = this.transportTypeFlag(data.homeBoundTransport.type);
        }
        data.showSectionTransport = app.bookingPages.get(Constants.PAGE_TRAVEL).isDone();
        data.showSectionInsurances = app.bookingPages.get(Constants.PAGE_INSURANCE).isDone();

        const currentRoute = app.router.getCurrentFragment();

        data.showSectionPartyEdit = (currentRoute !== Constants.PAGE_CABIN);
        data.showSectionCabinEdit = (currentRoute !== Constants.PAGE_CABIN);
        data.showSectionTransportEdit = (currentRoute !== Constants.PAGE_TRAVEL);
        data.showSectionInsurancesEdit = (currentRoute !== Constants.PAGE_INSURANCE);

        data.section = {};
        data.section.travel = this.travelHeadline(data.shipBoundTransport.type, data.homeBoundTransport.type);
        data.section.insurances = this.prepareInsuranceInformations(data.insurances);

        data.section.bookingHint = this.bookingHint(data.cabin, data.trip);
        data.section.cancelHint = this.cancelHint(data.trip);
        // priceIndex -> kabine

        return {
            ...data,
            ...this.options.additionalTemplateContext,
            webpAddOn: (app.canWebp) ? '&format=webp' : ''
        };
    }

    transportTypeFlag (type) {
        const flag = {
            isTrain: false,
            isBus: false,
            isFlight: false,
            isOwn: false
        };

        switch (type) {
            case Constants.NETMATCH_SESSION_TRANSPORT_TYPE_TRAIN:
                flag.isTrain = true;
                break;
            case Constants.NETMATCH_SESSION_TRANSPORT_TYPE_BUS:
                flag.isBus = true;
                break;
            case Constants.NETMATCH_SESSION_TRANSPORT_TYPE_FLIGHT:
                flag.isFlight = true;
                break;
            case Constants.NETMATCH_SESSION_TRANSPORT_TYPE_OWN:
                flag.isOwn = true;
                break;
            default:break;
        }

        return flag;
    }

    templateContext () {
        return {
            isB2B: app.apiSession.bookingStatus.agency.isB2B()
        };
    }

    /**
     *  find Headline by shipBoundType and homeBoundType
     **/
    travelHeadline (shipBoundType, homeBoundType) {
        let value = '';
        const { formatMessage } = getIntl();
        if (shipBoundType === homeBoundType) {
            switch (shipBoundType) {
                case Constants.NETMATCH_SESSION_TRANSPORT_TYPE_FLIGHT:
                    value = formatMessage({
                        id: 'js.item.booking-status.view.layout.from-to.flight'
                    });
                    break;
                case Constants.NETMATCH_SESSION_TRANSPORT_TYPE_TRAIN:
                    value = formatMessage({
                        id: 'js.item.booking-status.view.layout.from-to.train'
                    });
                    break;
                case Constants.NETMATCH_SESSION_TRANSPORT_TYPE_BUS:
                    value = formatMessage({
                        id: 'js.item.booking-status.view.layout.from-to.bus'
                    });
                    break;
                case Constants.NETMATCH_SESSION_TRANSPORT_TYPE_OWN:
                    value = formatMessage({
                        id: 'js.item.booking-status.view.layout.from-to.own'
                    });
                    break;
            }
        } else {
            switch (shipBoundType) {
                case Constants.NETMATCH_SESSION_TRANSPORT_TYPE_FLIGHT:
                    value = formatMessage({
                        id: 'js.item.booking-status.view.layout.to.flight'
                    });
                    break;
                case Constants.NETMATCH_SESSION_TRANSPORT_TYPE_TRAIN:
                    value = formatMessage({
                        id: 'js.item.booking-status.view.layout.to.train'
                    });
                    break;
                case Constants.NETMATCH_SESSION_TRANSPORT_TYPE_BUS:
                    value = formatMessage({
                        id: 'js.item.booking-status.view.layout.to.bus'
                    });
                    break;
                case Constants.NETMATCH_SESSION_TRANSPORT_TYPE_OWN:
                    value = formatMessage({
                        id: 'js.item.booking-status.view.layout.to.own'
                    });
                    break;
            }
            switch (homeBoundType) {
                case Constants.NETMATCH_SESSION_TRANSPORT_TYPE_FLIGHT:
                    value = `${value}<br>${formatMessage({
                        id: 'js.item.booking-status.view.layout.from.flight'
                    })}`;
                    break;
                case Constants.NETMATCH_SESSION_TRANSPORT_TYPE_TRAIN:
                    value = `${value}<br>${formatMessage({
                        id: 'js.item.booking-status.view.layout.from.train'
                    })}`;
                    break;
                case Constants.NETMATCH_SESSION_TRANSPORT_TYPE_BUS:
                    value = `${value}<br>${formatMessage({
                        id: 'js.item.booking-status.view.layout.from.bus'
                    })}`;
                    break;
                case Constants.NETMATCH_SESSION_TRANSPORT_TYPE_OWN:
                    value = `${value}<br>${formatMessage({
                        id: 'js.item.booking-status.view.layout.from.own'
                    })}`;
                    break;
            }
        }
        return value;
    }

    /**
     *  group Insurances
     **/
    prepareInsuranceInformations (raw = {}) {
        const groupName = _.groupBy(raw, 'insurance');
        const result = [];
        Object.keys(groupName).forEach((index) => {
            if (groupName[index][0].memberType !== Constants.NETMATCH_SESSION_INSURANCE_MEMBERSTYPE_DUMMY) {
                result.push({
                    insurance: groupName[index][0].insurance,
                    count: groupName[index].length
                });
            }
        });

        return result;
    }

    /**
     *  filter, group, participantPrices Discounts
     **/
    prepareDiscountInformations (raw = {}) {
        // prepared participantPrices list to flat list with necessary discount data
        const filter = raw.reduce((list, item) => {
            item.prices.forEach((all) => {
                if (all.discounts.length !== 0) {
                    all.discounts.forEach((part) => {
                        list.push({
                            category: part.name,
                            price: part.discount
                        });
                    });
                }
            });
            return list;
        }, []);

        // group list by category
        const groupByCategory = _.groupBy(filter, 'category');

        const result = [];
        Object.keys(groupByCategory).forEach((index) => {
            result.push({
                category: groupByCategory[index][0].category,
                price: groupByCategory[index][0].price,
                count: groupByCategory[index].length
            });
        });

        return result;
    }

    /**
     *  findbooking Hint Text by priceModel
     **/
    bookingHint (cabin = {}, trip = {}) {
        const value = {
            show: false,
            isPro: false,
            isPlus: false,
            isPur: false
        };
        if (cabin && cabin[0] && cabin[0].priceModel && cabin[0].priceModel !== null) {
            value.show = true;
        }
        const type = app.apiSession.bookingStatus.getPriceModel();
        switch (type) {
            case 'feel':
            case 'feelP':
            case 'pro':
                value.isPro = true;
                break;
            case 'flex':
            case 'pur':
                value.isPur = true;
                break;
            case 'plus':
                value.isPlus = true;
                break;
        }
        if (trip && trip.travelDates && trip.travelDates.from) {
            if (trip.travelDates.daysToStart < 30) {
                value.show = false;
            }
        }
        return value;
    }

    /**
     *  findbooking cancel Hint Text by priceModel
     **/
    cancelHint (trip = {}) {
        const value = {
            show: false,
            text: ''
        };
        const type = app.apiSession.bookingStatus.getPriceModel();
        if (type === 'feel' || type === 'feelP' || type === 'pro') {
            value.show = true;
        }
        if (value.show === true) {
            value.text = (app.contentApi.messages.prepareMessages('meta')) ? app.contentApi.messages.prepareMessages('meta').cancelHint : '';
            if (trip && trip.travelDates && trip.travelDates.from) {
                if (trip.travelDates.daysToStart < 50) {
                    value.show = false;
                }
            }
        }
        return value;
    }

    openRouteMapImageOverlay () {
        const data = this.serializeData();

        this.eventTracking('sidebar.custom.route');
        OverlayFactory.show('route-map', {
            model: new BaseModel({
                tripName: data.trip.tripName,
                routeMapURL: data.trip.routeMapURL
            })
        });
    }


    /**
     * CabinList Click eventHandler
     **/
    onCabinList (e) {
        const arrowEL = this.$(e.currentTarget);
        const textBlocks = this.$el.find(`.js-cabin-list[data-cabin='${e.currentTarget.dataset.cabin}']`);
        let trackingState = 'öffnen';
        arrowEL.toggleClass('open');

        if (textBlocks.hasClass('show')) {
            textBlocks.removeClass('show').css({display: 'none'});
            trackingState = 'schließen';
        } else {
            textBlocks.fadeIn('fast', () => {
                textBlocks.addClass('show');
            });
        }
        this.eventTracking(`sidebar.custom.buchungsdetails-${e.currentTarget.dataset.cabin}-${trackingState}`);
    }


    /**
     * PremiumList Click eventHandler
     **/
    onPremiumList (e) {
        const textBlocks = this.$el.find('.js-premium-list');
        const arrowEL = this.$(e.currentTarget);
        arrowEL.toggleClass('open');

        if (textBlocks.hasClass('show')) {
            textBlocks.removeClass('show').css({display: 'none'});
        } else {
            textBlocks.fadeIn('fast', () => {
                textBlocks.addClass('show');
            });
        }
        this.eventTracking('sidebar.custom.inklusivleistungen');
    }

    eventTracking (labe) {
        if (app.trackController) {
            app.trackController.eventTracking({
                ga4clickLabel: labe
            });
        }
    }

}
