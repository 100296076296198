import app from '../../app/app';
import InactivityOverlayView from './view/inactivity';

import TravelChildView from './view/travel-child';
import TravelFlightOverlayView from './view/travel-flight';
import TravelFlightWithoutDetailsOverlayView from './view/travel-flight-without-details';
import TravleFlightTrainOverlayView from './view/travel-flight-train';
import RouteMapOverlayView from './view/route-map';
import PaymentPaydownOverlayView from './view/payment-paydown';
import PaymentInvoiceOverlayView from './view/payment-invoice';
import PaymentIbanOverlayView from './view/payment-iban';

const create = (overlayId, ...args) => {
    let OverlayViewClass;
    switch (overlayId) {
        case 'inactivity':
            OverlayViewClass = InactivityOverlayView;
            break;
        case 'travel-child':
            OverlayViewClass = TravelChildView;
            break;
        case 'travel-flight':
            OverlayViewClass = TravelFlightOverlayView;
            break;
        case 'travel-flight-without-details':
            OverlayViewClass = TravelFlightWithoutDetailsOverlayView;
            break;
        case 'travel-flight-train':
            OverlayViewClass = TravleFlightTrainOverlayView;
            break;
        case 'route-map':
            OverlayViewClass = RouteMapOverlayView;
            break;
        case 'payment-paydown':
            OverlayViewClass = PaymentPaydownOverlayView;
            break;
        case 'payment-invoice':
            OverlayViewClass = PaymentInvoiceOverlayView;
            break;
        case 'payment-iban':
            OverlayViewClass = PaymentIbanOverlayView;
            break;
        default:
            console.warn(`Unknown overlay "${overlayId}"`);
            return null;
    }
    return new OverlayViewClass(...args);
};


const show = (overlayId, ...args) => {
    const overlay = create(overlayId, ...args);
    if (overlay) {
        app.trigger('overlay:start', overlay);
    }
};

export default {
    create,
    show
};
