import {TravelBound} from './interface';
import React, {useRef} from 'react';
import app from '../../app/app';
import CampaingCodeExitView from '../overlay/campaign-code-exit';

import StringUtil from '../../util/string';
import * as Constants from '../../config/constants';
import {FormattedMessage, IntlShape, useIntl} from 'react-intl';
import {formatDate} from '../../intl';

type TravelDetailsType = {
    headline: string;
    details: string;
    isOwn: boolean;
    hasAdditionalDetails: boolean;
    departureDate: Date | null;
    departureTime: Date | null;
    class: string | null;
    additionalDetails: string | null;
}

type TravelInfoProps = {
    shipBound: TravelBound,
    homeBound: TravelBound,
    moodImage: string,
    isB2B: boolean,
    hideEdit?: boolean
}

/**
 * View: Ihre An- und Abreise
 *
 * @param props TravelInfoType
 */
export default function TravelInfoView (props: TravelInfoProps) {
    const {formatMessage} = useIntl();
    const {shipBound, homeBound, moodImage, isB2B, hideEdit} = props;

    const {headline, details} = prepareTravel(shipBound, homeBound, formatMessage);

    const trackEvent = () => {
        if (app.trackController) {
            app.trackController.eventTracking({
                action: 'Übersicht',
                label: 'An- und Abreise',
                ga4clickLabel: 'content.icon.uebersicht-an-abreise'
            });
        }
        if (app.router) {
            if (app?.apiSession?.bookingStatus?.campaignCodes.hasEnteredCode()) {
                onCampaignCodeExitOverlay();
            } else {
                app.router.redirect('travel', true, false);
            }
        }
        return false;
    };

    // TUICUNIT-3170
    const gotToTarget = () => {
        if (app.router) {
            app.router.redirect('travel', true, false);
        }
    };

    const overlayRef = useRef();
    const onCampaignCodeExitOverlay = () => {
        // @ts-ignore
        if (overlayRef.current && overlayRef.current.showModal === false) {
            // @ts-ignore
            overlayRef.current.show();
        }
    };

    const confirmData = {
        confirmCallback: gotToTarget,
        closeOnBgClick: true
    };

    return (
        <section className="check-section">
            <div className="row">
                {!isB2B ?
                    <div className="col-sm-4 hidden-xs-down">
                        <div className="check-image">
                            <img alt={formatMessage({
                                id: 'components.overview.travel.imgFallback'
                            })} src={moodImage} width="500" loading="lazy"/>
                        </div>
                    </div>
                    : ''}
                <div className="col-xs-12 col-sm-8 text-wrapper">
                    <div className="section-headline">
                        <FormattedMessage id="components.overview.travel.headline" values={{
                            headline
                        }}/>
                    </div>
                    {details.map((item: TravelDetailsType, key: number) => {
                        return (
                            <span key={key}>
                                <div className="bold">{item.headline}</div>
                                {item.details}
                                {!item.isOwn ?
                                    <>
                                        {StringUtil.formatDate(item.departureDate, 'germanDateWithDay')}
                                        {item.departureTime ?
                                            (
                                                <>
                                                    <FormattedMessage id="general.time" values={{
                                                        time: formatDate(item.departureTime, 'shortTime')
                                                    }}/>
                                                </>
                                            )
                                            : <br/>}
                                    </>
                                    : ''}
                                {item.class}<br/>
                                {!!item.hasAdditionalDetails && <>{item.additionalDetails}<br/></>}
                                {key === 0 && !item.isOwn && <br/>}
                            </span>
                        );
                    })}
                </div>
            </div>
            <span className={`button button-secondary edit-link ${hideEdit ? 'hidden' : ''}`} aria-label={formatMessage({
                id: 'components.overview.travel.back'
            })} onClick={trackEvent}>
                <span className="icon-ic-edit"></span>
            </span>
            <CampaingCodeExitView data={confirmData} ref={overlayRef}></CampaingCodeExitView>
        </section>
    );
}


/**
 * collects Travel Date, general information, healine and details
 **/
const prepareTravel = (shipBound: TravelBound, homeBound: TravelBound, formatMessage: IntlShape['formatMessage']) => {
    const headline = travelHeadline(shipBound.type, homeBound.type, formatMessage);
    const details = travelDetails(shipBound, homeBound, formatMessage);

    return {headline, details};
};

/**
 *  find Headline by shipBoundType and homeBoundType
 **/
const travelHeadline = (shipBoundType: string, homeBoundType: string, formatMessage: IntlShape['formatMessage']) => {
    let value = '';
    if (shipBoundType === homeBoundType) {
        switch (shipBoundType) {
            case Constants.NETMATCH_SESSION_TRANSPORT_TYPE_FLIGHT:
                value = formatMessage({
                    id: 'components.overview.travel.typePlane'
                });
                break;
            case Constants.NETMATCH_SESSION_TRANSPORT_TYPE_TRAIN:
                value = formatMessage({
                    id: 'components.overview.travel.typeTrain'
                });
                break;
            case Constants.NETMATCH_SESSION_TRANSPORT_TYPE_BUS:
                value = formatMessage({
                    id: 'components.overview.travel.typeBus'
                });
                break;
            default:
                break;
        }
    }
    return value;
};

/**
 *  collects Details for shipBound aund homeBound in all different cases
 **/
const travelDetails = (shipBoundRaw: TravelBound, homeBoundRaw: TravelBound, formatMessage: IntlShape['formatMessage']) => {
    const value: TravelDetailsType[] = [
        {
            headline: formatMessage({
                id: 'components.overview.travel.travelDetailHeadline'
            }, {
                origin: shipBoundRaw.origin,
                destination: shipBoundRaw.destination
            }),
            details: '',
            isOwn: false,
            hasAdditionalDetails: false,
            departureDate: (shipBoundRaw.departureDate) ? (shipBoundRaw.departureDate) : null,
            departureTime: (shipBoundRaw.departureTime) ? (StringUtil.formatTime(shipBoundRaw.departureTime)) : null,
            class: (shipBoundRaw.class) ? (shipBoundRaw.class) : null,
            additionalDetails: (shipBoundRaw.additionalDetails) ? (shipBoundRaw.additionalDetails) : null
        },
        {
            headline: formatMessage({
                id: 'components.overview.travel.travelDetailHeadline'
            }, {
                origin: homeBoundRaw.origin,
                destination: homeBoundRaw.destination
            }),
            details: '',
            isOwn: false,
            hasAdditionalDetails: false,
            departureDate: (homeBoundRaw.departureDate) ? (homeBoundRaw.departureDate) : null,
            departureTime: (homeBoundRaw.departureTime) ? (StringUtil.formatTime(homeBoundRaw.departureTime)) : null,
            class: (homeBoundRaw.class) ? (homeBoundRaw.class) : null,
            additionalDetails: (homeBoundRaw.additionalDetails) ? (homeBoundRaw.additionalDetails) : null
        }
    ];

    if (shipBoundRaw.type === Constants.NETMATCH_SESSION_TRANSPORT_TYPE_FLIGHT) {
        value[0].headline = formatMessage({
            id: 'components.overview.travel.flightOutboundHeadline'
        }, {
            origin: shipBoundRaw.origin,
            destination: shipBoundRaw.destination
        });
        value[0].details = `${formatMessage({
            id: 'general.flight-departure'
        })} `;
        value[0].hasAdditionalDetails = true;
    }

    if (homeBoundRaw.type === Constants.NETMATCH_SESSION_TRANSPORT_TYPE_FLIGHT) {
        value[1].headline = formatMessage({
            id: 'components.overview.travel.flightReturnHeadline'
        }, {
            origin: homeBoundRaw.origin,
            destination: homeBoundRaw.destination
        });
        value[1].details = `${formatMessage({
            id: 'general.flight-departure'
        })} `;
        value[1].hasAdditionalDetails = true;
    }

    if (shipBoundRaw.type === Constants.NETMATCH_SESSION_TRANSPORT_TYPE_TRAIN) {
        value[0].headline = formatMessage({
            id: 'components.overview.travel.trainOutboundHeadline'
        }, {
            destination: shipBoundRaw.destination
        });
    }

    if (homeBoundRaw.type === Constants.NETMATCH_SESSION_TRANSPORT_TYPE_TRAIN) {
        value[1].headline = formatMessage({
            id: 'components.overview.travel.trainReturnHeadline'
        }, {
            destination: homeBoundRaw.origin
        });
    }

    if (shipBoundRaw.type === Constants.NETMATCH_SESSION_TRANSPORT_TYPE_OWN && homeBoundRaw.type === Constants.NETMATCH_SESSION_TRANSPORT_TYPE_OWN) {
        value[0].headline = formatMessage({
            id: 'components.overview.travel.ownTransportBothHeadline'
        });
        value[0].details = formatMessage({
            id: 'components.overview.travel.ownOutboundDetails'
        });
        value[0].isOwn = true;
        value[1].headline = '';
        value[1].isOwn = true;
    } else if (shipBoundRaw.type === Constants.NETMATCH_SESSION_TRANSPORT_TYPE_OWN || homeBoundRaw.type === Constants.NETMATCH_SESSION_TRANSPORT_TYPE_OWN) {
        if (shipBoundRaw.type === Constants.NETMATCH_SESSION_TRANSPORT_TYPE_OWN) {
            value[0].headline = formatMessage({
                id: 'components.overview.travel.ownTransportOnlyOutboundHeadline'
            });
            value[0].details = formatMessage({
                id: 'components.overview.travel.ownOutboundDetails'
            });
            value[0].isOwn = true;
        } else {
            value[1].headline = formatMessage({
                id: 'components.overview.travel.ownTransportOnlyReturnHeadline'
            });
            value[1].isOwn = true;
        }
    }

    return value;
};
