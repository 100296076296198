var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.api-step.confirm.headline",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":1,"column":75},"end":{"line":2,"column":48}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"confirm-info\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isB2B") : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":12},"end":{"line":17,"column":23}}})) != null ? stack1 : "")
    + "            <div class=\"\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"bookingStatus") : depth0)) != null ? lookupProperty(stack1,"bookingIsFailed") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":65,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n        <div class=\"section-documents hidden-for-print\">\n            <h3 class=\"headline\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(alias1,"tpl.item.api-step.confirm.documents.headline",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":70,"column":33},"end":{"line":70,"column":101}}})) != null ? stack1 : "")
    + "</h3>\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"contentAPI") : depth0)) != null ? lookupProperty(stack1,"meta") : stack1),{"name":"with","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":12},"end":{"line":87,"column":21}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isB2B") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.program(37, data, 0),"data":data,"loc":{"start":{"line":88,"column":12},"end":{"line":96,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"hidden-for-print\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"shipImage") : depth0)) != null ? lookupProperty(stack1,"desktopImageURL") : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":20},"end":{"line":11,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"shipImage") : depth0)) != null ? lookupProperty(stack1,"stageImageURL") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":15,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"ship-image\" style=\"background-image:url("
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"shipImage") : depth0)) != null ? lookupProperty(stack1,"rawImage") : stack1), depth0))
    + "?w=380&h=280)\">\n                        </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"ship-image small\" style=\"background-image:url("
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"shipImage") : depth0)) != null ? lookupProperty(stack1,"rawImage") : stack1), depth0))
    + "?w=768)\">\n                        </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <h3 class=\"headline\">"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isB2B") : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":41},"end":{"line":23,"column":37}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.api-step.confirm.bookingIsFailed",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":23,"column":37},"end":{"line":23,"column":102}}})) != null ? stack1 : "")
    + "</h3>\n                    <p>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.api-step.confirm.bookingIsFailed.hint",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":24,"column":23},"end":{"line":24,"column":93}}})) != null ? stack1 : "")
    + "</p>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isFemale") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":20,"column":58},"end":{"line":22,"column":80}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"invoicePerson") : depth0)) != null ? lookupProperty(stack1,"lastName") : stack1), depth0))
    + "\n                        , ";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.api-step.confirm.salutation_ms",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":20,"column":74},"end":{"line":21,"column":73}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.api-step.confirm.salutation_mr",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":21,"column":81},"end":{"line":22,"column":73}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"bookingStatus") : depth0)) != null ? lookupProperty(stack1,"bookingTimeOut") : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":20},"end":{"line":35,"column":27}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"bookingStatus") : depth0)) != null ? lookupProperty(stack1,"bookingIsRunning") : stack1),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":20},"end":{"line":44,"column":27}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"bookingStatus") : depth0)) != null ? lookupProperty(stack1,"bookingIsSuccess") : stack1),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":20},"end":{"line":64,"column":27}}})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <h3 class=\"headline\">"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isB2B") : depth0),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":45},"end":{"line":29,"column":123}}})) != null ? stack1 : "")
    + "</h3>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isB2B") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":30,"column":24},"end":{"line":34,"column":31}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isFemale") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":27,"column":62},"end":{"line":29,"column":84}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"invoicePerson") : depth0)) != null ? lookupProperty(stack1,"lastName") : stack1), depth0))
    + "!";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <p>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.api-step.confirm.bookingTimeOut.hint_sales",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":31,"column":31},"end":{"line":31,"column":106}}})) != null ? stack1 : "")
    + "</p>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <p>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.api-step.confirm.bookingTimeOut.hint_service",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":33,"column":31},"end":{"line":33,"column":108}}})) != null ? stack1 : "")
    + "</p>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <h3 class=\"headline\">"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isB2B") : depth0),{"name":"unless","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":45},"end":{"line":40,"column":123}}})) != null ? stack1 : "")
    + "</h3>\n                        <p>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.api-step.confirm.bookingIsRunning.hint",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":41,"column":27},"end":{"line":41,"column":98}}})) != null ? stack1 : "")
    + "</p>\n                        <p class=\"transaction-number wait\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.api-step.confirm.bookingIsRunning.number",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":42,"column":59},"end":{"line":43,"column":87}}})) != null ? stack1 : "")
    + "<span class=\"loader\"></span></p>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <h2 class=\"headline\">\n                            "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(alias1,"tpl.item.api-step.confirm.bookingIsSuccess.headline",{"name":"formatMessage","hash":{"ship":(depth0 != null ? lookupProperty(depth0,"ship") : depth0)},"data":data,"loc":{"start":{"line":48,"column":28},"end":{"line":48,"column":113}}})) != null ? stack1 : "")
    + "\n                        </h2>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isB2BAgentur") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":51,"column":24},"end":{"line":63,"column":31}}})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <p>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.api-step.confirm.bookingIsSuccess.b2b.text1",{"name":"formatMessage","hash":{"ship":(depth0 != null ? lookupProperty(depth0,"ship") : depth0)},"data":data,"loc":{"start":{"line":52,"column":31},"end":{"line":52,"column":117}}})) != null ? stack1 : "")
    + "</p>\n\n                            <p class=\"transaction-number\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.api-step.confirm.bookingIsSuccess.transaction-number",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":54,"column":58},"end":{"line":55,"column":103}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"bookingStatus") : depth0)) != null ? lookupProperty(stack1,"bookingNumber") : stack1), depth0))
    + "</p>\n\n                            <p>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.api-step.confirm.bookingIsSuccess.b2b.text3",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":57,"column":31},"end":{"line":57,"column":107}}})) != null ? stack1 : "")
    + "</p>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <p>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.api-step.confirm.bookingIsSuccess.text1",{"name":"formatMessage","hash":{"ship":(depth0 != null ? lookupProperty(depth0,"ship") : depth0)},"data":data,"loc":{"start":{"line":59,"column":31},"end":{"line":59,"column":113}}})) != null ? stack1 : "")
    + "</p>\n\n                            <p class=\"transaction-number\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.api-step.confirm.bookingIsSuccess.transaction-number",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":61,"column":58},"end":{"line":62,"column":103}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"bookingStatus") : depth0)) != null ? lookupProperty(stack1,"bookingNumber") : stack1), depth0))
    + "</p>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"documents\">\n                    <a class=\"pdf-text button button-secondary js-tracking\" href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"insurance") : depth0)) != null ? lookupProperty(stack1,"pdfURL") : stack1), depth0))
    + "\">\n                        <span class=\"icon icon-pdf\"></span>\n                        <span class=\"link-text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"tpl.item.api-step.confirm.documents.insurance",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":75,"column":48},"end":{"line":75,"column":117}}})) != null ? stack1 : "")
    + "</span>\n                    </a>\n                    <a class=\"pdf-text button button-secondary js-tracking\" href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"agb") : depth0)) != null ? lookupProperty(stack1,"pdfURL") : stack1), depth0))
    + "\">\n                        <span class=\"icon icon-pdf\"></span>\n                        <span class=\"link-text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"tpl.item.api-step.confirm.documents.tos",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":79,"column":48},"end":{"line":79,"column":111}}})) != null ? stack1 : "")
    + "</span>\n                    </a>\n\n                    <a class=\"pdf-text button button-secondary js-tracking\" href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"boardAbc") : depth0)) != null ? lookupProperty(stack1,"pdfURL") : stack1), depth0))
    + "\">\n                        <span class=\"icon icon-pdf\"></span>\n                        <span class=\"link-text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias4).call(alias3,"tpl.item.api-step.confirm.documents.boardAbc",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":84,"column":48},"end":{"line":84,"column":116}}})) != null ? stack1 : "")
    + "</span>\n                    </a>\n                </div>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isB2BOperator") : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.program(35, data, 0),"data":data,"loc":{"start":{"line":89,"column":16},"end":{"line":93,"column":23}}})) != null ? stack1 : "");
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.api-step.confirm.documents.b2bOperator",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":90,"column":23},"end":{"line":90,"column":94}}})) != null ? stack1 : "")
    + "</p>\n";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <p>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.api-step.confirm.documents.b2b",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":92,"column":23},"end":{"line":92,"column":86}}})) != null ? stack1 : "")
    + "</p>\n";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.api-step.confirm.documents.mailCopy",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":95,"column":19},"end":{"line":95,"column":87}}})) != null ? stack1 : "")
    + "</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1 class=\"booking-page-headline\">"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"bookingStatus") : depth0)) != null ? lookupProperty(stack1,"bookingIsFailed") : stack1),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":34},"end":{"line":2,"column":59}}})) != null ? stack1 : "")
    + "</h1>\n<div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"bookingStatus") : depth0)) != null ? lookupProperty(stack1,"bookingIsBlocked") : stack1),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":4},"end":{"line":109,"column":15}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});