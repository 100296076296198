import fecha from 'fecha/lib/fecha';
import BookingPageContent from '../../booking-page/base/content';
import app from '../../../app/app';
import StringUtil from '../../../util/string';
import templateWebpack from '../../../../tpl/page/booking-page/content/print-offer.hbs';
import * as Constants from '../../../config/constants';
import { getIntl } from '../../../intl';


export default class PrintOfferPageContent extends BookingPageContent {
    constructor (options = {}) {
        super({
            key: 'page-offer-detail-content',
            templateWebpack,
            ...options
        });
    }

    /**
     * Add details to the data passed into the template.
     * Will be merged into the model / collection data.
     */
    templateContext () {
        const cabin = app.apiSession.bookingStatus.get('cabin') || {};
        const apiTexte = app.contentApi.messages.prepareMessages('pricemodel') || {};

        let b2cTexte;
        if (cabin.priceModel === 'Wohlfühlpreis') {
            b2cTexte = apiTexte.feelgoodPrice;
        } else {
            b2cTexte = apiTexte.flexPrice;
        }

        const shipBoundTransport = app.apiSession.bookingStatus.shipBoundTransport.toJSON();
        const homeBoundTransport = app.apiSession.bookingStatus.homeBoundTransport.toJSON();
        const insurances = app.apiSession.bookingStatus.insurances.toJSON();

        return {
            trip: app.contentApi.tripInfo.toJSON(),
            hasTender: app.contentApi.tripInfo.hasTender(),
            agency: app.apiSession.bookingStatus.agency.toJSON(),
            cabin,
            partySummary: app.apiSession.bookingStatus.party.getSummary(),
            totalBookingPrice: app.apiSession.bookingStatus.get('totalBookingPrice'),
            date: fecha.format(new Date(), 'ddd, DD.MM.YYYY HH:mm'),
            isB2B: app.apiSession.bookingStatus.agency.isB2B(),
            b2cTexte,
            travel: this.prepareTravel(shipBoundTransport, homeBoundTransport),
            insurance: this.prepareInsurances(insurances)
        };
    }

    /**
     * Delay print call til page is completely faded in
     *
     * @return {[type]} [description]
     */
    onAttach () {
        setTimeout(window.print, 1000);
    }

    /**
     * collects Travel Date, general information, healine and details
     **/
    prepareTravel (shipBound, homeBound) {
        let value = {};
        if (shipBound.type && homeBound.type) {
            value.isRoundTrip = app.ibeController.ibeStorage.get(Constants.IS_ROUNDTRIP);
            value.headline = this.travelHeadline(shipBound.type, homeBound.type);
            value.details = this.travelDetails(shipBound, homeBound);
        } else {
            value = null;
        }

        return value;
    }

    /**
     *  find Headline by shipBoundType and homeBoundType
     **/
    travelHeadline (shipBoundType, homeBoundType) {
        const { formatMessage } = getIntl();
        let value = '';
        if (shipBoundType === homeBoundType) {
            switch (shipBoundType) {
                case Constants.NETMATCH_SESSION_TRANSPORT_TYPE_FLIGHT:
                    value = formatMessage({
                        id: 'components.overview.travel.typePlane'
                    });
                    break;
                case Constants.NETMATCH_SESSION_TRANSPORT_TYPE_TRAIN:
                    value = formatMessage({
                        id: 'components.overview.travel.typeTrain'
                    });
                    break;
                case Constants.NETMATCH_SESSION_TRANSPORT_TYPE_BUS:
                    value = formatMessage({
                        id: 'components.overview.travel.typeBus'
                    });
                    break;
                default:
                    break;
            }
        }
        return value;
    }

    /**
     *  collects Details for shipBound aund homeBound in all different cases
     **/
    travelDetails (shipBoundRow, homeBoundRow) {
        const { formatMessage } = getIntl();

        let value = {
            shipBound: {
                headline: formatMessage({
                    id: 'components.overview.travel.travelDetailHeadline'
                }, {
                    origin: shipBoundRow.origin,
                    destination: shipBoundRow.destination
                }),
                details: '',
                isOwn: false,
                hasAdditionalDetails: false,
                departureDate: (shipBoundRow.departureDate) ? (shipBoundRow.departureDate) : null,
                departureTime: (shipBoundRow.departureTime) ? (StringUtil.formatTime(shipBoundRow.departureTime)) : null,
                class: (shipBoundRow.class) ? (shipBoundRow.class) : null,
                additionalDetails: (shipBoundRow.additionalDetails) ? (shipBoundRow.additionalDetails) : null
            },
            homeBound: {
                headline: formatMessage({
                    id: 'components.overview.travel.travelDetailHeadlineReturn'
                }, {
                    origin: homeBoundRow.origin,
                    destination: homeBoundRow.destination
                }),
                details: '',
                isOwn: false,
                hasAdditionalDetails: false,
                departureDate: (homeBoundRow.departureDate) ? (homeBoundRow.departureDate) : null,
                departureTime: (homeBoundRow.departureTime) ? (StringUtil.formatTime(homeBoundRow.departureTime)) : null,
                class: (homeBoundRow.class) ? (homeBoundRow.class) : null,
                additionalDetails: (homeBoundRow.additionalDetails) ? (homeBoundRow.additionalDetails) : null
            }
        };


        if (shipBoundRow.type === Constants.NETMATCH_SESSION_TRANSPORT_TYPE_FLIGHT) {
            value.shipBound.headline = formatMessage({
                id: 'components.overview.travel.flightOutboundHeadline'
            }, {
                origin: shipBoundRow.origin,
                destination: shipBoundRow.destination
            });
            value.shipBound.details = formatMessage({
                id: 'general.flight-departure'
            });
            value.shipBound.hasAdditionalDetails = true;
        }

        if (homeBoundRow.type === Constants.NETMATCH_SESSION_TRANSPORT_TYPE_FLIGHT) {
            value.homeBound.headline = formatMessage({
                id: 'components.overview.travel.flightReturnHeadline'
            }, {
                origin: homeBoundRow.origin,
                destination: homeBoundRow.destination
            });
            value.homeBound.details = formatMessage({
                id: 'general.flight-departure'
            });
            value.homeBound.hasAdditionalDetails = true;
        }

        if (shipBoundRow.type === Constants.NETMATCH_SESSION_TRANSPORT_TYPE_TRAIN) {
            value.shipBound.headline = formatMessage({
                id: 'components.overview.travel.trainOutboundHeadline'
            }, {
                destination: shipBoundRow.destination
            });
        }

        if (homeBoundRow.type === Constants.NETMATCH_SESSION_TRANSPORT_TYPE_TRAIN) {
            value.homeBound.headline = formatMessage({
                id: 'components.overview.travel.trainReturnHeadline'
            }, {
                destination: homeBoundRow.origin
            });
        }

        if (shipBoundRow.type === Constants.NETMATCH_SESSION_TRANSPORT_TYPE_OWN && homeBoundRow.type === Constants.NETMATCH_SESSION_TRANSPORT_TYPE_OWN) {
            value = {
                shipBound: {
                    headline: formatMessage({
                        id: 'components.overview.travel.ownTransportBothHeadline'
                    }),
                    details: formatMessage({
                        id: 'components.overview.travel.ownOutboundDetails'
                    }),
                    isOwn: true
                },
                homeBound: {
                    headline: '',
                    isOwn: true
                }
            };
        } else if (shipBoundRow.type === Constants.NETMATCH_SESSION_TRANSPORT_TYPE_OWN || homeBoundRow.type === Constants.NETMATCH_SESSION_TRANSPORT_TYPE_OWN) {
            if (shipBoundRow.type === Constants.NETMATCH_SESSION_TRANSPORT_TYPE_OWN) {
                value.shipBound.headline = formatMessage({
                    id: 'components.overview.travel.ownTransportOnlyOutboundHeadline'
                });
                value.shipBound.details = formatMessage({
                    id: 'components.overview.travel.ownOutboundDetails'
                });
                value.shipBound.isOwn = true;
            } else {
                value.homeBound.headline = formatMessage({
                    id: 'components.overview.travel.ownTransportOnlyReturnHeadline'
                });
                value.homeBound.isOwn = true;
            }
        }
        return value;
    }


    prepareInsurances (raw = {}) {
        const value = {
            display: false,
            withoutText: ''
        };

        if (raw.length === 1 && raw[0].memberType === Constants.NETMATCH_SESSION_INSURANCE_MEMBERSTYPE_DUMMY) {
            value.display = false;
            value.withoutText = raw[0].insurance || '';
        } else {
            value.items = raw.reduce((list, item) => {
                value.display = true;
                if (item.memberType !== Constants.NETMATCH_SESSION_INSURANCE_MEMBERSTYPE_DUMMY) {
                    list.push(item);
                }
                return list;
            }, []);
        }

        return value;
    };
}
