import React, {useState} from 'react';
import PartyView from './party';
import CabinListView from './cabin-list';
import app from '../../app/app';
import viewSize from '../../util/viewport';
import * as Constants from '../../config/constants';
import {FormattedMessage} from 'react-intl';

type StepModelType = {
    get: Function;
}

type CabinPartProps = {
    stepmodels: StepModelType;
    cabinIndex: number;
    callback: Function;
}
type StepsToSubmitType = [{ id: string, content: {} }];

export default function CabinPartView (props: CabinPartProps) {
    const {stepmodels, cabinIndex} = props;
    // const [renderAfterSubmit, setRenderAfterSubmit] = useState(false);
    const [isRunning, setIsRunning] = useState(false);
    const [apiError, setAPIError] = useState(false);

    const submitStepCabin = (stepsToSubmit: StepsToSubmitType) => {
        // @ts-ignore Backbone Event
        app.trigger('scrollToFromReact', '.scroll-point-cabin');
        if (app && app.apiSession && app.apiSession.steps && stepsToSubmit) {
            setIsRunning(true);
            /* setTimeout(() => {
                // @ts-ignore Backbone Event
                app.trigger('loading:start');
            }, 10); */
            console.log('%c stepsToSubmit ', 'background: #ff0021; color: #000000', stepsToSubmit);

            app.apiSession.submitSteps(stepsToSubmit, true, false).then(() => {
                // setRenderAfterSubmit(cabinType);
                setIsRunning(false);
                setAPIError(false);
                if (viewSize.isSmall()) {
                    setTimeout(() => {
                        // @ts-ignore Backbone Event
                        app.trigger('scrollToFromReact', '.scroll-point-cabin-catergory-s');
                    }, 10);
                }
            }).catch(() => {
                setIsRunning(false);
                setAPIError(true);
                // @ts-ignore Backbone Event
                app.trigger('overlay:start', {
                    text: Constants.NM_SAVE_ERROR_TXT
                });
            });
        }
    };

    const searchSubmitStepCabin = (stepsToSubmit: StepsToSubmitType) => {
        // @ts-ignore Backbone Event
        app.trigger('scrollToFromReact', '.scroll-point-cabin');
        if (app && app.apiSession && app.apiSession.steps && stepsToSubmit) {
            setIsRunning(true);
            console.log('%c stepsToSubmit ', 'background: #ff0021; color: #000000', stepsToSubmit);
            app.apiSession.submitStepsIfYouKnowWhatYouDo(stepsToSubmit, true, false, null, false).then(() => {
                setIsRunning(false);
                setAPIError(false);
            }).catch(() => {
                setIsRunning(false);
                setAPIError(true);
                // @ts-ignore Backbone Event
                app.trigger('overlay:start', {
                    text: Constants.NM_SAVE_ERROR_TXT
                });
            });

        }
    };

    return (
        <div className="cabin-part-view">
            {stepmodels.get(`party/cabin-${cabinIndex}`) ?
                <PartyView
                    key={`p${cabinIndex}`}
                    stepModel={stepmodels.get(`party/cabin-${cabinIndex}`)}
                    callback={props.callback}/>
                : ''}
            <span className="scroll-point-cabin"></span>
            <div className={`api-running-info${isRunning ? ' in' : ' hidden'}`}>
                <div className="cabin-list">
                    <h2 className="api-step-headline expand">
                        <FormattedMessage id="components.cabin.cabin-section.headline"/>
                    </h2>
                </div>
                <div className="spinner-container"></div>
                <div className="wrapper">
                    <div className="wrapper-loader">
                        <div className="loader"></div>
                        <div className="icon-cabin-type"></div>
                    </div>
                    <FormattedMessage id="components.cabin.cabin-section.configuring"/>
                </div>
            </div>
            <div className={`api-running${isRunning ? ' out' : ''}`}>
                <div className="cabin-view">
                    {stepmodels.get(`cabinType/cabin-${cabinIndex}`) ?
                        <CabinListView
                            key={`c${cabinIndex}`}
                            stepModel={stepmodels.get(`cabinType/cabin-${cabinIndex}`)}
                            cabinIndex={cabinIndex}
                            callback={submitStepCabin}
                            apiError={apiError}
                            searchSubmit={searchSubmitStepCabin}></CabinListView>
                        : ''}
                </div>
            </div>
        </div>
    );
}
