import ApiInputChoiceBaseView from './basechoice';
import templateWebpack from '../../../../tpl/item/api-input/select.hbs';


export default class ApiInputCountrySelectView extends ApiInputChoiceBaseView {
    constructor (options) {
        super({
            templateWebpack,
            ...options
        });
    }

    /**
     *
     * @return {Object} The extended serialized model data
     */
    serializeData () {
        const data = super.serializeData();
        return data;
    }
}
