import React from 'react';
import app from '../../app/app';

export default function TrainInfoOverlay () {
    const content = app.contentApi;
    const texte:any = content ? content.messages.prepareMessages('transport') : null;

    return (
        <div className="overlay-travel-info">
            {texte.popUp.head ? <h3 className="overlay-headline">{texte.popUp.head}</h3> : ''}
            {texte.popUp.text ? <div dangerouslySetInnerHTML={{__html: texte.popUp.text}}/> : ''}
        </div>
    );
}
