import ApiInputBaseView from './base';


export default class ApiInputChoiceBaseView extends ApiInputBaseView {
    /**
     * Overwrites the Marionette method to add a "selected" property to
     * the submitted or highlighted option
     *
     */
    serializeData () {
        const data = super.serializeData();
        let options = data.options || [];

        const filterBlockedOptions = this.getOption('filterBlockedOptions') !== false;
        if (filterBlockedOptions) {
            options = options.filter(option => !option.isBlocked);
        }

        const inputValue = data.inputValue;
        options.forEach(option => {
            option.selected = (inputValue === option.id) && (filterBlockedOptions ? !option.isBlocked : true);
        });

        data.options = options;
        return data;
    }
}
