import _ from 'underscore';
import fecha from './fecha-german';
import {getLocale} from '../intl';


const formatNumber = (num) => {
    return String(num).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};


const formatPrice = (price, currency, decimalsCount) => {
    if (!currency || typeof currency !== 'string') {
        currency = '€';
    }

    if (typeof decimalsCount !== 'number' || decimalsCount < 0 || decimalsCount > 20) {
        if (String(price).indexOf('.') !== -1) {
            decimalsCount = 2;
        } else {
            decimalsCount = 0;
        }
    }

    if (_.isNumber(price)) {
        price = price.toFixed(decimalsCount).replace('.', ',');
        const commaPos = price.indexOf(',');

        if (commaPos !== -1) {
            const beforeComma = price.substring(0, commaPos);
            price = formatNumber(beforeComma) + price.substring(commaPos);
        } else {
            price = formatNumber(price);
        }
        return `${price} ${currency}`;
    }

    return price;
};


const formatDate = (date, format) => {
    let formattedDate;
    try {
        formattedDate = fecha.format(date, format);
    } catch (e) {
        console.error(e);
    }
    return formattedDate;
};


const formatTime = (time) => {
    if (!time) {
        return null;
    }
    try {
        // v1: longTime: v2:mediumTime
        return fecha.parse(time, 'mediumTime');
    } catch (e) {
        return null;
    }
};

const capitalize = (string) => {
    return (`${string}`).charAt(0).toUpperCase() + string.substring(1).toLowerCase();
};


const validateEmail = (email) => {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

const lowercase = (string) => {
    if (string && typeof string === 'string') {
        return string.toLowerCase();
    }
};

const formatNumberWithOrdinalSuffix = (numberAsString) => {
    console.log('formatNumberWithOrdinalSuffix', numberAsString);
    const language = getLocale();

    if (language === 'de') {
        return `${numberAsString}.`;
    }

    const suffixes = [
        'th',
        'st',
        'nd',
        'rd'
    ];
    const remainder = parseInt(numberAsString) % 100;

    // Handle special cases for 11th, 12th, and 13th
    if (remainder >= 11 && remainder <= 13) {
        return numberAsString + 'th';
    }

    // Determine the appropriate suffix
    const lastDigit = parseInt(numberAsString) % 10;
    const suffix = suffixes[lastDigit] || 'th';

    return numberAsString + suffix;
};


export default {
    formatNumber,
    formatPrice,
    formatDate,
    formatTime,
    capitalize,
    validateEmail,
    lowercase,
    formatNumberWithOrdinalSuffix
};
