import React from 'react';


export default function CabinPanoramOverlay (props:any) {
    const { data } = props;
    console.log(props);

    const prepareImages = () => {
        const images:any = [];

        data.content.images.forEach((item:any) => {
            images.push(item.imageURL);
        });

        data.content.floorplanImageURLs.forEach((item:any) => {
            images.push(item);
        });

        data.content.panoramas.forEach((item:any) => {
            images.push(item.panoramaURL);
        });
        return images;
    };
    const preparedImages = prepareImages();
    const parorama = preparedImages[data.activeIndex];
    return (
        <div className="overlay-cabin-panorama">
            {!!parorama && <iframe src={parorama} className="panorama-iframe" scrolling="no"></iframe>}
        </div>
    );
}
