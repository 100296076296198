import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
// import * as Constants from '../../config/constants';

type Props = {
    isB2B: boolean
};

export default function BabiesOverlay ({ isB2B }: Props) {
    const { formatMessage } = useIntl();

    return (
        <div className="overlay-babies">
            <h3 className="overlay-headline">
                <FormattedMessage id="components.overlay.babies.headline" />
            </h3>
            <p>
                <FormattedMessage id="components.overlay.babies.text1" />
            </p>
            <p>
                <FormattedMessage id="components.overlay.babies.text2" values={{
                    a: (chunks: React.ReactNode) => <a className="underline" target="_blank" href="https://www.meinschiff.com/an-und-abreise/einflug" rel="noopener noreferrer">{chunks}</a>,
                    textTeam: formatMessage({
                        id: isB2B ? 'general.team.sales' : 'general.team.service'
                    })
                }} />
            </p>
            <p>
                <FormattedMessage id="components.overlay.babies.text3" values={{
                    a: (chunks: React.ReactNode) => <a className="underline" target="_blank" href="https://www.meinschiff.com/an-bord/kreuzfahrt-mit-kindern" rel="noopener noreferrer">{chunks}</a>
                }} />
            </p>
        </div>
    );
}
