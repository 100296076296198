import View from '../../../../base/view/item';
import BaseModel from '../../../../base/model/base';
import templateWebpack from '../../../../../tpl/item/modal/overlay/content.hbs';


export default class ContentView extends View {
    constructor (options = {}) {
        super({
            key: 'modal/content',
            templateWebpack,
            model: new BaseModel(options),
            ...options
        });
    }
}

