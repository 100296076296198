import Base from '../../../base/model/base';
import ApiInputsCollection from '../collection/inputs';
import * as Constants from '../../../config/constants';


export default class ApiStepModel extends Base {
    /**
     * @see: http://backbonejs.org/#Model-idAttribute
     */
    get idAttribute () {
        return 'id';
    }


    /**
     * Getter for the status
     */
    get status () {
        return this.get('status');
    }

    /**
     *  Some steps appear multiple times with a numeric post-fix (policytype-1).
     *  This method retrieves the numeric part of the step id
     */
    // Insurance -> Participant Match
    get insuranceNumericStepId () {
        const numericId = +this.id.split('-').pop();
        return isNaN(numericId) ? null : numericId;
    }


    /**
     * Helper function to determine the state of the step
     */
    isDone () {
        return this.isCompleted() || this.isCompletedAutomatically() || this.isCompletedWithDefault() || this.isOptional() || this.isNotApplicable();
    }


    /**
     * Helper function to determine the state of the step
     */
    isOpen () {
        return this.status === Constants.NETMATCH_STEP_STATUS_OPEN;
    }


    /**
     * Helper function to determine the state of the step
     */
    isPending () {
        return this.status === Constants.NETMATCH_STEP_STATUS_PENDING;
    }


    /**
     * Helper function to determine the state of the step
     */
    isOptional () {
        return this.status === Constants.NETMATCH_STEP_STATUS_OPTIONAL;
    }


    /**
     * Helper function to determine the state of the step
     */
    isCompleted () {
        return this.status === Constants.NETMATCH_STEP_STATUS_COMPLETED;
    }


    /**
     * Helper function to determine the state of the step
     */
    isCompletedAutomatically () {
        return this.status === Constants.NETMATCH_STEP_STATUS_COMPLETED_AUTOMATICALLY;
    }


    /**
     * Helper function to determine the state of the step
     */
    isCompletedWithDefault () {
        return this.status === Constants.NETMATCH_STEP_STATUS_COMPLETED_WITH_DEFAULT;
    }


    /**
     * Helper function to determine the state of the step
     */
    isSomehowCompleted () {
        return this.isCompleted() || this.isCompletedAutomatically() || this.isCompletedWithDefault();
    }


    /**
     * Helper function to determine the state of the step
     */
    isNotApplicable () {
        return this.status === Constants.NETMATCH_STEP_STATUS_NOT_APPLICABLE;
    }


    /**
     * Helper function to determine the state of the step
     */
    isBlocked () {
        return this.status === Constants.NETMATCH_STEP_STATUS_BLOCKED;
    }


    /**
     * Helper function to determine whether the step needs to be submitted
     */
    needsToBeSubmitted () {
        return this.hasChangedInput() || this.isOpen() || this.isCompletedAutomatically() || this.isCompletedWithDefault();
    }


    /**
     * Loops through the inputs of the steps and returns an object containing all of its values
     */
    getInputData () {
        return this.inputs.reduce((data, apiInputModel) => {
            data[apiInputModel.id] = apiInputModel.getInputValue();
            return data;
        }, {});
    }


    /**
     * Loops through the inputs of the steps and returns an object containing all of its values
     */
    resetInputData () {
        this.inputs.forEach(apiInputModel => apiInputModel.resetInputValue());
    }


    /**
     * Checks all inputs whether they have valid input data
     */
    hasValidInputData () {
        return this.inputs.every(apiInputModel => !apiInputModel.hasInputError());
    }


    /**
     * Checks all inputs whether they have valid input data
     */
    hasInputErrors () {
        return !!this.inputs.some(apiInputModel => apiInputModel.hasInputError());
    }


    /**
     * Loops through the inputs of the steps and returns an object containing error messages if the input was invalid
     */
    getInputErrors () {
        return this.inputs.reduce((errors, inputModel) => {
            const error = inputModel.getInputError();
            if (error) {
                errors[inputModel.id] = error;
            }
            return errors;
        }, {});
    }


    /**
     * Checks all inputs whether they have valid submit data
     */
    hasSubmitErrors () {
        if (!this.get('hasSubmits')) {
            return false;
        }
        return !!this.inputs.some(apiInputModel => apiInputModel.hasSubmitError());
    }


    /**
     * Loops through the inputs of the steps and returns an object containing error messages if one of its validators is invald
     */
    getSubmitErrors () {
        if (!this.get('hasSubmits')) {
            return {};
        }
        return this.inputs.reduce((errors, apiInputModel) => {
            const error = apiInputModel.getSubmitError();
            if (error) {
                errors[apiInputModel.id] = error;
            }
            return errors;
        }, {});
    }


    /**
     * Checks all inputs whether at least one has changed input
     */
    hasChangedInput () {
        return this.inputs.some(apiInputModel => apiInputModel.hasChangedInput());
    }


    /**
     * Loops through the inputs of the steps and validates them with the data passed in
     */
    validateData (data) {

        const validationErrors = this.inputs.reduce((errors, input) => {
            const validation = input.validateValue(data.content[input.id]);
            if (!validation.valid) {
                errors.push({
                    stepId: this.id,
                    inputId: input.id,
                    warning: validation.error
                });
            }
            return errors;
        }, []);

        return {
            valid: !validationErrors.length,
            errors: validationErrors
        };
    }

    reNewInputData () {
        const inputsData = this.get('inputs') || [];
        this.inputs.set(inputsData, {
            parse: true
        });
    }

    /**
     * @see : http://backbonejs.org/#Model-parse
     */
    on () {
        // console.log('%c foo ', 'background: #222; color: #ffff00', this);

        // WATCH OUT
        // the parse method will be called before (!!) the constructor because
        // attributes will be passed to this model.
        // So we need to create the inputs collection here when used first
        if (!this.inputs) {
            this.inputs = new ApiInputsCollection([], {
                parentModel: this
            });
        }
        const inputsData = this.get('inputs') || [];

        // TODO 17.11.16: remove the following forEach, when the API delivers the resultValue  properly
        inputsData.forEach(input => {
            input.stepStatus = this.status;
        });

        this.inputs.set(inputsData, {
            parse: true
        });
    }
}
