/* eslint no-nested-ternary: 0 */
/* eslint no-lonely-if: 0 */
/* eslint prefer-template: 0 */

// taken from
// http://stackoverflow.com/questions/384286/javascript-isdom-how-do-you-check-if-a-javascript-object-is-a-dom-object#answer-384380
const isDOMElement = (obj) => {
    try {
        // Using W3 DOM2 (works for FF, Opera and Chrom)
        return obj instanceof HTMLElement;
    } catch (e) {
        // Browsers not supporting W3 DOM2 don't have HTMLElement and
        // an exception is thrown and we end up here. Testing some
        // properties that all elements have. (works on IE7)
        return (typeof obj === 'object') &&
            (obj.nodeType === 1) && (typeof obj.style === 'object') &&
            (typeof obj.ownerDocument === 'object');
    }
};

const deparam = (params, coerce) => {
    const obj = {};
    const coerceTypes = {
        'true': !0,
        'false': !1,
        'null': null
    };

    // Iterate over all name=value pairs.
    params.replace(/\+/g, ' ').split('&').forEach((v) => {
        const param = v.split('=');
        let key = decodeURIComponent(param[0]);
        let val;
        let cur = obj;
        let i = 0;

        // If key is more complex than 'foo', like 'a[]' or 'a[b][c]', split it
        // into its component parts.
        let keys = key.split('][');
        let keysLast = keys.length - 1;

        // If the first keys part contains [ and the last ends with ], then []
        // are correctly balanced.
        if (/\[/.test(keys[0]) && /\]$/.test(keys[keysLast])) {
            // Remove the trailing ] from the last keys part.
            keys[keysLast] = keys[keysLast].replace(/\]$/, '');

            // Split first keys part into two parts on the [ and add them back onto
            // the beginning of the keys array.
            keys = keys.shift().split('[').concat(keys);

            keysLast = keys.length - 1;
        } else {
            // Basic 'foo' style key.
            keysLast = 0;
        }

        // Are we dealing with a name=value pair, or just a name?
        if (param.length === 2) {
            val = decodeURIComponent(param[1]);

            // Coerce values.
            if (coerce) {
                val = val && !isNaN(val) && ((+val + '') === val) ?
                    +val // number
                    : val === 'undefined' ?
                        undefined // undefined
                        : coerceTypes[val] !== undefined ?
                            coerceTypes[val] // true, false, null
                            : val; // string
            }

            if (keysLast) {
                // Complex key, build deep object structure based on a few rules:
                // * The 'cur' pointer starts at the object top-level.
                // * [] = array push (n is set to array length), [n] = array if n is
                //   numeric, otherwise object.
                // * If at the last keys part, set the value.
                // * For each keys part, if the current level is undefined create an
                //   object or array based on the type of the next keys part.
                // * Move the 'cur' pointer to the next level.
                // * Rinse & repeat.
                for (; i <= keysLast; i++) {
                    key = keys[i] === '' ? cur.length : keys[i];
                    cur = cur[key] = i < keysLast ? cur[key] || (keys[i + 1] && isNaN(keys[i + 1]) ? {} : []) : val;
                }
            } else {
                // Simple key, even simpler rules, since only scalars and shallow
                // arrays are allowed.

                if (Object.prototype.toString.call(obj[key]) === '[object Array]') {
                    // val is already an array, so push on the next value.
                    obj[key].push(val);
                } else if ({}.hasOwnProperty.call(obj, key)) {
                    // val isn't an array, but since a second value has been specified,
                    // convert val into an array.
                    obj[key] = [obj[key], val];
                } else {
                    // val is a scalar.
                    obj[key] = val;
                }
            }
        } else if (key) {
            // No value was defined, so set something meaningful.
            obj[key] = coerce ? undefined : '';
        }
    });

    return obj;
};


export default class Url {
    constructor (param = window.document.location.href) {

        if (typeof param === 'string') {
            // https://tuicruises.atlassian.net/browse/IBE-253
            if (param.indexOf('__proto__') !== -1) {
                param = window.document.location.origin;
            };
            this.linkElement = window.document.createElement('a');
            this.linkElement.href = param;
            this.url = param;
        } else if (isDOMElement(param)) {
            this.linkElement = param;
        } else if (param && (typeof param.get === 'function')) {
            this.linkElement = param.get(0);
        } else {
            throw new Error('Parameter mus be either a string, a dom element or a jquery dom collection');
        }

        if (!this.url) {
            this.url = this.linkElement.getAttribute('href');
        }
    }

    get fullUrl () {
        return this.linkElement.href;
    }

    get protocol () {
        return this.linkElement.protocol;
    }

    get protocolValue () {
        return this.protocol ? this.protocol.substr(0, this.protocol.length - 1) : null;
    }

    get host () {
        return this.linkElement.hostname || null;
    }

    get port () {
        return this.linkElement.port || null;
    }

    get path () {
        return this.linkElement.pathname || null;
    }

    get pathValue () {
        return this.path ? this.path.substring(1) : null;
    }

    get query () {
        return this.linkElement.search || null;
    }

    get queryValue () {
        return this.query ? this.query.substring(1) : null;
    }

    get queryParameters () {
        return this.queryValue ? deparam(this.queryValue) : {};
    }

    get hash () {
        return this.linkElement.hash || null;
    }

    get hashValue () {
        return this.hash ? this.hash.substring(1) : null;
    }

    get isExternal () {
        return this.host && (this.host !== window.document.location.hostname);
    }

    get isInternal () {
        return this.host && (this.host === window.document.location.hostname);
    }

    get isSpecial () {
        return !this.host && !!this.protocol;
    }

    get specialValue () {
        return this.isSpecial ? this.url.substring(this.protocol.length) : null;
    }
}

