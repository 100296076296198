var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"b2b-header\">\n        <div class=\"container container-with-padding\">\n            <div class=\"row\">\n                <div class=\"col-xs-7\">\n                    <div class=\"welcome\">\n                        "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.header.layout.welcome",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":7,"column":24},"end":{"line":7,"column":78}}})) != null ? stack1 : "")
    + ", "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"agencyName") || (depth0 != null ? lookupProperty(depth0,"agencyName") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"agencyName","hash":{},"data":data,"loc":{"start":{"line":7,"column":80},"end":{"line":7,"column":94}}}) : helper)))
    + "!\n                    </div>\n                </div>\n                <div class=\"col-xs-5 align-right\">\n                    <div class=\"js-b2b-logout cta\">\n                        <svg width=\"30\" height=\"60\" class=\"\">\n                            <use xlink:href=\"#icon-logout\"></use>\n                        </svg>\n                        <span class=\"cta-text\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.header.layout.logout",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":15,"column":47},"end":{"line":15,"column":100}}})) != null ? stack1 : "")
    + "</span>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"ts-header\">\n        <div class=\"container container-with-padding\">\n            <div class=\"row\">\n                <div class=\"col-xs-12 wrapper\">\n                    <div class=\"ts-info\">\n                        <span class=\"icon-info\"></span>\n                    </div>\n                    <div class=\"ts-text\">\n                        <div class=\"ts-headline\">\n                            "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(alias1,"tpl.item.header.layout.ts",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":52,"column":28},"end":{"line":52,"column":77}}})) != null ? stack1 : "")
    + "\n                        </div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"healthcheckDetail") : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":24},"end":{"line":60,"column":33}}})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div>\n                                <span class=\"status status-"
    + alias3((lookupProperty(helpers,"lowercase")||(depth0 && lookupProperty(depth0,"lowercase"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"report") : depth0)) != null ? lookupProperty(stack1,"status") : stack1),{"name":"lowercase","hash":{},"data":data,"loc":{"start":{"line":56,"column":59},"end":{"line":56,"column":86}}}))
    + "\">"
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"report") : depth0)) != null ? lookupProperty(stack1,"status") : stack1), depth0))
    + "</span>\n                                <span class=\"status-topic\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"topicName") || (depth0 != null ? lookupProperty(depth0,"topicName") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"topicName","hash":{},"data":data,"loc":{"start":{"line":57,"column":59},"end":{"line":57,"column":74}}}) : helper)))
    + ":</span>\n                                <span class=\"status-reason "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(data && lookupProperty(data,"last")),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":59},"end":{"line":58,"column":83}}})) != null ? stack1 : "")
    + "\">"
    + alias3(alias4(((stack1 = (depth0 != null ? lookupProperty(depth0,"report") : depth0)) != null ? lookupProperty(stack1,"reason") : stack1), depth0))
    + "</span>\n                            </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "last";
},"7":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"backLink") || (depth0 != null ? lookupProperty(depth0,"backLink") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"backLink","hash":{},"data":data,"loc":{"start":{"line":75,"column":28},"end":{"line":75,"column":40}}}) : helper)));
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"contentAPITexte") : depth0)) != null ? lookupProperty(stack1,"logo") : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":77,"column":28},"end":{"line":81,"column":65}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"contentAPITexte") : depth0)) != null ? lookupProperty(stack1,"logo") : stack1), depth0));
},"14":function(container,depth0,helpers,partials,data) {
    return "https://www.meinschiff.com/";
},"16":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a href=\"tel:"
    + alias3((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||alias2).call(alias1,"VIEW_B2B_SERVICE_NUMBER_DISPLAY_CTA",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":90,"column":41},"end":{"line":90,"column":95}}}))
    + "\" class=\"button button-secondary button-large-icon js-tracking\" data-totrack=\"button.anrufen\"><span class=\"icon-telephone\"></span> "
    + alias3((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||alias2).call(alias1,"VIEW_B2B_SERVICE_NUMBER_DISPLAY_INT",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":90,"column":226},"end":{"line":90,"column":280}}}))
    + " </a>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a href=\"tel:"
    + alias3((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||alias2).call(alias1,"VIEW_SERVICE_NUMBER_DISPLAY_CTA",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":92,"column":41},"end":{"line":92,"column":91}}}))
    + "\" class=\"button button-secondary button-large-icon js-tracking\" data-totrack=\"button.anrufen\"><span class=\"icon-telephone\"></span> "
    + alias3((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||alias2).call(alias1,"VIEW_SERVICE_NUMBER_DISPLAY_INT",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":92,"column":222},"end":{"line":92,"column":272}}}))
    + " </a>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a href=\"mailto:reservierung@tuicruises.com\" class=\"button button-secondary button-large-icon js-tracking\" data-totrack=\"button.kontakt\"><span class=\"icon-email\"></span> "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.header.layout.contact",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":98,"column":198},"end":{"line":98,"column":252}}})) != null ? stack1 : "")
    + "</a>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <a href=\"mailto:info@tuicruises.com\" class=\"button button-secondary button-large-icon js-tracking\" data-totrack=\"button.kontakt\"><span class=\"icon-email\"></span> "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.header.layout.contact",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":100,"column":190},"end":{"line":100,"column":244}}})) != null ? stack1 : "")
    + "</a>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"tel:"
    + container.escapeExpression((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"VIEW_B2B_SERVICE_NUMBER_DISPLAY_CTA",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":140,"column":25},"end":{"line":140,"column":79}}}))
    + "\" class=\"button button-secondary button-large-icon phone js-tracking\" data-totrack=\"button.anrufen\"><span class=\"icon-telephone\"></span></a>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a href=\"tel:"
    + container.escapeExpression((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"VIEW_SERVICE_NUMBER_DISPLAY_CTA",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":142,"column":25},"end":{"line":142,"column":75}}}))
    + "\" class=\"button button-secondary button-large-icon phone js-tracking\" data-totrack=\"button.anrufen\"><span class=\"icon-telephone\"></span></a>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"mobile-navigation__list-item mobile-navigation__list-item--b2b\">\n                        <div class=\"js-b2b-logout cta-text\">\n                            <svg width=\"30\" height=\"30\" class=\"b2b-svg\">\n                                <use xlink:href=\"#icon-logout\"></use>\n                            </svg>\n                            "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.header.layout.logout",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":164,"column":28},"end":{"line":164,"column":81}}})) != null ? stack1 : "")
    + "</div>\n                    </li>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <a href=\"mailto:reservierung@tuicruises.com\" class=\"button button-secondary button-block button-left-aligned button-large-icon js-tracking\" data-totrack=\"button.kontakt\"><span class=\"icon-email\"></span> "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.header.layout.contact",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":195,"column":227},"end":{"line":195,"column":281}}})) != null ? stack1 : "")
    + "</a>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <a href=\"mailto:info@tuicruises.com\" class=\"button button-secondary button-block button-left-aligned button-large-icon js-tracking\" data-totrack=\"button.kontakt\"><span class=\"icon-email\"></span> "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"tpl.item.header.layout.contact",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":197,"column":219},"end":{"line":197,"column":273}}})) != null ? stack1 : "")
    + "</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isB2B") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":21,"column":7}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"healthcheckDetail") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":0},"end":{"line":66,"column":7}}})) != null ? stack1 : "")
    + "\n<div class=\"container page-header-info-bar "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isInAppView") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":43},"end":{"line":68,"column":75}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"container container-with-padding\">\n        <div class=\"row\">\n            <div class=\"col-xs-7\">\n                <ul class=\"link-list\">\n                    <li>\n                        <a title=\"Mein Schiff\" href=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isB2B") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":74,"column":53},"end":{"line":82,"column":33}}})) != null ? stack1 : "")
    + "\" class=\"info-bar-logo js-tracking\" rel=\"noopener noreferrer\" data-totrack=\"logo.mein-schiff\" aria-label=\"www.meinschiff.com\">\n                            <svg width=\"292\" height=\"40\">\n                                <use xlink:href=\"#meinSchiffLogo\"></use>\n                            </svg>\n                        </a>\n                    </li>\n                    <li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isB2B") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":89,"column":24},"end":{"line":93,"column":31}}})) != null ? stack1 : "")
    + "\n                    </li>\n                    <li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isB2B") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":97,"column":24},"end":{"line":101,"column":31}}})) != null ? stack1 : "")
    + "                    </li>\n                </ul>\n            </div>\n            <div class=\"col-xs-5 align-right\">\n                <ul class=\"checkmark-list inline\">\n                    <li>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.header.layout.checkmark1",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":107,"column":24},"end":{"line":107,"column":81}}})) != null ? stack1 : "")
    + "</li>\n                    <li>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.header.layout.checkmark2",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":108,"column":24},"end":{"line":108,"column":81}}})) != null ? stack1 : "")
    + "</li>\n                </ul>\n            </div>\n        </div>\n    </div>\n</div>\n\n\n<div class=\"container container-with-padding page-header-logo-bar "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isInAppView") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":116,"column":66},"end":{"line":116,"column":98}}})) != null ? stack1 : "")
    + "\">\n    <div class=\"page-header-info-bar-menu-left \">\n        <a href=\"\" class=\"wrapper js-open-menu\">\n            <span class=\"line-1\"></span>\n            <span class=\"line-2\"></span>\n            <span class=\"line-3\"></span>\n        </a>\n\n    </div>\n    <a title=\"Mein Schiff\" href=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isB2B") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":125,"column":33},"end":{"line":133,"column":13}}})) != null ? stack1 : "")
    + "\" class=\"logo js-tracking\" data-totrack=\"logo.mein-schiff\" rel=\"noopener noreferrer\">\n        <svg width=\"292\" height=\"40\">\n            <use xlink:href=\"#meinSchiffLogo\"></use>\n        </svg>\n    </a>\n    <div class=\"page-header-info-bar-menu-right\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isB2B") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(26, data, 0),"data":data,"loc":{"start":{"line":139,"column":8},"end":{"line":143,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n\n\n<div class=\"page-header-navigation-bar\">\n    <div class=\"fixed-content\">\n        <nav class=\"container container-with-padding main-navigation-container\"></nav>\n    </div>\n</div>\n\n<div class=\"mobile-navigation\">\n    <div class=\"mobile-navigation__drawer\">\n        <div class=\"mobile-navigation__content\">\n            <ul>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isB2B") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":158,"column":16},"end":{"line":166,"column":23}}})) != null ? stack1 : "")
    + "                <li class=\"mobile-navigation__list-item mobile-navigation__list-item--back\">\n                    <a href=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"appBacklink") || (depth0 != null ? lookupProperty(depth0,"appBacklink") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"appBacklink","hash":{},"data":data,"loc":{"start":{"line":168,"column":29},"end":{"line":168,"column":46}}}) : helper)))
    + "\" class=\"button button-secondary button-block button-left-aligned\" rel=\"noopener noreferrer\"><span class=\"icon-small-arrow-left\"></span> <span class=\"mobile-navigation-back-title\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.header.layout.tripdetails",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":168,"column":226},"end":{"line":168,"column":284}}})) != null ? stack1 : "")
    + "</span></a>\n                </li>\n                <li class=\"mobile-navigation__list-item mobile-navigation__list-item--foldable\">\n                    <div class=\"js-toggle-fold\">\n                        <a class=\"button button-secondary button-block button-left-aligned button-alike button-large-icon\"><span class=\"icon-telephone\"></span> "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.header.layout.hotline",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":172,"column":160},"end":{"line":172,"column":214}}})) != null ? stack1 : "")
    + "</a>\n                    </div>\n                    <div class=\"mobile-navigation__list-item-content\">\n                        <p>\n                            <strong>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"general.team.service",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":176,"column":36},"end":{"line":176,"column":80}}})) != null ? stack1 : "")
    + "</strong> <br>\n                            <a href=\"tel:"
    + alias3((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||alias2).call(alias1,"VIEW_SERVICE_NUMBER_DISPLAY_CTA",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":177,"column":41},"end":{"line":177,"column":91}}}))
    + "\" class=\"mobile-navigation__phone-link js-tracking\" data-totrack=\"button.anrufen\">"
    + alias3((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||alias2).call(alias1,"VIEW_SERVICE_NUMBER_DISPLAY_DE",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":177,"column":173},"end":{"line":177,"column":222}}}))
    + "</a> <br>\n                            "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.header.layout.abroad",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":178,"column":28},"end":{"line":178,"column":81}}})) != null ? stack1 : "")
    + " <br>\n                            <a href=\"tel:"
    + alias3((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||alias2).call(alias1,"VIEW_SERVICE_NUMBER_DISPLAY_CTA",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":179,"column":41},"end":{"line":179,"column":91}}}))
    + "\" class=\"js-tracking\" data-totrack=\"button.anrufen\">"
    + alias3((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||alias2).call(alias1,"VIEW_SERVICE_NUMBER_DISPLAY_INT",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":179,"column":143},"end":{"line":179,"column":193}}}))
    + "</a>\n                        </p>\n                        <p>\n                            <strong>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.header.layout.sales-offices",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":182,"column":36},"end":{"line":182,"column":96}}})) != null ? stack1 : "")
    + "</strong> <br>\n                            <a href=\"tel:"
    + alias3((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||alias2).call(alias1,"VIEW_B2B_SERVICE_NUMBER_DISPLAY_CTA",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":183,"column":41},"end":{"line":183,"column":95}}}))
    + "\" class=\"mobile-navigation__phone-link js-tracking\" data-totrack=\"button.anrufen\">"
    + alias3((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||alias2).call(alias1,"VIEW_B2B_SERVICE_NUMBER_DISPLAY_DE",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":183,"column":177},"end":{"line":183,"column":230}}}))
    + "</a> <br>\n                            "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.header.layout.abroad",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":184,"column":28},"end":{"line":184,"column":81}}})) != null ? stack1 : "")
    + " <br>\n                            <a href=\"tel:"
    + alias3((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||alias2).call(alias1,"VIEW_B2B_SERVICE_NUMBER_DISPLAY_CTA",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":185,"column":41},"end":{"line":185,"column":95}}}))
    + "\" class=\"js-tracking\" data-totrack=\"button.anrufen\">"
    + alias3((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||alias2).call(alias1,"VIEW_B2B_SERVICE_NUMBER_DISPLAY_INT",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":185,"column":147},"end":{"line":185,"column":201}}}))
    + "</a>\n                        </p>\n                        <p>\n                            <strong>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"general.opening-hours-name",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":188,"column":36},"end":{"line":188,"column":86}}})) != null ? stack1 : "")
    + "</strong> <br>\n                            "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"general.opening-hours",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":189,"column":28},"end":{"line":189,"column":73}}})) != null ? stack1 : "")
    + "\n                        </p>\n                    </div>\n                </li>\n                <li class=\"mobile-navigation__list-item\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isB2B") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.program(32, data, 0),"data":data,"loc":{"start":{"line":194,"column":20},"end":{"line":198,"column":27}}})) != null ? stack1 : "")
    + "\n                </li>\n                <li class=\"mobile-navigation__list-item mobile-navigation__list-item--check\">\n                    <a class=\"button button-secondary button-block button-left-aligned button-alike button-large-icon\"><span class=\"icon-checkmark\"></span> "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.header.layout.checkmark1",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":202,"column":156},"end":{"line":202,"column":213}}})) != null ? stack1 : "")
    + "</a>\n                </li>\n                <li class=\"mobile-navigation__list-item mobile-navigation__list-item--check\">\n                    <a class=\"button button-secondary button-block button-left-aligned button-alike button-large-icon\"><span class=\"icon-checkmark\"></span> "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.header.layout.checkmark2",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":205,"column":156},"end":{"line":205,"column":213}}})) != null ? stack1 : "")
    + "</a>\n                </li>\n            </ul>\n        </div>\n        <div class=\"mobile-navigation__closer js-close-menu\">\n            <span class=\"icon-close-small\"></span>\n        </div>\n    </div>\n</div>\n";
},"useData":true});