import _ from 'underscore';

import config from '../config/config';
import app from '../app/app';

const normalizePath = (path) => {
    if (path.indexOf(config.root) !== 0) {
        return config.root + path;
    }
    return path;
};


const getPath = (key) => {
    const result = _.findWhere(config.routes, {
        key
    });

    if (!_.isEmpty(result)) {
        return normalizePath(result.routes ? result.routes[0] : key);
    }

    return config.root;
};

const routeToRegexp = (route) => {
    const regexp = _.isRegExp(route) ? route : app.router._routeToRegExp(route);
    return regexp;
};


const getMeta = (key) => {
    const result = _.findWhere(config.routes, {
        key
    });

    return result ? result.meta || {} : {};
};


const getRoutes = (key) => {
    const result = _.findWhere(config.routes, {
        key
    });

    return result ? result.routes || [] : [];
};


const getRedirects = (key) => {
    const result = _.findWhere(config.routes, {
        key
    });

    return result ? result.redirects || [] : [];
};


const getLinks = (key) => {
    const result = _.findWhere(config.routes, {
        key
    });

    return result ? result.links || [] : [];
};


const redirect = (path, hard) => {
    const pathDetail = _.findWhere(config.routes, {
        'key': path
    });
    if (hard || (path === config.root && config.reloadRoot === true)) {
        // hard reload for basePath
        window.location.href = path;
    } else if (_.isEmpty(path)) {
        // do nothing for empty paths
    } else if ((config.pushState === true) && (/^#.+/.test(path))) {
        // change hash only if push state is used
        window.location.hash = path;
    } else if (/^https?:\/\/.+/.test(path)) {
        if (config.openExternalUrlsInNewTab === true) {
            // new window for full paths
            const tap = window.open();
            if (tap) {
                tap.opener = null;
                tap.location = path;
            } else {
                window.open(path);
            }

        } else {
            window.location.href = path;
        }
    } else if (pathDetail && pathDetail.newTab === true) {
        const tap = window.open();
        if (tap) {
            tap.opener = null;
            tap.location = path;
        } else {
            window.open(path);
        }
    } else {
        // navigate
        app.router.redirect(path);
    }
};


const goBack = () => {
    window.history.back();
};


const gotoErrorPage = () => {
    if (window.location.pathname !== _.getPath('error503')) {
        redirect(_.getPath('error503'));
    }
};


export default {
    getPath,
    normalizePath,
    routeToRegexp,
    getMeta,
    getRoutes,
    getRedirects,
    getLinks,
    redirect,
    goBack,
    gotoErrorPage
};
