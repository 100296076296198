var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"booking-page-section\" >\n    <div>\n        <div class=\"headline\">\n            <div>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.api-step.bank-transfer.text",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":4,"column":17},"end":{"line":4,"column":77}}})) != null ? stack1 : "")
    + "</div>\n            "
    + alias3(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"contentAPITexte") : depth0)) != null ? lookupProperty(stack1,"bankTransfer") : stack1), depth0))
    + "\n        </div>\n        <div class=\"api-input-Acknowledged\"></div>\n        <!--div class=\"accept\">\n            <div class=\"checkbox-element\">\n                <input type=\"checkbox\" id=\"input-accept\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":57},"end":{"line":10,"column":88}}})) != null ? stack1 : "")
    + " class=\"js-accept-checkbox\">\n                <label for=\"input-accept\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":11,"column":42},"end":{"line":11,"column":51}}}) : helper)))
    + "</label>\n            </div>\n        </div-->\n    </div>\n</div>\n<div><br></div>\n";
},"useData":true});