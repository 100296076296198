import BaseItem from '../../../base/view/item';
import templateWebpack from '../../../../tpl/item/api-input/bic-select.hbs';


export default class BicSelectView extends BaseItem {
    constructor (options) {
        super({
            ...options,
            key: 'api-input',
            templateWebpack
        });
    }

    /**
     * View events triggered by the user
     */
    events () {
        return {
            'change #bic-select': this.onValueChange
        };
    }

    templateContext () {
        return {
            label: this.options.label,
            bicOptions: this.options.seclectData
        };
    }

    onValueChange (e) {
        const $element = this.$(e.currentTarget);
        this.triggerMethod('payment:sepa:bic:select:change', $element.val());
    }
}
