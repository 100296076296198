import Marionette from 'backbone.marionette';
import InsuranceSelectorFamilyInsuranceStepperView from './family-insurance-stepper';


export default class InsuranceSelectorFamilyInsuranceSteppersCollectionView extends Marionette.CollectionView {
    get className () {
        return 'row';
    }


    get childView () {
        return InsuranceSelectorFamilyInsuranceStepperView;
    }


    get childViewOptions () {
        return {
            familyCollection: this.collection
        };
    }
}
