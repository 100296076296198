const getValue = (cname) => {
    cname = `${cname}=`;
    const ca = document.cookie.split(';');
    let i = 0;
    for (i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(cname) === 0) {
            return c.substring(cname.length, c.length);
        }
    }
    return false;
};

const setValue = (cname, value, params) => {
    let domainStr = '';
    if (window.location.hostname.indexOf('.') !== -1) {
        domainStr = `; domain=.${window.location.hostname.split('.').slice(-2).join('.')}`;
    }
    cname = `${cname}=${value}${domainStr}; Path=/`;
    if (params && params.expires) {
        const expires = new Date();
        expires.setMilliseconds(expires.getMilliseconds() + params.expires * 864e+5);
        params.expires = expires.toUTCString();
        cname = `${cname};expires=${params.expires}`;
    }
    if (params && params.secure && domainStr !== '') {
        cname = `${cname};secure`;
    }

    document.cookie = cname;
    return true;
};

const deleteCookie = (cname) => {
    let domainStr = '';
    // TUICIBE-563: because local development runs under http://tuic-ibe-v/
    if (window.location.hostname.indexOf('.') !== -1) {
        domainStr = `domain=.${window.location.hostname.split('.').slice(-2).join('.')}`;
    }
    document.cookie = `${cname}=; ${domainStr}; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export default {
    getValue,
    setValue,
    deleteCookie
};
