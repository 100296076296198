import BaseCollection from '../../../base/collection/base';
import BookingPageModel from '../model/booking-page';

export default class BookingPagesCollection extends BaseCollection {
    constructor (models, options = {}) {
        // pass the given models as BookingPageModels to the BaseCollection constructor
        super(models, {
            model: BookingPageModel,
            ...options
        });

        // this.listenTo(app.apiSession.steps, 'sync', this.setPageStatus);
        this.setPageStatus();
    }

    setPageDataSync () {
        this.setPageApiSteps();
        this.setPageStatus();
    }

    setPageApiSteps () {
        console.log('%c BookingPagesCollection: setPageApiSteps ', 'background: #222; color: #ffff00');
        this.forEach(model => model.setApiSteps());
    }

    /**
     * reevaluate the pageStatus for each page of the booking page collection
     */
    setPageStatus () {
        console.log('===== setPageStatus');

        this.forEach(model => model.refreshStatus());
    }


    getFirstOpenPage () {
        return this.find((pageModel) => pageModel.isOpen());
    }
}
