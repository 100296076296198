import ApiInputSelectView from './select';
import templateWebpack from '../../../../tpl/item/api-input/bus-select.hbs';


export default class ApiInputBusSelectView extends ApiInputSelectView {
    constructor (options) {
        super({
            templateWebpack,
            ...options
        });
    }

}
