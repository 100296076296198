import { ParticipantPrices } from './interface';
import React from 'react';

import StringUtil from '../../util/string';
import { FormattedMessage, useIntl } from 'react-intl';
import { CabinPrice } from '../cabin/cabin-price';


type DiscountDetail = {
    category: string;
    price:number;
    person:string;
    key: number;
}

type DiscountWrapper = {
    type: string;
    value: DiscountDetail[]
};

type PriceDiscountProps = {
    participantPrices: ParticipantPrices[],
    moodImage: string;
    isB2B: boolean;
}

/**
 * View: Zu- und Abschläge
 *
 * @param props PriceDiscountProps
 */
export default function PriceDiscountlView (props:PriceDiscountProps) {
    const { formatMessage } = useIntl();
    const {participantPrices, moodImage, isB2B } = props;

    const { hasDiscounts, discounts } = prepareDiscountInformations(participantPrices);

    return (
        <>
            {hasDiscounts ?
                <section className="check-section">
                    <div className="row">
                        {!isB2B ?
                            <div className="col-sm-4 hidden-xs-down">
                                <div className="check-image">
                                    <img alt={formatMessage({
                                        id: 'components.overview.price-discount.headline'
                                    })} src={moodImage} width="500" loading="lazy"/>
                                </div>
                            </div>
                            : '' }
                        <div className={`col-xs-12${!isB2B ? ' col-sm-8' : ''} text-wrapper`}>
                            <div className="section-headline">
                                <FormattedMessage id="components.overview.price-discount.headline" />
                            </div>
                            <div>
                                {discounts.map((item:DiscountWrapper, key:number) => {
                                    return (
                                        <div key={key} className="discount-section">
                                            <div className="bold">{item.type}</div>
                                            {item.value.map((pItem: DiscountDetail, pKey: number) => {
                                                return (
                                                    <div key={pKey} className="row price-per-person price-discount">
                                                        <div className="col-xs-7 label">{pItem.person}</div>
                                                        <div className="col-xs-5 value total-value nobr">
                                                            <CabinPrice mode='simple' price={pItem.price} />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </section>
                : ''}
        </>
    );
}


/**
 *  filter, group, participantPrices Discounts
 **/
const prepareDiscountInformations = (raw:ParticipantPrices[]) => {
    // prepared participantPrices list to flat list with necessary discount data
    const filter = raw.reduce((list: DiscountDetail[], item) => {
        item.prices.forEach((all) => {
            if (all.discounts.length > 0) {
                all.discounts.forEach((part) => {
                    list.push({
                        category: part.name,
                        price: part.discount,
                        person: item.displayName,
                        key: item.index
                    });
                });
            }
        });
        return list;
    }, []);

    type GroupType = {
        [name:string]: DiscountDetail[]
    }
    // group list by category
    const groupByCategory = filter.reduce((list: GroupType, item) => {
        list[item.category] = (list[item.category] || []).concat(item);
        return list;
    }, {});

    let hasDiscounts = false;
    const discounts: DiscountWrapper[] = [];
    Object.keys(groupByCategory).forEach((item) => {
        hasDiscounts = true;
        const value = groupByCategory[item].sort((a: DiscountDetail, b: DiscountDetail) => {
            return (a.key) - (b.key);
        });
        discounts.push({
            type: item,
            value
        });
    });

    return {hasDiscounts, discounts};
};
