import BookingPage from '../../booking-page/base/page';
import PersonalPageContentView from './content';


export default class PersonalPage extends BookingPage {
    constructor (options = {}) {
        super({
            key: 'personal',
            ...options
        });
    }


    get contentViewClass () {
        return PersonalPageContentView;
    }

    onBeforeDestroy () {
        this.checkReactModalPortalDivSpam();
    }
}

