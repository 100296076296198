import BaseOverlayView from './base';
import templateWebpack from '../../../../tpl/item/overlay/travel-flight-without-details.hbs';


export default class TravelFlightWithoutDetailsOverlayView extends BaseOverlayView {
    constructor (options = {}) {
        super({
            key: 'overlay-travel-flight-without-details',
            templateWebpack,
            ...options
        });
    }
}
