import Backbone from 'backbone';

/**
 *  Represents a backbone model as a base model for this project with project specific extensions
 */
export default class BaseModel extends Backbone.Model {
    /**
     * Replace or remove any characters in the models id property to sanitize it for usage inside HTML tags
     *
     * @returns {string} - small case id derived from the models id property, save for CSS and HTML
     */
    get sanitizedId () {
        return this.id.replace(/[^a-zA-Z0-9]/g, (string) => {
            const charCode = string.charCodeAt(0);
            if (charCode === 32) { // space
                return '-';
            }
            if (charCode === 46) { // dot
                return '_';
            }
            if (charCode === 47) { // slash /
                return '_';
            }
            if (charCode < 128) { // latin
                return string;
            }
            // eslint-disable-next-line prefer-template
            return 'u' + ('000' + charCode.toString(16)).slice(-4);
        }).toLowerCase();
    }
}

