import React from 'react';
import { createRoot } from 'react-dom/client';
import CabinView from '../../../components/cabin/index';
import B2BCabinDetailView from '../../../components/sections/b2b/cabin-detail';

import BookingPageContent from '../../booking-page/base/content';
import app from '../../../app/app';
import templateWebpack from '../../../../tpl/page/booking-page/content/cabin.hbs';
import * as Constants from '../../../config/constants';
import { renderReact } from '../../../react';


export default class CabinPageContent extends BookingPageContent {
    reactB2bView = null;
    constructor (options = {}) {
        super({
            key: 'page-cabin-content',
            templateWebpack,
            ...options
        });
        this.listenTo(this, 'step:changed:react', this.onStepChange);
        this.listenTo(this, 'step:changed', this.render);
        this.listenTo(app, 'trip:has:change:need:update', this.render);
        // this.listenTo(app, 'stepsData:sync', this.render);

        this.listenTo(app, 'stepsData:sync', this.renderReact);
    }

    templateContext () {
        const contentTripInfo = app.contentApi.tripInfo || {};
        return {
            tripName: contentTripInfo.get('tripName'),
            tripInfo: app.contentApi.tripInfo,
            hasAlternativesTrips: this.hasAlternativesTrips
        };
    }

    childViewEvents () {
        return {
            ...super.childViewEvents.apply(this),
            'step:changed': this.onStepChange
        };
    }

    events () {
        return {
            'click .js-tracking-extern': (e) => {
                const action = e.currentTarget.parentElement.dataset.action;
                const label = e.currentTarget.parentElement.dataset.label;
                if (app && app.trackController && label && action) {
                    app.trackController.eventTracking({
                        action: action,
                        label: label,
                        ga4clickLabel: `content.link.kabine-${label}`
                    });
                }
            }
        };
    }

    onStepChange (modeId) {
        console.log('===================== onStepChange');
        console.info('%c tealiumEvent -> kein Plan wann das ding kommt ', 'background: #263e6a; color: #ffffff');

        // all value this.triggerMethod('step:changed', this.model.id, inputData, this, this.model);
        const label = (modeId === Constants.NETMATCH_STEP_PARTY) ? ('Teilnehmer') : ('Kabine-Auswahl');
        app.trackController.eventTracking({action: 'Kabinenauswahl', label}); // ???
    }

    /**
     * Auto-render the steps to the regions
     */
    onRender () {
        let triggerCabin = 1;
        let maxCabin = 5;
        if (window.location.hash && window.location.hash.indexOf('#c') !== -1) {
            triggerCabin = parseInt(window.location.hash.replace('#c', '').trim(), 10);

            if (this.model.apiSteps.get(Constants.NETMATCH_STEP_CABINCOUNT)) {
                if (this.model.apiSteps.get(Constants.NETMATCH_STEP_CABINCOUNT).inputs.get(Constants.NETMATCH_INPUT_CABINCOUNT)) {
                    maxCabin = parseInt(this.model.apiSteps.get(Constants.NETMATCH_STEP_CABINCOUNT).inputs.get(Constants.NETMATCH_INPUT_CABINCOUNT).get('inputValue'), 10) || 1;
                }
            }
        }
        triggerCabin = (triggerCabin >= 1) ? triggerCabin : 1;
        triggerCabin = (triggerCabin <= maxCabin) ? triggerCabin : maxCabin;

        const container = this.$el.find('.region-cabin')[0];
        const root = createRoot(container);
        renderReact(root, <CabinView parent={this.model} stepmodels={this.model.apiSteps} initCabin={triggerCabin}/>);

        this.initPager();

        if (app.apiSession.bookingStatus.agency.isB2B()) {
            if (this.$el.find('.react-region-b2b-cabin-detail')[0]) {
                const container = this.$el.find('.react-region-b2b-cabin-detail')[0];
                this.reactB2bView = createRoot(container);
            }
        }

        this.renderReact();
    }

    renderReact () {
        if (this.reactB2bView) {
            renderReact(this.reactB2bView, <B2BCabinDetailView/>);
        }
    }
}
