import {CabinInfo, InsuranceInfo, InvoiceInfo, ParticipantPrices, TravelBound, TripInfo} from './interface';
import React from 'react';
import app from '../../app/app';
import TripInfoView from './trip-info';
import CabinInfoView from './cabin';
import TravelInfoView from './travel';
import InsuranceView from './insurance';
import PremiumView from './premium';
import ParticipantView from './participant';
import PriceVipView from './price-vip';
import PriceDiscountlView from './price-discount';
import PricePersonlView from './price-person';
import PriceTotalView from './price-total';
import CampaignCodeView from './campaign-code';

import * as Constants from '../../config/constants';

type OverviewProps = {
    trip: TripInfo;
    cabin: [CabinInfo];
    shipBoundTransport: TravelBound;
    homeBoundTransport: TravelBound;
    insurances: [InsuranceInfo] | null;
    invoice: InvoiceInfo;
    participantPrices: [ParticipantPrices]
    totalBookingPrice: string | null;
} | null;

export default function OverviewView ({hideEdit}: { hideEdit: boolean }) {
    const data: OverviewProps = app?.apiSession?.bookingStatus?.toJSON() || null;
    const isB2B: boolean = app?.apiSession?.bookingStatus?.agency.isB2B() !== false;

    return (
        <div className="row">
            {data ?
                <div className="col-lg-12 overview-check">
                    {data.trip ?
                        <TripInfoView tripInfo={data.trip} isB2B={isB2B}></TripInfoView>
                        : ''}
                    {data.cabin ?
                        <CabinInfoView cabinInfo={data.cabin} isB2B={isB2B} hideEdit={hideEdit}></CabinInfoView>
                        : ''}
                    {data.shipBoundTransport && data.homeBoundTransport ?
                        <TravelInfoView shipBound={data.shipBoundTransport} homeBound={data.homeBoundTransport} moodImage={moodImage(data.shipBoundTransport.type)} isB2B={isB2B} hideEdit={hideEdit}></TravelInfoView>
                        : ''}
                    {data.insurances ?
                        <InsuranceView insurancesInfo={data.insurances} moodImage={moodImage('insurances')} isB2B={isB2B} hideEdit={hideEdit}></InsuranceView>
                        : ''}

                    <PremiumView moodImage={moodImage('msi')} isB2B={isB2B}></PremiumView>
                    <ParticipantView cabinInfo={data.cabin} invoiceInfo={data.invoice} moodImage={moodImage('personal')} isB2B={isB2B} hideEdit={hideEdit}></ParticipantView>
                    <div className="check-price-section">
                        <PriceVipView participantPrices={data.participantPrices} cabinInfo={data.cabin} moodImage={moodImage('vip')} isB2B={isB2B}></PriceVipView>
                        <PriceDiscountlView participantPrices={data.participantPrices} moodImage={moodImage('discount')} isB2B={isB2B}></PriceDiscountlView>
                        <PricePersonlView cabinInfo={data.cabin} moodImage={moodImage('price')} isB2B={isB2B}></PricePersonlView>
                        <PriceTotalView totalBookingPrice={data.totalBookingPrice}></PriceTotalView>
                        <CampaignCodeView></CampaignCodeView>
                    </div>
                </div>
                : ''}
        </div>
    );
};


/**
 *  Mood Images by Type
 **/
const moodImage = (type: string) => {
    const imageRoot = '/images/overview/';
    let image;
    switch (type) {
        case Constants.NETMATCH_SESSION_TRANSPORT_TYPE_FLIGHT:
            image = 'picture-lastminute.jpg';
            break;
        case Constants.NETMATCH_SESSION_TRANSPORT_TYPE_TRAIN:
            image = 'picture-an-abreise-bahn.jpg';
            break;
        case Constants.NETMATCH_SESSION_TRANSPORT_TYPE_BUS:
            image = 'picture-an-abreise-bus.jpg';
            break;
        case Constants.NETMATCH_SESSION_TRANSPORT_TYPE_OWN:
            image = 'picture-an-abreise-eigene.jpg';
            break;
        case 'insurances':
            image = 'bild-reiseversicherung.jpg';
            break;
        case 'msi':
            image = 'bild-mspi.jpg';
            break;
        case 'personal':
            image = 'bild-ihre-daten2.jpg';
            break;
        case 'discount':
            image = 'bild-zu-abschl-ge2.jpg';
            break;
        case 'price':
            image = 'bild-preis-pro-person2.jpg';
            break;
        case 'vip':
            image = 'bild-vip.jpg';
            break;
        default:
            break;
    }

    return `${imageRoot}${image}`;
};
