import React from 'react';
import { FormattedMessage } from 'react-intl';
/**
 * B2B overview
 */
export default function B2BOverviewTextView () {
    return (
        <div className="b2b-overview-text">
            <FormattedMessage id="components.sections.b2b.overview-text.text" />
        </div>
    );
}
