import $ from 'jquery';
import BaseModel from '../../../base/model/base';
import BaseItem from '../../../base/view/item';
import NavigationView from '../../../item/navigation/view/navigation';
import NavigationUtil from '../../../util/navigation';
import app from '../../../app/app';
import templateWebpack from '../../../../tpl/item/header/layout.hbs';
import isInAppView from '../../../util/isInAppView';


const el = document.createElement('div');
const transitionsMapping = {
    'transition': 'transitionend',
    'OTransition': 'otransitionend',
    'MozTransition': 'transitionend',
    'WebkitTransition': 'webkitTransitionEnd'
};
const transitionEndEventName = transitionsMapping[Object.keys(transitionsMapping).find(key => el.style[key] !== undefined)];

export default class HeaderView extends BaseItem {
    constructor (options = {}) {
        super(Object.assign({
            key: 'header',
            templateWebpack,
            model: new BaseModel({
                appBacklink: app.ibeController.appBackLink()
            })
        }, options));
        this.listenTo(this.model, 'change', this.render);
        this.listenTo(app, 'confirm:removeNavigationBar', this.removeNavigationBar);
    }


    ui () {
        return {
            'mobileNavigation': '.mobile-navigation',
            'mobileNavigationDrawer': '.mobile-navigation__drawer'
        };
    }

    events () {
        return {
            'click .js-open-menu': () => {
                $('body').addClass('noscroll');
                this.getUI('mobileNavigation').addClass('mobile-navigation--animatable mobile-navigation--open');
            },
            'click .js-toggle-fold': (e) => {
                this.$(e.currentTarget).parents('.mobile-navigation__list-item--foldable').toggleClass('mobile-navigation__list-item--unfolded');
            },
            'click .js-close-menu': this.onClickCloseMenu,
            'click .mobile-navigation--open': this.onClickBackground,
            'click .js-b2b-logout': this.onClickB2BLogout,
            'click .js-tracking': (e) => {
                const label = e.currentTarget.dataset.totrack;
                if (app && app.trackController && label) {
                    app.trackController.eventTracking({
                        ga4clickLabel: `navi-main.${label}`
                    });
                }
            }
        };
    }

    get tagName () {
        return 'header';
    }


    /**
     * Getter for the views class name
     */
    get className () {
        return 'page-header';
    }

    /**
     * View regions
     */
    regions () {
        return {
            navigation: {
                el: '.main-navigation-container'
                // replaceElement: true,
            }
        };
    }

    templateContext () {
        let agencyName = '';
        if (app.apiSession.bookingStatus.agency.isB2B()) {
            agencyName = app.apiSession.bookingStatus.agency.get('name');
        }
        return {
            isB2B: app.apiSession.bookingStatus.agency.isB2B(),
            backLink: app.ibeController.appBackLink(),
            agencyName,
            contentAPITexte: app.contentApi.messages.prepareMessages('header'),
            healthcheckDetail: app.ibeController.ibeStatus.healthcheckDetail,
            isInAppView
        };
    }

    onRender () {
        this.showChildView('navigation', new NavigationView());
    }


    removeBackButton () {
        this.$('.js-back').addClass('is-hidden');
    }

    showBackButton () {
        this.$('.js-back').removeClass('is-hidden');
    }

    removeNavigationBar () {
        this.$('.page-header-navigation-bar').hide();
    }

    onClickBackground (e) {
        if ($(e.target).closest('.mobile-navigation__content').length) {
            return;
        }
        this.onClickCloseMenu();
    }

    onClickCloseMenu () {
        this.getUI('mobileNavigationDrawer').one(transitionEndEventName, () => {
            $('body').removeClass('noscroll');
            this.getUI('mobileNavigation').removeClass('mobile-navigation--animatable');
        });
        this.getUI('mobileNavigation').removeClass('mobile-navigation--open');
    }

    onClickB2BLogout () {
        app.ibeController.removeB2BCookieAndLocalStorage();
        NavigationUtil.redirect(app.ibeController.getB2BinfonetAuthURL(), true);
    }
}
