import BookingPage from '../../booking-page/base/page';
import ConfirmPageContentView from './content';

export default class ConfirmPage extends BookingPage {
    constructor (options = {}) {
        super({
            key: 'confirm',
            ...options
        });
    }

    /**
     * Getter for the views class name
     */
    get className () {
        return `${this.getOption('className')} without-booking-status`;
    }


    get pageLayoutType () {
        return 'basic';
    }


    get contentViewClass () {
        return ConfirmPageContentView;
    }
}
