import _ from 'underscore';
import $ from 'jquery';
import ApiStepBaseView from './base';
import app from '../../../app/app';
import OverlayFactory from '../../../item/overlay/factory';
import templateWebpack from '../../../../tpl/item/api-step/creditcard.hbs';
import * as Constants from '../../../config/constants';
import {getIntl, getLocaleAPI} from '../../../intl';

export default class PaymentCreditCardApiStep extends ApiStepBaseView {
    constructor (options = {}) {
        super({
            templateWebpack, ...options
        });
        this.receiveIframMassage = this.receiveIframMassage.bind(this);
    }

    events () {
        return {
            'click .js-invoice-overlay': 'onInvoiceOverlay'
        };
    }

    /**
     * View regions.
     * Any region defined under the key of an input id will be filled with the
     * corresponding input view when calling the 'renderApiInputsToRegions' method..
     */
    regions () {
        return {
            [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_TRANSACTIONID]: {
                el: '.api-input-TransactionId', replaceElement: true
            },
            [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_CREDITCARDBRAND]: {
                el: '.api-input-CreditCardBrand', replaceElement: true
            },
            [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_CREDITCARDNUMBER]: {
                el: '.api-input-CreditCardNumber', replaceElement: true
            },
            [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_CREDITCARDVALID]: {
                el: '.api-input-CreditCardValid', replaceElement: false
            },
            [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_CREDITCARDOWNER]: {
                el: '.api-input-CreditCardOwner', replaceElement: false
            },
            [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_TOKEN]: {
                el: '.api-input-Token', replaceElement: false
            },
            [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_HASH]: {
                el: '.api-input-Hash', replaceElement: false
            },
            [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_STATUS]: {
                el: '.api-input-Status', replaceElement: false
            },
            [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_STATUSDESCRIPTION]: {
                el: '.api-input-StatusDescription', replaceElement: false
            },
            [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_RETURNCODE]: {
                el: '.api-input-Returncode', replaceElement: false
            },
            [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_TIMESTAMP]: {
                el: '.api-input-TimeStamp', replaceElement: false
            },
            [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_BIN]: {
                el: '.api-input-Bin', replaceElement: false
            }
        };
    }

    /**
     * Add details to the data passed into the template.
     * Will be merged into the model / collection data.
     */
    templateContext () {
        const templateContext = super.templateContext.apply(this);
        return {
            ...templateContext,
            invoice: app.apiSession.bookingStatus.get('invoice'),
            contentAPITexte: app.contentApi.messages.prepareMessages('payment'),
            isCompleted: this.model.isCompleted()
        };
    }

    /**
     * Any object defined under the key of an input id will be passed as options
     * into the input views created when calling the 'renderApiInputsToRegions' method.
     */
    apiInputViewOptions () {
        return {
            [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_TRANSACTIONID]: {additionalTemplateContext: {hidden: true}},
            [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_CREDITCARDBRAND]: {additionalTemplateContext: {hidden: true}},
            [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_CREDITCARDNUMBER]: {additionalTemplateContext: {hidden: true}},
            [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_CREDITCARDVALID]: {additionalTemplateContext: {hidden: true}},
            [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_CREDITCARDOWNER]: {additionalTemplateContext: {hidden: true}},
            [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_TOKEN]: {additionalTemplateContext: {hidden: true}},
            [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_HASH]: {additionalTemplateContext: {hidden: true}},
            [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_STATUS]: {additionalTemplateContext: {hidden: true}},
            [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_STATUSDESCRIPTION]: {additionalTemplateContext: {hidden: true}},
            [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_RETURNCODE]: {additionalTemplateContext: {hidden: true}},
            [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_TIMESTAMP]: {additionalTemplateContext: {hidden: true}},
            [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_BIN]: {additionalTemplateContext: {hidden: true}}
        };
    }


    onRender () {
        this.renderApiInputsToRegions(this.model.inputs);

        $(window).on('message', this.receiveIframMassage);

        if (this.isAttached()) {
            if (this.model.isOpen()) {
                setTimeout(() => {
                    app.trigger('loading:start');
                }, 10);
                this.initPapagena();
            }
        }

        if (this.model.isCompleted()) {
            app.trigger('scrollTop');
            this.triggerMethod('payment:trigger:butons');
        }
    }

    onAttach () {
        if (!this.model.isCompleted()) {
            setTimeout(() => {
                app.trigger('loading:start');
            }, 10);
            this.initPapagena();
        }
    }

    initPapagena () {
        this.submitForm();
    }

    onInvoiceOverlay () {
        OverlayFactory.show('payment-invoice');
    }

    receiveIframMassage (e) {
        const iframeData = (e.origin) ? (e) : (e.originalEvent);
        if (iframeData.origin === window.location.origin) {
            const key = iframeData.message ? 'message' : 'data';
            const data = iframeData[key];
            // console.log('papagenaData', data);
            if (data.Status === 'OK') {
                this.setDataToApiInputViews(data);
            } else {
                this.handelIFrameError(data);
            }
        } else {
            // console.error(`IframMassage Error! IframMassage origin: ${iframeData.origin} is not location.origin: ${window.location.origin}`);
        }
    }

    setDataToApiInputViews (data) {
        this.$('.js-papagena-form').remove();
        this.$('.js-papagena-iframe').hide();
        this.$('.js-iframe-success').show().text('Register erfolgreich');
        $(window).off('message', this.receiveIframMassage);
        const stepsToSubmit = [];
        let stepData = {};
        let step = '';

        const invoice = app.apiSession.bookingStatus.get('invoice') || {};
        // [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_CREDITCARDOWNER]: `${invoice.firstName} ${invoice.lastName}`,
        stepData = {
            'id': Constants.NETMATCH_STEP_PAYMENT_CREDITCARD,
            'content': {
                [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_TRANSACTIONID]: data.TransID,
                [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_CREDITCARDBRAND]: data.CCTyp,
                [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_CREDITCARDNUMBER]: data.PCNr,
                [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_CREDITCARDVALID]: `${data.CCYear}${data.CCMonth}`,
                [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_CREDITCARDOWNER]: `${invoice.firstName} ${invoice.lastName}`,
                [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_TOKEN]: data.Token,
                [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_HASH]: '',
                [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_STATUS]: data.Status,
                [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_STATUSDESCRIPTION]: '',
                [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_RETURNCODE]: data.Code,
                [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_TIMESTAMP]: data.Date,
                [Constants.NETMATCH_INPUT_PAYMENT_CREDITCARD_BIN]: data.BIN
            }
        };
        step = app.apiSession.steps.get(stepData.id);
        if (step && step.validateData(stepData).valid) {
            stepsToSubmit.push(stepData);
        }
        app.apiSession.submitSteps(stepsToSubmit, true)
            .then(() => {
                this.triggerMethod('pager:next:page');
            });
    }

    handelIFrameError (data) {
        const {formatMessage} = getIntl();
        let errorDescription = '';

        const code = parseInt(data.Code, 10);
        if (code) {
            switch (code) {
                case 20110015:
                case 20110019:
                case 20110024:
                case 20110026:
                case 21000102:
                case 21100502:
                    errorDescription = formatMessage({
                        id: 'tpl.item.api-step.view.creditcard.msg'
                    }, {
                        err: formatMessage({
                            id: 'tpl.item.api-step.view.creditcard.err.submit'
                        })
                    });
                    break;
                case 20110094:
                case 22050304:
                case 22050305:
                case 22050334:
                    errorDescription = formatMessage({
                        id: 'tpl.item.api-step.view.creditcard.msg'
                    }, {
                        err: formatMessage({
                            id: 'tpl.item.api-step.view.creditcard.err.denied'
                        })
                    });
                    break;
                case 20400045:
                case 21000130:
                case 22050040:
                case 22050392:
                    errorDescription = formatMessage({
                        id: 'tpl.item.api-step.view.creditcard.msg'
                    }, {
                        err: formatMessage({
                            id: 'tpl.item.api-step.view.creditcard.err.error'
                        })
                    });
                    break;
                default:
                    errorDescription = formatMessage({
                        id: 'tpl.item.api-step.view.creditcard.general'
                    });
                    break;
            }
            const errorElement = this.$('.js-iframe-error');
            if (errorElement) {
                errorElement.classList.remove('hidden');
                errorElement.innerText = errorDescription;
            }
            this.initPapagena();
        }
    }

    iframeLoaded () {
        app.trigger('loading:stop');
        this.$('.js-papagena-iframe').off('load');
    }

    submitForm (e) {
        if (e) {
            e.preventDefault();
        }
        app.trigger('loading:start');

        const selectedType = app.ibeController.ibeStorage.get('selectedPaymentMethod') ? app.ibeController.ibeStorage.get('selectedPaymentMethod').type : null;

        const form = this.$('.js-papagena-form');
        const invoice = app.apiSession.bookingStatus.get('invoice') || {};
        _.map(form[0], item => {
            if (item.name === 'haendlerPAP') {
                item.value = app.ibeController.getPapagenaId();
            }
            if (item.name === 'Type') {
                item.value = 'check';
            }
            if (item.name === 'TransID') {
                item.value = `IBE${app.apiSession.get('id').split('-').join('')}${Math.round(+new Date() / 1000)}`;
            }
            if (item.name === 'returnURL') {
                item.value = `${window.location.origin}/ccresult.php`;
            }
            if (item.name === 'Language') {
                item.value = getLocaleAPI();
            }
            // TUICUNIT-2249: new Papagena Parameter
            if (item.name === 'billToCustomer') {
                const data = {
                    'consumer': {
                        'firstName': invoice.firstName, 'lastName': invoice.lastName, 'birthDate': invoice.dateOfBirth
                    },
                    'email': invoice.email
                };
                item.value = this.b64EncodeUnicode(JSON.stringify(data));
            }
            if (item.name === 'billingAddress') {

                const invoice = app.apiSession.bookingStatus.get('invoice') || {};
                const staticOptionsModel = app.apiSession.staticInputOptions.get('countries');
                const staticOptions = staticOptionsModel ? staticOptionsModel.get('options') : null;
                const options = staticOptions || [];
                const countryInfo = options.find(item => item.displayText === invoice.country) || {};

                // console.log(countryInfo);

                const data = {
                    'city': invoice.city,
                    'country': {
                        'countryA3': countryInfo.alpha3Code
                    },
                    'addressLine1': {
                        'street': invoice.streetAndHouseNumber
                    },
                    'postalCode': invoice.postalCode
                };
                item.value = this.b64EncodeUnicode(JSON.stringify(data));
            }
            if (item.name === 'OrderDesc') {
                // only if papagena test id (for dev and test)
                if (app.ibeController.getPapagenaId() === 'bill' || app.ibeController.getPapagenaId() === 'bill2') {
                    item.value = 'Test:0000';
                }
            }
            if (item.name === 'CCSelect') {
                switch (selectedType) {
                    case 'MasterCard':
                        item.value = 'MasterCard';
                        break;
                    case 'TUI Card':
                    case 'GuteReise Card':
                    case 'VisaCard':
                        item.value = 'VISA';
                        break;
                    case 'American Express':
                        item.value = 'AMEX';
                        break;
                    default:
                        item.value = 'VISA';
                        break;
                }
            }
        });
        this.$('.js-papagena-iframe').on('load', this.iframeLoaded.bind(this));
        form.submit();
    }

    onBeforeDestroy () {
        this.$('.js-papagena-iframe').off('load');
        $(window).off('message', this.receiveIframMassage);
    }

    b64EncodeUnicode (str) {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
            return String.fromCharCode(parseInt(p1, 16));
        }));
    }
}
