var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"col col-sm-6 form-input \">\n    <label class=\"headline\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"index") || (depth0 != null ? lookupProperty(depth0,"index") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data,"loc":{"start":{"line":2,"column":28},"end":{"line":2,"column":37}}}) : helper)))
    + ". "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.insurance-selector.family-insurance.title",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":2,"column":39},"end":{"line":2,"column":113}}})) != null ? stack1 : "")
    + "</label>\n\n    <div class=\"row\">\n        <div class=\"col col-xs-6 form-group\">\n            <label>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.insurance-selector.family-insurance.adult",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":6,"column":19},"end":{"line":6,"column":93}}})) != null ? stack1 : "")
    + "</label>\n            <div class=\"number-input-element js-number-input-adults\">\n                <a class=\"button button-minus\">\n                    <span class=\"icon-magnifier-minus\"></span>\n                </a>\n                <input type=\"text\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"adults") || (depth0 != null ? lookupProperty(depth0,"adults") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"adults","hash":{},"data":data,"loc":{"start":{"line":11,"column":42},"end":{"line":11,"column":52}}}) : helper)))
    + "\" class=\"form-element\" readonly>\n                <a class=\"button button-plus\">\n                    <span class=\"icon-magnifier-plus\"></span>\n                </a>\n            </div>\n        </div>\n\n        <div class=\"col col-xs-6 form-group\">\n            <label>"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.insurance-selector.family-insurance.children",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":19,"column":19},"end":{"line":19,"column":96}}})) != null ? stack1 : "")
    + "</label>\n            <div class=\"number-input-element js-number-input-childs\">\n                <a class=\"button button-minus\">\n                    <span class=\"icon-magnifier-minus\"></span>\n                </a>\n                <input type=\"text\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"childs") || (depth0 != null ? lookupProperty(depth0,"childs") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childs","hash":{},"data":data,"loc":{"start":{"line":24,"column":42},"end":{"line":24,"column":52}}}) : helper)))
    + "\" class=\"form-element\" readonly>\n                <a class=\"button button-plus\">\n                    <span class=\"icon-magnifier-plus\"></span>\n                </a>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});