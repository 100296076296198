import React from 'react';

export default function ChangeView () {

    return (
        <div className="change-examples">
            <h2>2. Colors TUICUNIT-2976</h2>
            <div className="part">
                <div className="box"> von</div>
                <div className="box"> zu</div>
            </div>
            <div className="part">
                <div className="box von1">BGC - #98d4e3</div>
                <div className="box zu1">BGC - #85cee4</div>
            </div>
            <div className="part">
                <div className="box von2">BGC - #c7f1ff</div>
                <div className="box zu2">BGC - #ceebf4</div>
            </div>
            <div className="part">
                <div className="box von3">BGC - #c7ecf7</div>
                <div className="box zu3">BGC - #ceebf4</div>
            </div>
            <div className="part">
                <div className="box von4">BGC - #eaf6f9</div>
                <div className="box zu4">BGC - #e7f5fa</div>
            </div>
            <div className="part">
                <div className="box von5">BGC - #e3f8ff</div>
                <div className="box zu5">BGC - #e7f5fa</div>
            </div>
            <div className="part">
                <div className="box2 von6">BGC - #f5a70f</div>
                <div className="box von6 von61">FC- #ffffff</div>
                <div className="box2 zu6">BGC - #eba6bd</div>
                <div className="box zu6 zu61">FC- #263e6a</div>
            </div>
            <div className="part">
                <div className="box von7">BGC - #98cfe1</div>
                <div className="box zu7">BGC - #9dd8e9</div>
            </div>
            <div className="part">
                <div className="box von8">BGC - #a4d2e1</div>
                <div className="box zu8">BGC - #9dd8e9</div>
            </div>
            <div className="part">
                <div className="box von9">BGC - #afe0f0</div>
                <div className="box zu9">BGC - #b6e2ef</div>
            </div>
        </div>
    );
}
