import _ from 'underscore';
import ApiStepBaseView from './base';
import app from '../../../app/app';
import ApiInputFactory from '../../../item/api-input/factory';
import templateWebpack from '../../../../tpl/item/api-step/bus.hbs';
import * as Constants from '../../../config/constants';
import { getIntl } from '../../../intl';

export default class BusApiStep extends ApiStepBaseView {
    constructor (options = {}) {
        super({
            templateWebpack,
            ...options
        });
        this.listenTo(this.model.inputs, 'change:inputValue', this.onChangeTracking);
    }

    /**
     * View regions.
     * Any region defined under the key of an input id will be filled with the
     * corresponding input view when calling the 'renderApiInputsToRegions' method..
     */
    regions () {
        return {
            [Constants.NETMATCH_INPUT_BUS_BUSID]: {
                el: '.api-input-busid',
                replaceElement: true
            },
            'bus-options': {
                el: '.api-display-bus-options',
                replaceElement: true
            }
        };
    }

    /**
     * Getter for the views class name
     */
    get className () {
        return `api-step api-step-bus api-step-${this.model.sanitizedId}`;
    }

    /**
     * Any object defined under the key of an input id will be passed as options
     * into the input views created when calling the 'renderApiInputsToRegions' method.
     */
    apiInputViewOptions () {
        const { formatMessage } = getIntl();

        return {
            [Constants.NETMATCH_INPUT_BUS_BUSID]: {
                displayType: 'bus-select',
                additionalTemplateContext: {
                    label: this.getInputLabel(),
                    title: this.getTitle(),
                    isShipbound: this.isShipbound(),
                    isRoundTrip: this.isRoundTrip(),
                    parties: this.getParties(),
                    optionGroup: formatMessage({
                        id: 'tpl.item.api-step.view.bus.options'
                    }),
                    nothingSubmittedOption: formatMessage({
                        id: 'tpl.item.api-step.view.bus.nothingToSubmitted'
                    })
                }
            }
        };
    }

    onRender () {
        this.renderApiInputsToRegions(this.model.inputs);
        if (this.model.isSomehowCompleted()) {
            const apiSubStepView = this.getBusFareView();
            if (apiSubStepView) {
                if (this.options.isRoundTrip) {
                    if (this.isShipbound()) {
                        this.options.mainView.renderSubviewFromChildShipbound(
                            {
                                isShipbound: true,
                                view: apiSubStepView
                            }
                        );
                    } else {
                        this.options.mainView.renderSubviewFromChildHomebound(
                            {
                                isShipbound: false,
                                view: apiSubStepView
                            }
                        );
                    }
                } else {
                    this.showChildView('bus-options', apiSubStepView);
                }
            }
        }
    }

    getBusFareView () {
        const { formatMessage } = getIntl();

        return ApiInputFactory.create('bus-options', {
            model: this.model.inputs.get('BusId'),
            autoSubmit: false,
            darkStyling: true,
            additionalTemplateContext: {
                title: this.getTitle(),
                isShipbound: this.isShipbound(),
                isRoundTrip: this.isRoundTrip(),
                isChoice: (this.isRoundTrip() && !this.isShipbound()) || !this.isRoundTrip(),
                parties: this.getParties(),
                optionGroup: formatMessage({
                    id: 'tpl.item.api-step.view.bus.options'
                }),
                nothingSubmittedOption: formatMessage({
                    id: 'tpl.item.api-step.view.bus.nothingToSubmitted'
                })
            }
        });
    }

    isRoundTrip () {
        return app.options.roundTrip;
    }

    isShipbound () {
        return this.model.id.indexOf(Constants.SHIPBOUND) !== -1;
    }

    getParties () {
        const { formatMessage } = getIntl();
        const getParty = (index, adult) => {
            const party = {
                title: `${index}. ${formatMessage({
                    id: adult ? 'general.persons.adult' : 'general.persons.child'
                }, {
                    amount: 1
                })}`,
                adult
            };
            return party;
        };
        const adults = Array.from(
            Array(app.apiSession.bookingStatus.party.get('adultCount')),
            (item, index) => getParty(index + 1, true)
        );
        const children = Array.from(
            Array(app.apiSession.bookingStatus.party.get('childCount')),
            (item, index) => getParty(index + 1, false)
        );
        return adults.concat(children);
    }

    getTitle () {
        const { formatMessage } = getIntl();
        let title = formatMessage({
            id: 'tpl.item.api-step.view.bus.cost.both'
        });

        if (this.isShipbound()) {
            title = formatMessage({
                id: 'tpl.item.api-step.view.bus.cost.to'
            });
        } else {
            title = formatMessage({
                id: 'tpl.item.api-step.view.bus.cost.back'
            });
        }

        return title;
    }

    getInputLabel () {
        const { formatMessage } = getIntl();
        // const ship = app.apiSession.bookingStatus.trip.get('name') || 'Mein Schiff';
        let label = '';

        if (this.isShipbound()) {
            label = formatMessage({
                id: 'tpl.item.api-step.view.bus.departure'
            });
        } else {
            label = formatMessage({
                id: 'tpl.item.api-step.view.bus.arrival'
            });
        }

        return label;
    }

    onChangeTracking (inputModelChange) {
        const translate = {
            [Constants.NETMATCH_STEP_SHIPBOUNDBUS]: 'Anreise',
            [Constants.NETMATCH_STEP_HOMEBOUNDBUS]: 'Abreise'
        };
        if (_.isUndefined(inputModelChange.changed.defaultValue)) {
            app.trackController.eventTracking({
                action: 'Bus',
                label: translate[inputModelChange.collection.parentModel.id],
                ga4clickLabel: `content.button.bus-${translate[inputModelChange.collection.parentModel.id]}`
            });
        }
    }
}
