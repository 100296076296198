import BaseModel from '../../../base/model/base';
import fecha from '../../../util/fecha-german';
import * as Constants from '../../../config/constants';


export default class ApiInputValidatorModel extends BaseModel {
    /**
     * Getter for the corresponding api input model
     */
    get apiInput () {
        return this.collection.parentModel;
    }


    /**
     * validates the given value depending on the validator type and properties
     */
    validateValue (value) {
        if (typeof value === 'string') {
            value = value.trim();
        }
        const validationType = this.get('type');
        let valid = true;

        // only validate when having a value, except Required validator
        if (validationType !== Constants.NETMATCH_VALIDATOR_TYPE_REQUIRED) {
            if (!this.hasValue(value)) {
                // TUICIBE-475: the Bus Step has no NETMATCH_VALIDATOR_TYPE_REQUIRED, but this Step need a Value!
                if (this.apiInput.get('id') !== Constants.NETMATCH_INPUT_BUS_BUSID) {
                    return true;
                }
            }
        }

        switch (validationType) {
            case Constants.NETMATCH_VALIDATOR_TYPE_REQUIRED:
                valid = this.validateRequired(value);
                break;
            case Constants.NETMATCH_VALIDATOR_TYPE_REQUIRED_WHEN_OTHER: {
                const otherInput = this.apiInput.apiStep.inputs.get(this.get('otherPropertyName'));
                if (otherInput) {
                    if (otherInput.getInputValue() === this.get('otherPropertyValue')) {
                        // only check own value if other value is matched
                        return this.validateRequired(value);
                    }
                }
                return true;
            }
            case Constants.NETMATCH_VALIDATOR_TYPE_RANGE:
                valid = this.validateRange(value);
                break;
            case Constants.NETMATCH_VALIDATOR_TYPE_DATERANGE:
                valid = this.validateDate(value);
                break;
            case Constants.NETMATCH_VALIDATOR_TYPE_REGULAREXPRESSION:
            case Constants.NETMATCH_VALIDATOR_TYPE_MUSTMATCHCONTEXREGEX:
                valid = this.validateRegEx(value);
                break;
            case Constants.NETMATCH_VALIDATOR_TYPE_MINLENGTH:
                valid = this.validateMinLength(value);
                break;
            case Constants.NETMATCH_VALIDATOR_TYPE_MAXLENGTH:
                valid = this.validateMaxLength(value);
                break;
            case Constants.NETMATCH_VALIDATOR_TYPE_MUSTMATCHONEFROMCOLLECTION:
                valid = this.validateMustMatchCollection(value);
                break;
            case Constants.NETMATCH_VALIDATOR_TYPE_EMAILADDRESS:
                valid = this.validateEmailAddress(value);
                break;
            case Constants.NETMATCH_VALIDATOR_TYPE_COMPARE:
                valid = this.validateCompareEmail(value);
                break;
            case Constants.NETMATCH_VALIDATOR_TYPE_CASEINSENSITIVECOMPARE:
                valid = this.validateCompareEmail(value);
                break;
            case Constants.NETMATCH_VALIDATOR_TYPE_VALIDIBAN:
                valid = this.validateIBAN(value);
                break;
            case Constants.NETMATCH_VALIDATOR_TYPE_LENGTH:
                valid = this.validateLength(value);
                break;
            case Constants.NETMATCH_VALIDATOR_TYPE_MINIMUMOFFSETDATE:
                valid = this.validateMinOffsetDate(value);
                break;
            case Constants.NETMATCH_VALIDATOR_TYPE_MAXIMUMOFFSETDATE:
                valid = this.validateMaxOffsetDate(value);
                break;
            case Constants.NETMATCH_VALIDATOR_TYPE_VALIDPARTYCOMPOSITION:
                valid = this.validPartyComposition(value);
                break;
            case Constants.NETMATCH_VALIDATOR_TYPE_EQUALS:
                if (this.apiInput.apiStep.id === Constants.NETMATCH_STEP_INSURANCETERMSANDCONDITIONS || this.apiInput.apiStep.id === Constants.NETMATCH_STEP_APPROVAL) {
                    valid = value === this.get('matchValue');
                    break;
                } else {
                    valid = true;
                }
                break;
            case Constants.NETMATCH_VALIDATOR_TYPE_EQUALS_WHEN_OTHER: {
                const otherInput = this.apiInput.apiStep.inputs.get(this.get('otherPropertyName'));
                if (otherInput) {
                    if (otherInput.getInputValue() === this.get('otherPropertyValue')) {
                        // only check own value if other value is matched
                        return value === this.get('matchValue');
                    }
                }
                return true;
            }
            // if (this.apiInput.apiStep.id === Constants.NETMATCH_STEP_APPROVAL) {
            //     valid = value === this.get('matchValue');
            //     break;
            // } else {
            //     valid = true;
            // }
            // break;

            // maybe unneeded validators
            case Constants.NETMATCH_VALIDATOR_TYPE_ADULTSLENGTH:
            case Constants.NETMATCH_VALIDATOR_TYPE_CHILDRENLENGTH:
            case Constants.NETMATCH_VALIDATOR_TYPE_MAXINSURANCEPOLICIES:
            case Constants.NETMATCH_VALIDATOR_TYPE_INDIVIDUALPOLICIESRANGE:
            case Constants.NETMATCH_VALIDATOR_TYPE_COUPLEPOLICIESRANGE:
            case Constants.NETMATCH_VALIDATOR_TYPE_FAMILYPOLICIESRANGE:
            case Constants.NETMATCH_VALIDATOR_TYPE_DUMMYPOLICIESRANGE:
            case Constants.NETMATCH_VALIDATOR_TYPE_PARTICIPANTINDEXESLENGTH:
            case Constants.NETMATCH_VALIDATOR_TYPE_ADULTPARTICIPANTSTYPEINDEXESLENGTH:
            case Constants.NETMATCH_VALIDATOR_TYPE_CHILDPARTICIPANTSTYPEINDEXESLENGTH:
                valid = true;
                break;

            // validators only used / set by the backend
            case Constants.NETMATCH_VALIDATOR_TYPE_DATATYPE:
            case Constants.NETMATCH_VALIDATOR_TYPE_VALIDAGENCYID:
            case Constants.NETMATCH_VALIDATOR_TYPE_VALIDCRUISECODE:
            case Constants.NETMATCH_VALIDATOR_TYPE_VALIDTRAINSTATION:
            case Constants.NETMATCH_VALIDATOR_TYPE_VALIDPARTICIPANTINDEXES:
            case Constants.NETMATCH_VALIDATOR_TYPE_MAXPOLICIES:
            case Constants.NETMATCH_VALIDATOR_TYPE_VALIDTRIPCODE:
            case Constants.NETMATCH_VALIDATOR_TYPE_VALIDEPACKAGECODE:
            case Constants.NETMATCH_VALIDATOR_TYPE_VALIDTRAINSTATIONID:
            case Constants.NETMATCH_VALIDATOR_TYPE_VALIDTRAINSTATIONNAME:
            case Constants.NETMATCH_VALIDATOR_TYPE_VALIDCOUNTRYCODE:
            case Constants.NETMATCH_VALIDATOR_TYPE_VALIDNATIONALITY:
            case Constants.NETMATCH_VALIDATOR_TYPE_NOTEQUALTRAINSTATION:
            case Constants.NETMATCH_VALIDATOR_TYPE_MUSTMATCHREFERENCEINFLIGHTOPTIN:
            case 'ValidCampaignCode':
            case 'CompatibleCampaignCodes':
            case 'MustMatchQueueTripCode':
                valid = true;
                break;
            default:
                console.warn('Unknown validator', validationType, this.toJSON(), this.apiInput.id);
        }
        if (!valid) {
            console.warn('failed validator', this.apiInput.get('id'), validationType.toUpperCase(), 'with value', value);
            return this.get('warning');
        }
        return valid;
    }


    /**
     *
     */
    validateRequired (value) {
        return this.hasValue(value);
    }


    /**
     *
     */
    validateEmailAddress (value) {
        value = `${value}`;
        let regEx = this.get('regexPattern');
        if (!this.hasValue(regEx)) {
            // TODO we do not want to have the validation logic here
            // very basic email validation by regex
            regEx = '^\\S+@\\S+\\.\\S+$';
        }
        try {
            regEx = new RegExp(regEx);
        } catch (e) {
            return true;
        }
        if (!regEx.test(value)) {
            return false;
        }
        return true;
    }

    /**
     *
     */
    validateRange (value) {
        value = +value;
        if (isNaN(value)) {
            return false;
        }
        const min = +this.get('minimum') || 0;
        const max = +this.get('maximum') || 0;
        if (value < min || value > max) {
            return false;
        }
        return true;
    }


    /**
     *
     */
    validateMinLength (value) {
        if (!this.hasValue(this.get('length'))) {
            console.warn('broken minLength validator: length:', this.get('length'));
            return true;
        }
        value = `${value}`;
        const minLength = +this.get('length');
        if (value.length < minLength) {
            return false;
        }
        return true;
    }


    /**
     *
     */
    validateMaxLength (value) {
        if (!this.hasValue(this.get('length'))) {
            console.warn('broken maxLength validator: length:', this.get('length'));
            return true;
        }
        value = `${value}`;
        const maxLength = +this.get('length');
        if (value.length > maxLength) {
            return false;
        }
        return true;
    }


    /**
     * @param {string} value    Expected sample values: "2", "[1,2,3]"
     * @return {boolean}
     */
    validateLength (value) {
        value = `${value}`; // avoid null values
        const valueLength = value.split(',').length;
        const minLength = +this.get('minParticipants');
        const maxLength = +this.get('maxParticipants');

        if (isNaN(minLength) || !this.hasValue(minLength) || isNaN(maxLength) || !this.hasValue(maxLength)) {
            console.warn('broken length validator: min-/maxParticipants:', minLength, maxLength);
            return true;
        }

        return valueLength >= minLength && valueLength <= maxLength;
    }


    /**
     *
     */
    validateRegEx (value) {
        value = `${value}`;
        let regEx = this.get('regexPattern');
        if (!this.hasValue(regEx)) {
            console.warn('empty regEx validator: pattern:', regEx);
            return true;
        }
        try {
            regEx = new RegExp(regEx);
        } catch (e) {
            console.warn('broken regEx validator: pattern:', regEx);
            return true;
        }
        if (!regEx.test(value)) {
            return false;
        }
        return true;
    }


    /**
     *
     */
    validateDate (value) {
        try {
            value = fecha.parse(value, 'germanShortDate');
        } catch (e) {
            return false;
        }

        let min;
        let max;
        try {
            min = fecha.parse(this.get('minimum'), 'edmDateTimeOffset');
            max = fecha.parse(this.get('maximum'), 'edmDateTimeOffset');
        } catch (e) {
            return true;
        }
        if (value < min || value > max) {
            return false;
        }
        return true;
    }


    /**
     *
     */
    validateMinOffsetDate (value) {
        // pre Date validation
        try {
            const valuePart = value.split('.');
            const checkdate = new Date(valuePart[2], valuePart[1] - 1, valuePart[0]);
            // is "value" a "Date"
            if (checkdate instanceof Date && !isNaN(checkdate.valueOf()) === false) {
                return false;
            }
            // is "checkdate" still gives the same "value"
            if (checkdate.getFullYear() !== parseInt(valuePart[2], 10) || (checkdate.getMonth() + 1) !== parseInt(valuePart[1], 10) || checkdate.getDate() !== parseInt(valuePart[0], 10)) {
                return false;
            }
        } catch (e) {
            return false;
        }

        try {
            value = fecha.parse(value, 'germanShortDate');
        } catch (e) {
            return false;
        }

        let minimumDate;
        try {
            minimumDate = fecha.parse(this.get('minimumDate'), 'edmDate');
        } catch (e) {
            return true;
        }
        if (value < minimumDate) {
            return false;
        }
        return true;
    }


    /**
     *
     */
    validateMaxOffsetDate (value) {
        try {
            value = fecha.parse(value, 'germanShortDate');
        } catch (e) {
            return false;
        }

        let maximumDate;
        try {
            maximumDate = fecha.parse(this.get('maximumDate'), 'edmDate');
        } catch (e) {
            return true;
        }
        if (value > maximumDate) {
            return false;
        }
        return true;
    }


    /**
     *
     */
    validateMustMatchCollection (value) {
        const options = (this.apiInput.get('options') && this.apiInput.get('options').length) ? this.apiInput.get('options') : [];

        // add check if option not isBlocked
        // eslint-disable-next-line eqeqeq
        // return !!options.find(option => option.id == value);
        // eslint-disable-next-line eqeqeq
        return !!options.find(option => option.id == value && option.isBlocked !== true);
    }


    /**
     *
     */
    hasValue (value) {
        return value !== null && value !== undefined && value !== '';
    }

    /**
     * set by the backend
     */
    validateIBAN (value) {
        if (!this.get('isValid') || this.get('isValid') === null) {
            return true;
        }
        if (this.get('isValid') === false) {
            if (this.apiInput.get('resultValue') !== value) {
                return true;
            }
        }
        return this.get('isValid');
    }

    validPartyComposition () {
        const min = this.get('minimum') || 1;
        const max = this.get('maximum') || 6;
        const adultCount = parseInt(this.apiInput.collection.get(Constants.NETMATCH_INPUT_PARTY_ADULTS).get('inputValue'), 10);
        const childCount = parseInt(this.apiInput.collection.get(Constants.NETMATCH_INPUT_PARTY_CHILDREN).get('inputValue'), 10);

        if ((adultCount + childCount) > max) {
            return false;
        }
        if ((adultCount + childCount) < min) {
            return false;
        }

        return true;
    }

    validateCompareEmail (value) {
        if (this.apiInput.id !== Constants.NETMATCH_INPUT_INVOICE_EMAILREPEAT) {
            return true;
        }
        const eMail = this.apiInput.collection.get(Constants.NETMATCH_INPUT_INVOICE_EMAIL).get('inputValue');
        return eMail && eMail.toLowerCase() === value.toLowerCase();
    }

    compareEmail (value) {
        const eMail = this.apiInput.collection.get(Constants.NETMATCH_INPUT_INVOICE_EMAIL).get('inputValue');
        if (eMail && value) {
            if (eMail.toLowerCase() === value.toLowerCase()) {
                return true;
            }
        }
        return false;
    }
}
