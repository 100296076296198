import $ from 'jquery';
import _ from 'underscore';
import app from '../../../app/app';
import BaseItem from '../../../base/view/item';
import { getIntl } from '../../../intl';
import ApiInputFactory from '../../../item/api-input/factory';


export default class ApiStepBaseView extends BaseItem {
    constructor (options = {}) {
        super({
            ...options,
            key: 'api-step'
        });

        this.apiInputViews = {};

        this.autoSubmit = !!this.getOption('autoSubmit');

        this.listenTo(this.model, 'change', this.render);
        this.listenTo(this.model.inputs, 'change:inputValue', this.onInputValueChanged);
    }


    /**
     * Getter for the views class name
     */
    get className () {
        return `api-step api-step-${this.model.sanitizedId}`;
    }

    /**
     * Add details to the data passed into the template.
     * Will be merged into the model / collection data.
     */
    templateContext () {
        return {
            ...this.options.additionalTemplateContext,
            apiStatus: this.model.status,
            disabled: this.model.isPending() || this.model.isBlocked() || this.model.isNotApplicable()
        };
    }


    /**
     *
     */
    get stepId () {
        return this.model.id;
    }


    /**
     * Triggers an event on the view when the models input value changed.
     * Submits the  changed value (if valid) if the view was passed the 'autoSubmit' option
     */
    onInputValueChanged () {
        console.log('onInputValueChanged');
        const inputData = this.model.getInputData();
        this.triggerMethod('step:did:change');
        this.triggerMethod('step:changed', this.model.id, inputData, this, this.model);
        if (!this.model.hasInputErrors()) {
            this.triggerMethod('step:changed:valid', this.model.id, inputData, this, this.model);
            if (this.autoSubmit && this.model.hasChangedInput()) {
                this.doAutoSubmit(this.model.id, inputData);
            }
        } else {
            this.triggerMethod('step:changed:invalid', this.model.id, inputData, this, this.model);
        }
    }


    /**
     *
     */
    doAutoSubmit (stepId, stepData) {
        const showAutoSubmitError = () => {
            this.render();
            app.trigger('overlay:start', {
                text: getIntl().formatMessage({
                    id: 'tpl.item.api-step.view.base.error'
                })
            });
            app.trigger('scrollTo', this.$el, () => {
                const $window = $(window);
                $window.scrollTop($window.scrollTop() + 1);
            });
        };
        app.apiSession.submitStep(stepId, stepData, true)
            .then(() => {
                if (this.model.hasSubmitErrors()) {
                    showAutoSubmitError();
                }
                this.triggerMethod('step:did:change');
            })
            .catch(showAutoSubmitError);
    }


    /**
     *
     */
    validateInputs () {
        let allInputsValid = true;
        _.forEach(this.apiInputViews, apiInputView => {
            if (apiInputView.validateInput() !== true) {
                allInputsValid = false;
            }
        });
        return allInputsValid;
    }


    /**
     * Used by api-step views, passing in their model.inputs, which describe what inputs are needed for the step
     */
    renderApiInputsToRegions (apiInputModels) {
        apiInputModels.forEach(apiInputModel => {
            const apiInputId = apiInputModel.id;
            if (this.getRegion(apiInputId)) {
                const inputViewOptions = this.getApiInputViewOptions(apiInputId, apiInputModel);
                const apiInputView = ApiInputFactory.create(inputViewOptions.displayType, inputViewOptions);
                this.showChildView(apiInputId, apiInputView);
                this.apiInputViews[apiInputId] = apiInputView;
            }
        });
        return this.apiInputViews;
    }


    /**
     * @param {string} inputId
     * @param {ApiInputModel} apiInputModel
     */
    getApiInputViewOptions (inputId, apiInputModel) {
        // console.log('ApiStepBaseView::getApiInputViewOptions | inputId:', inputId, 'apiInputModel:', apiInputModel);
        const defaultDisplayType = apiInputModel.get('type');

        // retrieve all specified apiInputViewOptions from an api-step view implementation
        let inputViewOptions = _.result(this, 'apiInputViewOptions', {});
        // use only the option selected by param
        inputViewOptions = inputViewOptions[inputId] || {};

        let displayType = inputViewOptions.displayType || defaultDisplayType;
        switch (displayType) {
            case 'choice':
                displayType = 'select';
                break;
            case 'boolean':
                displayType = 'checkbox';
                break;
            case 'typeahead':
                // pass in apiInputModel.domId in order to construct a domId for typeAheadModel
                inputViewOptions.typeAheadModel.apiInputDomId = apiInputModel.domId;
                break;
            default:
        // nothing to do here
        }
        inputViewOptions.displayType = displayType;

        inputViewOptions.darkStyling = !!this.getOption('darkStyling');
        // overwrite with given apiInputModel from the api-step view
        inputViewOptions.model = apiInputModel;

        return inputViewOptions;
    }

    getParties () {
        const getParty = (index, adult) => {
            const party = {
                title: `${index}. ${getIntl().formatMessage({
                    id: adult ? 'general.persons.adult' : 'general.persons.child'
                }, {
                    amount: 1
                })}`,
                adult
            };
            return party;
        };
        const adults = Array.from(
            Array(app.apiSession.bookingStatus.party.get('adultCount')),
            (item, index) => getParty(index + 1, true)
        );
        const children = Array.from(
            Array(app.apiSession.bookingStatus.party.get('childCount')),
            (item, index) => getParty(index + 1, false)
        );
        return adults.concat(children);
    }
}
