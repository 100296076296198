import BaseModel from '../../../base/model/base';
import BaseCollection from '../../../base/collection/base';
import * as Constants from '../../../config/constants';


export default class InsuranceModel extends BaseModel {
    constructor (attributes = {}, options = {}) {
        super(attributes, options);

        this.assignedParticipantSteps = new BaseCollection();
        this.listenTo(this.policyAssigmentStep, 'all', () => {
            this.resetAssignedParticipants();
        });
        this.resetAssignedParticipants();

        this.listenTo(this.assignedParticipantSteps, 'all', () => {
            this.setCountAttributes();
            this.setPolicyAssignment();
            this.trigger('change');
        });

        this.assignedParticipantSteps.forEach(assignedParticipantStep => {
            this.listenTo(assignedParticipantStep.inputs, 'change:inputValue', () => {
                if (!this.collection || !this.collection.insuranceSelectorModel) {
                    return;
                }

                this.collection.insuranceSelectorModel.trigger('update:pager');
            });
        });

        if (attributes.type) {
            this.setCountAttributes();
        }

        if (this.policyTypeStep && this.policyTypeStep.inputs.get(Constants.NETMATCH_INPUT_POLICY)) {
            this.listenTo(this.policyTypeStep.inputs.get(Constants.NETMATCH_INPUT_POLICY), 'change:inputValue', () => {
                this.trigger('change');
            });
        }
    }

    /**
     * @see: http://backbonejs.org/#Model-idAttribute
     */
    get idAttribute () {
        return 'id';
    }


    get policyAssigmentStep () {
        try {
            return this.collection.insuranceSelectorModel.getApiStepsPolicyAssignment().find(step => step.insuranceNumericStepId === this.id);
        } catch (e) {
            return null;
        }
    }


    get policyTypeStep () {
        try {
            return this.collection.insuranceSelectorModel.getApiStepsPolicyType().find(step => step.insuranceNumericStepId === this.id);
        } catch (e) {
            return null;
        }
    }


    get assignedAdultSteps () {
        const adultRegex = new RegExp(Constants.NETMATCH_STEP_ADULT);
        return this.assignedParticipantSteps.filter(step => step.id.match(adultRegex));
    }


    get assignedChildSteps () {
        const childRegex = new RegExp(Constants.NETMATCH_STEP_CHILD);
        return this.assignedParticipantSteps.filter(step => step.id.match(childRegex));
    }


    setPolicyAssignment () {
        if (!this.policyAssigmentStep) {
            return;
        }
        const assignedParticipantIds = this.assignedParticipantSteps.map(step => step.get('occupantIndex'));
        this.policyAssigmentStep.inputs.get(Constants.NETMATCH_INPUT_ASSIGN_TO).setInputValue(assignedParticipantIds, {
            silent: true
        });
    }


    setCountAttributes () {
        let adults = this.assignedAdultSteps.length;
        let childs = this.assignedChildSteps.length;

        // adults can be assigned as childs
        const adultsAsChilds = adults > 2 ? (adults - 2) : 0;
        adults = Math.min(adults, 2);
        childs += adultsAsChilds;

        if (!adults) {
            adults = this.isCoupleInsurance() ? 2 : 1;
            if (!this.isFamilyInsurance() && childs) {
                adults -= childs;
            }
        }

        if (!childs) {
            childs = this.isFamilyInsurance() ? 1 : 0;
        }

        this.set({
            adults,
            childs,
            minAdults: this.isCoupleInsurance() ? 2 : 1,
            maxAdults: this.isIndividualInsurance() || this.isDummyInsurance() ? 1 : 2,
            minChilds: this.isFamilyInsurance() ? 1 : this.isIndividualInsurance() || this.isDummyInsurance() ? 1 : 2,
            maxChilds: this.isFamilyInsurance() ? 5 : (this.isIndividualInsurance() || this.isDummyInsurance() ? 1 : 2),
            minTotal: this.isIndividualInsurance() || this.isDummyInsurance() ? 1 : 2,
            maxTotal: this.isIndividualInsurance() || this.isDummyInsurance() ? 1 : (this.isFamilyInsurance() ? 7 : 2)
        });
    }


    hasAssignedParticipants () {
        return !!this.assignedParticipantSteps.length;
    }


    resetAssignedParticipants () {
        if (!this.policyAssigmentStep || !this.policyAssigmentStep.inputs.get(Constants.NETMATCH_INPUT_ASSIGN_TO) || !this.policyAssigmentStep.inputs.get(Constants.NETMATCH_INPUT_ASSIGN_TO).getInputValue()) {
            this.assignedParticipantSteps.reset();
            return;
        }

        const participantSteps = this.collection.insuranceSelectorModel.getApiStepsParticipants().reduce((steps, step) => {

            steps[step.get('occupantIndex')] = step;
            return steps;
        }, {});

        let assignedIds = this.policyAssigmentStep.inputs.get(Constants.NETMATCH_INPUT_ASSIGN_TO).getInputValue();

        if (typeof assignedIds === 'string') {
            // Insurance -> Participant Match
            assignedIds = assignedIds.replace('[', '').replace(']', '');
            assignedIds = assignedIds.split(',');
        }
        const assignedParticipantSteps = assignedIds.reduce((participants, participantId) => {
            if (participantSteps[participantId]) {
                participants.push(participantSteps[participantId]);
            }
            return participants;
        }, []);
        this.assignedParticipantSteps.reset(assignedParticipantSteps);
    }


    isIndividualInsurance () {
        return this.get('type') === Constants.NETMATCH_INSURANCE_MEMBERSTYPE_INDIVIDUAL;
    }


    isCoupleInsurance () {
        return this.get('type') === Constants.NETMATCH_INSURANCE_MEMBERSTYPE_COUPLE;
    }


    isFamilyInsurance () {
        return this.get('type') === Constants.NETMATCH_INSURANCE_MEMBERSTYPE_FAMILY;
    }


    isDummyInsurance () {
        return this.get('type') === Constants.NETMATCH_INSURANCE_MEMBERSTYPE_DUMMY;
    }
}
