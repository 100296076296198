import config from '../config/config';


const NativeStorage = (config.storage === 'local' && window.localStorage) ? window.localStorage : (window.sessionStorage || null);
const storageSupported = NativeStorage !== null;


export default {

    /**
     * get browser storage support
     *
     * @return {Boolean}
     */
    supported: () => storageSupported,

    /**
     * Retrieves a value from the storage
     *
     * @param  {string}  key
     * @param  {Boolean} [raw] If TRUE, no JSON decoding will be performed on the value
     * @return {object}
     */
    get: (key, raw = false) => {
        if (!storageSupported) {
            return null;
        }
        let value = NativeStorage.getItem(key);
        if (!raw) {
            value = JSON.parse(value);
        }
        return value;
    },

    /**
     * Stores a key / value pair in the storage
     *
     * @param  {string}  key
     * @param  {object}  value
     * @param  {Boolean} [raw] If TRUE, no JSON encoding will be performed on the value
     * @return {Boolean}       TRUE if the browsers supports the storage
     */
    set: (key, value, raw = false) => {
        if (!storageSupported) {
            return false;
        }
        if (!raw) {
            try {
                value = JSON.stringify(value);
            } catch (e) {
                throw new Error('invalid JSON');
            }
        }
        NativeStorage.setItem(key, value);
        return true;
    },


    /**
     * Deletes a key and its value from the storege
     *
     * @param  {strin}    key
     * @return {Boolean}       TRUE if the browsers supports the storage
     */
    unset: (key) => {
        if (!storageSupported) {
            return false;
        }
        NativeStorage.removeItem(key);
        return true;
    },


    /**
     * Deletes all keys / values from the storege
     *
     * @return {Boolean} TRUE if the browsers supports the storage
     */
    clear: () => {
        if (!storageSupported) {
            return false;
        }
        NativeStorage.clear();
        return true;
    }
};
