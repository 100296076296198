import React, {useState, useEffect, useRef} from 'react';
import OverlayView from '../overlay';

import app from '../../app/app';

import * as Constants from '../../config/constants';
import {CabinPrice} from '../cabin/cabin-price';
import {FormattedMessage, useIntl} from 'react-intl';
import StringUtil from '../../util/string';
import {getIntl} from '../../intl';

type TrainOptionsProps = {
    stepModelTrain: any;
    isRoundTrip: boolean;
};

type OptionsTrainInput = {
    class: number;
    id: string;
    isBlocked?: boolean;
    price: number;
    priceType: string;
    totalPrice: string;
    tariff: string;
}
type StepDataType = { id: string, content: {} };

export default function TrainOptionsView (props: TrainOptionsProps) {
    const {
        stepModelTrain,
        isRoundTrip
    } = props;
    const {
        priceType,
        classOption
    } = prepareTrainOptions(stepModelTrain);


    const [refresh, setRefresh] = useState(false);

    const partyData = app?.apiSession?.bookingStatus?.party.toJSON();


    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setRefresh(!refresh);
    }, [stepModelTrain]);
    /* eslint-enable react-hooks/exhaustive-deps */

    const isShipbound = stepModelTrain.id.indexOf(Constants.SHIPBOUND) !== -1;

    /**
     * TUICIBE-273
     */
    const onTravelClassChange = (newTravelClass: string) => {
        const stepsToSubmit: Array<StepDataType> = [];

        const apiStepModelsToSubmit = stepModelTrain;

        if (isRoundTrip === true) {
            const apiSteps: any = app?.apiSession?.steps || null;
            const homeBoundTrainSteps = apiSteps?.filter((apiStep: any) => apiStep.id === Constants.NETMATCH_STEP_HOMEBOUNDTRAIN) || [];
            let submitModels = [].concat(apiStepModelsToSubmit);
            submitModels = submitModels.concat(homeBoundTrainSteps);

            submitModels.forEach((model: any) => {
                const stepData = valueToSubmit(model, newTravelClass);
                if (stepData) {
                    stepsToSubmit.push(stepData);
                }
            });
        } else {
            const stepData = valueToSubmit(apiStepModelsToSubmit, newTravelClass);
            if (stepData) {
                stepsToSubmit.push(stepData);
            }
        }

        console.log(stepsToSubmit);

        if (stepsToSubmit.length && app?.apiSession) {
            app.apiSession.submitSteps(stepsToSubmit, true);
        }
        if (app?.trackController) {
            app.trackController.eventTracking({
                action: 'Bahn',
                label: newTravelClass,
                ga4clickLabel: `content.button.bahn-klasse-${newTravelClass}`
            });
        }
    };

    const valueToSubmit = (model: any, newTravelClass: string) => {
        let stepToSubmit: StepDataType | null = null;
        const options = model.inputs.get(Constants.NETMATCH_INPUT_TRAIN_SERVICEID).getOptions();
        const newClassOption = options.find((item: any) => item.id === newTravelClass);

        if (newClassOption) {
            const stepData = {
                'id': model.get('id'),
                'content': {
                    [Constants.NETMATCH_INPUT_TRAIN_SERVICEID]: newClassOption.id
                }
            };

            const apiSteps: any = app?.apiSession?.steps || null;
            const step = apiSteps?.get(stepData.id);
            if (step && step.validateData(stepData).valid) {
                stepToSubmit = stepData;
            }

            return stepToSubmit;
        }
    };

    const isSelect = priceType.find((value: any) => value.selected === true) || null;
    let initClass = 2;
    if (isSelect) {
        initClass = isSelect.selectedClass;
    }

    const overlayTrainRef = useRef();
    const onTrainOverlay = () => {
        // @ts-ignore
        if (overlayTrainRef.current && overlayTrainRef.current.showModal === false) {
            // @ts-ignore
            overlayTrainRef.current.handleOpenModal();
        }
    };


    return (
        <div className={`option-wrapper${isRoundTrip ? ' is-round-trip' : ''}`}>
            <div className="api-step-train">{refresh}
                {!isRoundTrip
                    ? (
                        <>
                            <PartyView partyData={partyData}></PartyView>
                            <div className="form-group">
                                <TrainOptionView classOption={classOption} priceType={priceType} isSelect={initClass} isRoundTrip={isRoundTrip} callback={onTravelClassChange}/>
                            </div>
                            <div className="transfer-to-ship">
                                <FormattedMessage id={
                                    isShipbound
                                        ? 'components.travel.train-options.transfer-to'
                                        : 'components.travel.train-options.transfer-from'
                                }/> <span className="icon icon-ship-transfer"></span>
                            </div>
                            <div className="hint help-hint train-hint">
                                <a onClick={onTrainOverlay}>
                                    <FormattedMessage id="components.travel.train-options.more-information"/>
                                </a>
                            </div>
                            <OverlayView overlayId={'train-info'} closeOnBgClick={true} hasBackButton={true} data={null} ref={overlayTrainRef}/>
                        </>
                    )
                    : !!isShipbound && (
                        <>
                            <PartyView partyData={partyData}></PartyView>
                            <div className="form-group">
                                <TrainOptionView classOption={classOption} priceType={priceType} isSelect={initClass} isRoundTrip={isRoundTrip} callback={onTravelClassChange}/>
                            </div>
                            <div className="hint transfer-to-ship">
                                <FormattedMessage id="components.travel.train-options.transfer"/> <span className="icon icon-ship-transfer"/>
                            </div>
                            <div className="hint help-hint train-hint">
                                <a onClick={onTrainOverlay}>
                                    <FormattedMessage id="components.travel.train-options.more-information"/>
                                </a>
                            </div>
                            <OverlayView overlayId={'train-info'} closeOnBgClick={true} hasBackButton={true} data={null} ref={overlayTrainRef}/>
                        </>
                    )
                }
            </div>

        </div>
    );
}

const PartyView = (props: { partyData: any }) => {
    const {formatMessage} = useIntl();
    const {partyData} = props;

    const adult = formatMessage({
        id: 'general.persons.adult'
    }, {
        amount: partyData.adultCount
    });

    const child = partyData.childCount > 0
        ? [
            formatMessage({
                id: 'general.persons.child'
            }, {
                amount: partyData.childCount
            })`(${formatMessage({
                id: 'components.travel.train-options.inclusive'
            })})`
        ].join(' ')
        : '';

    const overlayChildRef = useRef();
    const onChildOverlay = () => {
        // @ts-ignore
        if (overlayChildRef.current && overlayChildRef.current.showModal === false) {
            // @ts-ignore
            overlayChildRef.current.handleOpenModal();
        }
    };

    return (
        <div className="party">
            <div className="top">
                <FormattedMessage id="components.travel.train-options.travelers"/>:
            </div>
            <div>{adult}</div>
            {child ?
                <div className="hint help-hint child-hint">
                    <a onClick={onChildOverlay}>{child}</a>
                    <OverlayView overlayId={'train-child'} closeOnBgClick={true} hasBackButton={true} data={null} ref={overlayChildRef}/>
                </div>
                : ''}
        </div>
    );

};

const TrainOptionView = (props: {
    classOption: any,
    priceType: any,
    isSelect: number,
    isRoundTrip: boolean,
    callback: Function
}) => {
    const {
        classOption,
        priceType,
        isSelect,
        isRoundTrip,
        callback
    } = props;
    const [selectClass, setSelectClass] = useState(isSelect);

    const toSumbit = (id: string) => {
        callback(id);
    };

    return (
        <div className="travel-options">
            <div className="travel-class-options">
                {classOption.map((option: any, key: number) => {
                    return (
                        <div key={key} className={`radiobutton-element is-inline${selectClass === option.class ? ' checked' : ''}`}>
                            <input type="radio" id={option.domId} value={option.class} disabled={option.disabled} checked={selectClass === option.class} onChange={() => {
                                setSelectClass(option.class);
                            }}/>
                            <label htmlFor={option.domId}>{option.label}</label>
                        </div>);
                })}
                <div className="tarif">
                    {priceType.map((item: any, key: number) => {
                        return (
                            <div key={key} className={`part ${item.count}${item.selected ? ' selected' : ''}`}>
                                <div className="info">
                                    <div className="head">Deutsche Bahn AG</div>
                                    <div className="api-step-headline"> {item.tariff}</div>
                                    <ul className="part-l">
                                        {item.texte.map((value: any, textkey: number) => {
                                            return (
                                                <li key={textkey}><span>{value.text}</span></li>
                                            );
                                        })}
                                    </ul>
                                </div>
                                <div className="price-wrapper">
                                    {item.data.map((value: any, datakey: number) => {
                                        // maybe RoundTrip we need to double the price (ship and home bound)
                                        let price = {
                                            singlePrice: value.price,
                                            totalPrice: value.totalPrice
                                        };
                                        if (isRoundTrip) {
                                            price = {
                                                singlePrice: value.price + value.price,
                                                totalPrice: value.totalPrice + value.totalPrice
                                            };
                                        }
                                        return (
                                            <span key={datakey} className="cta">
                                                {selectClass === value.class ?
                                                    <div>
                                                        <div className="nobr">
                                                            <CabinPrice price={price.singlePrice} mode="simple_pp"/>
                                                        </div>
                                                        <div className="price nobr">
                                                            <span className="p2">
                                                                <CabinPrice price={price.totalPrice} mode="simple"/>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    : ''}
                                                {selectClass === value.class && value.selected === false ?
                                                    <a className={`button train-${value.id}`} onClick={() => {
                                                        toSumbit(value.id);
                                                    }}>
                                                        <FormattedMessage id="general.tarif.select"/>
                                                    </a>
                                                    : ''}
                                                {selectClass === value.class && value.selected === true ?
                                                    <span className="button-selected">
                                                        <FormattedMessage id="general.selected"/>
                                                    </span>
                                                    : ''}
                                            </span>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

const prepareTrainOptions = (stepModelTrain: any) => {

    const content = app.contentApi;
    const texte: any = content ? content.messages.prepareMessages('transport') : null;
    const thisInput = stepModelTrain?.inputs?.get(Constants.NETMATCH_INPUT_TRAIN_SERVICEID) || null;
    const inputOptions = thisInput?.getOptions();

    inputOptions.sort((a: OptionsTrainInput, b: OptionsTrainInput) => {
        return b.class - a.class;
    });

    const serviceId = `${thisInput?.getInputValue()}`;
    const selectedOption = thisInput?.getOptionById(serviceId) || {};

    const groupByClassOption: any = {};
    const groupByPriceType: any = {};
    inputOptions.forEach((item: OptionsTrainInput) => {
        if (!item.isBlocked) {
            const data = {
                id: item.id,
                value: item.id,
                class: item.class,
                selected: (serviceId === item.id) && !item.isBlocked,
                disabled: item.isBlocked,
                priceType: item.priceType,
                price: item.price,
                totalPrice: item.totalPrice,
                tariff: item.tariff
            };
            const classes = {
                domId: `travel_class_${item.class}_${stepModelTrain.id}`,
                key: `${Constants.NETMATCH_INPUT_TRAIN_TRAVELCLASS}${item.class}`,
                selected: (selectedOption.class === item.class) && !item.isBlocked,
                label: `${StringUtil.formatNumberWithOrdinalSuffix(item.class)} ${getIntl().formatMessage({id: 'general.class'})}`,
                disabled: item.isBlocked,
                class: item.class
            };

            groupByPriceType[item.priceType] = (groupByPriceType[item.priceType] || []).concat(data);
            if (!groupByClassOption[classes.key]) {
                groupByClassOption[classes.key] = classes;
            }
        }
    });

    const classOption: any = [];
    Object.keys(groupByClassOption).forEach((key: string) => {
        classOption.push(groupByClassOption[key]);
    });

    const priceType: any = [];
    const count = Object.keys(groupByPriceType).length;
    Object.keys(groupByPriceType).forEach((key: string) => {
        const isSelect = groupByPriceType[key].find((value: any) => value.selected === true) || null;

        const item = {
            tariff: groupByPriceType[key][0].tariff,
            priceType: groupByPriceType[key][0].priceType,
            // elected: groupByPriceType[key].some((value:any) => value.selected === true),
            selected: isSelect !== null,
            selectedClass: isSelect?.class || 2,
            texte: texte.trainTarif[groupByPriceType[key][0].priceType],
            count: `has-${count}`,
            data: groupByPriceType[key]
        };
        priceType.push(item);
    });

    return {
        priceType,
        classOption
    };
};
