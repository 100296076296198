import ApiStepBaseView from './base';
import app from '../../../app/app';
import templateWebpack from '../../../../tpl/item/api-step/invoice.hbs';
import * as Constants from '../../../config/constants';
import {getIntl} from '../../../intl';


export default class InvoiceApiStep extends ApiStepBaseView {
    constructor (options = {}) {
        super({
            templateWebpack,
            showValidationSuccess: true,
            ...options
        });

        this.listenTo(this.model.inputs, 'change:inputValue', this.onChangeTracking);

        this.emailRepeater = null;
        this.mirroredInputIdsFromAdult = [
            Constants.NETMATCH_INPUT_INVOICE_SALUTATIONCODE,
            Constants.NETMATCH_INPUT_INVOICE_TITLE,
            Constants.NETMATCH_INPUT_INVOICE_FIRSTNAME,
            Constants.NETMATCH_INPUT_INVOICE_LASTNAME,
            Constants.NETMATCH_INPUT_INVOICE_DATEOFBIRTH
        ];
    }


    /**
     * View regions.
     * Any region defined under the key of an input id will be filled with the
     * corresponding input view when calling the 'renderApiInputsToRegions' method..
     */
    regions () {
        return {
            [Constants.NETMATCH_INPUT_INVOICE_SALUTATIONCODE]: {
                el: '.api-input-salutationcode',
                replaceElement: true
            },
            [Constants.NETMATCH_INPUT_INVOICE_TITLE]: {
                el: '.api-input-title',
                replaceElement: true
            },
            [Constants.NETMATCH_INPUT_INVOICE_FIRSTNAME]: {
                el: '.api-input-firstname',
                replaceElement: true
            },
            [Constants.NETMATCH_INPUT_INVOICE_LASTNAME]: {
                el: '.api-input-lastname',
                replaceElement: true
            },
            [Constants.NETMATCH_INPUT_INVOICE_DATEOFBIRTH]: {
                el: '.api-input-dayofbirth',
                replaceElement: true
            },
            [Constants.NETMATCH_INPUT_INVOICE_STREETANDHOUSENUMBER]: {
                el: '.api-input-streetandhousenumber',
                replaceElement: true
            },
            [Constants.NETMATCH_INPUT_INVOICE_ADDITIONALADDRESS]: {
                el: '.api-input-additionaladdress',
                replaceElement: true
            },
            [Constants.NETMATCH_INPUT_INVOICE_POSTALCODE]: {
                el: '.api-input-postalcode',
                replaceElement: true
            },
            [Constants.NETMATCH_INPUT_INVOICE_CITY]: {
                el: '.api-input-city',
                replaceElement: true
            },
            [Constants.NETMATCH_INPUT_INVOICE_COUNTRY]: {
                el: '.api-input-country',
                replaceElement: true
            },
            [Constants.NETMATCH_INPUT_INVOICE_TELEPHONE]: {
                el: '.api-input-telephone',
                replaceElement: true
            },
            [Constants.NETMATCH_INPUT_INVOICE_MOBILENUMBER]: {
                el: '.api-input-mobilenumber',
                replaceElement: true
            },
            [Constants.NETMATCH_INPUT_INVOICE_EMAIL]: {
                el: '.api-input-email',
                replaceElement: true
            },
            [Constants.NETMATCH_INPUT_INVOICE_EMAILREPEAT]: {
                el: '.api-input-email-repeat',
                replaceElement: true
            }
        };
    }

    templateContext () {
        const templateContext = super.templateContext.apply(this);

        if (templateContext.selectedId && Array.isArray(templateContext.adults)) {
            let adult;
            if (templateContext.adults.length > 1) {
                adult = app.apiSession.steps.get(templateContext.selectedId);
            } else if (templateContext.adults.length === 1) {
                adult = app.apiSession.steps.get(templateContext.adults[0]);
            } else {
                adult = null;
            }

            if (adult) {
                this.mirroredInputIdsFromAdult.forEach((inputId) => {
                    const adultInputModel = adult.inputs.get(inputId);
                    const invoiceInputModel = this.model.inputs.get(inputId);
                    invoiceInputModel.setInputValue(adultInputModel.getInputValue());
                });
            } else {
                this.mirroredInputIdsFromAdult.forEach((inputId) => {
                    const invoiceInputModel = this.model.inputs.get(inputId);
                    invoiceInputModel.setInputValue('');
                });
            }

        }
        return {
            ...templateContext,
            contentAPITexte: app.contentApi.messages.prepareMessages('personalData')
        };
    }

    /**
     * Any object defined under the key of an input id will be passed as options
     * into the input views created when calling the 'renderApiInputsToRegions' method.
     */
    apiInputViewOptions () {
        const {formatMessage} = getIntl();
        const showValidationSuccess = !!this.getOption('showValidationSuccess');
        return {
            [Constants.NETMATCH_INPUT_INVOICE_SALUTATIONCODE]: {
                showValidationSuccess,
                displayType: 'radiobuttons',
                additionalTemplateContext: {
                    // darkStyling: true,
                    label: '',
                    nothingSubmittedOption: formatMessage({
                        id: 'tpl.item.api-step.view.invoice.salutation'
                    })
                }
            },
            [Constants.NETMATCH_INPUT_INVOICE_TITLE]: {
                showValidationSuccess,
                additionalTemplateContext: {
                    label: '',
                    nothingSubmittedOption: formatMessage({
                        id: 'tpl.item.api-step.view.invoice.title'
                    }),
                    resetDummyOption: formatMessage({
                        id: 'tpl.item.api-step.view.invoice.title'
                    })
                }
            },
            [Constants.NETMATCH_INPUT_INVOICE_FIRSTNAME]: {
                showValidationSuccess,
                additionalTemplateContext: {
                    autocomplete: 'billing given-name', // autocomplete: 'on',
                    name: 'fname',
                    maxlength: 34
                }
            },
            [Constants.NETMATCH_INPUT_INVOICE_LASTNAME]: {
                showValidationSuccess,
                additionalTemplateContext: {
                    autocomplete: 'billing family-name', // autocomplete: 'on',
                    name: 'lname',
                    maxlength: 34
                }
            },
            [Constants.NETMATCH_INPUT_INVOICE_DATEOFBIRTH]: {
                displayType: 'date',
                showValidationSuccess,
                additionalTemplateContext: {
                    label: formatMessage({
                        id: 'tpl.item.api-step.view.invoice.dateofbirth'
                    }),
                    placeholderValue: formatMessage({
                        id: 'tpl.item.api-step.view.adult.dateofbirth-placeholder'
                    }),
                    maxlength: 10,
                    autocomplete: 'on',
                    name: 'bday'
                }
            },
            [Constants.NETMATCH_INPUT_INVOICE_STREETANDHOUSENUMBER]: {
                showValidationSuccess,
                additionalTemplateContext: {
                    autocomplete: 'billing address-line1', // autocomplete: 'on',
                    name: 'address'
                }
            },
            [Constants.NETMATCH_INPUT_INVOICE_ADDITIONALADDRESS]: {
                showValidationSuccess,
                additionalTemplateContext: {
                    autocomplete: 'billing address-line2', // autocomplete: 'on',
                    name: 'address'
                }
            },
            [Constants.NETMATCH_INPUT_INVOICE_POSTALCODE]: {
                showValidationSuccess,
                additionalTemplateContext: {
                    inputTypeAs: 'number', // SUPTUICMRS-2516: Firefox eliminates a zero at prefix for the input type=number
                    autocomplete: 'billing postal-code', // autocomplete: 'on',
                    name: 'zip'
                }
            },
            [Constants.NETMATCH_INPUT_INVOICE_CITY]: {
                showValidationSuccess,
                additionalTemplateContext: {
                    autocomplete: 'billing address-level2', // autocomplete: 'on',
                    name: 'city'
                }
            },
            [Constants.NETMATCH_INPUT_INVOICE_COUNTRY]: {
                showValidationSuccess,
                displayType: 'country-select',
                additionalTemplateContext: {
                    autocomplete: 'billing country',
                    name: 'country'
                }
            },
            [Constants.NETMATCH_INPUT_INVOICE_TELEPHONE]: {
                showValidationSuccess,
                additionalTemplateContext: {
                    inputType: 'tel',
                    autocomplete: 'billing tel', // autocomplete: 'on',
                    name: 'phone'
                }
            },
            [Constants.NETMATCH_INPUT_INVOICE_MOBILENUMBER]: {
                showValidationSuccess,
                additionalTemplateContext: {
                    inputType: 'tel',
                    autocomplete: 'billing tel', // autocomplete: 'on',
                    name: 'mobile'
                }
            },
            [Constants.NETMATCH_INPUT_INVOICE_EMAIL]: {
                showValidationSuccess,
                displayType: 'email',
                additionalTemplateContext: {
                    inputType: 'email',
                    autocomplete: 'billing email', // autocomplete: 'on',
                    name: 'email'
                }
            },
            [Constants.NETMATCH_INPUT_INVOICE_EMAILREPEAT]: {
                showValidationSuccess,
                displayType: 'email-repeat',
                additionalTemplateContext: {
                    autocomplete: 'billing email',
                    inputType: 'email'
                }
            }
        };
    }


    ui () {
        return {
            'invoicePersonSelect': '#invoicePersonSelector',
            'invoicePersonSelector': '.invoice-person-selector',
            'invoiceNameInputs': '.invoice-name-inputs',
            'invoiceEmailRepeat': '#input_invoice_emailrepeat'
        };
    }


    /**
     * View events
     */
    events () {
        return {
            'change @ui.invoicePersonSelect': 'onChangeInvoicePerson',
            'change .invoice-person-selector select': 'onChangeInvoicePersonTracking',
            'keypress @ui.invoiceEmailRepeat': 'emailRepeaterValidation'
        };
    }

    onRender () {
        this.renderApiInputsToRegions(this.model.inputs);
    }

    /**
     * While typing in the email repeater field each time a timeout starts, and if it has started already
     * it deletes the timer so after pressing a key it always takes 6 seconds of inactivity before the
     * field gets valided
     *
     * TUICIBE-541: change form (length > 6) and 6sek to indexOf('@') and 2sek
     */
    emailRepeaterValidation (e) {
        if (e.target.value.indexOf('@') !== -1) {
            if (this.emailRepeater) {
                clearTimeout(this.emailRepeater);
                this.emailRepeater = null;
            }
            this.emailRepeater = setTimeout(() => {
                e.target.blur();
            }, 2000);
        }
    }

    setAdultSalutation (stepId, salutation) {
        this.getUI('invoicePersonSelector').find(`option[value="${stepId}"]`).html(salutation);
    }


    onChangeInvoicePerson (e) {
        const $select = this.$(e.currentTarget);
        const selectedPerson = $select.val();
        if (!selectedPerson) {
            return;
        }
        if (selectedPerson === 'other') {
            this.mirroredInputIdsFromAdult.forEach((inputId) => {
                const invoiceInputModel = this.model.inputs.get(inputId);
                invoiceInputModel.setInputValue('');
                invoiceInputModel.triggerHideValidationError();
            });
        } else {
            const adult = app.apiSession.steps.get(selectedPerson);
            this.mirroredInputIdsFromAdult.forEach((inputId) => {
                const adultInputModel = adult.inputs.get(inputId);
                const invoiceInputModel = this.model.inputs.get(inputId);
                invoiceInputModel.setInputValue(adultInputModel.getInputValue());
            });
        }
        this.getUI('invoiceNameInputs').toggleClass('is-hidden', !!selectedPerson.match(new RegExp(Constants.NETMATCH_STEP_ADULT)));
        this.triggerMethod('invoice:change:person', selectedPerson);
    }

    onChangeTracking (inputModelChange) {
        const translate = {
            [Constants.NETMATCH_INPUT_INVOICE_STREETANDHOUSENUMBER]: 'Strasse',
            [Constants.NETMATCH_INPUT_INVOICE_ADDITIONALADDRESS]: 'Adresszusatz',
            [Constants.NETMATCH_INPUT_INVOICE_POSTALCODE]: 'PLZ',
            [Constants.NETMATCH_INPUT_INVOICE_CITY]: 'Ort',
            [Constants.NETMATCH_INPUT_INVOICE_COUNTRY]: 'Land',
            [Constants.NETMATCH_INPUT_INVOICE_TELEPHONE]: 'Telefon',
            [Constants.NETMATCH_INPUT_INVOICE_MOBILENUMBER]: 'Mobil',
            [Constants.NETMATCH_INPUT_INVOICE_EMAIL]: 'Mail',
            [Constants.NETMATCH_INPUT_INVOICE_EMAILREPEAT]: 'Mail-recheck'
        };

        if (translate[inputModelChange.id]) {
            this.trackEvent(translate[inputModelChange.id]);
        }
    }

    trackEvent (label) {
        app.trackController.eventTracking({
            action: 'Daten',
            label,
            ga4clickLabel: `content.custom.daten-${label}`
        });
    }
}
