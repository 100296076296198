import React, {useMemo} from 'react';
import * as Constants from '../../config/constants';
import app from '../../app/app';
import {FormattedMessage} from 'react-intl';
import {TUIC_B2B_AGENCY_COOKIE_NAME} from '../../config/constants';


function parseCookies (cookieString: string): { [key: string]: string } {
    return cookieString
        .split('; ')
        .map(cookie => cookie.split('='))
        .reduce((acc, [key, value]) => {
            acc[key] = decodeURIComponent(value);
            return acc;
        }, {} as { [key: string]: string });
}


export default function Error503View () {
    const ibeStatus = app?.ibeController?.ibeStatus;

    let errorType: string;
    switch (true) {
        case (ibeStatus?.restoreBooking === true):
            errorType = '503-restoreBooking';
            break;
        case (ibeStatus?.netMatchApi === false):
            errorType = '503-ibe';
            break;
        case (ibeStatus?.ensureTripCode === false):
            errorType = '503-data';
            break;
        case (ibeStatus?.contentApi === false):
            errorType = '503-content';
            break;
        default:
            errorType = '503-other';
            break;
    }
    let label = '';

    const storage: any = app?.ibeController?.ibeStorage;
    const initParamas = storage?.get(Constants.INITIAL_PARAMETER);
    if (initParamas) {
        label = `${initParamas.tripCode}_${initParamas.ePackageCode}`;
    }

    if (app && app.trackController && errorType !== '503-other') {
        app.trackController.eventTracking({
            error: errorType,
            path: window.location.pathname,
            label,
            ga4eventType: '503'
        });
    }

    const appBacklink = app?.ibeController?.appBackLink();
    const restoreBooking = ibeStatus?.restoreBooking;


    const allParams = storage ? storage.get(Constants.ALL_QUERY_PARAMETER) : '';

    const hasAgencyNumber = useMemo(() => {
        const cookies = parseCookies(document.cookie);
        return Object.entries(cookies).some(([key, value]) => key === TUIC_B2B_AGENCY_COOKIE_NAME && !!value);
    }, []);

    const fallbackLink = useMemo(() => {
        if (allParams && !hasAgencyNumber) {
            return `${window.location.host.includes('test') ? 'https://next.aws.test.meinschiff.com/de/ibe-fallback' : 'https://meinschiff.com/de/ibe-fallback'}?${allParams}`;
        }
        return '';
    }, [allParams, hasAgencyNumber]);

    return (
        <div className="container container-with-padding">
            <div className="row">
                <div className="col-lg-12">
                    <h1 className="headline"><FormattedMessage id="components.error.503.headline"/></h1>
                    {restoreBooking ?
                        <>
                            <div className="text">
                                <FormattedMessage id="components.error.503.text1"/>
                            </div>
                            <div className="button-wrapper">
                                <a className="button" href={appBacklink} rel="noopener noreferrer">
                                    <span>
                                        <FormattedMessage id="components.error.503.button1"/>
                                    </span>
                                    <span className="icon-small-arrow-right"/>
                                </a>
                            </div>
                        </>
                        :
                        <>
                            <div className="text">
                                <FormattedMessage id={fallbackLink ? 'components.error.503.text2' : 'components.error.503.text3'}/>
                            </div>
                            {fallbackLink && <div className="button-wrapper">
                                <a className="button" href={fallbackLink} rel="noopener noreferrer"><span><FormattedMessage id="components.error.503.button2"/></span><span className="icon-small-arrow-right"></span></a>
                            </div>}
                            <div className="button-wrapper">
                                <a className={`button ${fallbackLink && 'button-light'}`} href={appBacklink} rel="noopener noreferrer"><span><FormattedMessage id="components.error.503.button3"/></span><span className="icon-small-arrow-right"></span></a>
                            </div>
                        </>}
                </div>
            </div>
        </div>
    );

}
