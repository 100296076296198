import React from 'react';
import { FormattedMessage } from 'react-intl';
import app from '../../app/app';
import NavigationUtil from '../../util/navigation';

export interface DeckPlanLegendeType {
    iconURL: string,
    name: string
};

export default function LegendeView (props:{deckplanLegend:Array<DeckPlanLegendeType>}) {
    const { deckplanLegend } = props;
    const contentAPITexte:{cabinSelected?:string, cabinAvailable?:string} = (app.contentApi && app.contentApi.messages) ? app.contentApi.messages.prepareMessages('desiredCabin') : '';

    return (
        <div className="legend-info">
            <div className="legend">
                <h3 className="headline deck-legend-headline">
                    <FormattedMessage id="components.cabinnumber.legende.headline" />
                </h3>
                <ul className="deck-legend-list">
                    {contentAPITexte.cabinSelected ? <li><svg width="54" height="18"><use xlinkHref="#icon-key-choosen-cabin"></use></svg> {contentAPITexte.cabinSelected}</li> : ''}
                    {contentAPITexte.cabinAvailable ? <li><svg width="54" height="18"><use xlinkHref="#icon-key-available-cabin"></use></svg> {contentAPITexte.cabinAvailable}</li> : ''}

                    {deckplanLegend.map((item:DeckPlanLegendeType, key:number) => {
                        return (
                            <li key={key}> <img alt={item.name} src={item.iconURL} width="18" height="18"/>{item.name}</li>
                        );
                    })}
                </ul>
            </div>
            <div className="info">
                <h3 className="headline deck-legend-headline">
                    <FormattedMessage id="components.cabinnumber.legende.informations" />
                </h3>
                <div className="deckplan-use-hint">
                    <img alt="Hinweis" src={`${NavigationUtil.normalizePath('images/ic-deckplanhinweis-mouse.png')}`} width="48" height="48"/>
                    <div className="text">
                        <FormattedMessage id="components.cabinnumber.legende.informations-hint" />
                    </div>
                </div>
            </div>
        </div>
    );
}
