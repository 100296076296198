var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"contentAPITexte") : depth0)) != null ? lookupProperty(stack1,"overlayInvoiceAddressB2b") : stack1)) != null ? lookupProperty(stack1,"text") : stack1), depth0))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"contentAPITexte") : depth0)) != null ? lookupProperty(stack1,"overlayInvoiceAddress") : stack1)) != null ? lookupProperty(stack1,"text") : stack1), depth0))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <a class=\"action-link\" href=\"tel:"
    + alias3((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||alias2).call(alias1,"VIEW_B2B_SERVICE_NUMBER_DISPLAY_CTA",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":20,"column":57},"end":{"line":20,"column":111}}}))
    + "\">"
    + alias3((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||alias2).call(alias1,"VIEW_B2B_SERVICE_NUMBER_DISPLAY_INT",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":20,"column":113},"end":{"line":20,"column":167}}}))
    + "</a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <a class=\"action-link\" href=\"tel:"
    + alias3((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||alias2).call(alias1,"VIEW_SERVICE_NUMBER_DISPLAY_CTA",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":22,"column":57},"end":{"line":22,"column":107}}}))
    + "\">"
    + alias3((lookupProperty(helpers,"getConstants")||(depth0 && lookupProperty(depth0,"getConstants"))||alias2).call(alias1,"VIEW_SERVICE_NUMBER_DISPLAY_INT",{"name":"getConstants","hash":{},"data":data,"loc":{"start":{"line":22,"column":109},"end":{"line":22,"column":159}}}))
    + "</a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "                        <a class=\"action-link\" href=\"mailto:reservierung@tuicruises.com\">reservierung@tuicruises.com</a>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                        <a class=\"action-link\" href=\"mailto:info@tuicruises.com\">info@tuicruises.com</a>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"overlay-close-wrapper-bottom\">\n                    <a class=\"button button-secondary js-close\"><span class=\"icon-arrow-left\"></span> "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"closeButtonText") || (depth0 != null ? lookupProperty(depth0,"closeButtonText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"closeButtonText","hash":{},"data":data,"loc":{"start":{"line":37,"column":102},"end":{"line":37,"column":121}}}) : helper)))
    + "</a>\n                </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <div class=\"col-lg-8\">\n\n        <div class=\"overlay overlay-payment-invoice\">\n\n            <a class=\"js-close overlay-close\"></a>\n\n            <h3 class=\"overlay-headline\">"
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"tpl.item.overlay.payment-invoice.headline",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":8,"column":41},"end":{"line":8,"column":106}}})) != null ? stack1 : "")
    + "</h3>\n\n            <div class=\"row bordered-row\">\n                <div class=\"col-sm-6\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isB2B") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":12,"column":20},"end":{"line":16,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n                <div class=\"col-sm-6\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isB2B") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":19,"column":20},"end":{"line":23,"column":27}}})) != null ? stack1 : "")
    + "                    <p class=\"action-link-details\">\n                        "
    + ((stack1 = (lookupProperty(helpers,"formatMessage")||(depth0 && lookupProperty(depth0,"formatMessage"))||alias2).call(alias1,"general.opening-hours",{"name":"formatMessage","hash":{},"data":data,"loc":{"start":{"line":25,"column":24},"end":{"line":25,"column":69}}})) != null ? stack1 : "")
    + "\n                    </p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isB2B") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":27,"column":20},"end":{"line":31,"column":27}}})) != null ? stack1 : "")
    + "                </div>\n            </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"closeButtonText") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":12},"end":{"line":39,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n";
},"useData":true});