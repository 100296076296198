import BookingPage from '../../booking-page/base/page';
import PrintOfferPageContentView from './content';


export default class PrintOfferPage extends BookingPage {
    constructor (options = {}) {
        super({
            key: 'print-offer',
            className: 'print-page print-page-print-offer',
            ...options
        });
    }


    get contentViewClass () {
        return PrintOfferPageContentView;
    }


    get appLayoutType () {
        return 'print';
    }


    get pageLayoutType () {
        return 'print';
    }
}
