import Base from '../../../base/collection/base';
import ApiInputValidatorModel from '../model/validator';


export default class ApiInputValidatorsCollection extends Base {
    /**
     * Getter fot the view model
     */
    get model () {
        return ApiInputValidatorModel;
    }
}
