import React, { useState, useRef, useEffect } from 'react';
import CabinImageGallery from './image-gallery-js';

export default function CabinImageView (props:{data:any}) {
    const { data } = props;
    const [isIntersecting, setIntersecting] = useState();
    const observerSupport = !!window.IntersectionObserver;

    const itemsRef:any = useRef();
    // State and setter for storing whether element is visible
    useEffect(() => {
        try {
            const observer = new IntersectionObserver((entries:any) => {
                entries.forEach((entry:any) => {
                    if (entry.isIntersecting === true) {
                        setIntersecting(entry.target.dataset.cabinId);
                        observer.unobserve(entry.target);
                    }
                });
            });
            if (itemsRef) {
                observer.observe(itemsRef.current);
            }
        } catch (e2) {
        }
    }, [data]);

    return (
        <div className="cabin-images">
            {observerSupport ?
                <div ref={itemsRef} className={`region-image-gallery-${data.id}`} data-cabin-id={data.id} data-cabin-type={data.id}>
                    {(isIntersecting === data.id) ? <CabinImageGallery data={data}></CabinImageGallery> : ''}
                </div>
                :
                <div className={`region-image-gallery-${data.id}`} data-cabin-id={data.id} data-cabin-type={data.id}>
                    <CabinImageGallery data={data}></CabinImageGallery>
                </div>
            }
        </div>
    );
};
