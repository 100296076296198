import BaseOverlayView from './base';
import templateWebpack from '../../../../tpl/item/overlay/travel-flight.hbs';


export default class travelFlightOverlayView extends BaseOverlayView {
    constructor (options = {}) {
        super({
            key: 'overlay-travel-flight',
            templateWebpack,
            ...options
        });
    }
}
