import BaseItem from '../../../base/view/item';
import templateWebpack from '../../../../tpl/item/confirm-trustpilot/layout.hbs';


export default class ConfirmTrustpilotView extends BaseItem {
    constructor (options = {}) {
        super({
            key: 'confirm-trustpilot',
            templateWebpack,
            className: 'confirm-trustpilot',
            ...options
        });
    }

    /*
    // TUICUNIT-3332: deprecated
    onRender () {
        // sonst läde dieses scheiß Trustpilot ding nicht immer
        $('.js-confirm-rate-element').appendTo(this.$('.js-confirm-rate-wrapper'));
        $('.js-confirm-rate-element').remove();
    } */
}
