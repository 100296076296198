import Base from '../../../base/model/base';
import app from '../../../app/app';
import ApiValidatorsCollection from '../collection/validators';
import fecha from '../../../util/fecha-german';
import * as Constants from '../../../config/constants';


export default class ApiInputModel extends Base {
    /**
     * @see: http://backbonejs.org/#Model-idAttribute
     */
    get idAttribute () {
        return 'id';
    }


    /**
     * Getter for the step to which this input belongs to
     */
    get apiStep () {
        return this.collection.parentModel;
    }


    /**
     * Getter for the id of the step to which this input belongs to
     */
    get apiStepId () {
        return this.apiStep.id;
    }


    /**
     * Returns a combined id from the input id and the step id
     */
    get domId () {
        return `input_${this.apiStep.sanitizedId}_${this.sanitizedId}`;
    }


    /**
     * Check if this input has a "required" validator
     */
    requiresInput () {
        return this.validators.some(validatorModel => validatorModel.get('type') === Constants.NETMATCH_VALIDATOR_TYPE_REQUIRED);
    }


    /**
     * Setter for the input value
     */
    setInputValue (value, options) {
        return this.set('inputValue', value, options);
    }


    /**
     * Getter for the input value
     */
    getInputValue () {
        return this.get('inputValue');
    }

    /**
     * Getter for the input value
     */
    getPreviouslySubmitted () {
        return this.get('previouslySubmitted');
    }


    /**
     * resets the inputValue to the resultValue
     */
    resetInputValue (options) {
        return this.setInputValue(this.get('resultValue'), options);
    }


    /**
     *  Checks if the model value of the input changed
     */
    hasChangedInput () {
        return this.getInputValue() !== this.get('resultValue');
    }


    /**
     * Check whether the input has a value set
     * @returns {boolean}
     */
    hasValue () {
        const value = this.getInputValue();
        return value !== null && value !== '';
    }


    /**
     * Check whether the input has errors (either input or submit errors)
     */
    hasError () {
        if (this.hasChangedInput()) {
            return this.hasInputError();
        }
        return this.hasSubmitError();
    }


    /**
     * Returns any errors (either input or submit errors)
     */
    getError () {
        if (this.hasChangedInput()) {
            return this.getInputError();
        }
        if (this.apiStep.get('hasSubmits') && this.apiStep.isOpen()) {
            return this.getSubmitError();
        }
        return null;
    }


    /**
     * Check whether the input has submit errors
     */
    hasSubmitError () {
        if (this.apiStep.get('hasSubmits') && this.apiStep.isOpen()) {
            return this.validators.some(validatorModel => validatorModel.get('isValid') === false);
        }
        return false;
    }


    /**
     * Returns any submit errors
     */
    getSubmitError () {
        if (!this.apiStep.get('hasSubmits') || !this.apiStep.isOpen()) {
            return null;
        }
        const invalidValidatorModel = this.validators.find((validatorModel) => validatorModel.get('isValid') === false);
        if (!invalidValidatorModel) {
            return null;
        }
        return invalidValidatorModel.get('warning');
    }


    /**
     * Check whether the input has input errors
     */
    hasInputError () {
        return !this.validateValue(this.getInputValue()).valid;
    }


    /**
     * Returns any input errors
     */
    getInputError () {
        return this.validateValue(this.getInputValue()).error;
    }


    /**
     * Trigger an event that the view can listen to in order to hide validation errors
     */
    triggerHideValidationError () {
        this.trigger('hide:validation:error');
    }


    /**
     * Validates the given value by looping through the validators
     */
    validateValue (value) {
        let result = {
            valid: true
        };
        const failedValidator = this.validators.find(validator => validator.validateValue(value) !== true);
        if (failedValidator) {
            result = {
                valid: false,
                // error: failedValidator.get('warning'), correct hendling
                // TODO Work'a'round : remove if API correct
                error: (failedValidator.get('warning')) ? (failedValidator.get('warning')) : ('API warning Text is missing')
            };
        }
        return result;
    }

    /**
     * Retrieves the options array as a whole
     *
     * @returns {*|Array}
     */
    getOptions () {
        return this.get('options') || [];
    }

    /**
     * Retrieves the option that matches the given optionId
     */
    getOptionById (optionId) {
        const options = this.get('options') || [];
        return options.find(option => option.id === optionId);
    }


    /**
     * Retrieves the option that matches the current input value
     */
    getSelectedOption () {
        return this.getOptionById(this.getInputValue());
    }


    /**
     * @see: http://backbonejs.org/#Model-parse
     */
    parse (data) {

        // WATCH OUT
        // the parse method will be called before (!!) the constructor because
        // attributes will be passed to this model.
        // So we need to create the validators collection here when used first
        if (!this.validators) {
            this.validators = new ApiValidatorsCollection([], {
                parentModel: this
            });
        }

        const validatorsData = data.validators || [];
        this.validators.set(validatorsData, {
            parse: true,
            parentModel: this
        });
        // delete data.validators;

        // TUICIBE-425: handle static input options
        if (data.staticOptions && app.apiSession.staticInputOptions) {
            const staticOptionsModel = app.apiSession.staticInputOptions.get(data.staticOptions);
            const staticOptions = staticOptionsModel ? staticOptionsModel.get('options') : null;
            data.options = staticOptions || data.options || [];
        }

        // case INVOICE_COUNTRY Select: if defaultValue but not resultValue and not previouslySubmitted then set resultValue as defaultValue
        // so the defaultValue is selected in Seclect-View
        if (data.id === Constants.NETMATCH_INPUT_INVOICE_COUNTRY && data.defaultValue && !data.resultValue) {
            if (!data.previouslySubmitted) {
                data.resultValue = data.defaultValue;
            }
        }

        // inject rel="noopener noreferrer" in Netmatch Label href-links
        if (data.id === Constants.NETMATCH_INPUT_APPROVAL_OF_TERMS || data.id === Constants.NETMATCH_INPUT_SUBSCRIBE_TO_NEWSLETTER) {
            if (data.label.indexOf('noopener noreferr') === -1) {
                data.label = data.label.split('href').join('rel="noopener noreferrer" href');
            }
        }

        // in case: after submit, api says the data are not valid, so the step ist OPEN and previouslySubmitted has data
        if (data.stepStatus === Constants.NETMATCH_STEP_STATUS_OPEN && data.previouslySubmitted) {
            data.resultValue = data.resultValue || data.previouslySubmitted;
        }

        // the "calculation" (all next line) of the resultValue once it is delivered for all inputs by the API
        if (data.stepStatus === Constants.NETMATCH_STEP_STATUS_COMPLETED_WITH_DEFAULT) {
            data.resultValue = data.resultValue || data.defaultValue;
        } else if (data.stepStatus === Constants.NETMATCH_STEP_STATUS_COMPLETED_AUTOMATICALLY) {
            data.resultValue = data.resultValue || data.defaultValue || data.previouslySubmitted;
        } else if (data.stepStatus === Constants.NETMATCH_STEP_STATUS_COMPLETED) {
            data.resultValue = data.resultValue || data.previouslySubmitted;
        }

        data.inputValue = data.resultValue;

        // Work'a'Round: Convert Date Format z.B. "1/1/1970 12:00:00 AM" or "1970-01-01" to "01.01.1970"
        if (data.id === Constants.NETMATCH_INPUT_ADULT_DATEOFBIRTH && data.resultValue) {
            try {
                // TODO: I expected the resultValue date to be in odata format, but it isn't
                // next line does the odata conversion if needed
                const germanDate = fecha.format(new Date(data.resultValue), 'germanShortDate');
                data.resultValue = germanDate;
                data.inputValue = germanDate;
                data.previouslySubmitted = germanDate;
            } catch (e) {
                // nothing to do here
            }
        }
        // console.log(data);

        // TODO API: this should not be necessary!
        if (data.type === 'boolean' && ((typeof data.resultValue) === 'string')) {
            // all to lowercase because we have now 'true' and 'True'
            data.resultValue = data.resultValue.toLowerCase();
            if (data.previouslySubmitted) {
                data.previouslySubmitted = data.previouslySubmitted.toLowerCase();
            }
            data.defaultValue = data.defaultValue.toLowerCase();

            data.resultValue = data.resultValue === 'true';
            data.inputValue = data.resultValue === 'true' || data.resultValue;
            data.previouslySubmitted = data.previouslySubmitted === 'true';
            data.defaultValue = data.defaultValue === 'true' || data.resultValue;
        }
        // console.log(data);

        return data;
    }
}
