import app from './app/app';

import BaseRouter from './base/router/base';


export default class Router extends BaseRouter {
    requestPage (routeKey) {
        if (!app.ibeController.requestPage(routeKey)) {
            if (routeKey !== 'error404') {
                this.requestPage('error404');
            }
        }
    }
}

