import BaseModel from '../../../base/model/base';
import contentApiConfig from '../../../config/content-api';

export default class AirportsModel extends BaseModel {
    prepareUrlAndFetch () {
        this.url = contentApiConfig.airportsURL;
        this.url = contentApiConfig.i18nUrlAirports();

        return this.fetch();
    }

    parse (response) {
        return Object.entries(response).reduce((prev, [key, curr]) => ({
            ...prev,
            [key]: {
                iata: curr.iata,
                name: curr.name
            }
        }), {});
    }
}

/*
@TODO: Remove this later
NEW:
{
    "BCN": {
        "iata": "BCN",
        "country": null,
        "name": "Barcelona"
    }
}
OLD:
{
    "SXF": {
        "country": "Deutschland",
        "iata": "SXF",
        "name": "Berlin Schönefeld"
    },
    "MLA": {
        "country": "Malta",
        "iata": "MLA",
        "name": "Malta"
    },
    "DRS": {
        "country": "Deutschland",
        "iata": "DRS",
        "name": "Dresden"
    },
    "LRM": {
        "country": "Dominikanische Republik",
        "iata": "LRM",
        "name": "La Romana"
    },
    "CPT": {
        "country": "Südafrika",
        "iata": "CPT",
        "name": "Kapstadt"
    },
    "CLT": {
        "country": "Vereinigte Staaten von Amerika",
        "iata": "CLT",
        "name": "Charlotte Douglas International Airport"
    },
    "MHG": {
        "country": "Deutschland",
        "iata": "MHG",
        "name": "Mannheim"
    },
    "JFK": {
        "country": "Vereinigte Staaten von Amerika",
        "iata": "JFK",
        "name": "New York JFK (USA)"
    },
    "STR": {
        "country": "Deutschland",
        "iata": "STR",
        "name": "Stuttgart"
    },
    "DWC": {
        "country": "Vereinigte Arabische Emirate",
        "iata": "DWC",
        "name": "Dubai (DWC)"
    },
    "ZRH": {
        "country": "Schweiz",
        "iata": "ZRH",
        "name": "Zürich"
    },
    "AUH": {
        "country": "Vereinigte Arabische Emirate",
        "iata": "AUH",
        "name": "Abu Dhabi"
    },
    "SHJ": {
        "country": "Vereinigte Arabische Emirate",
        "iata": "SHJ",
        "name": "Sharjah (Dubai)"
    },
    "TRS": {
        "country": "Italien",
        "iata": "TRS",
        "name": "Triest"
    },
    "LJU": {
        "country": "Slowenien",
        "iata": "LJU",
        "name": "Ljubljana"
    },
    "BSL": {
        "country": "Schweiz",
        "iata": "BSL",
        "name": "Basel"
    },
    "MUC": {
        "country": "Deutschland",
        "iata": "MUC",
        "name": "München"
    },
    "FKB": {
        "country": "Deutschland",
        "iata": "FKB",
        "name": "Flughafen Karlsruhe/Baden-Baden"
    },
    "DOH": {
        "country": "Katar",
        "iata": "DOH",
        "name": "Doha"
    },
    "AYT": {
        "country": "Türkei",
        "iata": "AYT",
        "name": "Antalya"
    },
    "TFS": {
        "country": "Spanien",
        "iata": "TFS",
        "name": "Teneriffa, Süd"
    },
    "SDQ": {
        "country": "Dominikanische Republik",
        "iata": "SDQ",
        "name": "Santo Domingo"
    },
    "BGI": {
        "country": "Barbados",
        "iata": "BGI",
        "name": "Barbados"
    },
    "HKG": {
        "country": "China, Volksrepublik",
        "iata": "HKG",
        "name": "Hongkong"
    },
    "AMS": {
        "country": "Niederlande",
        "iata": "AMS",
        "name": "Amsterdam"
    },
    "DXB": {
        "country": "Vereinigte Arabische Emirate",
        "iata": "DXB",
        "name": "Dubai (DXB)"
    },
    "NCE": {
        "country": "Frankreich",
        "iata": "NCE",
        "name": "Nizza Airport"
    },
    "GVA": {
        "country": "Schweiz",
        "iata": "GVA",
        "name": "Geneva"
    },
    "ERF": {
        "country": "Deutschland",
        "iata": "ERF",
        "name": "Erfurt"
    },
    "VCE": {
        "country": "Italien",
        "iata": "VCE",
        "name": "Venedig"
    },
    "SIN": {
        "country": "Singapur",
        "iata": "SIN",
        "name": "Singapur"
    },
    "RKT": {
        "country": "Vereinigte Arabische Emirate",
        "iata": "RKT",
        "name": "Ras Al Khaimah"
    },
    "DTM": {
        "country": "Deutschland",
        "iata": "DTM",
        "name": "Flughafen Dortmund"
    },
    "LPA": {
        "country": "Spanien",
        "iata": "LPA",
        "name": "Las Palmas"
    },
    "SZG": {
        "country": "Österreich",
        "iata": "SZG",
        "name": "Salzburg Airport W. A. Mozart"
    },
    "DLM": {
        "country": "Türkei",
        "iata": "DLM",
        "name": "Dalaman"
    },
    "TXL": {
        "country": "Deutschland",
        "iata": "TXL",
        "name": "Berlin Tegel"
    },
    "FDH": {
        "country": "Deutschland",
        "iata": "FDH",
        "name": "Friedrichshafen Flug."
    },
    "GRZ": {
        "country": "Österreich",
        "iata": "GRZ",
        "name": "Graz"
    },
    "MRS": {
        "country": "Frankreich",
        "iata": "MRS",
        "name": "Aéroport Marseille Provence"
    },
    "MBJ": {
        "country": "Jamaika",
        "iata": "MBJ",
        "name": "Montego Bay"
    },
    "GOA": {
        "country": "Italien",
        "iata": "GOA",
        "name": "Genua"
    },
    "NYC": {
        "country": "Vereinigte Staaten von Amerika",
        "iata": "NYC",
        "name": "New York"
    },
    "KSF": {
        "country": "Deutschland",
        "iata": "KSF",
        "name": "Kassel-Calden"
    },
    "NUE": {
        "country": "Deutschland",
        "iata": "NUE",
        "name": "Nürnberg"
    },
    "EWR": {
        "country": "Vereinigte Staaten von Amerika",
        "iata": "EWR",
        "name": "Newark EWR (USA)"
    },
    "DUS": {
        "country": "Deutschland",
        "iata": "DUS",
        "name": "Düsseldorf"
    },
    "SWF": {
        "country": "Vereinigte Staaten von Amerika",
        "iata": "SWF",
        "name": "New York SWF(USA)"
    },
    "PUJ": {
        "country": "Dominikanische Republik",
        "iata": "PUJ",
        "name": "Punta Cana"
    },
    "GWT": {
        "country": "Deutschland",
        "iata": "GWT",
        "name": "Sylt/Westerland"
    },
    "FMO": {
        "country": "Deutschland",
        "iata": "FMO",
        "name": "Münster/Osnabrück"
    },
    "AGP": {
        "country": "Spanien",
        "iata": "AGP",
        "name": "Malaga"
    },
    "CGN": {
        "country": "Deutschland",
        "iata": "CGN",
        "name": "Köln/Bonn"
    },
    "IST": {
        "country": "Türkei",
        "iata": "IST",
        "name": "Istanbul"
    },
    "PMI": {
        "country": "Spanien",
        "iata": "PMI",
        "name": "Palma de Mallorca"
    },
    "BER": {
        "country": "Deutschland",
        "iata": "BER",
        "name": "Berlin Brandenburg"
    },
    "LUX": {
        "country": "Luxemburg",
        "iata": "LUX",
        "name": "Luxemburg"
    },
    "HAJ": {
        "country": "Deutschland",
        "iata": "HAJ",
        "name": "Hannover"
    },
    "LEJ": {
        "country": "Deutschland",
        "iata": "LEJ",
        "name": "Leipzig"
    },
    "CTA": {
        "country": "Italien",
        "iata": "CTA",
        "name": "Flughafen Catania-Fontanarossa"
    },
    "PAD": {
        "country": "Deutschland",
        "iata": "PAD",
        "name": "Paderborn"
    },
    "VIE": {
        "country": "Österreich",
        "iata": "VIE",
        "name": "Wien"
    },
    "BRE": {
        "country": "Deutschland",
        "iata": "BRE",
        "name": "Bremen"
    },
    "FRA": {
        "country": "Deutschland",
        "iata": "FRA",
        "name": "Frankfurt am Main"
    },
    "HER": {
        "country": "Griechenland",
        "iata": "HER",
        "name": "Heraklion"
    },
    "HAM": {
        "country": "Deutschland",
        "iata": "HAM",
        "name": "Hamburg"
    },
    "ATH": {
        "country": "Griechenland",
        "iata": "ATH",
        "name": "Athens"
    },
    "LIS": {
        "country": "Portugal",
        "iata": "LIS",
        "name": "Lissabon"
    },
    "FNC": {
        "country": "Portugal",
        "iata": "FNC",
        "name": "Funchal"
    },
    "SCN": {
        "country": "Deutschland",
        "iata": "SCN",
        "name": "Saarbrücken"
    }
}
*/
