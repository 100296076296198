import BaseOverlayView from './base';
import templateWebpack from '../../../../tpl/item/overlay/payment-iban.hbs';


export default class PaymentIbanOverlayView extends BaseOverlayView {
    constructor (options = {}) {
        super({
            key: 'overlay-payment-iban',
            templateWebpack,
            ...options
        });
    }
}
