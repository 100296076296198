import ApiInputBaseView from './base';
import templateWebpack from '../../../../tpl/item/api-input/iban.hbs';
import IbanModel from '../../../entities/netmatch-api/model/iban';


export default class ApiInputIbanView extends ApiInputBaseView {
    constructor (options) {
        super({
            templateWebpack,
            ...options
        });
    }

    /**
     * View events triggered by the user
     */

    events () {
        const superEvents = super.events.apply(this);
        return {
            ...superEvents,
            'change .js-iban': 'onValueChangeIban'
        };
    }

    onValueChangeIban () {
        const $element = this.$('.js-iban');
        const value = $element.val();
        if (value && !this.model.hasInputError()) {
            const ibanModel = new IbanModel();
            const checkResult = ibanModel.getBankInformation(value);
            checkResult.then((response) => {
                this.triggerMethod('payment:sepa:from:iban', response);
            }).catch(() => {
                this.triggerMethod('payment:sepa:from:iban:error');
            });
        } else {
            this.triggerMethod('payment:sepa:from:iban:input:error');
        }
    }
}
